import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import Table from "./core/Table.jsx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MedicalInfoCol from "../MedicalInfoCol";
import { useUser } from "../../providers/UserProvider.jsx";
import DocumentViewer from "../boarding/DocumentViewer.jsx";
import { useState } from "react";
import { parseFullName } from "../../helpers/helpers.js";
import { IconStethoscope } from "@tabler/icons-react";

export default function MedicalTable({ fetching, shouldFetch, setShouldFetch, teams }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const [viewerOpened, setViewerOpened] = useState(false);
  const [viewingDocument, setViewingDocument] = useState("");

  const childTeam = (child) => {
    return (
      <Text span color={!child?.team?.number && "red"}>
        {child?.team?.number ? child.team.number : "Nepřidělen"}
      </Text>
    );
  };

  const actionButtons = (child) => {
    return (
      <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Akreditace">
        <ActionIcon variant="light" component={Link} to={`/akreditace/zdravotnik?child=${child.id}`}>
          <IconStethoscope size={18} stroke={1.5} />
        </ActionIcon>
      </Tooltip>
    );
  };

  const columns = [
    {
      accessor: "ucastnik_prijmeni",
      title: "Jméno",
      disableHiding: true,
      render: (child) => (
        <Text fw="bold" className="capitalize">
          {parseFullName(child)}
        </Text>
      ),
      sortable: true,
    },
    {
      accessor: "ucastnik_date_narozeni",
      title: "Věk",
      sortable: true,
      visibleMediaQuery: (theme) => `(min-width: 600px)`,
      render: (x) => x.age,
    },
    {
      accessor: "oddil",
      title: "Oddíl",
      sortable: true,
      render: (child) => childTeam(child),
    },
    {
      accessor: "omezeni",
      title: "Zdravotní omezení",
      render: (child) => (
        <Text color={!child.medicalInfo.medical_restrictions && "dimmed"}>
          {child.medicalInfo.medical_restrictions ? child.medicalInfo.medical_restrictions : "–"}
        </Text>
      ),
    },
    {
      accessor: "alergie",
      title: "Alergie",
      render: (child) => (
        <Text color={!child.medicalInfo.allergies && "dimmed"}>
          {child.medicalInfo.allergies ? child.medicalInfo.allergies : "–"}
        </Text>
      ),
    },
    {
      accessor: "stravovani",
      title: "Stravování",
      render: (child) => (
        <Text color={!child.medicalInfo.dietary_restrictions && "dimmed"}>
          {child.medicalInfo.dietary_restrictions ? child.medicalInfo.dietary_restrictions : "–"}
        </Text>
      ),
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (child) => actionButtons(child),
    },
    /*
    {
      accessor: "zdravi",
      title: "Zdraví",
      render: (child) => {
        return <MedicalInfoCol child={child} />;
      },
      visibleMediaQuery: (theme) => `(min-width: 420px)`,
      sortable: true,
      firstSort: "desc",
    }, ,
    ...(!user.permissions.includes("children.child.update")
      ? []
      : [
          {
            accessor: "actions",
            title: "Akce",
            disableHiding: true,
            hidden: !user.permissions.includes("children.child.update"),
            render: (child) => actionButtons(child),
            narrow: true,
          },
        ]),*/
  ];

  const filterConfig = [
    {
      key: "jmeno",
      label: "Jméno",
      type: "search",
    },
    {
      key: "oddil",
      label: "Oddíl",
      type: "select",
      options: teams?.map((team) => {
        return { value: String(team.id), label: `${team.name} ${team.number}` };
      }),
    },
    /* {
      key: "pohlavi",
      label: "Pohlaví",
      type: "select",
      options: [
        { value: "Chlapec", label: "Chlapec" },
        { value: "Dívka", label: "Dívka" },
      ],
    }, */
  ];

  return (
    <>
      <Table
        id="zdravotnik-zdravi"
        columns={columns}
        useServer={true}
        serverUrl={"/children"}
        shouldServerFetch={shouldFetch}
        setShouldServerFetch={setShouldFetch}
        fetching={fetching}
        filterConfig={filterConfig}
      />
      {viewerOpened && <DocumentViewer document={viewingDocument} setOpened={setViewerOpened} />}
    </>
  );
}
