import { ActionIcon, Code, Collapse, Group, Text, Tooltip, useMantineTheme } from "@mantine/core";
import { CodeHighlight } from "@mantine/code-highlight";
import { IconCake, IconCalendarEvent, IconChevronDown, IconDoorExit, IconNote } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import Table from "./core/Table.jsx";
import { useNavigate } from "react-router-dom";
import styles from "../../pages.module.scss";
import classNames from "classnames";
import { getMode, parseFullName } from "../../helpers/helpers.js";
import dayjs from "dayjs";

export default function LogsTable({ fetching, shouldFetch, setShouldFetch }) {
  const navigate = useNavigate();
  const [openLogId, setOpenLogId] = useState(null);

  const expandLog = (log) => {
    if (openLogId === log.id) return setOpenLogId(0);
    setOpenLogId(log.id);
  };

  const actionButtons = (log) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Rozbalit informace">
          <ActionIcon
            variant="light"
            className={classNames(styles.Expander, openLogId === log.id ? styles.Expanded : "")}>
            <IconChevronDown stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const { mode, theme } = getMode();

  const columns = [
    {
      accessor: "id",
      title: "ID",
      render: (log) => log.id,
      sortable: true,
    },
    {
      accessor: "event",
      title: "Událost",
      render: (log) => (
        <>
          {log.event === "created" && <Text color="teal">Vytvořeno</Text>}
          {log.event === "updated" && <Text color="yellow">Upraveno</Text>}
          {log.event === "deleted" && <Text color="red">Smazáno</Text>}
          {log.event === "restored" && <Text color="teal">Obnoveno</Text>}
        </>
      ),
      sortable: true,
    },
    {
      accessor: "type",
      title: "Typ",
      render: (log) => log.auditable_type.replace("App\\Models\\", ""),
      sortable: true,
    },
    {
      accessor: "user",
      title: "Autor",
      render: (log) => (log.user ? parseFullName(log.user) : "—"),
      sortable: true,
    },
    {
      accessor: "created_at",
      title: "Datum a čas",
      render: (log) => dayjs(log.created_at).format("D. M. YYYY HH:mm"),
      sortable: true,
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (child) => actionButtons(child),
      narrow: true,
    },
  ];

  const expandContent = (log) => {
    return (
      <div className="font-normal">
        <Text>
          <Text span fw="bold">
            Celý typ:
          </Text>{" "}
          {log?.auditable_type}
        </Text>
        <Text>
          <Text span fw="bold">
            ID záznamu:
          </Text>{" "}
          {log?.auditable_id}
        </Text>
        <Text>
          <Text span fw="bold">
            Uživatel:
          </Text>{" "}
          {log.user ? `${parseFullName(log.user)} (${log?.user?.id})` : "—"}
        </Text>
        <Text>
          <Text span fw="bold">
            IP adresa:
          </Text>{" "}
          {log?.ip_address}
        </Text>
        <Text>
          <Text span fw="bold">
            User agent:
          </Text>{" "}
          {log?.user_agent}
        </Text>
        <Text>
          <Text span fw="bold">
            Odkaz eventu na API:
          </Text>{" "}
          <Text span color={theme.colors[theme.primaryColor][4]} className="underline">
            {log?.url ? (
              <a href={log?.url} target="_blank" rel="noreferrer">
                {log?.url}
              </a>
            ) : (
              "—"
            )}
          </Text>
        </Text>
        <div className="mt-3 flex w-full max-w-full gap-4 md:columns-2">
          <div className="grow basis-[400px]">
            <Text color="red" mb={4}>
              Staré hodnoty
            </Text>
            <CodeHighlight
              noCopy
              className="max-w-[500px]"
              language="json"
              copyLabel="Zkopírovat"
              bg={mode === "dark" ? theme.colors.dark[7] : theme.colors.gray[0]}
              code={JSON.stringify(log.old_values, null, 2)}
            />
          </div>
          <div className="grow basis-[400px]">
            <Text color="teal" mb={4}>
              Nové hodnoty
            </Text>
            <CodeHighlight
              noCopy
              className="max-w-[500px]"
              language="json"
              copyLabel="Zkopírovat"
              bg={mode === "dark" ? theme.colors.dark[7] : theme.colors.gray[0]}
              code={JSON.stringify(log.new_values, null, 2)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Table
        columns={columns}
        id="logy"
        useServer={true}
        serverUrl={"/audit-log"}
        shouldServerFetch={shouldFetch}
        setShouldServerFetch={setShouldFetch}
        /* onRowClick={(child) => {
          navigate("/deti/" + child.id);
        }} */
        rowExpansion={{
          collapseProps: {
            transitionDuration: 300,
            transitionTimingFunction: "ease",
          },
          content: ({ record }) => expandContent(record),
        }}
        fetching={fetching}
        onRowClick={(record) => {
          expandLog(record);
        }}
      />
    </>
  );
}
