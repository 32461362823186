import { Paper, Text } from "@mantine/core";
import Page from "../../components/layout/Page";
import { useFetch } from "../../helpers/useFetch";
import ContractsTable from "../../components/table/ContractsTable";
import { useLoaderData } from "react-router-dom";
import { useState } from "react";
import DocumentViewer from "../../components/boarding/DocumentViewer";
import useTitle from "../../hooks/useTitle";

export const loader = async () => {
  return Promise.all([useFetch(`staff/contracts`), useFetch(`camp-sessions`)]);
};

export default function ContractsOverview() {
  const [viewerOpened, setViewerOpened] = useState(false);
  const [viewingDocument, setViewingDocument] = useState("");
  const data = useLoaderData();
  useTitle("Přehled smluv");

  const contracts = data[0].data;
  const sessions = data[1].data.map((session) => ({
    value: session.alias,
    label: session.name,
  }));

  const handleDocumentView = (document) => {
    setViewingDocument(document);
    setViewerOpened(true);
  };

  const filterConfig = [
    {
      key: "session",
      label: "Turnus",
      type: "select",
      options: sessions,
      function: (contract, value) => contract.sessions.includes(value),
    },
  ];

  return (
    <Page title="Přehled smluv">
      <ContractsTable filterConfig={filterConfig} handleDocumentView={handleDocumentView} contracts={contracts} />
      {viewerOpened && <DocumentViewer document={viewingDocument} setOpened={setViewerOpened} />}
    </Page>
  );
}
