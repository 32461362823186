import useTitle from "../../hooks/useTitle.js";
import Page from "../../components/layout/Page.jsx";
import { useFetch } from "../../helpers/useFetch.js";
import GroupOverview from "../../components/accommodation/overview/GroupOverview.jsx";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { IconDownload, IconSearch } from "@tabler/icons-react";

export async function loader() {
  const res = await useFetch(`accommodation`);

  return res;
}

export default function AccommodationOverview() {
  const groups = useLoaderData().data;
  const [roomsSearch, setRoomsSearch] = useState("");
  const [filteredRoom, setFilteredRoom] = useState(null);
  const userItem = localStorage.getItem("user");
  const user = JSON.parse(userItem);
  useTitle(`Přehled ubytování`);

  const revalidator = useRevalidator();

  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (params.get("room")) {
      setFilteredRoom(params.get("room"));
    }
  }, []);

  const actionButtons = [
    {
      name: "Stáhnout ubytování dětí",
      onTrigger: (e) => {
        e.stopPropagation();
        const url = import.meta.env.VITE_API_URL + "/export/pdf/room-accommodation";
        const downloadLink = document.createElement("a");
        downloadLink.target = "_blank";
        downloadLink.href = new URL(url + "?_token=" + user.token, import.meta.env.VITE_API_URL).href;
        downloadLink.click();
      },
      primary: true,
      icon: IconDownload,
    },
  ];

  return (
    <Page title="Přehled ubytování" actionIcons={actionButtons}>
      {!filteredRoom && (
        <TextInput
          placeholder="Hledat ubytované dítě nebo pokoj..."
          mb="md"
          onChange={(event) => setRoomsSearch(event.currentTarget.value)}
          value={roomsSearch}
          leftSection={<IconSearch stroke={1.5} size={16} />}
        />
      )}

      {groups.map(
        (group) =>
          (filteredRoom
            ? group.rooms.filter((room) => room.id === Number(filteredRoom)).length > 0
            : roomsSearch === "" ||
              group.rooms.filter(
                (room) =>
                  room.occupation.filter((occupation) =>
                    `${occupation?.firstName} ${occupation?.lastName}`
                      ?.toLowerCase()
                      .includes(roomsSearch.toLowerCase())
                  ).length > 0
              ).length > 0 ||
              group.rooms.filter((room) => room.name.toLowerCase().includes(roomsSearch.toLowerCase()))) && (
            <GroupOverview filteredRoom={filteredRoom} roomsSearch={roomsSearch} group={group} key={group.id} />
          )
      )}
    </Page>
  );
}
