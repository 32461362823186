import { useForm } from "@mantine/form";
import { useState, useEffect } from "react";
import { useRevalidator } from "react-router-dom";
import { useFetch } from "../../helpers/useFetch";
import { Alert, Button, Group, NumberInput, SegmentedControl, TextInput, Textarea } from "@mantine/core";
import toast from "react-hot-toast";
import { IconAlertCircle } from "@tabler/icons-react";
import { getMode } from "../../helpers/helpers";

const PcNewQuestModal = ({ context, id, innerProps }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const revalidate = useRevalidator();
  const setShouldFetch = innerProps.setShouldFetch;

  const form = useForm({
    initialValues: {
      camps: [],
      title: "",
      text: "",
      day: new Date().getDay() < 6 ? new Date().getDay() : 0,
      lore: "",
      url: "",
      points: 1,
      type: "main",
    },
  });

  useEffect(() => {
    if (innerProps?.camp) {
      form.setValues({
        camps: innerProps.camp ? [innerProps.camp] : [],
        title: "",
        text: "",
        day: new Date().getDay() < 6 ? new Date().getDay() : 0,
        lore: "",
        url: "",
        points: 1,
        type: "main",
      });
    } else {
      form.reset();
    }
  }, [innerProps]);

  let camps = innerProps?.camps || [];

  const days = innerProps?.days || [];

  const handleSubmit = async (values) => {
    setLoading(true);

    if (values.url === "") delete values.url;
    const res = await useFetch(`/pc/quests`, "POST", values);
    if (res.status === "ok") {
      context.closeModal(id);
      form.reset();
      if (setShouldFetch) setShouldFetch(true);
      toast.success("Úkol vytvořen");
      revalidate.revalidate();
    } else {
      setLoading(false);
      setError(res.message);
    }
  };

  const handleCampChange = (campId) => {
    const selCamps = [...form.values.camps];

    const index = selCamps.indexOf(campId);

    if (index !== -1) {
      selCamps.splice(index, 1);
    } else {
      selCamps.push(campId);
    }

    form.setFieldValue("camps", selCamps);
  };

  const { mode, theme } = getMode();

  return (
    <div className="max-h-[70vh] overflow-hidden overflow-y-auto lg:max-h-full">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {error && (
          <Alert
            icon={<IconAlertCircle size={16} stroke={1.5} />}
            title="Při vytváření questu nastala chyba."
            color="red"
            mb="md"
            mt="lg"
            withCloseButton
            closeButtonLabel="Zavřít upozornění"
            onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        <p className="mb-1 text-sm font-bold">Camp</p>
        <Group className="flex-grow" mb="lg" gap="sm" grow>
          {camps
            ? camps.map((camp) => (
                <Button
                  key={camp.id}
                  variant={form.values.camps.includes(camp.id) ? "filled" : "light"}
                  onClick={() => {
                    handleCampChange(camp.id);
                  }}
                  className="text-xs">
                  {camp.label}
                </Button>
              ))
            : null}
        </Group>
        <p className="mb-1 text-sm font-bold">Den</p>
        <div className="mb-4 overflow-auto">
          <SegmentedControl
            data={days}
            value={form.values.day}
            color={theme.primaryColor}
            className=" min-w-full"
            onChange={(value) => {
              form.setFieldValue("day", value);
            }}
          />
        </div>
        <TextInput label="Název" placeholder="Hlavasování" mb="sm" {...form.getInputProps("title")} />
        <Textarea
          maxRows={2}
          label="Popis"
          placeholder="Já jsem hlavas, já jsem hlavas, já jsem hlavas..."
          mb="sm"
          {...form.getInputProps("text")}
        />
        <Textarea
          maxRows={2}
          label="Lore"
          placeholder="Potřebujeme novou hlavu areálu..."
          mb="sm"
          {...form.getInputProps("lore")}
        />
        <NumberInput min={0} label="Odměna" placeholder="1" mb="sm" {...form.getInputProps("points")} />
        <TextInput label="Odkaz" placeholder="https://example.com" mb="sm" {...form.getInputProps("url")} />
        <Button fullWidth type="submit" mt="md" loading={loading}>
          Vytvořit
        </Button>
      </form>
    </div>
  );
};

export default PcNewQuestModal;
