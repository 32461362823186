import { AlertError, AlertWarning } from "../Alerts";
import useHash from "../../hooks/useHash";
import { ActionIcon, Button, Group, Paper, Select, Stack, Stepper, Text } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { useListState } from "@mantine/hooks";
import { IconCheck, IconChevronLeft, IconFile, IconTrash, IconUpload, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../helpers/useFetch.js";

export default function TeamsSetup({ importState }) {
  //TODO: predelat spolecne se session
  const userItem = localStorage.getItem("user");
  const user = JSON.parse(userItem);

  const [hash, setHash] = useHash();

  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [prevalidateTeams, prevalidateTeamsHandlers] = useListState([]);
  const [prevalidateError, setPrevalidateError] = useState("");
  const [finishError, setFinishError] = useState("");
  const [prevalidateFile, setPrevalidateFile] = useState(null);
  const [localStaff, setLocalStaff] = useState();
  const [displayStaff, displayStaffHandlers] = useListState([]);
  const [fileSelected, setFileSelected] = useState(false);

  const navigate = useNavigate();

  const nextStep = () => {
    setActive((current) => (current < 3 ? current + 1 : current));
  };
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  let setDisplayStaff = () => {
    displayStaffHandlers.setState(
      localStaff
        .filter((leader) => leader.position === "Oddílový vedoucí")
        .map((leader) => {
          return {
            label: `${leader.firstName} ${leader.lastName} (${leader.age} let)`,
            value: String(leader.id),
            key: leader.id,
            disabled: false,
          };
        })
    );
  };

  useEffect(() => {
    if (hash.split("#step")[1]) {
      if (hash < active) {
        setActive(Number(hash.split("#step")[1]) - 1);
      }
    }
  }, [hash]);

  useEffect(() => {
    setHash("#step" + (active + 1));

    if (active === 1) {
      useFetch("/staff").then((staff) => {
        setLocalStaff(staff.data);
      });
    }
  }, [active]);

  const changeLeaderState = (leaderId, team) => {
    if (leaderId !== team.leader_id) {
      displayStaffHandlers.applyWhere(
        (item) => item.value === team.leader_id,
        (item) => ({ ...item, disabled: false })
      );
      displayStaffHandlers.applyWhere(
        (item) => item.value === leaderId,
        (item) => ({ ...item, disabled: true })
      );
    } else if (leaderId) {
      displayStaffHandlers.applyWhere(
        (item) => item.value === leaderId,
        (item) => ({ ...item, disabled: true })
      );
    } else {
      displayStaffHandlers.applyWhere(
        (item) => item.value === team.leader_id,
        (item) => ({ ...item, disabled: false })
      );
    }

    prevalidateTeamsHandlers.applyWhere(
      (teamLocal) => teamLocal.number === team.number,
      (teamLocal) => ({ ...teamLocal, leader_id: leaderId })
    );
  };

  const downloadTable = () => {
    setLoading(true);
    localStorage.setItem("lastDownloadSession", user.currentCampSession.id);
    const downloadLink = document.createElement("a");
    downloadLink.href = new URL("/export/children?_token=" + user.token, import.meta.env.VITE_API_URL).href;
    downloadLink.click();
    setTimeout(() => {
      setLoading(false);
      nextStep();
    }, 2000);
  };

  const uploadTable = (values) => {
    setLoading(true);
    if (!prevalidateFile) {
      setLoading(false);
      setPrevalidateError("Nebyl vybrán žádný soubor.");
      return;
    }
    const formData = new FormData();
    formData.append("file", prevalidateFile[0]);
    fetch(new URL("/children/import/prevalidate", import.meta.env.VITE_API_URL).href, {
      //TODO: variable api URL
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + user.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        if (data.error) {
          setPrevalidateError(data.error);
          setPrevalidateFile(null);
          return;
        }
        prevalidateTeamsHandlers.setState(data.data);
        setDisplayStaff();
        nextStep();
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setPrevalidateError(
          err.message ? err.message : "Nestala neznámá chyba při nahrávání, nejspíše je API offline."
        );
        setPrevalidateFile(null);
      });
  };

  const saveTeams = () => {
    setLoading(true);
    fetch(new URL("/children/import/finish", import.meta.env.VITE_API_URL).href, {
      //TODO: variable api URL
      method: "POST",
      body: JSON.stringify({ teams: prevalidateTeams }),
      headers: {
        Authorization: "Bearer " + user.token,
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          setLoading(false);
          setFinishError(data.error);
          return;
        }

        navigate("/oddily/prehled");
        //router.replace("/oddily/sprava");
      })
      .catch((err) => {
        setLoading(false);
        setFinishError(err.message);
      });
  };

  useEffect(() => {
    setFileSelected(true);
  }, [prevalidateFile]);

  const shouldAllowStepSelect = (step) => {
    return active > step;
  };

  return (
    <Paper className="mx-auto max-w-3xl" p="xl" radius="lg" withBorder>
      <h2>✏️ Průvodce</h2>
      {/* po vybrání vedoucího ho odebrat ze seznamu, naprogramovat */}
      <Text c="dark.1">Je na čase vytvořit oddíly! Tento průvodce tě celým procesem provede.</Text>
      <Stepper mt="sm" active={active} onStepClick={setActive} breakpoint="sm" completedIcon={<IconCheck />}>
        <Stepper.Step allowStepSelect={active > 0}>
          {importState?.status === "error" && (
            <AlertWarning mb="sm" mt="sm" title="Přenahrání oddílů">
              Oddíly máš již nahrané. Pokud budeš v průvodci pokračovat, vytvoří se všechny oddíly odznova.
            </AlertWarning>
          )}

          <Text mb="md" mt="sm">
            Prvně si stáhni tabulku se seznamem všech dětí. Do ní poté do sloupce oddil doplň čísla oddílů.
          </Text>
          <Button fullWidth loading={loading} className="flex-grow" onClick={downloadTable}>
            Stáhnout tabulku
          </Button>

          <Button mt="sm" loading={loading} variant="light" fullWidth onClick={nextStep}>
            Tabulku již mám, chci jí jen nahrát
          </Button>
        </Stepper.Step>
        <Stepper.Step allowStepSelect={shouldAllowStepSelect(0)}>
          <Text mb="md" mt="sm">
            Po vyplnění tabulku nahraj přes pole níže. Po úspěšném nahrání klikni na tlačítko.
          </Text>
          {prevalidateError !== "" && (
            <AlertError mb="md" onClose={() => setPrevalidateError("")} title="Nastala chyba při nahrávání.">
              {prevalidateError}
            </AlertError>
          )}
          {!prevalidateFile && (
            <Dropzone
              onDrop={(file) => {
                if (user.currentCampSession.id !== parseInt(localStorage.getItem("lastDownloadSession"))) {
                  setPrevalidateError("Tuto tabulku jsi pravděpodobně stáhl na jiným turnusu. Zkontroluj si to.");
                } else {
                  setPrevalidateFile(file);
                }
              }}
              maxSize={1 * 1024 ** 2}
              mb="md"
              loading={loading}
              accept={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}>
              <Dropzone.Idle>
                <Stack my="md" justify="center" align="center" gap={0}>
                  <Text mb="sm">
                    <IconUpload stroke={1.5} size={48} />
                  </Text>

                  <Text fw="bold" inline>
                    Přetáhni soubor s tabulkou do tohoto pole.
                  </Text>
                  <Text size="sm" color="dimmed" inline mt={7}>
                    Případně můžeš na pole kliknout a soubor vybrat ručně.
                  </Text>
                </Stack>
              </Dropzone.Idle>
              <Dropzone.Accept>
                <Stack my="md" justify="center" align="center" gap={0}>
                  <Text mb="sm">
                    <IconUpload stroke={1.5} size={48} />
                  </Text>

                  <Text fw="bold" inline>
                    Přetáhni soubor s tabulkou do tohoto pole.
                  </Text>
                  <Text size="sm" color="dimmed" inline mt={7}>
                    Případně můžeš na pole kliknout a soubor vybrat ručně.
                  </Text>
                </Stack>
              </Dropzone.Accept>
              <Dropzone.Reject>
                <Stack my="md" justify="center" align="center" gap={0}>
                  <Text mb="sm" color="red">
                    <IconX stroke={1.5} size={48} />
                  </Text>

                  <Text fw="bold" inline>
                    Přetáhni soubor s tabulkou do tohoto pole.
                  </Text>
                  <Text size="sm" color="dimmed" inline mt={7}>
                    Případně můžeš na pole kliknout a soubor vybrat ručně.
                  </Text>
                </Stack>
              </Dropzone.Reject>
            </Dropzone>
          )}

          {prevalidateFile && (
            <Group gap="xs" justify="space-between" align="center" mb="md">
              <Group gap="xs" align="center">
                <Text>
                  <IconFile stroke={1.5} size={24} />
                </Text>

                <Text fw="bold">{prevalidateFile[0].name}</Text>
                <Text size="sm" color="dimmed">
                  {prevalidateFile[0].size} B
                </Text>
              </Group>
              <ActionIcon loading={loading} color="red">
                <IconTrash stroke={1.5} size={20} onClick={() => setPrevalidateFile(null)} />
              </ActionIcon>
            </Group>
          )}
          <Group gap="xs">
            <ActionIcon variant="light" className="flex-grow-0" onClick={prevStep} size="lg">
              <IconChevronLeft stroke={1.5} size={20} />
            </ActionIcon>

            <Button loading={loading} className="flex-grow" onClick={uploadTable}>
              Nahrát tabulku
            </Button>
          </Group>
        </Stepper.Step>
        <Stepper.Step allowStepSelect={shouldAllowStepSelect(1)}>
          <Text mb="md" mt="sm">
            Tabulka byla úspěšně nahrána. Nyní je na čase vybrat oddílové vedoucí. Pokud u nějakého oddílu vedoucí
            chybí, zanechej pole prázdné a oddíláka doplníš později.
          </Text>
          <div className="grid gap-x-6 md:grid-cols-2">
            {prevalidateTeams.slice(0, Math.ceil(prevalidateTeams.length / 2)).map((team) => (
              <Select
                key={team.id}
                label={`#${team.number} ${team.name} (${team.children.length} dětí)`}
                placeholder="Vyber vedoucího"
                onChange={(value) => changeLeaderState(value, team)}
                mb="xs"
                value={team.leader_id}
                clearable
                searchable
                hoverOnSearchChange={true}
                data={displayStaff}
              />
            ))}
            {prevalidateTeams.slice(Math.ceil(prevalidateTeams.length / 2), prevalidateTeams.length).map((team) => (
              <Select
                key={team.id}
                label={`#${team.number} ${team.name} (${team.children.length} dětí)`}
                placeholder="Vyber vedoucího"
                value={team.leader_id}
                onChange={(value) => changeLeaderState(value, team)}
                mb="xs"
                clearable
                searchable
                hoverOnSearchChange={true}
                data={displayStaff}
              />
            ))}
          </div>
          <Group gap="xs" mt="md">
            <ActionIcon variant="light" className="flex-grow-0" onClick={prevStep} size="lg">
              <IconChevronLeft stroke={1.5} size={20} />
            </ActionIcon>

            <Button className="flex-grow" onClick={nextStep}>
              Potvrdit vedoucí
            </Button>
          </Group>
        </Stepper.Step>
        <Stepper.Step allowStepSelect={shouldAllowStepSelect(2)}>
          <Text>Oddíly byly úspěšně vytvořeny. K oddílům byli také přiřazeni oddíloví vedoucí.</Text>
          {finishError !== "" && (
            <AlertError mt="md" onClose={() => setPrevalidateError("")} title="Nastala chyba při ukládání oddílů.">
              {finishError}
            </AlertError>
          )}
          <Group grow>
            <ul>
              {prevalidateTeams.map((team) => {
                let leaderDisplay;
                if (team.leader_id) {
                  let leader = localStaff.find((leader) => leader.id === Number(team.leader_id));
                  leaderDisplay = `– ${leader.firstName} ${leader.lastName}`;
                } else {
                  leaderDisplay = "";
                }

                return (
                  <li key={team.id}>
                    <strong>{team.name}</strong> ({team.children.length} dětí) {leaderDisplay}
                  </li>
                );
              })}
            </ul>
          </Group>

          <Group gap="xs" mt="md">
            <ActionIcon variant="light" className="flex-grow-0" onClick={prevStep} size="lg">
              <IconChevronLeft stroke={1.5} size={20} />
            </ActionIcon>

            <Button onClick={saveTeams} className="flex-grow" loading={loading} color="teal">
              Uložit oddíly
            </Button>
          </Group>
        </Stepper.Step>
      </Stepper>
    </Paper>
  );
}
