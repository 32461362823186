import { useFetch } from "../../helpers/useFetch.js";
import { useLoaderData } from "react-router-dom";
import TeamsSetup from "../../components/modals/TeamsSetup.jsx";
import useTitle from "../../hooks/useTitle.js";
import Page from "../../components/layout/Page.jsx";
import FormsSetup from "../../components/pcapp/FormsSetup.jsx";
import { useSocketFetch } from "../../helpers/useSocketFetch.js";

export default function FormsSetupPage() {
  const teams = useLoaderData();
  useTitle(`Dotazníky`);

  return (
    <Page title="Dotazníky">
      <FormsSetup />
    </Page>
  );
}
