import { useFetch } from "../../helpers/useFetch.js";
import { Link, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { ActionIcon, Group, Loader, Paper, Stack, Text, Tooltip } from "@mantine/core";
import {
  IconAtom2,
  IconBookmark,
  IconBus,
  IconClock,
  IconCloudCheck,
  IconDoorExit,
  IconEdit,
  IconExternalLink,
  IconEye,
  IconFileText,
  IconInfoCircle,
  IconListDetails,
  IconNote,
  IconSwitchHorizontal,
  IconX,
} from "@tabler/icons-react";
import {
  openChildTransferModal,
  openEarlyLeaveModal,
  openTicketModal,
  openTransportBuyModal,
} from "../../helpers/modals.js";
import useTitle from "../../hooks/useTitle.js";
import Page from "../../components/layout/Page.jsx";
import { getMode, parseFullName, parseSocialNumber } from "../../helpers/helpers.js";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import MedicalReportDetailDrawer from "../../components/medical/MedicalReportDetailDrawer.jsx";
import DocumentViewer from "../../components/boarding/DocumentViewer.jsx";
import classNames from "classnames";
import { useUser } from "../../providers/UserProvider.jsx";
import { usePermissions } from "../../providers/PermissionsProvider.jsx";

export async function loader({ params }) {
  const res = await useFetch(`children/${params.childId}`);
  if (!res.data) {
    throw new Response("Dítě nenalezeno. Jsi ve správném turnusu?", {
      status: 404,
    });
  }
  return res;
}

export default function ChildDetailPage() {
  const child = useLoaderData().data;
  const teams = { data: [] };
  const birthDate = new Date(child.birthDate);
  useTitle(`${child.firstName} ${child.lastName} – Karta dítěte`);
  const [medication, setMedication] = useState([]);
  const [reportDetail, setReportDetail] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentsLoading, setDocumentsLoading] = useState(true);
  const [viewerOpened, setViewerOpened] = useState(false);
  const [viewingDocument, setViewingDocument] = useState(null);

  const { user } = useUser();
  const { hasPermission } = usePermissions();

  let path = useLocation().pathname;
  const navigate = useNavigate();

  const { theme, mode } = getMode();

  const actionIcons = [
    {
      name: "Přesunout dítě",
      icon: IconSwitchHorizontal,
      onTrigger: () => openChildTransferModal("Přesun dítěte", child, `${child?.firstName} ${child?.lastName}`),
      disabled: child?.earlyLeaving?.hasLeft,
    },
    {
      name: "Vytvořit předčasný odjezd",
      icon: IconDoorExit,
      disabled: child?.earlyLeaving,
      onTrigger: () =>
        openEarlyLeaveModal("Vytvořit předčasný odjezd", child, null, `${child?.firstName} ${child?.lastName}`),
    },
    {
      name: "Zobrazit oddíl",
      icon: IconBookmark,
      disabled: !child.team,
      onTrigger: () => navigate(`/oddily/${child?.team?.id}`),
    },
    {
      name: "Zobrazit ošetření",
      icon: IconListDetails,
      onTrigger: () =>
        navigate(
          "/zdravotnik/osetreni/?filter[ucastnik_jmeno]=" +
            child.firstName +
            "&filter[ucastnik_prijmeni]=" +
            child.lastName
        ),
    },
    {
      name: "Změna dopravy",
      onTrigger: () => openTransportBuyModal(child?.id),
      icon: IconBus,
      disabled: child?.earlyLeaving?.hasLeft,
    },
    ...(hasPermission("audit-log")
      ? [
          {
            name: "Zobrazit objednávku v CORE",
            onTrigger: () =>
              window.open(`https://core.zrgames.cz/vstupenky/admin.objednavky/edit/${child?.id}`, "_blank"),
            icon: IconAtom2,
          },
        ]
      : []),
    {
      name: "Vytvořit požadavek",
      icon: IconNote,
      onTrigger: () => openTicketModal(`Vytvořit požadavek`, child, null, parseFullName(child)),
    },
    {
      name: "Editovat dítě",
      icon: IconEdit,
      onTrigger: () => navigate(path + "/edit"),
      primary: true,
    },
  ];

  let dosages = {
    s_m: "S-",
    s_p: "S+",
    o_m: "O-",
    o_p: "O+",
    v_m: "V-",
    v_p: "V+",
  };

  useEffect(() => {
    const medicationFunction = async () => {
      let medicationLocal = [];
      await child.medicalMedications.forEach(async (med) => {
        let medicationDosages = [];
        await Object.keys(dosages).forEach((key) => {
          if (key.includes("_")) {
            if (med[key].amount !== null) {
              medicationDosages.push(dosages[key]);
            }
          }
        });
        medicationLocal.push(
          <li className="my-2">
            <Text span className="flex items-center gap-1">
              {med.name}
              {med.description && (
                <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label={med.description}>
                  <IconInfoCircle size={20} stroke={1.5} />
                </Tooltip>
              )}
              <div className="ml-3 flex gap-2">
                {medicationDosages?.map((dosage) => (
                  <ActionIcon className="pointer-events-none cursor-auto" variant="light">
                    <strong>{dosage}</strong>
                  </ActionIcon>
                ))}
              </div>
            </Text>
          </li>
        );
      });
      setMedication([...medication, medicationLocal]);
    };
    medicationFunction();

    useFetch(`children/${child.id}/documents`).then((res) => {
      setDocuments(res.data);
      setDocumentsLoading(false);
    });
  }, []);

  const handleDocumentView = (document) => {
    setViewingDocument(document);
    setViewerOpened(true);
  };

  return (
    <Page
      subtitle="Karta dítěte"
      title={
        <p>
          {parseFullName(child)}
          {child.earlyLeaving && (
            <Text span color={"red.6"} className={"ml-3"}>
              <IconDoorExit stroke={1.5} size={25} />
            </Text>
          )}
        </p>
      }
      actionIcons={actionIcons}>
      <MedicalReportDetailDrawer opened={reportDetail} onClose={() => setReportDetail(null)} report={reportDetail} />
      <Paper p="lg" className="mb-4" withBorder radius="lg">
        <h2 className="mb-4">Základní informace</h2>
        <div className="flex justify-between gap-x-4">
          <div className="flex w-1/2 flex-col gap-y-4">
            <div>
              <p>
                <strong>Jméno</strong>
              </p>
              <p>
                {child.firstName}&nbsp;{child.lastName}
              </p>
            </div>
            <div>
              <p>
                <strong>Datum narození</strong>
              </p>
              <p>
                {dayjs(birthDate).format("D. M. YYYY")} ({child.age} let, {parseSocialNumber(child.nationalIdNumber)})
              </p>
            </div>
            <div>
              <p>
                <strong>Pohlaví</strong>
              </p>
              <p>{child.gender}</p>
            </div>
            <div>
              <p>
                <strong>Velikost trička</strong>
              </p>
              <p>{child.tShirtSize}</p>
            </div>
            <div>
              <p>
                <strong>Pokoj</strong>
              </p>
              <p>
                {child.roomName ? (
                  <Text color={child.roomReservation && "orange"} className="flex items-center gap-1">
                    {child.roomReservation && <IconClock stroke={1.5} strokeWidth={1.5} size={20} />}{" "}
                    <Text span className="hover:underline" color={theme.colors[theme.primaryColor][4]}>
                      <Link to={`/ubytovani/prehled?room=${child.room.id}`}>{child.roomName}</Link>
                    </Text>
                  </Text>
                ) : (
                  <Text colcor="dimmed">—</Text>
                )}
              </p>
            </div>
            <div>
              <p>
                <strong>Finance</strong>
                <ul className="my-0 pl-4">
                  <li>
                    <strong>Kauce:</strong>{" "}
                    {child.money.deposit.amount.czk > 0 || child.money.deposit.amount.eur > 0 ? (
                      <>
                        {child.money.deposit.amount.czk > 0 &&
                          child.money.deposit.amount.eur > 0 &&
                          `${child.money.deposit.amount.czk} CZK + ${child.money.deposit.amount.eur}`}
                        {child.money.deposit.amount.czk > 0 &&
                          child.money.deposit.amount.eur === 0 &&
                          `${child.money.deposit.amount.czk} CZK`}
                        {child.money.deposit.amount.czk === 0 &&
                          child.money.deposit.amount.eur > 0 &&
                          `${child.money.deposit.amount.eur} EUR`}
                      </>
                    ) : (
                      <Text c="dimmed" span>
                        —
                      </Text>
                    )}
                  </li>
                  <li>
                    <strong>Kapesné:</strong>{" "}
                    {child.money.pocketMoney.amount.czk > 0 || child.money.pocketMoney.amount.eur > 0 ? (
                      <>
                        {child.money.pocketMoney.amount.czk > 0 &&
                          child.money.pocketMoney.amount.eur > 0 &&
                          `${child.money.pocketMoney.amount.czk} CZK + ${child.money.pocketMoney.amount.eur} EUR`}
                        {child.money.pocketMoney.amount.czk > 0 &&
                          child.money.pocketMoney.amount.eur === 0 &&
                          `${child.money.pocketMoney.amount.czk} CZK`}
                        {child.money.pocketMoney.amount.czk === 0 &&
                          child.money.pocketMoney.amount.eur > 0 &&
                          `${child.money.pocketMoney.amount.eur} EUR`}
                      </>
                    ) : (
                      <Text ccolor="dimmed" span>
                        —
                      </Text>
                    )}
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="flex w-1/2 flex-col gap-y-4">
            <div>
              <p>
                <strong>Jméno rodiče</strong>
              </p>
              <p>{parseFullName(child.parent)}</p>
            </div>
            <div>
              <p>
                <strong>Adresa</strong>
              </p>
              <Text className="w-fit hover:underline" color={theme.colors[theme.primaryColor][4]}>
                <a
                  href={`https://maps.google.com/?z=12&q=${child.address.street},+${child.address.city},+${child.address.zip}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {child.address.street}, {child.address.city}, {child.address.zip}
                </a>
              </Text>
            </div>
            <div>
              <p>
                <strong>Telefonní čísla na rodiče</strong>
              </p>
              <p>
                <Text span className="hover:underline" color={theme.colors[theme.primaryColor][4]}>
                  <a href={`tel:${child.tel1}`}>{child.tel1}</a>
                </Text>
                {child.tel1 === "" ? "" : ", "}
                <Text span className="hover:underline" color={theme.colors[theme.primaryColor][4]}>
                  <a href={`tel:${child.tel2}`}>{child.tel2}</a>
                </Text>
              </p>
            </div>
            <div>
              <p>
                <strong>E-mail na zákonného zástupce</strong>
              </p>
              <Text className="w-fit hover:underline" color={theme.colors[theme.primaryColor][4]}>
                <a href={`mailto:${child.emailParent}`} target="_blank" rel="noreferrer">
                  {child.emailParent}
                </a>
              </Text>
            </div>
            <div>
              <p>
                <strong>E-mail na dítě</strong>
              </p>
              <Text className="w-fit hover:underline" color={theme.colors[theme.primaryColor][4]}>
                <a href={`mailto:${child.emailChild}`} target="_blank" rel="noreferrer">
                  {child.emailChild}
                </a>
              </Text>
            </div>
            <div>
              <p>
                <strong>Oddíl</strong>
              </p>
              <Text color={child.team ? "" : "red"}>
                {!child.team && (child.gender === "Chlapec" ? "Nezařazen" : "Nezařazena")}
                {child.team && (
                  <Text className="w-fit hover:underline" color={theme.colors[theme.primaryColor][4]}>
                    <Link to={`/oddily/${child.team.id}`}>
                      {child.team.name} {child.team.number}
                      {child.team?.leader && ` (${child.team.leader.firstName} ${child.team.leader.lastName})`}
                    </Link>
                  </Text>
                )}
              </Text>
            </div>
            <div>
              <p>
                <strong>Zakoupené doplňky</strong>
              </p>
              <p>
                {child.merch.length >= 1 ? (
                  <ul className="my-0 pl-4">
                    {child.merch?.map((merch) => (
                      <li key={merch.id}>
                        <Text span className="w-fit">
                          <strong>{merch.name}:</strong> {merch.count}x{merch.size ? `, ${merch.size}` : ""}
                        </Text>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Text c="dimmed">—</Text>
                )}
              </p>
            </div>
          </div>
        </div>
      </Paper>
      <Paper p="lg" mb="md" withBorder radius="lg">
        <h2 className="mb-4">Zdravotní informace</h2>
        <div className="flex justify-between gap-x-4">
          <div className="flex w-1/2 flex-col gap-y-4">
            <div>
              <p>
                <strong>Zdravotní omezení</strong>
              </p>
              <p>
                {child.parentMedicalInfo.medical_restrictions ? (
                  child.parentMedicalInfo.medical_restrictions
                ) : (
                  <Text span color="dimmed">
                    —
                  </Text>
                )}
              </p>
            </div>

            <div>
              <p>
                <strong>Alergie</strong>
              </p>
              <p>
                {child.parentMedicalInfo.allergies ? (
                  child.parentMedicalInfo.allergies
                ) : (
                  <Text span color="dimmed">
                    —
                  </Text>
                )}
              </p>
            </div>

            <div>
              <p>
                <strong>Stravování</strong>
              </p>
              <p>
                {child.parentMedicalInfo.dietary_restrictions ? (
                  child.parentMedicalInfo.dietary_restrictions
                ) : (
                  <Text span color="dimmed">
                    —
                  </Text>
                )}
              </p>
            </div>

            <div>
              <p>
                <strong>Léky</strong>
              </p>
              <p>
                {child.parentMedicalInfo.medications ? (
                  child.parentMedicalInfo.medications
                ) : (
                  <Text span color="dimmed">
                    —
                  </Text>
                )}
              </p>
            </div>

            <div>
              <p>
                <strong>Plavec</strong>
              </p>
              {child.canSwim ? <Text color="teal">Ano</Text> : <Text color="red">Ne</Text>}
            </div>
            <div>
              <p>
                <strong>Seznam ošetření</strong>
              </p>
              {child.medicalRecords.length > 0 ? (
                <>
                  <ul className="my-0 pl-4">
                    {child.medicalRecords.slice(0, 5)?.map((record) => (
                      <li key={record.id}>
                        <Text
                          span
                          onClick={() => setReportDetail({ child: child, ...record })}
                          className="w-fit cursor-pointer hover:underline"
                          color={theme.colors[theme.primaryColor][4]}>
                          <strong>{record.type}</strong> ({dayjs(record.createdAt).format("D. M. YYYY HH:mm")})
                        </Text>
                      </li>
                    ))}
                  </ul>
                  {child.medicalRecords.length > 5 && (
                    <Text className="mt-2 cursor-pointer hover:underline" color={theme.colors[theme.primaryColor][4]}>
                      <Link
                        to={
                          "/zdravotnik/osetreni/?filter[ucastnik_jmeno]=" +
                          child.firstName +
                          "&filter[ucastnik_prijmeni]=" +
                          child.lastName
                        }
                        className="flex items-center gap-2">
                        <IconExternalLink size={18} stroke={1.5} /> Zobrazit všechny ošetření (
                        {child.medicalRecords.length})
                      </Link>
                    </Text>
                  )}
                </>
              ) : (
                <Text span color="dimmed">
                  —
                </Text>
              )}
            </div>
          </div>
          <div className="flex w-1/2 flex-col gap-y-4">
            <div>
              <p>
                <strong>Zdravotní omezení od zdravotníka</strong>
              </p>
              <p>
                {child.medicalInfo.medical_restrictions ? (
                  child.medicalInfo.medical_restrictions
                ) : (
                  <Text span color="dimmed">
                    —
                  </Text>
                )}
              </p>
            </div>

            <div>
              <p>
                <strong>Alergie od zdravotníka</strong>
              </p>
              <p>
                {child.medicalInfo.allergies ? (
                  child.medicalInfo.allergies
                ) : (
                  <Text span color="dimmed">
                    —
                  </Text>
                )}
              </p>
            </div>

            <div>
              <p>
                <strong>Stravování od zdravotníka</strong>
              </p>
              <p>
                {child.medicalInfo.dietary_restrictions ? (
                  child.medicalInfo.dietary_restrictions
                ) : (
                  <Text span color="dimmed">
                    —
                  </Text>
                )}
              </p>
            </div>

            <div>
              <p>
                <strong>Pravidelné léky od zdravotníka</strong>
              </p>
              {child.medicalMedications.length > 0 ? (
                <ul className="my-0 pl-4">{medication}</ul>
              ) : (
                <Text c="dimmed">—</Text>
              )}
            </div>

            <div>
              <p>
                <strong>Poznámka od rodiče</strong>
              </p>
              {child.parentNote ? <p>{child.parentNote}</p> : <Text c="dimmed">—</Text>}
            </div>

            {child.permanentNote && (
              <div>
                <p>
                  <strong>Historická poznámka</strong>
                </p>
                <p>{child.permanentNote}</p>
              </div>
            )}
          </div>
        </div>
      </Paper>
      <Paper p="lg" className="mb-4" withBorder radius="lg">
        <h2 className="mb-4">Dokumenty k dítěti</h2>
        {documentsLoading ? (
          <div className="flex justify-center">
            <Loader mb="lg" />
          </div>
        ) : (
          <div className="flex flex-wrap gap-4 sm:columns-2 lg:columns-3">
            {documents
              ?.filter((document) => document.type !== "deposit")
              .map((document) => {
                return (
                  <Group
                    className={classNames(
                      "min-w-[300px] grow justify-between gap-0 gap-y-2 rounded-md border-2 border-solid border-dark-500 bg-dark-300 p-2",
                      {
                        "border-dark-500 bg-dark-300": mode === "dark",
                        "border-gray-500 bg-white-900": mode === "light",
                      }
                    )}>
                    <Stack gap={0}>
                      <Group gap={"6px"}>
                        <IconFileText stroke={1.5} size={20} />
                        <Text fw="bold">{document.name}</Text>
                      </Group>
                      <Text c={"dimmed"} className="mt-1 text-sm">
                        {document.status === "none" && "Dokument nepředán"}
                        {document.status === "core" && "Dokument evidován online"}
                        {document.status === "paper" && "Dokument předán papírově"}
                        {document.status === "signed" && "Dokument podepsán elektronicky"}
                        {document.original && " (originál)"}
                      </Text>
                    </Stack>
                    {document.status === "none" && (
                      <Tooltip label={document.preview ? "Dokument nepředán" : "Dokument nepředán"}>
                        <ActionIcon variant="filled" color="red">
                          <IconX stroke={1.5} size={20} />
                        </ActionIcon>
                      </Tooltip>
                    )}
                    {document.status === "core" && (
                      <Tooltip label="Zobrazit dokument">
                        <ActionIcon variant="filled" color="teal" onClick={() => handleDocumentView(document.filePath)}>
                          <IconCloudCheck stroke={1.5} size={20} />
                        </ActionIcon>
                      </Tooltip>
                    )}
                    {document.status === "paper" && (
                      <Tooltip label="Dokument předán papírově">
                        <ActionIcon
                          variant="filled"
                          color="teal"
                          className={classNames({
                            "border-[3px] border-solid border-yellow-400": document.original,
                          })}>
                          <IconFileText stroke={1.5} size={20} />
                        </ActionIcon>
                      </Tooltip>
                    )}
                    {document.status === "signed" && (
                      <Tooltip label="Zobrazit dokument">
                        <ActionIcon variant="filled" color="teal" onClick={() => handleDocumentView(document.filePath)}>
                          <IconEye stroke={1.5} size={20} />
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </Group>
                );
              })}
          </div>
        )}
      </Paper>
      <Paper p="lg" withBorder radius="lg">
        <h2 className="mb-4">Historie dítěte</h2>
        {child.eventHistory.length > 0 ? (
          <ul>
            {child.eventHistory?.map((event, i) => (
              <li key={i}>
                {event.camp} - {event.area} - {event.campSession}
              </li>
            ))}
          </ul>
        ) : (
          <Text className="flex items-center" color="dimmed">
            🤔 Dítě s námi ještě nejelo
          </Text>
        )}
      </Paper>
      {viewerOpened && <DocumentViewer document={viewingDocument} setOpened={setViewerOpened} />}
    </Page>
  );
}
