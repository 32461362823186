import { Collapse } from "@mantine/core";
import { IconDownload, IconFilter, IconReportMedical, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate, useRevalidator } from "react-router-dom";
import Page from "../../components/layout/Page";
import MedicalReportDetailDrawer from "../../components/medical/MedicalReportDetailDrawer";
import NewMedicalReportDrawer from "../../components/medical/NewMedicalReportDrawer";
import Filters from "../../components/table/filters/Filters";
import MedicalRecordsTable from "../../components/table/MedicalRecordsTable";
import useTitle from "../../hooks/useTitle";

const MedicalRecordsPage = () => {
  useTitle(`Zdravotní záznamy`);
  const [newReport, setNewReport] = useState(false);
  const [reportDetail, setReportDetail] = useState(null);
  const [openedFilters, setOpenedFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [shouldFetch, setShouldFetch] = useState(false);
  const userItem = localStorage.getItem("user");
  const user = JSON.parse(userItem);

  const revalidator = useRevalidator();

  const navigate = useNavigate();

  const actionButtons = [
    {
      name: "Stáhnout seznam ošetření",
      onTrigger: (e) => {
        e.stopPropagation();
        const url = import.meta.env.VITE_API_URL + "/export/zip/children-medical-records";
        const downloadLink = document.createElement("a");
        downloadLink.target = "_blank";
        downloadLink.href = new URL(url + "?_token=" + user.token, import.meta.env.VITE_API_URL).href;
        downloadLink.click();
      },
      icon: IconDownload,
    },
    {
      name: "Smazaná ošetření",
      onTrigger: (e) => {
        e.stopPropagation();
        navigate("/zdravotnik/osetreni/smazana");
      },
      icon: IconTrash,
    },
    {
      name: "Otevření filtrů",
      onTrigger: (e) => {
        e.stopPropagation();
        setOpenedFilters(!openedFilters);
      },
      icon: IconFilter,
      primary: openedFilters,
    },
    {
      name: "Nový záznam ošetření",
      onTrigger: (e) => {
        e.stopPropagation();
        setNewReport(true);
      },
      icon: IconReportMedical,
      primary: true,
    },
  ];

  const filterConfig = [
    {
      key: "ucastnik_jmeno",
      label: "Jméno dítěte",
      type: "text",
    },
    {
      key: "ucastnik_prijmeni",
      label: "Příjmení dítěte",
      type: "text",
    },
  ];

  return (
    <Page title="Seznam ošetření" actionIcons={actionButtons}>
      <Collapse in={openedFilters}>
        <Filters filterConfig={filterConfig} setFilters={setFilters} />
      </Collapse>
      <MedicalRecordsTable
        shouldFetch={shouldFetch}
        setShouldFetch={setShouldFetch}
        filters={filters}
        setReportDetail={setReportDetail}
      />
      <NewMedicalReportDrawer
        opened={newReport}
        onClose={() => {
          setNewReport(false);
        }}
        setShouldFetch={setShouldFetch}
      />
      <MedicalReportDetailDrawer
        opened={reportDetail}
        onClose={() => {
          setReportDetail(null);
        }}
        report={reportDetail}
        setShouldFetch={setShouldFetch}
      />
    </Page>
  );
};

export default MedicalRecordsPage;
