import { IconDatabaseOff } from "@tabler/icons-react";
import { useEffect, useState } from "react";

import classes from "./TableEmptyState.module.scss";
import { Center, Text } from "@mantine/core";

export default function TableEmptyState({ icon, text, pt, pb, active, children }) {
  const [activeLocal, setActiveLocal] = useState(false);

  useEffect(() => {
    setActiveLocal(active);
  }, [active]);

  return (
    activeLocal && (
      <tr>
        <th colSpan={100} className={classes.wrapper}>
          <div>
            <Center pt={pt} pb={pb} className="my-4 flex flex-col font-normal">
              <div className={classes.standardIcon}>
                <IconDatabaseOff stroke={1.5} />
              </div>
              <Text size="sm" c="dimmed">
                Nic tu není 😿
              </Text>
            </Center>
          </div>
        </th>
      </tr>
    )
  );
}
