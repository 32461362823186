import { useState } from "react";
import Drawer from "../../drawer/Drawer";
import Table from "../../table/core/Table";
import { SegmentedControl, Text } from "@mantine/core";
import { modalTitle } from "../../modals/Modal";
import { IconMedal2 } from "@tabler/icons-react";
import { getMode, parseFullName } from "../../../helpers/helpers";
import PointsSummary from "./PointsSummary";
import { sortPoints } from "../../table/sort";

export default function PointsSummaryDrawer({ isOpen, onClose, groups }) {
  let rooms = [];
  const [type, setType] = useState("rooms");

  groups.forEach((group) => {
    group.rooms.forEach((room) => {
      rooms.push(room);
    });
  });

  rooms = rooms.filter((room) => room.occupationCount > 0);

  rooms = rooms.sort((a, b) => {
    return b.pointsTotal - a.pointsTotal;
  });

  const columns = [
    {
      accessor: "name",
      title: "Pokoj",
      render: (room) => room.fullName,
    },
    {
      accessor: "count",
      title: "Body",
      sortable: true,
      render: (room) => (
        <Text className="flex flex-nowrap gap-1">
          <PointsSummary room={room} />= <Text span>{room.pointsTotal}</Text>
        </Text>
      ),
    },
  ];

  const columnsChildren = [
    {
      accessor: "name",
      title: "Jméno",
      render: (child) => parseFullName(child),
    },
    {
      accessor: "team",
      title: "Oddíl",
      render: (child) => child.team,
    },
    {
      accessor: "room",
      title: "Pokoj",
      render: (child) => child.room,
    },
    {
      accessor: "count",
      title: "Počet",
      sortable: true,
      render: (child) => (
        <Text className="flex flex-nowrap gap-1">
          <PointsSummary room={child.roomFull} />= <Text span>{child.roomFull.pointsTotal}</Text>
        </Text>
      ),
    },
  ];

  let childrenData = [];

  rooms.forEach((room) => {
    room.occupation.forEach((child) => {
      if (child?.id) {
        childrenData.push({
          ...child,
          room: room.name,
          roomFull: room,
        });
      }
    });
  });

  return (
    <Drawer title={modalTitle(IconMedal2, "Součty bodů")} opened={isOpen} onClose={onClose}>
      <SegmentedControl
        mb="sm"
        fullWidth
        color={getMode().theme.primaryColor}
        value={type}
        onChange={setType}
        data={[
          { label: "Pokoje", value: "rooms" },
          { label: "Děti", value: "children" },
        ]}
      />
      {/* počty bodů udělat jako přehled = barevné dny a pak = a součet */}
      {groups && type === "rooms" && (
        <Table id="pokoje-body-mistnosti" sortFunction={sortPoints} columns={columns} records={rooms} />
      )}
      {groups && type === "children" && (
        <Table id="pokoje-body-deti" columns={columnsChildren} sortFunction={sortPoints} records={childrenData} />
      )}
    </Drawer>
  );
}
