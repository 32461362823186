import { useState } from "react";
import Page from "../../components/layout/Page";
import LogsTable from "../../components/table/LogsTable";
import useTitle from "../../hooks/useTitle";

export default function LogsPage() {
  const [shouldFetch, setShouldFetch] = useState(false);

  useTitle("Audit logy");

  return (
    <Page title="Audit logy">
      <LogsTable
        setShouldFetch={setShouldFetch}
        shouldFetch={shouldFetch}
        //actionButtons={actionButtonsTable}
      />
    </Page>
  );
}
