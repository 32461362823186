import { Button, Loader, Stack, Text, rem } from "@mantine/core";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import ChildMerchTable from "../../components/table/ChildMerchTable";
import { useFetch } from "../../helpers/useFetch";
import { toast } from "react-hot-toast";

export default function BoardingMerch() {
  const childId = useOutletContext();
  const [merch, setMerch] = useState();
  const [method, setMethod] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const getMerch = async () => {
    setLoading(true);
    const res = await useFetch("/children/" + childId + "/merch", "GET");
    setLoading(false);
    if (res.message) {
      return setMerch([]);
    }
    if (res.data[0].merch.filter((item) => !item.delivered).length === 0) {
      setMethod("undeliver");
    } else {
      setMethod("deliver");
    }
    setMerch(res.data[0].merch);
  };

  const handOverAllMerch = async () => {
    setLoading(true);
    const res = await useFetch("/children/" + childId + "/merch/" + method, "PUT");
    setLoading(false);

    if (res.status !== "ok") {
      return toast.error("Nastala chyba při předání veškerého merche a byla nahlášena.");
    }

    toast.success(`Veškerý merch byl ${method === "deliver" ? "předán" : "vrácen"}.`);

    if (method === "deliver") {
      setMethod("undeliver");
    } else {
      setMethod("deliver");
    }

    setMerch(
      merch.map((item) => {
        item.delivered = method === "deliver" ? true : false;
        return item;
      })
    );
  };

  useEffect(() => {
    getMerch();
  }, [childId]);

  useEffect(() => {
    if (merch?.filter((item) => !item.delivered).length === 0) {
      setMethod("undeliver");
    } else {
      setMethod("deliver");
    }
  }, [tableLoading]);

  return (
    <>
      {loading ? (
        <Text align="center" mt={50}>
          <Loader />
        </Text>
      ) : merch && merch?.length > 0 ? (
        <>
          <ChildMerchTable merches={merch} childId={childId} loading={tableLoading} setLoading={setTableLoading} />
          <Button color={method === "deliver" ? "teal" : "red"} fullWidth mt="md" onClick={() => handOverAllMerch()}>
            {method === "deliver" ? "Předat" : "Vrátit"} veškerý merch
          </Button>
        </>
      ) : (
        <Stack py="xl">
          <Text size={rem(50)} align="center">
            😢
          </Text>
          <h2 align="center">Dítě nemá žádný merch</h2>
        </Stack>
      )}
    </>
  );
}
