import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { pdfjs } from "react-pdf";
import posthog from "posthog-js";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

const user = JSON.parse(localStorage.getItem("user"));

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
