import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconFilePlus, IconTransfer } from "@tabler/icons-react";
import Table from "./core/Table";
import { openDepotHandoverCreateModal, openEditArticleModal, openMoveArticleModal } from "../../helpers/modals";

export const DepotTable = ({
  shouldFetch,
  setShouldFetch,
  serverFetching,
  setServerFetching,
  sellData,
  categories,
  category,
}) => {
  const actionButtons = (article) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Úprava artiklu">
          <ActionIcon
            variant="light"
            color="gray"
            onClick={(e) => {
              e.stopPropagation();
              openEditArticleModal(setShouldFetch, article, sellData);
            }}>
            <IconEdit stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Pohyb artiklu">
          <ActionIcon
            variant="light"
            color="gray"
            onClick={(e) => {
              e.stopPropagation();
              openMoveArticleModal(setShouldFetch, article, sellData);
            }}>
            <IconTransfer stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        {sellData ? null : (
          <Tooltip
            position="top"
            withArrow
            transition="fade"
            transitionDuration={200}
            label="Vytvořit předávací protokol">
            <ActionIcon
              variant="light"
              color="gray"
              onClick={(e) => {
                e.stopPropagation();
                openDepotHandoverCreateModal(setShouldFetch, article);
              }}>
              <IconFilePlus stroke={1.5} size={18} />
            </ActionIcon>
          </Tooltip>
        )}
      </Group>
    );
  };

  const columns = [
    {
      accessor: "name",
      title: "Název",
      disableHiding: true,
      render: (row) => row.name,
    },
    {
      accessor: "quantity",
      title: "Množství",
      render: (row) => row.quantity + " ks",
    },
    {
      accessor: "depot",
      title: "Sklad",
      render: (row) =>
        row.depots.length > 0 ? (
          row.depots.map((depot) => depot.depot.name + " (" + depot.quantity + ")").join(", ")
        ) : (
          <Text span c="dimmed">
            -
          </Text>
        ),
    },
    {
      accessor: "category",
      title: "Kategorie",
      render: (row) =>
        row.category ? (
          row.category.name
        ) : (
          <Text span c="dimmed">
            -
          </Text>
        ),
    },
    {
      accessor: "note",
      title: "Poznámka",
      render: (row) =>
        row.note ? (
          row.note
        ) : (
          <Text span c="dimmed">
            -
          </Text>
        ),
    },
    // ... (!user.permissions.includes("depots.depot.update") ? [] : [
    {
      accessor: "actions",
      title: "Akce",
      disableHiding: true,
      // hidden: !user.permissions.includes("depots.depot.update"),
      render: (depot) => actionButtons(depot),
      narrow: true,
    },
    // ]),
  ];

  const filterConfig = [
    {
      key: "name",
      label: "Název",
      type: "search",
    },
    {
      key: "category_id",
      label: "Kategorie",
      type: "select",
      options: categories?.map((category) => {
        return { value: String(category.id), label: `${category.name}` };
      }),
    },
  ];

  return (
    <Table
      id={"depot-" + (sellData ? "buffet" : "depot")}
      key={"depot-" + (sellData ? "buffet" : "depot")}
      columns={columns}
      useServer={true}
      serverUrl={sellData ? "depot/products/bufet" : "/depot/products"}
      shouldServerFetch={shouldFetch}
      setShouldServerFetch={setShouldFetch}
      serverFetching={serverFetching}
      setServerFetching={setServerFetching}
      filterConfig={sellData ? [] : filterConfig}
      filtersParent={{
        category_id: category ? category : "",
      }}
    />
  );
};
