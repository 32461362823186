import { ActionIcon, Card, Collapse, Group, Paper, Text, Tooltip } from "@mantine/core";
import {
  IconChevronDown,
  IconChevronUp,
  IconListDetails,
  IconMars,
  IconMedicalCross,
  IconTool,
  IconUsers,
  IconVenus,
} from "@tabler/icons-react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IssuesDrawer from "../IssuesDrawer.jsx";
import PointsSummary from "../points/PointsSummary.jsx";
import { openRoomIssueModal } from "../../../helpers/modals.js";
import { useUser } from "../../../providers/UserProvider.jsx";
import { getMode } from "../../../helpers/helpers.js";
import { usePermissions } from "../../../providers/PermissionsProvider.jsx";

export default function GroupOverview({ group, roomsSearch, filteredRoom }) {
  const [opened, setOpened] = useState(false);
  const [openedDrawerId, setOpenedDrawerId] = useState(null);
  const [rooms, setRooms] = useState(group.rooms);
  const { user } = useUser();
  const { hasPermission } = usePermissions();

  useEffect(() => {
    if (roomsSearch !== "") {
      setRooms(
        group.rooms.filter(
          (room) =>
            room?.occupation.filter((kid) =>
              `${kid?.firstName} ${kid?.lastName}`?.toLowerCase().includes(roomsSearch.toLowerCase())
            ).length > 0 || room.name.toLowerCase().includes(roomsSearch.toLowerCase())
        )
      );
      if (rooms.length > 0) {
        setOpened(true);
      } else {
        setOpened(false);
      }
    } else {
      setRooms(group.rooms);
      setOpened(false);
    }
  }, [roomsSearch]);

  useEffect(() => {
    if (filteredRoom) {
      let filteredRooms = group.rooms.filter((room) => room.id === Number(filteredRoom));
      setRooms(filteredRooms);
      if (filteredRooms.length > 0) {
        setOpened(true);
      }
    } else {
      setRooms(group.rooms);
      setOpened(false);
    }
  }, [filteredRoom]);

  const { mode, theme } = getMode();

  return (
    <>
      <Paper p="sm" className="mb-4 cursor-pointer" withBorder radius="md" onClick={() => setOpened(!opened)}>
        <Group justify="space-between">
          <h2>{group.name}</h2>
          <div className="flex items-center gap-4">
            <Text c="dimmed">
              {group?.rooms
                .filter((room) => room.type === "room")
                .reduce((accumulator, object) => {
                  return accumulator + object.capacity;
                }, 0)}{" "}
              míst celkem
            </Text>
            {opened ? <IconChevronUp stroke={1.5} /> : <IconChevronDown stroke={1.5} />}
          </div>
        </Group>
      </Paper>
      <Collapse in={opened}>
        <div className="mb-5 grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
          {rooms.map((room) => {
            return (
              <>
                <Paper
                  radius="md"
                  className={classNames("relative flex flex-col justify-between overflow-hidden")}
                  p={0}
                  withBorder
                  key={group.id}>
                  <div>
                    <Group
                      justify="space-between"
                      className={classNames("py-2 pl-4 pr-3", {
                        "bg-pink-200": room.roomGender === "Dívka" && room.type === "room" && mode === "dark",
                        "bg-cyan-200": room.roomGender === "Chlapec" && room.type === "room" && mode === "dark",
                        "bg-pink-500": room.roomGender === "Dívka" && room.type === "room" && mode === "light",
                        "bg-cyan-500": room.roomGender === "Chlapec" && room.type === "room" && mode === "light",
                        "bg-yellow-200": room.type === "staff" && mode === "dark",
                        "bg-yellow-400": room.type === "staff" && mode === "light",
                        "bg-red-100": room.type === "sickroom" && mode === "dark",
                        "bg-red-500": room.type === "sickroom" && mode === "light",
                        "bg-dark-300": !room.roomGender && mode === "dark",
                        "bg-gray-600": !room.roomGender && mode === "light",
                      })}>
                      <div className="flex items-center gap-2">
                        {room.roomGender === "Dívka" && room.type === "room" && <IconVenus stroke={2} size={24} />}
                        {room.roomGender === "Chlapec" && room.type === "room" && <IconMars stroke={2} size={24} />}
                        {room.type === "sickroom" && <IconMedicalCross stroke={2} size={24} />}
                        {room.type === "staff" && <IconUsers stroke={2} size={24} />}
                        <h2 className="text-xl">{room.name}</h2>
                      </div>
                      {room.issues?.length && hasPermission("accommodation.rooms.issues") ? (
                        <Tooltip
                          position="top"
                          withArrow
                          transition="fade"
                          transitionDuration={200}
                          label="Přehled škod">
                          <ActionIcon
                            variant="light"
                            color="gray"
                            onClick={(e) => {
                              e.stopPropagation();
                              //openEarlyLeaveModal(`Předčasný odjezd – ${child?.firstName} ${child?.lastName}`, child);
                              setOpenedDrawerId(room.id);
                            }}>
                            <IconListDetails stroke={1.5} size={20} />
                          </ActionIcon>
                        </Tooltip>
                      ) : (
                        hasPermission("accommodation.rooms.issues.create") && (
                          <Tooltip
                            position="top"
                            withArrow
                            transition="fade"
                            transitionDuration={200}
                            label="Vytvořit škodu">
                            <ActionIcon
                              variant="light"
                              color="gray"
                              onClick={(e) => {
                                e.stopPropagation();
                                openRoomIssueModal(`Přidání škody`, room, room.name);
                              }}>
                              <IconTool stroke={1.5} size={20} />
                            </ActionIcon>
                          </Tooltip>
                        )
                      )}
                    </Group>
                    <div className="px-4 py-2">
                      {room.occupation.map((record) => {
                        let Component =
                          record.id && room.type === "room" && hasPermission("children.child.detail") ? Link : "div";
                        return (
                          <Component to={`/deti/${record.id}`}>
                            <div className="flex items-center justify-between py-1" key={record.id}>
                              <Text
                                span
                                color={record.lastName ? (record.roomReservation ? "orange.5" : undefined) : "dimmed"}>
                                {record.firstName ? record.firstName : "–"} {record.lastName}
                              </Text>
                              <Text className="text-end" color="dimmed">
                                {record.age} {record.team}
                              </Text>
                            </div>
                          </Component>
                        );
                      })}
                    </div>
                  </div>
                  <div className="flex w-full justify-between px-4 pb-4 pt-0">
                    {room.points &&
                      [...Array(6)].map((_, i) => (
                        <div
                          className={classNames(
                            "flex h-9 w-9 items-center justify-center rounded-full text-xl font-bold",
                            {
                              "bg-red-200/25 text-red-800": room?.points[i]?.points <= 2 && mode === "dark",
                              "bg-red-500/25 text-red-200": room?.points[i]?.points <= 2 && mode === "light",
                              "bg-dark-300":
                                (!room?.points[i]?.points || room?.points[i]?.points > 2) && mode === "dark",
                              "bg-gray-600":
                                (!room?.points[i]?.points || room?.points[i]?.points > 2) && mode === "light",
                            }
                          )}>
                          {room.points[i]?.points ? room.points[i]?.points : "X"}
                        </div>
                      ))}
                  </div>
                </Paper>
                <IssuesDrawer
                  opened={openedDrawerId === room.id}
                  onClose={() => {
                    setOpenedDrawerId(null);
                  }}
                  room={room}
                />
              </>
            );
          })}
        </div>
      </Collapse>
    </>
  );
}
