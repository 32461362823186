import { IconListDetails } from "@tabler/icons-react";
import { useNavigate, useRevalidator } from "react-router-dom";
import Page from "../../components/layout/Page";
import MedicalRecordsTable from "../../components/table/MedicalRecordsTable";
import useTitle from "../../hooks/useTitle";
import { useState } from "react";

const MedicalRecordsTrashPage = () => {
  useTitle(`Smazaná ošetření`);

  const revalidator = useRevalidator();
  const [filters, setFilters] = useState({ trashed: "only" });
  const [shouldFetch, setShouldFetch] = useState(false);

  const navigate = useNavigate();

  const actionButtons = [
    {
      name: "Seznam ošetření",
      onTrigger: (e) => {
        e.stopPropagation();
        navigate("/zdravotnik/osetreni");
      },
      icon: IconListDetails,
      primary: true,
    },
  ];

  const resetTable = () => {
    revalidator.revalidate();
  };

  return (
    <Page title="Smazaná ošetření" actionIcons={actionButtons}>
      <MedicalRecordsTable setShouldFetch={setShouldFetch} shouldFetch={shouldFetch} trash={true} filters={filters} />
    </Page>
  );
};

export default MedicalRecordsTrashPage;
