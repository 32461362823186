import { useSearchParams } from "react-router-dom";

const apiUrl = import.meta.env.VITE_DCREATIVE_URL;
const apiToken = import.meta.env.VITE_DCREATIVE_TOKEN;
const db = "4camps-losovani";

export const getLastDraw = async (type) => {
  const area = JSON.parse(localStorage.getItem("user"))["area"];
  const params = {
    database: db,
    table: type,
    area: area,
  };
  const settings = {
    method: "GET",
    headers: {
      Authorization: apiToken,
    },
  };
  const res = await fetch(apiUrl + "site/getlastroll?" + new URLSearchParams(params), settings);
  return res.json();
};

export const saveDraw = async (data, type) => {
  const area = JSON.parse(localStorage.getItem("user"))["area"];
  data.meta.area = area;
  const params = {
    database: db,
    table: type === "sluzby" ? "duties" : "activities",
  };
  const settings = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: apiToken,
    },
    body: JSON.stringify(data),
  };
  const res = await fetch(apiUrl + "site/drawsave?" + new URLSearchParams(params), settings);
  return res.json();
};
