import { ActionIcon, Group, Menu, Tooltip } from "@mantine/core";
import { getMode } from "../helpers/helpers";
import classNames from "classnames";

export default function ActionIcons({ actions }) {
  const { mode, theme } = getMode();

  const elements = actions.map((action) => {
    const Icon = action.icon;

    return action.type === "dropdown" ? (
      <Menu withArrow shadow="md" key={action.name} width={200}>
        <Menu.Target>
          <span>
            <Tooltip withArrow label={action.name}>
              <ActionIcon
                title={action.name}
                size="lg"
                disabled={action.disabled}
                onClick={action.onTrigger}
                loading={action?.loading}
                color={action?.primary ? "primary" : mode === "dark" ? "dark.5" : "gray.9"}
                variant={mode === "dark" ? "filled" : "light"}>
                <Icon size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </span>
        </Menu.Target>

        <Menu.Dropdown>
          {action.children.map((child) => {
            const Icon = child.icon;
            return (
              <Menu.Item key={child.name} leftSection={<Icon size={18} />} onClick={child.onTrigger}>
                {child.name}
              </Menu.Item>
            );
          })}
        </Menu.Dropdown>
      </Menu>
    ) : (
      <Tooltip key={action.name} withArrow label={action.name} withinPortal>
        <ActionIcon
          title={action.name}
          size="lg"
          disabled={action.disabled}
          onClick={action.onTrigger}
          loading={action?.loading}
          color={action?.primary ? undefined : mode === "dark" ? "dark.5" : "gray.6"}
          variant={mode === "dark" ? "filled" : "light"}>
          <Icon size={20} stroke={1.5} />
        </ActionIcon>
      </Tooltip>
    );
  });

  return (
    <Group wrap="nowrap" gap={4}>
      {elements}
    </Group>
  );
}
