import { ActionIcon, Button, MultiSelect, Select, Text, Tooltip } from "@mantine/core";
import Page from "../../components/layout/Page";
import { useFetch } from "../../helpers/useFetch";
import { useLoaderData } from "react-router-dom";
import { parseFullName } from "../../helpers/helpers";
import Table from "../../components/table/core/Table.jsx";
import { useState } from "react";
import { IconCheck, IconX } from "@tabler/icons-react";
import { toast } from "react-hot-toast";

export const loader = async () => {
  return useFetch(`photographer/flashdisks`);
};

export default function FlashOverview() {
  const flashList = useLoaderData().data;
  const [loading, setLoading] = useState(false);
  const [children, setChildren] = useState(flashList.children);

  const changeMerchState = async (merchToChange, child) => {
    setLoading(true);
    const changeTo = merchToChange.delivered ? "undeliver" : "deliver";
    const res = await useFetch("/merch/" + merchToChange.id + "/" + changeTo, "PUT");
    if (res.status === "ok") {
      setChildren(
        (children.find((childLocal) => childLocal.id === child.id).merch[0].delivered = !merchToChange.delivered)
      );
      setLoading(false);
      toast.success(merchToChange.delivered ? "Flashka předána" : "Flashka vrácena");
    } else {
      setLoading(false);
      console.error(res);
      return toast.error("Nastala chyba při změně stavu flashky");
    }
  };

  const actionButtons = (child) => {
    let merch = child.merch[0];
    return (
      <>
        <Tooltip label={merch.delivered ? "Vrátit" : "Předat"} withArrow withinPortal>
          <ActionIcon
            color={merch.delivered ? "red" : "teal"}
            onClick={() => {
              changeMerchState(merch, child);
            }}
            loading={loading}
            disabled={loading}>
            {merch.delivered ? <IconX stroke={1.5} size={18} /> : <IconCheck stroke={1.5} size={18} />}
          </ActionIcon>
        </Tooltip>
      </>
    );
  };

  const columns = [
    {
      accessor: "name",
      title: "Jméno",
      render: (child) => parseFullName(child),
    },
    {
      accessor: "handed",
      title: "Předáno",
      render: (child) => (child.merch[0].delivered ? <Text color="teal">Ano</Text> : <Text color="red">Ne</Text>),
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (child) => actionButtons(child),
      narrow: true,
    },
  ];

  return (
    <Page title="Seznam flashek" subtitleDown={`Celkový počet flashek: ${flashList.summary.count}`}>
      <Table columns={columns} fetching={loading} records={flashList.children} id="flash" />
    </Page>
  );
}
