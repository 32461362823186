import Table from "./core/Table.jsx";

import { ActionIcon, Group, Tooltip } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import { useState } from "react";
import DocumentViewer from "../boarding/DocumentViewer.jsx";

export default function DocumentsTable({ documents }) {
  const userItem = localStorage.getItem("user");
  const user = JSON.parse(userItem);
  const [viewerOpened, setViewerOpened] = useState(false);
  const [viewingDocument, setViewingDocument] = useState(null);

  const openViewer = (document) => {
    setViewingDocument(document);
    setViewerOpened(true);
  };

  const actionButtons = (pdf) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Stáhnout">
          <ActionIcon
            onClick={() =>
              pdf.url.includes("pdf")
                ? openViewer(new URL(pdf.url + "?_token=" + user.token, import.meta.env.VITE_API_URL).href)
                : undefined
            }
            component={pdf.url.includes("pdf") ? undefined : "a"}
            href={
              pdf.url.includes("pdf")
                ? undefined
                : new URL(pdf.url + "?_token=" + user.token, import.meta.env.VITE_API_URL).href
            }
            variant="light">
            <IconDownload stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const columns = [
    {
      accessor: "name",
      title: "Název",
      render: (pdf) => pdf.label,
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (pdf) => actionButtons(pdf),
      narrow: true,
    },
  ];

  return (
    <>
      <Table id="dokumenty" columns={columns} records={documents} />
      {viewerOpened && <DocumentViewer document={viewingDocument} setOpened={setViewerOpened} />}
    </>
  );
}
