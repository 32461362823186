import { useFetch } from "../../helpers/useFetch.js";
import { Link, useRevalidator } from "react-router-dom";
import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import { IconId, IconEdit, IconTrash, IconWriting, IconEye } from "@tabler/icons-react";
import { useRef, useState } from "react";
import Table from "../../components/table/core/Table.jsx";
import dayjs from "dayjs";
import { openEarlyLeaveModal } from "../../helpers/modals.js";
import { openConfirmModal } from "@mantine/modals";
import { modalTitle } from "../modals/Modal.jsx";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { parseFullName } from "../../helpers/helpers.js";
import { toast } from "react-hot-toast";
import { sortEarlyLeavings } from "./sort.js";
import DocumentSign from "../boarding/DocumentSign.jsx";
import { optimize } from "svgo";
import { earlyLeaveReasons } from "../../helpers/earlyLeave.js";
dayjs.extend(customParseFormat);

export function EarlyLeavingTable({ earlyLeavings }) {
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [signChild, setSignChild] = useState(null);
  const [earlyLeaveDocument, setEarlyLeaveDocument] = useState(null);
  const revalidator = useRevalidator();
  const signatureRef = useRef(null);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const removeEarlyLeave = (leaving) => {
    setLoading(true);
    openConfirmModal({
      title: modalTitle(IconTrash, "Smazat předčasný odjezd"),
      children: <Text size="sm">Opravdu chceš smazat tento předčasný odjezd dítěte?</Text>,
      onCancel: () => setLoading(false),
      onConfirm: async () => {
        const res = await useFetch(`/children/early-leavings/${leaving.id}`, "DELETE");
        if (res.status === "ok") {
          revalidator.revalidate();
          toast.success("Předčasný odjezd odebrán.");
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Nastala chyba při odebrání předčasného odjezdu.");
        }
      },
      onClose: () => setLoading(false),
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
    });
  };

  const childName = (leaving) => {
    return (
      <Text fw={700} color={leaving.hasLeft && "dimmed"}>
        {parseFullName(leaving.child)}
      </Text>
    );
  };

  const expectedLeave = (leaving) => {
    const date = leaving.leavingDate ? dayjs(leaving.leavingDate, "YYYY-MM-DD").format("D. M. YYYY") : "–";
    const time = leaving.leavingTime ? dayjs(leaving.leavingTime, "HH:mm").format("HH:mm") : "–";

    return (
      <Text color={leaving.hasLeft && "dimmed"}>
        {date} {time}
      </Text>
    );
  };

  const reasonOfLeave = (leaving) => {
    const reasons = earlyLeaveReasons;

    return (
      <Text color={leaving.hasLeft && "dimmed"}>
        {reasons.find((reason) => reason.value === leaving.reason)?.label || "Chyba na FE"}
      </Text>
    );
  };

  const downloadDocument = (leaving, type) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = new URL(
      `/export/pdf/early-leaving${type === "deposit" ? "-deposit" : ""}/${leaving.id}/?_token=${session.accessToken}`,
      process.env.NEXT_PUBLIC_API_URL
    ).href;
    downloadLink.target = "_blank";
    downloadLink.click();
  };

  const getDocument = (leaving) => {
    setSelectedDocument(leaving);
    const child = leaving.child.id;
    useFetch(`/children/${child}/documents?accreditation=1&types=early_leaving`)
      .then((res) => {
        setEarlyLeaveDocument(res.data);
        setOpened(true);
        setSignChild(leaving.child.id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const signDocument = async (fields) => {
    const svgElement = document.createElement("svg");
    const trimElement = signatureRef.current;

    const bBox = trimElement.getBBox();
    svgElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svgElement.setAttribute("viewBox", `${bBox.x} ${bBox.y} ${bBox.width} ${bBox.height}`);
    svgElement.setAttribute("width", trimElement.getBBox().width);
    svgElement.setAttribute("height", trimElement.getBBox().height);
    svgElement.appendChild(trimElement.cloneNode(true));

    const result = optimize(svgElement.outerHTML, { multipass: true, floatPrecision: 2 });

    let parser = new DOMParser();
    let doc = parser.parseFromString(result.data, "image/svg+xml");
    let optimizedSvg = doc.querySelector("svg");
    optimizedSvg.setAttribute(
      "viewBox",
      `${Math.round(bBox.x)} ${Math.round(bBox.y)} ${Math.round(bBox.width)} ${Math.round(bBox.height)}`
    );
    optimizedSvg.setAttribute("width", Math.round(bBox.width));
    optimizedSvg.setAttribute("height", Math.round(bBox.height));

    const serialized = new XMLSerializer().serializeToString(optimizedSvg);

    const payload = btoa(serialized);

    useFetch(`/children/${signChild}/documents`, "POST", {
      type: "early_leaving",
      status: "signed",
      fields:
        fields &&
        Object.keys(fields).map((field) => {
          let value = fields[field];
          if (field === "ucastnik-narozeni") {
            value = dayjs(value).format("D. M. YYYY");
          }
          return {
            variable: field,
            value: value,
          };
        }),
      svg: payload,
    })
      .then((res) => {
        if (res.status === "ok") {
          let earlyLeave = false;
          let deposit = false;
          let pocketMoney = false;
          selectedDocument.has_left = true;
          Promise.all([
            useFetch(`/children/early-leavings/${selectedDocument.id}`, "PUT", { ...selectedDocument })
              .then((res1) => {
                earlyLeave = true;
              })
              .catch((err) => {
                console.error(err);
                toast.error("Nastala chyba při potvrzení odjezdu");
              }),
            useFetch(`/children/${selectedDocument.child.id}/money/deposit/reset`, "PUT", { note: "Odjezd" })
              .then((res1) => {
                deposit = true;
              })
              .catch((err) => {
                console.error(err);
                toast.error("Nastala chyba při odstranění kauce");
              }),
            useFetch(`/children/${selectedDocument.child.id}/money/pocketMoney/reset`, "PUT", { note: "Odjezd" })
              .then((res1) => {
                pocketMoney = true;
              })
              .catch((err) => {
                console.error(err);
                toast.error("Nastala chyba při odstranění kapesného");
              }),
          ])
            .then(() => {
              toast.success("Dokument úspěšně podepsán.");
              revalidator.revalidate();
            })
            .catch((err) => {
              console.error(err);
              toast.error("Nastala chyba při podpisu dokumentu a byla nahlášena.");
            });
        } else {
          toast.error("Nastala chyba při podpisu dokumentu a byla nahlášena.");
        }
        setOpened(false);
      })
      .catch((err) => {
        toast.error("Nastala chyba při podpisu dokumentu a byla nahlášena.");
        setOpened(false);
        console.error(err);
      });
  };

  const hasLeft = (leaving) => {
    return leaving.hasLeft ? <Text color="teal">Ano</Text> : <Text color="red">Ne</Text>;
  };

  const actionButtons = (leaving) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Link to={"/deti/" + leaving.child.id}>
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Karta dítěte">
            <ActionIcon variant="light" color="gray">
              <IconId stroke={1.5} size={18} />
            </ActionIcon>
          </Tooltip>
        </Link>
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Zobrazit dokument">
          <ActionIcon
            variant="light"
            component="a"
            color="gray"
            disabled={!leaving?.document?.filePath}
            href={leaving?.document?.filePath ? leaving.document.filePath : null}
            target="_blank">
            <IconEye stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>

        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          opened={loading ? false : undefined}
          label="Odstranit předčasný odjezd">
          <ActionIcon
            variant="light"
            color="red"
            disabled={leaving.hasLeft}
            loading={loading}
            onClick={(e) => {
              e.stopPropagation();
              removeEarlyLeave(leaving);
            }}>
            <IconTrash stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          opened={loading ? false : undefined}
          label="Upravit předčasný odjezd">
          <ActionIcon
            variant="light"
            loading={loading}
            color="gray"
            onClick={(e) => {
              e.stopPropagation();
              openEarlyLeaveModal(`Upravit předčasný odjezd`, leaving.child, leaving, parseFullName(leaving.child));
            }}>
            <IconEdit stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Podepsat předčasný odjezd">
          <ActionIcon
            variant="filled"
            color={leaving.document ? "teal" : "red"}
            onClick={(e) => {
              e.stopPropagation();
              getDocument(leaving);
            }}>
            <IconWriting stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const columns = [
    {
      accessor: "name",
      title: "Jméno",
      render: (leaving) => childName(leaving),
      sortable: true,
    },
    {
      accessor: "leaving_date",
      title: "Předběžný odjezd",
      render: (leaving) => expectedLeave(leaving),
      sortable: true,
    },
    {
      accessor: "reason",
      title: "Důvod",
      render: (leaving) => reasonOfLeave(leaving),
    },
    {
      accessor: "hasLeft",
      title: "Odjel/a",
      render: (leaving) => hasLeft(leaving),
      sortable: true,
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (leaving) => actionButtons(leaving),
      narrow: true,
    },
  ];

  return (
    <>
      <Table id="odjezdy" columns={columns} records={earlyLeavings} sortFunction={sortEarlyLeavings} />
      {opened && earlyLeaveDocument && (
        <DocumentSign
          signDocument={signDocument}
          signatureRef={signatureRef}
          document={earlyLeaveDocument[0]}
          setOpened={setOpened}
        />
      )}
    </>
  );
}
