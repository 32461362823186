import Table from "./core/Table.jsx";

import { ActionIcon, Group, Image, Text, Tooltip } from "@mantine/core";
import { IconArrowBackUp, IconCheck, IconChevronDown } from "@tabler/icons-react";
import { parseDatetime, parseFullName, roomType } from "../../helpers/helpers.js";
import { useRevalidator } from "react-router-dom";
import { useState } from "react";
import { useFetch } from "../../helpers/useFetch.js";
import { toast } from "react-hot-toast";
import classNames from "classnames";
import styles from "../../pages.module.scss";
import { sortRoomIssues } from "./sort.js";
import Gallery from "../Gallery.jsx";

export default function RoomIssuesTable({ rooms }) {
  const revalidator = useRevalidator();
  const [toggleLoading, setToggleLoading] = useState(false);
  const [openedIssue, setOpenedIssue] = useState(0);

  let toggleStatus = async (issue) => {
    setToggleLoading(true);
    let type = issue.resolvedAt === null ? "resolve" : "open";
    const res = await useFetch(`accommodation/rooms/issues/${issue.id}/${type}`, "PUT");
    setToggleLoading(false);
    if (res.status === "ok") {
      toast.success("Škoda byla označena jako " + (type === "resolve" ? " vyřešená" : " znovu otevřena") + ".");
      return revalidator.revalidate();
    }
    return toast.error("Nastala chyba při ukládání změn a byla nahlášena");
  };

  const actionButtons = (issue) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={issue.isPermanent ? "Škoda je trvalá" : issue.resolvedAt ? "Znovu otevřít" : "Vyřešit"}>
          <ActionIcon
            variant="light"
            loading={toggleLoading}
            color={issue.resolvedAt ? "red" : "teal"}
            disabled={issue.isPermanent}
            onClick={(e) => {
              e.stopPropagation();
              toggleStatus(issue);
            }}>
            {issue.resolvedAt === null ? (
              <IconCheck size={18} stroke={1.5} />
            ) : (
              <IconArrowBackUp stroke={1.5} size={18} />
            )}
          </ActionIcon>
        </Tooltip>
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Rozbalit informace">
          <ActionIcon
            variant="light"
            color="gray"
            className={classNames(styles.Expander, openedIssue === issue.id ? styles.Expanded : "")}>
            <IconChevronDown stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const columns = [
    {
      accessor: "room",
      title: "Pokoj/místo",
      render: (issue) => (issue?.room ? issue?.room.fullName : issue?.place),
      sortable: true,
    },
    {
      accessor: "creator",
      title: "Vytvořil",
      render: (issue) => parseFullName(issue.createdBy),
      sortable: true,
    },
    {
      accessor: "createdAt",
      title: "Datum vytvoření",
      render: (issue) => parseDatetime(issue.createdAt),
      sortable: true,
    },
    {
      accessor: "type",
      title: "Typ pokoje",
      render: (issue) => (issue?.room ? roomType(issue.room.type) : <Text c="dimmed">—</Text>),
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (issue) => actionButtons(issue),
      narrow: true,
    },
  ];

  const expandContent = (issue) => {
    return (
      <>
        <p>{issue.text}</p>
        <Group className="mt-4">
          <Gallery
            thumbClassname={"w-[150px] h-[150px] object-cover object-center rounded-lg"}
            images={issue.images.map((image) => {
              return {
                src: image,
                thumb: image,
                description: issue.text,
              };
            })}
          />
        </Group>
        {issue.isPermanent ? (
          <Text c="dimmed" fw="normal">
            Škoda je trvalá, nelze označit za vyřešenou.
          </Text>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <Table
      columns={columns}
      id="skody"
      records={rooms}
      rowExpansion={{
        collapseProps: {
          transitionDuration: 300,
          transitionTimingFunction: "ease",
        },
        content: ({ record }) => expandContent(record),
      }}
      sortFunction={sortRoomIssues}
    />
  );
}
