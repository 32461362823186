import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import { IconBiohazard, IconCheck, IconReportMedical, IconX } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch";
import Table from "./core/Table";

const TodayChecksTable = ({ checks, setReportDetail, resetTable, handleCheck }) => {
  const [loading, setLoading] = useState(false);

  const childName = (child, check, isIsolated) => {
    return (
      <div className="flex items-center">
        <Text span fw="strong" color={check.isChecked && "dimmed"}>
          {child.firstName}&nbsp;{child.lastName}
        </Text>
        {isIsolated && (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="V izolaci">
            <Text h={18} span color="green.6">
              <IconBiohazard stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        )}
      </div>
    );
  };

  const checkDate = (check) => {
    const date = dayjs(check.checkAt).format("D. M. YYYY HH:mm");

    return <Text color={check.isChecked && "dimmed"}>{date}</Text>;
  };

  const lastRecord = (check) => {
    const date = dayjs(check.createdAt).format("D. M. YYYY HH:mm");
    return <Text color={check.isChecked && "dimmed"}>{date}</Text>;
  };

  const changeCheckState = (check) => {
    if (check.isChecked) return handleUncheck(check);
    return handleCheck(check);
  };

  const handleUncheck = async (check) => {
    setLoading(true);
    const res = await useFetch("medical/records/" + check.id + "/uncheck", "PUT");
    setLoading(false);
    if (res.status === "ok") {
      toast.success("Kontrola byla zrušena.");
      check.isChecked = false;
      resetTable();
    }
  };

  const actionButtons = (check) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Detail ošetření">
          <ActionIcon
            variant="light"
            onClick={(e) => {
              e.stopPropagation();
              setReportDetail(check);
            }}>
            <IconReportMedical stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Změnit stav kontroly">
          <ActionIcon
            variant="filled"
            color={check.isChecked ? "red" : "teal"}
            loading={loading}
            onClick={(e) => {
              e.stopPropagation();
              changeCheckState(check);
            }}>
            {check.isChecked ? <IconX stroke={1.5} size={18} /> : <IconCheck stroke={1.5} size={18} />}
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const columns = [
    {
      accessor: "name",
      title: "Jméno dítěte",
      render: (check) => childName(check.child, check, check.isIsolated),
      sortable: true,
    },
    {
      accessor: "team",
      title: "Oddíl",
      render: (check) => {
        return (
          <Text color={check.isChecked && "dimmed"}>
            {check.child?.team?.number ? (
              check.child.team.number
            ) : (
              <Text span color="dimmed">
                —
              </Text>
            )}
          </Text>
        );
      },
      sortable: true,
    },
    {
      accessor: "room",
      title: "Pokoj",
      render: (check) => (
        <Text color={check.isChecked && "dimmed"}>
          {check.child?.room?.name ? (
            check.child.room.name
          ) : (
            <Text span color="dimmed">
              —
            </Text>
          )}
        </Text>
      ),
    },
    {
      accessor: "date",
      title: "Datum",
      render: (check) => checkDate(check),
      sortable: true,
    },
    {
      accessor: "lastCheck",
      title: "Poslední ošetření",
      render: (check) => lastRecord(check),
      sortable: true,
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (check) => actionButtons(check),
      narrow: true,
    },
  ];

  return <Table id="kontroly" columns={columns} records={checks} />;
};

export default TodayChecksTable;
