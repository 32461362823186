import { useContext } from "react";
import { CartContext } from "./CartContext";
import { ActionIcon, Group, NumberInput, Paper, Stack, Text } from "@mantine/core";
import { IconMinus, IconPlus } from "@tabler/icons-react";

export const BuffetItem = ({ product }) => {
  const { cart, addToCart, removeFromCart, setQuantityInCart } = useContext(CartContext);

  return (
    <Paper
      withBorder
      p={0}
      radius={"md"}
      className={
        "flex min-w-[250px] flex-grow basis-1 flex-col justify-between overflow-hidden " +
        (product.quantity === 0 ? "opacity-30" : "")
      }>
      <Stack h={"7rem"} align="center" justify="center" gap={"2px"}>
        <Text fw={"bold"} size="1.25rem" ff={"heading"} ta={"center"}>
          {product.name}
        </Text>
        <Text size="lg">{product.sellPrice} Kč</Text>
      </Stack>
      <Group wrap="nowrap" align="center" justify="space-between">
        <ActionIcon
          onClick={() => {
            removeFromCart(product);
          }}
          variant="filled"
          color="red"
          size={46}
          w={"33%"}
          disabled={cart.find((p) => p.id === product.id)?.quantity === 0 || !cart.find((p) => p.id === product.id)}>
          <IconMinus size={20} stroke={1.5} />
        </ActionIcon>
        <NumberInput
          value={cart.find((p) => p.id === product.id)?.quantity || 0}
          onChange={(value) => setQuantityInCart(product, value)}
          hideControls
          step={1}
          allowDecimal={false}
          allowNegative={false}
          size={"46px"}
          w={"33%"}
          min={0}
          max={product.quantity}
          disabled={product.quantity === 0}
        />
        <ActionIcon
          onClick={() => {
            addToCart(product);
          }}
          variant="filled"
          color="teal"
          size={46}
          w={"33%"}
          disabled={cart.find((p) => p.id === product.id)?.quantity === product.quantity || product.quantity === 0}>
          <IconPlus size={20} stroke={1.5} />
        </ActionIcon>
      </Group>
    </Paper>
  );
};
