import { ActionIcon, Button } from "@mantine/core";
import { IconArrowLeft, IconLoader2 } from "@tabler/icons-react";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Roullete } from "../../components/roulette/Roullete";
import dayjs from "dayjs";
import { saveDraw } from "../../helpers/useRoulette";

export default function RouletteRollPage() {
  const { type } = useParams();
  const personal = localStorage
    .getItem("personal")
    ?.split("\n")
    ?.filter((row) => row !== "" && row !== " ");
  const data = localStorage
    .getItem(type === "sluzby" ? "duties" : "activities")
    ?.split("\n")
    ?.filter((row) => row !== "" && row !== " ");
  const [rolled, setRolled] = useState([]);
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSave = async () => {
    const date = dayjs(new Date()).format("YYYY-MM-DD");
    const dataLocal = [{}];
    if (type === "sluzby") {
      dataLocal[0].dutyName = "Služba";
    } else {
      dataLocal[0].activityName = "Aktivity";
    }
    dataLocal[0].personName = "Jméno";
    dataLocal[0].date = date;
    const reqData = {
      data: dataLocal.concat(rolled),
      meta: {
        title: type,
        folder: "4camps-losovani",
      },
    };
    setLoading(true);
    const res = await saveDraw(reqData, type);
    console.log(res);
    setLoading(false);
    if (res.file) {
      return setResponse(res);
    }
  };

  return (
    <div className="RoulettePage relative flex h-full w-full items-center justify-center">
      <ActionIcon
        className="absolute left-2 top-2 p-2"
        onClick={() => navigate(-1)}
        variant="light"
        color="indigo"
        w={"fit-content"}
        h={"fit-content"}>
        <IconArrowLeft size={24} stroke={1.5} />
      </ActionIcon>
      {!personal || !data ? (
        <h3>Nebyly zadány data na losování</h3>
      ) : rolled.length === data.length ? (
        <div className="flex flex-col">
          <h3 className="mb-4 text-2xl">Výsledky losu:</h3>
          {rolled.map((item) => {
            return (
              <p key={type === "sluzby" ? item.dutyName : item.activityName} className="my-1 text-lg">
                <b>{item.personName}</b> - {type === "sluzby" ? item.dutyName : item.activityName}
              </p>
            );
          })}
          {response ? (
            <Button
              className="mt-8 text-lg"
              component="a"
              href={import.meta.env.VITE_DCREATIVE_URL + "resource/4camps-losovani/" + response.file}>
              Stáhnout tabulku
            </Button>
          ) : (
            <Button className="mt-8 text-lg" onClick={handleSave}>
              {loading ? (
                <IconLoader2 size={16} stroke={1.5} className={loading ? "animate-spin" : ""} />
              ) : (
                "Uložit a vytvořit tabulku"
              )}
            </Button>
          )}
        </div>
      ) : (
        <Roullete data={data} personalData={personal} type={type} results={rolled} setResults={setRolled} />
      )}
    </div>
  );
}
