import { ActionIcon, Text, Tooltip } from "@mantine/core";
import { IconArrowBack, IconCheck, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch";
import Table from "./core/Table";

const ChildMerchTable = ({ merches, childId, loading, setLoading }) => {
  const changeMerchState = async (merchToChange) => {
    setLoading(true);
    const changeTo = merchToChange.delivered ? "undeliver" : "deliver";
    const res = await useFetch("/merch/" + merchToChange.id + "/" + changeTo, "PUT");
    setLoading(false);
    if (res.status === "ok") {
      merches.find((piece) => piece.id === merchToChange.id).delivered = !merchToChange.delivered;
      return toast.success(merchToChange.delivered ? "Merch předán" : "Merch vrácen");
    }
    return toast.error("Nastala chyba při změně stavu merche");
  };

  const merchName = (merch) => {
    return <Text color={merch.delivered && "dimmed"}>{merch.name}</Text>;
  };

  const merchCount = (merch) => {
    return <Text color={merch.delivered && "dimmed"}>{merch.count}x</Text>;
  };

  const merchSize = (merch) => {
    return <Text color={merch.delivered && "dimmed"}>{merch.size ? merch.size : "–"}</Text>;
  };

  const actions = (merch) => {
    return (
      <Tooltip label={merch.delivered ? "Vrátit" : "Předat"} withArrow withinPortal>
        <ActionIcon
          color={merch.delivered ? "red" : "teal"}
          variant={merch.delivered ? "light" : "filled"}
          onClick={() => {
            changeMerchState(merch);
          }}
          loading={loading}
          disabled={loading}>
          {merch.delivered ? <IconArrowBack stroke={1.5} size={20} /> : <IconCheck stroke={1.5} size={20} />}
        </ActionIcon>
      </Tooltip>
    );
  };

  const columns = [
    {
      accessor: "name",
      title: "Merch",
      render: (merch) => merchName(merch),
    },
    {
      accessor: "count",
      title: "Počet",
      render: (merch) => merchCount(merch),
    },
    {
      accessor: "size",
      title: "Velikost",
      render: (merch) => merchSize(merch),
    },
    {
      accessor: "actionButtons",
      title: "Akce",
      render: (merch) => actions(merch),
      narrow: true,
    },
  ];

  return (
    <Table
      fetching={loading}
      id="merch-dite"
      records={merches.sort((a, b) => a.delivered - b.delivered)}
      columns={columns}
    />
  );
};

export default ChildMerchTable;
