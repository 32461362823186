import { Collapse } from "@mantine/core";
import { useRowExpansionStatus } from "./hooks";
import classes from "./TableRowExpansion.module.scss";
import classNames from "classnames";
import { getMode } from "../../../helpers/helpers";

export default function TableRowExpansion({ open, colSpan, content, collapseProps }) {
  const { expanded, visible } = useRowExpansionStatus(open, collapseProps?.transitionDuration);
  const { mode } = getMode();

  return visible ? (
    <>
      {/* add an empty row to maintain striped rows consistency */}
      <tr />
      <tr>
        <td
          className={classNames(
            classes.cell,
            {
              [classes.expandedCell]: expanded,
              "border-b-dark-400": mode === "dark",
              "border-b-gray-600": mode === "light",
            },
            "border-0 border-b-2 border-solid"
          )}
          colSpan={colSpan}>
          <Collapse in={expanded} {...collapseProps}>
            {content()}
          </Collapse>
        </td>
      </tr>
    </>
  ) : null;
}
