import { ActionIcon, Button, Group, Input, Loader, NumberInput, Paper, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { openConfirmModal } from "@mantine/modals";
import { IconBasketDown, IconCash, IconCashOff } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useOutletContext, useRevalidator } from "react-router-dom";
import { modalTitle } from "../../components/modals/Modal";
import { useFetch } from "../../helpers/useFetch";
import classNames from "classnames";
import { getMode } from "../../helpers/helpers";

const BoardingPocketMoney = () => {
  const childId = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [deposits, setDeposits] = useState();
  const [transactions, setTransactions] = useState();
  const [depositLoading, setDepositLoading] = useState(false);
  const revalidator = useRevalidator();
  const { mode, theme } = getMode();

  const form = useForm({
    initialValues: {
      amount: 0,
    },
    validate: {
      amount: (value) => {
        if (value <= 0) return "Částka musí být větší než 0";
      },
    },
  });

  const getDeposits = async () => {
    setLoading(true);
    const res = await useFetch(`children/${childId}/money/pocketMoney`, "GET");
    setDeposits(res.data);
    setTransactions(res.data.transactions);
    setLoading(false);
  };

  const removeDeposit = async (transaction) => {
    setDepositLoading(true);
    openConfirmModal({
      title: modalTitle(IconCashOff, "Vrácení kapesného"),
      children: <Text size="sm">Opravdu chceš vrátit toto kapesné?</Text>,
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
      onCancel: () => setDepositLoading(false),
      onConfirm: () => {
        toast.promise(
          useFetch(`/children/${childId}/money/pocketMoney/withdraw`, "PUT", {
            amount: transaction.amount,
            currency: transaction.currency,
          }),
          {
            loading: "Potvrzuji vrácení...",
            success: (res) => {
              form.reset();
              revalidator.revalidate();
              setDepositLoading(false);
              if (res.status === "ok") {
                setTransactions([...transactions, res.data]);
                setDeposits({ ...deposits, amount: deposits.amount - transaction.amount });
                return <p>Kapesné vráceno!</p>;
              } else {
                return <p>Nastala chyba při vrácení kapesného a byla nahlášena.</p>;
              }
            },
            error: () => {
              setDepositLoading(false);
              return <p>Nastala chyba při vrácení kapesného a byla nahlášena.</p>;
            },
          }
        );
      },
      onClose: () => {
        setDepositLoading(false);
      },
    });
  };

  useEffect(() => {
    getDeposits();
  }, [childId]);

  const handleSubmit = async (values) => {
    setDepositLoading(true);
    const res = await useFetch("children/" + childId + "/money/pocketMoney/deposit", "PUT", values);
    setDepositLoading(false);
    if (res.status === "ok") {
      setTransactions([...transactions, res.data]);
      setDeposits({ ...deposits, amount: deposits.amount + values.amount });
      return toast.success("Kapesné přidáno!");
    }
    return toast.error("Nastala chyba při přidávání kapesného.");
  };

  if (loading) {
    return (
      <Text align="center" mt={50}>
        <Loader />
      </Text>
    );
  }

  const sumOfMoney = transactions?.reduce((acc, transaction) => {
    return acc + transaction.amount;
  }, 0);

  return (
    <div>
      <Paper p="lg" className="mb-4" withBorder radius="lg">
        <h2>Přidání kapesného</h2>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Group gap={"md"} className="w-full" grow={1} mt="lg">
            <Input.Wrapper label="Částka" withAsterisk>
              <NumberInput {...form.getInputProps("amount")} required precision={0} min={0} disabled={depositLoading} />
            </Input.Wrapper>
          </Group>
          <Button type="submit" mt={"lg"} className="w-full" loading={depositLoading}>
            Přidat kapesné
          </Button>
        </form>
      </Paper>
      <Paper p="lg" className="mb-4" withBorder radius="lg">
        <h2 className="mb-4">Kapesné</h2>
        <Stack gap={"md"}>
          {transactions?.map((transaction) => (
            <Group
              key={transaction.id}
              className={classNames("justify-between rounded-md border-2 border-solid p-2", {
                "border-dark-500 bg-dark-300": mode === "dark",
                "border-gray-500 bg-white-900": mode === "light",
              })}>
              <Group gap={"6px"}>
                {transaction.type === "deposit" ? (
                  <IconCash stroke={1.5} size={24} />
                ) : (
                  <IconCashOff stroke={1.5} size={24} />
                )}
                <Text>{transaction.type === "deposit" ? "Kapesné" : "Vrácení"}</Text>
              </Group>
              <Text c={transaction.type === "withdraw" ? "red.6" : "currentColor"}>
                {transaction.amount} {transaction.currency}
              </Text>
              <Group gap="xs">
                {transaction.type === "deposit" && (
                  <ActionIcon
                    loading={depositLoading}
                    disabled={sumOfMoney < transaction.amount}
                    variant={"light"}
                    color={"red"}
                    onClick={() => removeDeposit(transaction)}>
                    <IconCashOff stroke={1.5} size={20} />
                  </ActionIcon>
                )}
              </Group>
            </Group>
          ))}
        </Stack>
        <Text mt="sm" c="dimmed">
          <Text span fw="bold">
            Celková částka:
          </Text>{" "}
          {deposits?.amount?.czk} CZK
        </Text>
      </Paper>
    </div>
  );
};

export default BoardingPocketMoney;
