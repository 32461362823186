import { useEffect, useState } from "react";
import { useFetch } from "../../helpers/useFetch";
import { useUser } from "../../providers/UserProvider";
import { useForm } from "@mantine/form";
import {
  ActionIcon,
  Button,
  Divider,
  Group,
  Input,
  Loader,
  NumberInput,
  Select,
  Stack,
  Stepper,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { closeModal } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import toast from "react-hot-toast";
import RichSelect from "../RichSelect";

export const DepotHandoverCreateModal = ({ context, id, innerProps: { setShouldFetch, article } }) => {
  const [loading, setLoading] = useState(false);
  const [staff, setStaff] = useState(null);
  const [items, setItems] = useState([]);
  const [active, setActive] = useState(0);
  const [itemsText, setItemsText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [itemsMaxValues, setItemsMaxValues] = useState([]);
  const { user } = useUser();

  const nextStep = () => {
    if (
      form.values.transferer_id === null ||
      form.values.receiver_id === null ||
      form.values.handover_date === null ||
      form.values.name === null ||
      form.values.name === ""
    ) {
      return toast.error("Nejsou vyplněny povinná pole");
    }
    if (form.values.receiver_id === form.values.transferer_id) {
      return toast.error("Předávající a příjemce se nesmí rovnat");
    }
    setActive((current) => (current < 2 ? current + 1 : current));
  };
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const form = useForm({
    initialValues: {
      name: "",
      transferer_id: null,
      receiver_id: null,
      handover_date: new Date(),
      note: "",
    },
  });

  const onSubmit = async (values) => {
    setLoading(true);
    let data = {
      ...form.values,
      transferer_id: parseInt(form.values.transferer_id),
      receiver_id: parseInt(form.values.receiver_id),
      items: [
        ...items.map((i) => {
          return { product_id: parseInt(i.id), quantity: parseInt(i.quantity) };
        }),
      ],
    };
    if (itemsText !== "") {
      data.items.push({ text: itemsText });
    }
    const res = await useFetch("handover-protocols", "POST", data);
    setLoading(false);
    if (res.status === "ok") {
      toast.success("Předávací protokol byl úspěšně vytvořen");
      if (setShouldFetch) setShouldFetch(true);
      return context.closeModal(id);
    }
    toast.error("Chyba při uložení");
    return;
  };

  const getData = async () => {
    setLoading(true);
    const res = await useFetch("staff/all");
    if (res.data.find((s) => s.id === user.id)) {
      form.setValues({ transferer_id: String(user.id) });
    }
    setStaff(res.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    if (article) {
      const itemsLocal = [...items];
      itemsLocal.push({ id: article.id, label: article.name, quantity: 1 });
      setItems(itemsLocal);
    }
  }, []);

  const searchData = async () => {
    setSearching(true);
    const res = await useFetch(`depot/products?filter[name]=${searchText}`);
    setSearching(false);
    setItemsMaxValues(res.data.map((article) => ({ id: String(article.id), quantity: article.quantity })));
    setSearchResults(res.data.map((article) => ({ value: String(article.id), label: article.name })));
  };

  useEffect(() => {
    let oldSearchText = searchText;
    if (searchText.length > 2) {
      setTimeout(() => {
        if (oldSearchText !== searchText) return;
        searchData();
      }, 300);
    } else {
      setSearchResults([]);
    }
  }, [searchText]);

  const onItemSelect = (itemId) => {
    const itemsLocal = [...items];
    const item = itemsLocal.find((i) => i.id === itemId);
    if (item) return toast.error("Tento artikl je již vybrán");
    const selectedItem = searchResults.find((i) => i.value === itemId);
    itemsLocal.push({
      id: selectedItem.value,
      label: selectedItem.label,
      quantity: 1,
      max: itemsMaxValues.find((i) => i.id === selectedItem.value).quantity,
    });
    setItems(itemsLocal);
  };

  const setItemValue = (itemId, value) => {
    const itemsLocal = [...items];
    itemsLocal.map((i) => {
      if (i.id === itemId) {
        i.quantity = value;
      }
      return i;
    });
    setItems(itemsLocal);
  };

  const onItemRemove = (item) => {
    console.log("remove");
    const itemsLocal = [...items];
    itemsLocal.splice(itemsLocal.indexOf(item), 1);
    setItems(itemsLocal);
  };

  return (
    <>
      {!staff ? (
        <div className="flex h-full w-full flex-col items-center justify-center py-8">
          <Loader />
        </div>
      ) : (
        <form onSubmit={form.onSubmit(onSubmit)} className="flex flex-col gap-2">
          <Stepper mt="sm" active={active} onStepClick={setActive} breakpoint="sm" completedIcon={<IconCheck />}>
            <Stepper.Step allowStepSelect={active > 0} label="Základní informace">
              <Input.Wrapper label="Název (obecná kategorie, např. Elektronika, Merch, ...)" withAsterisk>
                <TextInput {...form.getInputProps("name")} placeholder="Název" />
              </Input.Wrapper>
              <Select
                label="Předávající"
                placeholder="Vyberte"
                {...form.getInputProps("transferer_id")}
                data={staff.map((s) => ({ value: String(s.id), label: s.firstName + " " + s.lastName }))}
                required
                searchable
                clearable
              />
              <Select
                label="Příjemce"
                placeholder="Vyberte"
                {...form.getInputProps("receiver_id")}
                data={staff.map((s) => ({ value: String(s.id), label: s.firstName + " " + s.lastName }))}
                required
                searchable
                clearable
              />
              <Input.Wrapper label="Datum" withAsterisk>
                <DateInput {...form.getInputProps("handover_date")} placeholder="Datum" locale="cs" />
              </Input.Wrapper>
              <Textarea label="Poznámka (né artikly)" placeholder="Poznámka" {...form.getInputProps("note")} rows={3} />
              <Group grow gap="md" mt={"md"}>
                <Button
                  type="button"
                  variant="light"
                  loading={loading}
                  onClick={() => closeModal(id)}
                  className="w-full">
                  Zrušit
                </Button>
                <Button type="button" variant="filled" loading={loading} onClick={nextStep} className="w-full">
                  Určit artikly
                </Button>
              </Group>
            </Stepper.Step>
            <Stepper.Step
              label="Výběr artiklů"
              disabled={
                form.values.transferer_id === null ||
                form.values.receiver_id === null ||
                form.values.handover_date === null ||
                form.values.name === null ||
                form.values.name === "" ||
                form.values.receiver_id === form.values.transferer_id
              }>
              <Text mt="sm">{`Název: ${form.values.name}`}</Text>
              <Text>
                {`
                  Předání věcí z rukou ${staff.find((s) => String(s.id) === form.values.transferer_id)?.firstName + " "}
                    ${staff.find((s) => String(s.id) === form.values.transferer_id)?.lastName} do rukou ${
                  staff.find((s) => String(s.id) === form.values.receiver_id)?.firstName + " "
                }
                    ${staff.find((s) => String(s.id) === form.values.receiver_id)?.lastName}
                `}
              </Text>
              <Text mb="md">
                {`
                  Datum: ${form.values.handover_date.toLocaleDateString()}
                `}
              </Text>
              <Divider mt="md" mb="md" />
              <RichSelect
                searchValue={searchText}
                onSearchChange={setSearchText}
                data={searchResults}
                value={null}
                label={(data) => data.label}
                placeholder={"Vyber artikl"}
                searchable
                getCreateLabel={(query) => `Vytvořit nový typ: ${query}`}
                searching={searching}
                onChange={(value) => onItemSelect(value)}
              />
              {items && (
                <Stack my={"sm"} spacing={"xs"}>
                  {items.map((item) => {
                    return (
                      <Group key={item.id} w={"100%"} justify="space-between">
                        <Text size="lg" fw={"bold"}>
                          {item.label}
                        </Text>
                        <Group>
                          <Input.Wrapper>
                            <NumberInput
                              value={item.quantity}
                              onChange={(value) => setItemValue(item.id, value)}
                              min={1}
                              max={item.max}
                              step={1}
                              w={"5rem"}
                            />
                          </Input.Wrapper>
                          <ActionIcon color="red" variant="light" onClick={() => onItemRemove(item)} size={"lg"}>
                            <IconX stroke={1.5} />
                          </ActionIcon>
                          <Button color="indigo" variant="light" onClick={() => setItemValue(item.id, item.max)}>
                            MAX
                          </Button>
                        </Group>
                      </Group>
                    );
                  })}
                </Stack>
              )}
              <Text size="sm" my={"sm"} c={"dimmed"}>
                Vyber artikl nebo je sepiš
              </Text>
              <Textarea
                label="Předané artikly"
                placeholder="Předané artikly"
                onChange={(e) => setItemsText(e.target.value)}
                value={itemsText}
                rows={3}
              />
              <Group grow gap="md" mt={"md"}>
                <Button type="button" variant="filled" loading={loading} onClick={prevStep} className="w-full">
                  Změnit detaily
                </Button>
                <Button type="submit" variant="filled" loading={loading} onClick={onSubmit} className="w-full">
                  Uložit
                </Button>
              </Group>
            </Stepper.Step>
          </Stepper>
        </form>
      )}
    </>
  );
};
