import { useEffect, useState } from "react";
import { ActionIcon, Badge, Button, Card, Flex, Group, Loader, Paper, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import "dayjs/locale/cs";
import { useFetch } from "../../helpers/useFetch.js";
import { openAddPcChildAccount, openEarlyLeaveModal } from "../../helpers/modals.js";
import { IconDeviceGamepad3, IconDoor, IconEdit, IconTrash } from "@tabler/icons-react";
import { parseFullName } from "../../helpers/helpers.js";
import { openConfirmModal } from "@mantine/modals";
import { modalTitle } from "./Modal.jsx";
import { toast } from "react-hot-toast";

const icons = {
  "Epic Games": "https://c.1password.com/richicons/images/login/60/www.epicgames.com.png",
  "Battle.net": "https://c.1password.com/richicons/images/login/60/www.battle.net.png",
  Steam: "https://c.1password.com/richicons/images/login/60/www.steampowered.com.png",
  Origin: "https://c.1password.com/richicons/images/login/60/www.origin.com.png",
  Uplay: "https://c.1password.com/richicons/images/login/60/www.ubisoft.com.png",
  Mojang: "https://c.1password.com/richicons/images/login/60/www.minecraft.net.png",
  Minecraft: "https://c.1password.com/richicons/images/login/60/www.minecraft.net.png",
  Microsoft: "https://c.1password.com/richicons/images/login/60/www.microsoft.com.png",
  GOG: "https://c.1password.com/richicons/images/login/60/www.gog.com.png",
  Rockstar: "https://c.1password.com/richicons/images/login/60/www.rockstargames.com.png",
  EA: "https://c.1password.com/richicons/images/login/60/www.ea.com.png",
  Bethesda: "https://c.1password.com/richicons/images/login/60/www.bethesda.com.png",
  Roblox: "https://c.1password.com/richicons/images/login/60/www.roblox.com.png",
  "Riot Games": "https://c.1password.com/richicons/images/login/60/www.riotgames.com.png",
  Blizzard: "https://c.1password.com/richicons/images/login/60/www.blizzard.com.png",
};

export default function PcChildAccountModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [gameAccounts, setGameAccounts] = useState(null);
  const [fetchingDiscord, setFetchingDiscord] = useState(true);
  const [discordAccount, setDiscordAccount] = useState(null);

  useEffect(() => {
    getGameAccounts();
    getDiscordAccount();
  }, [innerProps]);

  function getGameAccounts() {
    setFetching(true);
    const child = innerProps?.child;
    useFetch(`/pc/game-account/ticket/${child.id}`).then((res) => {
      if (res.data) {
        setGameAccounts(res.data);
        setFetching(false);
      }
    });
  }

  function getDiscordAccount() {
    setFetchingDiscord(true);
    const child = innerProps?.child;
    useFetch(`/pc/discord/ticket/${child.id}`).then((res) => {
      if (res.data) {
        setDiscordAccount(res.data);
        setFetchingDiscord(false);
      }
    });
  }

  const deleteGameAccount = async (id) => {
    openConfirmModal({
      title: modalTitle(IconDoor, "Smazat herní účet"),
      children: <Text size="sm">Opravdu chceš smazat tento herní účet?</Text>,
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
      onCancel: () => setLoading(false),
      onConfirm: () => {
        toast.promise(useFetch(`/pc/game-account/${id}`, "DELETE"), {
          loading: "Odstraňuji herní účet...",
          success: (res) => {
            setLoading(false);
            getGameAccounts();
            return <p>Herní účet odstraněn</p>;
          },
          error: () => {
            setLoading(false);
            return <p>Nastala chyba při odstranění herního účet a byla nahlášena.</p>;
          },
        });
      },
      onClose: () => {
        setLoading(false);
      },
    });
  };

  return (
    <div className="relative overflow-y-scroll">
      <Group className="flex w-full flex-col items-start gap-0">
        <Text size={"sm"} fw="bold" className="mb-2">
          Discord účet
        </Text>
        {fetchingDiscord ? (
          <div className="flex h-full w-full flex-col items-center justify-center py-8">
            <Loader />
          </div>
        ) : (
          <Group className="w-full">
            <Paper withBorder p="md" className="w-full">
              {discordAccount.id === null ? (
                <div className="flex items-center gap-4">
                  <img
                    src="https://c.1password.com/richicons/images/login/60/www.discord.com.png"
                    alt="discord"
                    width="32"
                    height="32"
                    className="rounded-md"
                  />

                  <div className="flex flex-col">
                    <Badge color="red" className="mb-2">
                      Čeká na ověření
                    </Badge>
                    <Text>
                      Ověřovací kód:{" "}
                      <Text span className="font-mono">
                        {discordAccount.code}
                      </Text>
                    </Text>
                    <Text c="dimmed" className="text-sm">
                      Ověření přes /verify
                    </Text>
                  </div>
                </div>
              ) : (
                <div className="flex items-center gap-4">
                  <img
                    src="https://c.1password.com/richicons/images/login/60/www.discord.com.png"
                    alt="discord"
                    width="32"
                    height="32"
                    className="rounded-md"
                  />
                  <div className="flex flex-col">
                    <Badge color="teal" className="mb-1">
                      Propojeno!
                    </Badge>
                    <Text>
                      @{discordAccount.username}{" "}
                      <Text span c="dimmed">
                        ({discordAccount.id})
                      </Text>
                    </Text>
                  </div>
                </div>
              )}
            </Paper>
          </Group>
        )}

        <Text size={"sm"} fw="bold" className="mb-2 mt-4">
          Seznam herních účtů
        </Text>

        {fetching ? (
          <div className="flex h-full w-full flex-col items-center justify-center py-8">
            <Loader />
          </div>
        ) : (
          <Group className="w-full gap-2">
            {gameAccounts.length ? (
              <>
                {gameAccounts.map((gameAccount) => {
                  return (
                    <Paper withBorder p="md" key={gameAccount.id} className="w-full">
                      <div className="flex w-full items-center gap-4">
                        <div className="relative flex h-8 w-8 items-center justify-center overflow-hidden rounded-md bg-white-900">
                          {icons[gameAccount.gameGroup.name] ? (
                            <img
                              src={icons[gameAccount.gameGroup.name]}
                              alt={gameAccount.gameGroup.name}
                              width="32"
                              height="32"
                            />
                          ) : (
                            <IconDeviceGamepad3 size={24} className="text-black-900" />
                          )}
                        </div>
                        <div className="flex grow justify-between">
                          <div className="grow">
                            <p className="mb-1 font-bold">{gameAccount.gameGroup.name}</p>
                            {gameAccount.gameGroup.loginIdentifier === "email" ? (
                              <Text size="sm" c="dimmed">
                                E-mail: {gameAccount.email} {gameAccount.username ? `(${gameAccount.username})` : ""}
                              </Text>
                            ) : (
                              <Text size="sm" c="dimmed">
                                Nick: {gameAccount.username} {gameAccount.email ? `(${gameAccount.email})` : ""}
                              </Text>
                            )}
                            <Text size="sm" c="dimmed">
                              Heslo:{" "}
                              <Text span className="font-mono">
                                {gameAccount.password}
                              </Text>
                            </Text>
                            {gameAccount.note && (
                              <Text size="sm" c="dimmed">
                                Poznámka: {gameAccount.note}
                              </Text>
                            )}
                          </div>
                          <Group gap={8}>
                            <ActionIcon
                              variant="light"
                              loading={loading}
                              color="gray"
                              onClick={(e) => {
                                e.stopPropagation();
                                openAddPcChildAccount(innerProps?.child, gameAccount);
                              }}>
                              <IconEdit size={18} stroke={1.5} />
                            </ActionIcon>
                            <ActionIcon color="red" variant="light" onClick={() => deleteGameAccount(gameAccount.id)}>
                              <IconTrash size={18} stroke={1.5} />
                            </ActionIcon>
                          </Group>
                        </div>
                      </div>
                    </Paper>
                  );
                })}
              </>
            ) : (
              <Paper withBorder p="md" className="w-full border-dashed">
                <Text size="sm" c="dimmed">
                  Toto dítě nemá žádný herní účet.
                </Text>
              </Paper>
            )}
          </Group>
        )}
      </Group>

      <Button
        fullWidth
        type="submit"
        loading={loading}
        mt="md"
        onClick={() => openAddPcChildAccount(innerProps?.child)}>
        Přidat herní účet
      </Button>
    </div>
  );
}
