import TeamsManageTable from "../../components/table/TeamsManageTable.jsx";
import { useFetch } from "../../helpers/useFetch.js";
import { useLoaderData } from "react-router-dom";
import useTitle from "../../hooks/useTitle.js";
import Page from "../../components/layout/Page.jsx";

export async function loader() {
  let teams = await useFetch(`teams`);
  let staff = await useFetch(`staff`);

  return { staff, teams };
}

export default function TeamsManagePage() {
  const teams = useLoaderData().teams.data;
  const staff = useLoaderData().staff.data;
  useTitle(`Přehled oddílů`);

  return (
    <Page title="Přehled oddílů">
      <TeamsManageTable teams={teams} staff={staff} />
    </Page>
  );
}
