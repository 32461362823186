import { ActionIcon, Text } from "@mantine/core";
import styles from "./Modal.module.scss";

export default function ModalTitle({ icon, title, smallTitle }) {
  const Icon = icon;
  return (
    <div className="pointer-events-none flex items-center pr-2">
      {Icon && (
        <ActionIcon size="lg" variant="light" mr="md" radius="md" className="pointer-events-none">
          <Icon stroke={1.5} size={20} />
        </ActionIcon>
      )}

      <span className="flex flex-col leading-7">
        <h2 className="text-lg md:text-2xl">{title}</h2>
        {smallTitle && (
          <Text c="dimmed" className="text-sm" span fw="normal">
            {smallTitle}
          </Text>
        )}
      </span>
    </div>
  );
}
