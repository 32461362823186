import { ActionIcon, Group, Text, Tooltip, Table as TableMantine } from "@mantine/core";
import { useState } from "react";
import Table from "./core/Table.jsx";
import { Link, useNavigate } from "react-router-dom";
import { parseFullName } from "../../helpers/helpers.js";
import classNames from "classnames";
import styles from "../../pages.module.scss";
import { IconCash, IconChevronDown, IconId } from "@tabler/icons-react";
import { openMoneyChangeModal } from "../../helpers/modals.js";
import dayjs from "dayjs";

export function ChildrenMoneyTable({ moneyCategory, moneyData, fetching, shouldFetch, setShouldFetch }) {
  const navigate = useNavigate();
  const [openChildId, setOpenChildId] = useState(0);
  const [filters, setFilters] = useState({});

  const expandChild = (child) => {
    if (openChildId === child.id) return setOpenChildId(0);
    setOpenChildId(child.id);
  };

  const actionButtons = (child) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Změna stavu peněz">
          <ActionIcon
            variant={"light"}
            color="gray"
            onClick={(e) => {
              e.stopPropagation();
              openMoneyChangeModal(
                `Změna stavu ${window.location.pathname.includes("kauce") ? "kaucí" : "kapesného"}`,
                null,
                child,
                child.money.amount.czk !== 0 ? child.money.amount.czk : child.money.amount.eur,
                () => setShouldFetch(true)
              );
            }}>
            <IconCash stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Link to={"/deti/" + child.id}>
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Karta dítěte">
            <ActionIcon variant="light" color="gray">
              <IconId stroke={1.5} size={18} />
            </ActionIcon>
          </Tooltip>
        </Link>
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Rozbalit informace">
          <ActionIcon
            variant="light"
            color="gray"
            className={classNames(styles.Expander, openChildId === child.id ? styles.Expanded : "")}>
            <IconChevronDown stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const expandContent = (child) => {
    const rows = child.money.transactions.map((x) => (
      <tr key={x.id}>
        <td>
          {x.amount} {x.currency}
        </td>
        <td>{x.note ? x.note : <Text c="dimmed">—</Text>}</td>
        <td>
          {x.createdBy.firstName} {x.createdBy.lastName}
        </td>
        <td className="w-auto">{dayjs(x.createdAt).format("D. M. YYYY, HH:mm")}</td>
      </tr>
    ));

    return (
      <div className={classNames(styles.ExpandedContent, "w-full")}>
        <div className={"my-3 w-full overflow-hidden rounded-md border-2 border-solid border-dark-500"}>
          <TableMantine withBorder>
            <thead>
              <tr>
                <th>Částka</th>
                <th>Poznámka</th>
                <th>Uživatel</th>
                <th className="w-auto">Datum a čas</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </TableMantine>
        </div>
      </div>
    );
  };

  const columns = [
    {
      accessor: "ucastnik_prijmeni",
      title: "Jméno",
      render: (child) => parseFullName(child),
      sortable: true,
      disableHiding: true,
    },
    {
      accessor: "oddil",
      title: "Oddíl",
      render: (child) => child.team,
      sortable: true,
    },
    {
      accessor: "ucastnik_date_narozeni",
      title: "Datum narození",
      sortable: true,
      render: (child) => dayjs(child.birthDate).format("D. M. YYYY"),
    },
    {
      accessor: "parents",
      title: "Jméno rodiče",
      render: (child) => parseFullName(child.parent),
      defaultHidden: moneyCategory === "deposit",
    },
    {
      accessor: "castka",
      title: "Částka",
      sortable: true,
      render: (child) => (
        <Text span>
          {child.money.amount.czk !== 0 &&
            child.money.amount.eur !== 0 &&
            `${child.money.amount.czk} CZK  ${moneyCategory === "deposit" ? child.money.amount.eur + " EUR" : ""}`}
          {child.money.amount.czk > 0 && child.money.amount.eur === 0 && `${child.money.amount.czk} CZK`}
          {moneyCategory === "deposit" &&
            child.money.amount.czk === 0 &&
            child.money.amount.eur !== 0 &&
            `${child.money.amount.eur} EUR`}
          {child.money.amount.czk === 0 && child.money.amount.eur === 0 && "-"}
        </Text>
      ),
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (child) => actionButtons(child),
      disableHiding: true,
      narrow: true,
    },
  ];

  const filterConfig = [
    {
      key: "jmeno",
      label: "Jméno",
      type: "search",
    },
  ];

  return (
    <>
      <Table
        key={moneyCategory}
        columns={columns}
        id="penize-deti"
        useServer={true}
        serverUrl={"/children/money/" + moneyCategory}
        shouldServerFetch={shouldFetch}
        setShouldServerFetch={setShouldFetch}
        onRowClick={(child) => {
          expandChild(child);
        }}
        rowExpansion={{
          collapseProps: {
            transitionDuration: 300,
            transitionTimingFunction: "ease",
          },
          content: ({ record }) => expandContent(record),
        }}
        fetching={fetching}
        filters={filters}
        filterConfig={filterConfig}
      />
    </>
  );
}
