import { useEffect, useState } from "react";
import { Alert, Button, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import "dayjs/locale/cs";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch.js";
import { IconAlertCircle } from "@tabler/icons-react";
import { closeModal } from "@mantine/modals";
import { useRevalidator } from "react-router-dom";

export default function HistoricalNoteModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const revalidate = useRevalidator();

  useEffect(() => {
    if (innerProps?.child) {
      form.setValues({ permanent_note: innerProps.child.permanentNote });
    } else {
      form.reset();
    }
  }, [innerProps]);

  async function handleSubmit(values) {
    setLoading(true);

    const child = innerProps?.child;

    const res = await useFetch(`/children/${child.id}/permanent-note`, "PUT", { ...values });

    if (res.status === "ok") {
      context.closeModal(id);
      form.reset();
      setLoading(false);
      toast.success("Historická poznámka upravena.");
      revalidate.revalidate();
    } else {
      setLoading(false);
      setError(res.message);
    }
  }

  const form = useForm({
    initialValues: {
      permanent_note: "",
    },
  });
  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při úpravě pokoje nastala chyba."
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}
        >
          {error}
        </Alert>
      )}
      <Textarea
        minRows={2}
        maxRows={10}
        autosize
        label="Poznámka"
        placeholder="Dítě má problémy se socializováním se..."
        mb="sm"
        {...form.getInputProps("permanent_note")}
      />

      <Button fullWidth type="submit" loading={loading} mt="sm">
        Upravit historickou poznámku
      </Button>
    </form>
    // </Modal>
  );
}
