import Page from "../../components/layout/Page";
import { DepotLogsTable } from "../../components/table/DepotLogsTable";
import useTitle from "../../hooks/useTitle";

const DepotLogsPage = () => {
  useTitle("Logy pohybů na skladech");

  return (
    <Page title={"Logy skladů"}>
      <DepotLogsTable />
    </Page>
  );
};

export default DepotLogsPage;
