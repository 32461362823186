import { Alert } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

export default function LoginError({ error, showError }) {
  return (
    <>
      {showError && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při přihlášení nastala chyba."
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => props.setShowError(false)}
        >
          {error}
        </Alert>
      )}
    </>
  );
}
