import { useTimeout } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { getValueAtPath } from "./utils";

export function useRowExpansion({ rowExpansion, records, idAccessor }) {
  let initiallyExpandedRecordIds = [];
  if (rowExpansion && records) {
    const { trigger, allowMultiple, initiallyExpanded } = rowExpansion;
    if (records && trigger === "always") {
      initiallyExpandedRecordIds = records.map((r) => getValueAtPath(r, idAccessor));
    } else if (initiallyExpanded) {
      initiallyExpandedRecordIds = records.filter(initiallyExpanded).map((r) => getValueAtPath(r, idAccessor));
      if (!allowMultiple) {
        initiallyExpandedRecordIds = [initiallyExpandedRecordIds[0]];
      }
    }
  }

  let expandedRecordIds;
  let setExpandedRecordIds;
  const expandedRecordIdsState = useState(initiallyExpandedRecordIds);

  if (rowExpansion) {
    const { trigger, allowMultiple, collapseProps, content } = rowExpansion;
    if (rowExpansion.expanded) {
      ({ recordIds: expandedRecordIds, onRecordIdsChange: setExpandedRecordIds } = rowExpansion.expanded);
    } else {
      [expandedRecordIds, setExpandedRecordIds] = expandedRecordIdsState;
    }

    const collapseRow = (record) =>
      setExpandedRecordIds(expandedRecordIds.filter((id) => id !== getValueAtPath(record, idAccessor)));

    return {
      expandOnClick: trigger !== "always" && trigger !== "never",
      isRowExpanded: (record) =>
        trigger === "always" ? true : expandedRecordIds.includes(getValueAtPath(record, idAccessor)),
      expandRow: (record) => {
        const recordId = getValueAtPath(record, idAccessor);
        setExpandedRecordIds(allowMultiple ? [...expandedRecordIds, recordId] : [recordId]);
      },
      collapseRow,
      collapseProps,
      content: (record, recordIndex) => () => content({ record, recordIndex, collapse: () => collapseRow(record) }),
    };
  }
}

export function useRowExpansionStatus(open, transitionDuration) {
  const [expanded, setExpanded] = useState(open);
  const [visible, setVisible] = useState(open);

  const expand = useTimeout(() => setExpanded(true), 0);
  const hide = useTimeout(() => setVisible(false), transitionDuration || 200);

  useEffect(() => {
    if (open) {
      hide.clear();
      setVisible(true);
      expand.start();
    } else {
      expand.clear();
      setExpanded(false);
      hide.start();
    }
  }, [expand, hide, open]);

  return { expanded, visible };
}
