import { useEffect, useState } from "react";
import { Alert, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import "dayjs/locale/cs";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch.js";
import { IconAlertCircle } from "@tabler/icons-react";
import { useRevalidator } from "react-router-dom";

export default function McNicknameModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const setShouldFetch = innerProps.setShouldFetch;
  const revalidate = useRevalidator();

  useEffect(() => {
    if (innerProps?.child) {
      form.setValues({ mcNickname: innerProps.child.mcNickname });
    } else {
      form.reset();
    }
  }, [innerProps]);

  async function handleSubmit(values) {
    setLoading(true);

    const child = innerProps?.child;

    const res = await useFetch(`/pc/mc/whitelist/${child.id}/${values.mcNickname}`, "POST");

    if (res.status === "ok") {
      context.closeModal(id);
      form.reset();
      if (setShouldFetch) setShouldFetch(true);
      setLoading(false);
      toast.success("Minecraft přezdívka upravena.");
      revalidate.revalidate();
    } else {
      setLoading(false);
      setError(res.message || res.error);
    }
  }

  const form = useForm({
    initialValues: {
      mcNickname: "",
    },
  });
  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při úpravě Minecraft nicku nastala chyba."
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      <TextInput
        disabled={loading}
        placeholder="Zadej Minecraft přezdívku..."
        autosize
        label="Minecraft přezdívka"
        required
        {...form.getInputProps("mcNickname")}
      />

      <Button fullWidth type="submit" loading={loading} mt="sm">
        Upravit Minecraft přezdívku
      </Button>
    </form>
    // </Modal>
  );
}
