import {useOutletContext} from "react-router-dom";
import DocumentsList from "../../components/boarding/DocumentsList";
import {useEffect, useState} from "react";
import {Loader, Text} from "@mantine/core";
import {useUser} from "../../providers/UserProvider.jsx";

export default function BoardingDelegateOutgoing() {
    const childId = useOutletContext();
    const [loading, setLoading] = useState(false);
    const { user } = useUser();
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        let documents;
        if (user.currentCampSession.id == 45) {
            documents = ["child_leave", "handover", "relax_gdpr"];
        } else {
            documents = ["child_leave", "handover"];
        }

        setDocuments(documents);
    }, []);

    return loading ? (
        <Text align="center" mt={50}>
            <Loader/>
        </Text>
    ) : (
        <>
            <DocumentsList childId={childId} documents={documents} outgoing/>
        </>
    );
}
