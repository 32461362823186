import { useComputedColorScheme, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import dayjs from "dayjs";

export function parseFullName(data) {
  if (data?.firstName && data?.lastName) return `${data?.firstName}${" "}${data?.lastName}`;
  else if (data?.first_name && data?.last_name) return `${data?.first_name}${" "}${data?.last_name}`;
  else return `—`;
}

export function parseTeam(data) {
  return `${data?.team}`;
}

export function parseDatetime(date) {
  return dayjs(date, "YYYY-MM-DD HH:mm").format("D. M. YYYY, HH:mm");
}

export function parseDate(date) {
  return dayjs(date, "YYYY-MM-DD").format("D. M. YYYY");
}

export function parseShortDay(day) {
  let shortDayArray = ["Ne", "Po", "Út", "St", "Čt", "Pá"];
  return shortDayArray[day];
}

export function parseSocialNumber(number) {
  if (number.toString().includes("/")) return number;
  const numberLocal = number.toString().split("");
  return numberLocal.slice(0, 6).join("") + "/" + numberLocal.slice(6).join("");
}

export function parsePointsColor(points) {
  switch (points) {
    case 0:
      return "red";
    case 1:
      return "red";
    case 2:
      return "orange";
    case 3:
      return "yellow";
    case 4:
      return "lime";
    case 5:
      return "green";
    default:
      return "white";
  }
}

export function roomType(type) {
  switch (type) {
    case "room":
      return "Děti";
    case "staff":
      return "Vedoucí";
    case "sickroom":
      return "Izolace";
  }
}

export function getMode() {
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme();
  return { mode: colorScheme, theme };
}

export function createAbbreviation(text) {
  return text
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase();
}
