import useTitle from "../../hooks/useTitle";
import Page from "../../components/layout/Page";
import { CartContextProvider } from "../../components/buffet/CartContext";
import { IconMaximize, IconMinimize } from "@tabler/icons-react";
import { useFullscreen } from "@mantine/hooks";
import { BuffetItems } from "../../components/buffet/BuffetItems";
import { CartOverview } from "../../components/buffet/CartOverview";
import { BarcodeScanner } from "../../components/buffet/BarcodeScanner";
import { useFetch } from "../../helpers/useFetch";
import { useLoaderData } from "react-router-dom";
import { useState } from "react";
import { Input, Stack, TextInput } from "@mantine/core";

export const loader = async () => {
  const products = await useFetch("depot/products/bufet?pos=1");
  return {
    products: products.data.filter(
      (product) => product.depots.filter((depot) => depot.depot.isForSell && depot.quantity > 0).length > 0
    ),
  };
};

const CheckoutPage = () => {
  const { products } = useLoaderData();
  const { toggle, fullscreen } = useFullscreen();
  const [searchValue, setSearchValue] = useState("");
  useTitle("Kasa");

  const actionButtons = [
    {
      name: "Fullscreen",
      onTrigger: () => toggle(),
      icon: fullscreen ? IconMinimize : IconMaximize,
      primary: true,
    },
  ];

  return (
    <Page title={"Kasa"} actionIcons={actionButtons}>
      <CartContextProvider>
        <BarcodeScanner products={products} />
        <div className="mt-4 grid grid-cols-3 gap-6">
          <Stack w={"100%"} className="col-span-2">
            <Input.Wrapper>
              <TextInput
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Název produktu"
              />
            </Input.Wrapper>
            <BuffetItems products={products.filter((p) => p.name.toLowerCase().includes(searchValue.toLowerCase()))} />
          </Stack>
          <CartOverview products={products} />
        </div>
      </CartContextProvider>
    </Page>
  );
};

export default CheckoutPage;
