import { ActionIcon, Button, Group, Input, Loader, NumberInput, Paper, Select, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCash, IconCashOff } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useOutletContext, useRevalidator } from "react-router-dom";
import DocumentsList from "../../components/boarding/DocumentsList";
import { openMoneyRemoveModal } from "../../helpers/modals";
import { useFetch } from "../../helpers/useFetch";
import classNames from "classnames";
import { getMode } from "../../helpers/helpers";
import { useUser } from "../../providers/UserProvider";

const BoardingDocuments = () => {
  const childId = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [deposits, setDeposits] = useState();
  const [transactions, setTransactions] = useState();
  const [depositLoading, setDepositLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const revalidator = useRevalidator();

  const { user } = useUser();

  const { mode, theme } = getMode();

  const currencies = [
    {
      label: "CZK",
      value: "CZK",
    },
    {
      label: "EUR",
      value: "EUR",
    },
  ];

  const form = useForm({
    initialValues: {
      amount: 500,
      currency: "CZK",
    },
    validate: {
      amount: (value) => {
        if (value <= 0) return "Částka musí být větší než 0";
      },
    },
  });

  const getDeposits = async () => {
    setLoading(true);
    const res = await useFetch(`children/${childId}/money/deposit`, "GET");
    setDeposits(res.data);
    setLoading(false);
  };

  const removeDeposit = async (transaction) => {
    openMoneyRemoveModal(`Stržení kauce`, transaction, childId, deposits, setDeposits);
  };

  useEffect(() => {
    getDeposits();
  }, [childId]);

  const handleSubmit = async (values) => {
    setDepositLoading(true);
    const res = await useFetch("children/" + childId + "/money/deposit/deposit", "PUT", values);
    setDepositLoading(false);
    if (res.status === "ok") {
      if (values.currency === "CZK") {
        setDeposits({
          transactions: [...deposits.transactions, res.data],
          amount: { ...deposits.amount, czk: deposits.amount.czk + values.amount },
        });
      } else {
        setDeposits({
          transactions: [...deposits.transactions, res.data],
          amount: { ...deposits.amount, eur: deposits.amount.eur + values.amount },
        });
      }
      return toast.success("Kauce přidána!");
    }
    return toast.error("Nastala chyba při přidávání kauce.");
  };

  useEffect(() => {
    setTransactions(deposits?.transactions);
  }, [deposits]);

  useEffect(() => {
    if (form.values.amount === 500 || form.values.amount === 20) {
      if (form.values.currency === "CZK") {
        form.setValues({ amount: 500 });
      } else {
        form.setValues({ amount: 20 });
      }
    }
  }, [form.values.currency]);

  useEffect(() => {
    let documents;
    if (user.currentCampSession.isSuburban) {
      documents = ["camp_rules", "handover", "child-leaving-suburban-without-accompaniment"];
    } else if(user.currentCampSession.id == 45) {
        documents = ["camp_rules", "handover", "relax_gdpr"];
    } else {
      documents = ["camp_rules", "handover"];
    }

    setDocuments(documents);
  }, []);

  const sumOfMoney = transactions?.reduce((acc, transaction) => {
    return acc + transaction.amount;
  }, 0);

  return (
    <div>
      {loading ? (
        <Text align="center" mt={50}>
          <Loader />
        </Text>
      ) : (
        <>
          <DocumentsList childId={childId} documents={documents} />
          <Paper p="lg" className="mb-4" withBorder radius="lg">
            <h2>Přidání kauce</h2>
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <Group gap={"md"} className="w-full" grow={1} mt="lg">
                <Input.Wrapper label="Částka" withAsterisk>
                  <NumberInput
                    {...form.getInputProps("amount")}
                    required
                    precision={0}
                    min={0}
                    disabled={depositLoading}
                  />
                </Input.Wrapper>
                <Select
                  data={currencies}
                  {...form.getInputProps("currency")}
                  label="Měna"
                  defaultValue={"CZK"}
                  required
                  disabled={depositLoading}
                />
              </Group>
              <Button type="submit" mt={"lg"} className="w-full" loading={depositLoading}>
                Přidat kauci
              </Button>
            </form>
          </Paper>
          <Paper p="lg" className="mb-4" withBorder radius="lg">
            <h2 className="mb-4">Kauce</h2>
            <Stack gap={"md"}>
              {transactions?.map((transaction) => (
                <Group
                  key={transaction.id}
                  grow
                  className={classNames("justify-between rounded-md border-2 border-solid p-2 [&>*]:basis-1/4", {
                    "border-dark-500 bg-dark-300": mode === "dark",
                    "border-gray-500 bg-white-900": mode === "light",
                  })}>
                  <Group gap={"6px"}>
                    <IconCash stroke={1.5} size={24} />
                    <Text>{transaction.type === "deposit" ? "Kauce" : "Vrácení"}</Text>
                  </Group>
                  <Text className="text-center" color={transaction.type === "withdraw" && "red"}>
                    {transaction.amount} {transaction.currency}
                  </Text>
                  <Group gap="xs" className="flex justify-end">
                    {transaction.type === "deposit" ? (
                      <ActionIcon
                        loading={depositLoading}
                        variant={"light"}
                        disabled={sumOfMoney < transaction.amount}
                        color={"red"}
                        onClick={() => removeDeposit(transaction)}>
                        <IconCashOff stroke={1.5} size={20} />
                      </ActionIcon>
                    ) : (
                      <Text size="sm">{transaction.note}</Text>
                    )}
                  </Group>
                </Group>
              ))}
            </Stack>
            <Text mt="sm" c="dimmed">
              <Text span fw="bold">
                Celková částka:
              </Text>{" "}
              {deposits?.amount?.czk} CZK + {deposits?.amount?.eur} EUR
            </Text>
          </Paper>
        </>
      )}
    </div>
  );
};

export default BoardingDocuments;
