import RoomGroup from "./RoomGroup.jsx";

export default function RoomGroupList({ groups }) {
  return (
    <>
      {groups.map((group) => {
        return <RoomGroup group={group} key={group.id} />;
      })}
    </>
  );
}
