import { useOutletContext } from "react-router-dom";
import DocumentsList from "../../components/boarding/DocumentsList";
import { useEffect, useState } from "react";
import { useFetch } from "../../helpers/useFetch";
import { Group, Loader, Paper, Stack, Text } from "@mantine/core";
import { IconCash } from "@tabler/icons-react";
import classNames from "classnames";
import { getMode } from "../../helpers/helpers";
import { useUser } from "../../providers/UserProvider";

export default function BoardingOutgoing() {
  const childId = useOutletContext();
  const [loading, setLoading] = useState(true);
  const [child, setChild] = useState({});
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    setLoading(true);
    useFetch(`children/${childId}`, "GET")
      .then((res) => {
        setChild(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [childId]);

  const { user } = useUser();

  useEffect(() => {
    let documents;
    if (user.currentCampSession.isSuburban) {
      documents = ["child-leaving-suburban", "handover", "child-leaving-suburban-without-accompaniment"];
    } else {
      documents = ["child_leave", "handover"];
    }

    setDocuments(documents);
  }, []);

  const { mode } = getMode();

  return loading ? (
    <Text align="center" mt={50}>
      <Loader />
    </Text>
  ) : (
    <>
      <DocumentsList childId={childId} documents={documents} outgoing />
      <Paper p="lg" className="mb-4" withBorder radius="lg">
        <h2 className="mb-4">Kauce a kapesné</h2>
        <p>
          {child.money.deposit.amount.czk > 0 || child.money.deposit.amount.eur > 0 ? (
            <>
              Rodičům vrátit{" "}
              <strong>
                {Object.keys(child.money.deposit.amount).map((amount, index) => {
                  return (
                    <>
                      {child.money.deposit.amount[amount]} {amount.toUpperCase()}
                      {index !== Object.keys(child.money.deposit.amount).length - 1 && " a "}
                    </>
                  );
                })}
              </strong>{" "}
              z kauce.
            </>
          ) : (
            (child.money.deposit.amount.czk < 0 || child.money.deposit.amount.eur < 0) && (
              <>
                Od rodičů převzít{" "}
                <strong className="text-red-200">
                  {Object.keys(child.money.deposit.amount).map((amount, index) => {
                    return (
                      <>
                        {child.money.deposit.amount[amount]} {amount.toUpperCase()}
                        {index !== Object.keys(child.money.deposit.amount).length - 1 && " a "}
                      </>
                    );
                  })}
                </strong>
                kvůli odečtům.
              </>
            )
          )}
        </p>
        <div className="flex flex-col gap-4 md:columns-2 md:flex-row">
          <div className="grow basis-1/3">
            <h3 className="mb-2 mt-4">Kauce</h3>
            <Stack gap={"md"}>
              {child.money.deposit.transactions?.map((transaction) => {
                return (
                  <Group
                    key={transaction.id}
                    grow
                    className={classNames("justify-between rounded-md border-2 border-solid p-2 [&>*]:basis-1/4", {
                      "border-dark-600 bg-dark-400": mode === "dark",
                      "border-gray-500 bg-white-900": mode === "light",
                    })}>
                    <Group gap={"6px"}>
                      <IconCash stroke={1.5} size={24} />
                      <Text>{transaction.type === "deposit" ? "Kauce" : "Vrácení"}</Text>
                    </Group>
                    <Text className="text-center" color={transaction.type === "withdraw" && "red"}>
                      {transaction.amount} {transaction.currency}
                    </Text>
                    <Group gap="xs" className="flex justify-end">
                      <Text size="sm">{transaction.note ? transaction.note : ""}</Text>
                    </Group>
                  </Group>
                );
              })}
            </Stack>
          </div>
        </div>
      </Paper>
    </>
  );
}
