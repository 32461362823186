import SignaturePad from "../boarding/SignaturePad.jsx";
import { Button, Group } from "@mantine/core";
import { optimize } from "svgo";
import { useFetch } from "../../helpers/useFetch.js";
import { toast } from "react-hot-toast";
import { useRef, useState } from "react";

export default function UpdateSignatureModal({ context, id, innerProps }) {
  const signatureRef = useRef();
  const [pathsCount, setPathsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const signDocument = async () => {
    setLoading(true);

    const svgElement = document.createElement("svg");
    const trimElement = signatureRef.current;

    const bBox = trimElement.getBBox();
    svgElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svgElement.setAttribute("viewBox", `${bBox.x} ${bBox.y} ${bBox.width} ${bBox.height}`);
    svgElement.setAttribute("width", trimElement.getBBox().width);
    svgElement.setAttribute("height", trimElement.getBBox().height);
    svgElement.appendChild(trimElement.cloneNode(true));

    const result = optimize(svgElement.outerHTML, { multipass: true, floatPrecision: 2 });

    let parser = new DOMParser();
    let doc = parser.parseFromString(result.data, "image/svg+xml");
    let optimizedSvg = doc.querySelector("svg");
    optimizedSvg.setAttribute(
      "viewBox",
      `${Math.round(bBox.x)} ${Math.round(bBox.y)} ${Math.round(bBox.width)} ${Math.round(bBox.height)}`
    );
    optimizedSvg.setAttribute("width", Math.round(bBox.width));
    optimizedSvg.setAttribute("height", Math.round(bBox.height));

    const serialized = new XMLSerializer().serializeToString(optimizedSvg);

    const payload = btoa(serialized);

    useFetch(`/user/signature`, "PUT", {
      svg: payload,
    })
      .then((res) => {
        if (res.status === "ok") {
          toast.success("Podpis upraven.");
        } else {
          toast.error("Nastala chyba při ukládání podpisu a byla nahlášena.");
        }
        setLoading(false);
        context.closeModal(id);
      })
      .catch((err) => {
        toast.error("Nastala chyba při ukládání podpisu a byla nahlášena.");
        setLoading(false);
        context.closeModal(id);
        console.error(err);
      });
  };

  return (
    <>
      <SignaturePad setPathsCount={setPathsCount} signatureRef={signatureRef} />
      <Button mt="sm" variant="light" fullWidth loading={loading} onClick={() => signDocument()}>
        Upravit podpis
      </Button>
    </>
  );
}
