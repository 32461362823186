import { useEffect, useRef, useState } from "react";
import { Alert, Button, Checkbox, Group, Select, Textarea } from "@mantine/core";
import { DateInput, TimeInput } from "@mantine/dates";
import { IconAlertCircle, IconCalendar } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import dayjs from "dayjs";
import "dayjs/locale/cs";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch.js";
import { useUser } from "../../providers/UserProvider.jsx";
import { useRevalidator } from "react-router-dom";
import { parseFullName } from "../../helpers/helpers.js";
import { earlyLeaveReasons } from "../../helpers/earlyLeave.js";

const getChildren = async () => {
  const res = await useFetch("/children/list/early-leaving");
  let children = res.data.filter((child) => !child?.earlyLeaving);
  return children;
};

export default function EarlyLeaveModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const child = innerProps.child;
  const [error, setError] = useState(false);
  const [children, setChildren] = useState([]);
  const [fetching, setFetching] = useState(innerProps?.child ? false : true);
  const { user } = useUser();
  const revalidator = useRevalidator();
  const timeRef = useRef(null);

  const reasons = earlyLeaveReasons;

  const createChildrenList = async () => {
    setFetching(true);
    const childrenFetchList = await getChildren();
    const childrenList = childrenFetchList.map((child) => {
      return {
        label: `${parseFullName(child)} (${child.age} let${child.team ? ", oddíl " + child.team : ""})`,
        value: String(child.id),
      };
    });
    setChildren(childrenList);
    setFetching(false);
  };

  useEffect(() => {
    if (!innerProps?.earlyLeaving) {
      createChildrenList();
    }
  }, []);

  useEffect(() => {
    if (innerProps?.earlyLeaving) {
      const leaving = innerProps.earlyLeaving;
      form.setValues({
        reason: leaving.reason,
        text: leaving.text || "",
        has_left: leaving.hasLeft,
        leave_time: leaving.leavingTime || null,
        leave_date: leaving.leavingDate ? new Date(leaving.leavingDate) : null,
      });
    } else {
      form.reset();
      form.setValues({
        reason: null,
        text: "",
        has_left: false,
        leave_time: null,
        leave_date: user?.currentCampSession.isActual ? new Date() : null,
      });
    }
  }, [innerProps]);

  async function handleSubmit(values) {
    setLoading(true);

    if (!values.text) {
      delete values.text;
    }

    const leaving = innerProps?.earlyLeaving;
    let res;

    if (leaving?.id) {
      res = await useFetch(`/children/early-leavings/${leaving.id}`, "PUT", { ...values });
    } else {
      res = await useFetch(`/children/${child ? child.id : form.values.child}/early-leaving`, "POST", { ...values });
    }

    if (res.status === "ok") {
      revalidator.revalidate();
      context.closeModal(id);
      form.reset();
      setLoading(false);
      toast.success("Předčasný odjezd uložen.");
    } else {
      console.error(res);
      setLoading(false);
      setError(res.message);
    }
  }

  const form = useForm({
    initialValues: {
      text: "",
      has_left: false,
      leave_time: null,
      reason: null,
      child: null,
      leave_date: user?.currentCampSession.isActual ? new Date() : null,
    },
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při vytváření předčasného odjezdu nastala chyba."
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      {!child && (
        <Select
          required
          label="Dítě"
          data={children}
          mb="sm"
          placeholder="Vyber dítě..."
          searchable
          {...form.getInputProps("child")}
        />
      )}

      <Select
        required
        label="Důvod"
        data={reasons}
        mb="sm"
        searchable
        placeholder="Vyber důvod..."
        {...form.getInputProps("reason")}
      />

      <Textarea
        minRows={2}
        maxRows={10}
        autosize
        label="Poznámka"
        placeholder="Pro dítě si přijede babička, jiný důvod"
        mb="sm"
        {...form.getInputProps("text")}
      />

      <Group gap="sm" grow={1} className="w-full" mb="sm">
        <DateInput
          leftSection={<IconCalendar size={18} stroke={1.5} />}
          initialMonth={dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD").toDate()}
          minDate={dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD").toDate()}
          maxDate={dayjs(user?.currentCampSession.dateTo, "YYYY-MM-DD").toDate()}
          locale="cs"
          label="Předběžné datum odjezdu"
          valueFormat="D. M. YYYY"
          value={form.values.leave_date}
          onChange={(value) => form.setFieldValue("leave_date", value ? new Date(value) : null)}
        />
        <TimeInput label="Předběžný čas odjezdu" timePlaceholder="" clearable {...form.getInputProps("leave_time")} />
      </Group>

      <Checkbox label="Dítě již odjelo" {...form.getInputProps("has_left", { type: "checkbox" })} />
      <Button disabled={!form.values.reason} fullWidth type="submit" loading={loading} mt="sm">
        Uložit předčasný odjezd
      </Button>
    </form>
  );
}
