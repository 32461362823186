import { ActionIcon, Button, Group, PasswordInput, Select, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconAt, IconEye, IconEyeOff, IconLock, IconUsers } from "@tabler/icons-react";
import LoginError from "./LoginError.jsx";
import { useEffect, useState } from "react";
import LogoSymbol from "../../components/LogoSymbol.jsx";
import logoText from "../../assets/logo-text.svg";
import { Link, useNavigate } from "react-router-dom";
import useTitle from "../../hooks/useTitle.js";

export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [showError, setShowError] = useState(false);
  const [rolesLogin, setRolesLogin] = useState(false);
  const navigate = useNavigate();
  useTitle(`Přihlášení`);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/");
    }
  }, []);

  const devLoginCredentials = [
    { label: "Hlavní vedoucí", value: "hu1@4camps.cz" },
    { label: "Zástupce HV", value: "hu2@4camps.cz" },
    { label: "Správce majetku", value: "hu3@4camps.cz" },
    { label: "Sporťák", value: "hu4@4camps.cz" },
    { label: "Zdravotník", value: "hu5@4camps.cz" },
    { label: "Fotograf", value: "hu6@4camps.cz" },
    { label: "Programový vedoucí PC", value: "hu7@4camps.cz" },
    { label: "Oddílový vedoucí", value: "hu8@4camps.cz" },
    { label: "Praktikant", value: "hu9@4camps.cz" },
  ];

  const handleDevLoginSubmit = async (values) => {
    login({ email: values.email, password: values.password });
  };

  const login = async (values) => {
    const { email, password } = values;
    setLoading(true);
    const result = await fetch(new URL("/login", import.meta.env.VITE_API_URL), {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
        //"User-Agent": agent,
      },
    });

    let user = await result.json();

    if (user.message) {
      setError(user.message);
      setShowError(true);
      setLoading(false);
      return;
    }

    localStorage.setItem("user", JSON.stringify(user));
    setLoading(false);
    navigate("/");
  };

  return (
    <div className="w-100 flex h-screen items-center justify-center overflow-hidden">
      {(import.meta.env.VITE_APP_ENV === "stage" || import.meta.env.DEV) && (
        <ActionIcon
          color={rolesLogin ? "primary" : "gray"}
          variant="light"
          size="lg"
          className="absolute left-4 top-4"
          onClick={() => setRolesLogin(!rolesLogin)}>
          <IconUsers stroke={1.5} size={20} />
        </ActionIcon>
      )}

      <Stack gap={0} className="max-w-[25rem] items-center">
        <Group wrap="nowrap" gap="sm" className="flex h-16 px-4" align="center">
          <LogoSymbol height={48} />
          <img src={logoText} height={48} alt="Logo" />
        </Group>
        <p className="mt-6 text-center">
          Pokračuj přihlášením do administrace. <strong>Stačí vyplnit stejné údaje, které používáš v CORE.</strong>
        </p>
        <form
          className="w-full"
          onSubmit={form.onSubmit((values) => {
            if (rolesLogin) {
              handleDevLoginSubmit(values);
            } else {
              login(values);
            }
          })}>
          <LoginError error={error} showError={showError} setShowError={setShowError} />

          {!rolesLogin ? (
            <>
              <TextInput
                required
                disabled={loading}
                type="email"
                leftSection={<IconAt stroke={1.5} size={16} />}
                placeholder="test@gmail.com"
                label="E-mail"
                mb="xs"
                mt="lg"
                {...form.getInputProps("email")}
              />
              <PasswordInput
                required
                disabled={loading}
                leftSection={<IconLock stroke={1.5} size={16} />}
                placeholder="TajneHeslo"
                label="Heslo"
                mb="lg"
                visibilityToggleIcon={({ reveal, size }) =>
                  reveal ? <IconEyeOff stroke={1.5} size={size} /> : <IconEye stroke={1.5} size={size} />
                }
                {...form.getInputProps("password")}
              />
              <Button type="submit" loading={loading} variant="filled" fullWidth>
                Přihlásit se
              </Button>
              <Button mt="xs" fullWidth variant="subtle" component={Link} to="/heslo/zapomenute">
                Zapomněl jsi heslo?
              </Button>
              <Button mt="xs" fullWidth variant="subtle" component={Link} to="/registrace">
                Ještě nemáš účet v Panelu?
              </Button>
            </>
          ) : (
            <>
              <Select
                disabled={loading}
                mb="xs"
                mt="lg"
                placeholder="Vyber funkci..."
                label="Funkce"
                data={devLoginCredentials}
                {...form.getInputProps("email")}
              />
              <PasswordInput
                required
                disabled={loading}
                leftSection={<IconLock stroke={1.5} size={16} />}
                placeholder="TajneHeslo"
                label="Tajný kód"
                mb="lg"
                visibilityToggleIcon={({ reveal, size }) =>
                  reveal ? <IconEyeOff stroke={1.5} size={size} /> : <IconEye stroke={1.5} size={size} />
                }
                {...form.getInputProps("password")}
              />
              <Button fullWidth loading={loading} type="submit" mt="sm">
                Přihlásit se přes funkci
              </Button>
            </>
          )}
        </form>
      </Stack>
    </div>
  );
}
