import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Group,
  Loader,
  MultiSelect,
  NumberInput,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useUser } from "../../providers/UserProvider.jsx";
import { useRevalidator } from "react-router-dom";
import { useFetch } from "../../helpers/useFetch.js";
import { toast } from "react-hot-toast";
import { forwardRef } from "react";
import classNames from "classnames";
import { IconAlertCircle } from "@tabler/icons-react";

export default function RoomEditModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [groups, setGroups] = useState([]);
  const [room, setRoom] = useState(innerProps.room);
  const [list, setList] = useState([]);
  const [canEditRoomType, setCanEditRoomType] = useState();
  const [listLoading, setListLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useUser();
  const revalidator = useRevalidator();

  const roomTypes = [
    {
      value: "room",
      label: "Dětský",
    },
    {
      value: "staff",
      label: "Personál",
    },
    {
      value: "sickroom",
      label: "Izolace",
    },
  ];

  const form = useForm({
    initialValues: {
      name: "",
      group_id: "",
      capacity: 0,
      type: "",
      update_future_sessions: false,
      occupation: [],
    },
  });

  const getGroups = async () => {
    await useFetch("/accommodation/groups").then((res) => {
      if (res.data) {
        setGroups(res.data);
      }
    });
  };

  const getList = async () => {
    let route;

    if (form.values.type === "room") route = "/children/list/accommodation";
    else if (form.values.type === "staff") route = "/staff";
    else {
      return;
    }
    const res = await useFetch(route);

    if (res.data) {
      let data = res.data;
      if (form.values.type === "room") {
        data = res.data.filter((person) => person.gender === room.roomGender);
      }
      const peopleArray = data.map((person) => ({
        value: String(person.id),
        label: `${person.firstName} ${person.lastName} (${person.age} let${person.team ? `, ${person.team}` : ""})`,
        gender: person.gender && person.gender,
        disabled: false,
      }));
      setList(peopleArray);

      return true;
    }
  };

  useEffect(() => {
    if (room) {
      Promise.all([getGroups(), getList()]).then(() => {
        setFetching(false);
      });

      const occ = room?.occupation.filter((o) => o.id).map((o) => o.id);

      setCanEditRoomType(occ.length > 0);

      form.setValues({
        name: room?.name,
        type: room?.type,
        capacity: room?.capacity ? room?.capacity : 0,
        group_id: String(room?.group.id),
        occupation: occ.map((o) => String(o)),
      });
    }
  }, [room]);

  const handleSubmit = async (values) => {
    setLoading(true);

    const res = await useFetch(`/accommodation/rooms/${room.id}`, "PUT", { ...values });

    if (res.status === "ok") {
      context.closeModal(id);
      form.reset();
      setLoading(false);
      toast.success("Pokoj upraven.");
      revalidator.revalidate();
    } else {
      console.error(res);
      setLoading(false);
      setError(res.message);
    }
  };

  useEffect(() => {
    if (form.values.type !== "sickroom") {
      setListLoading(true);
      getList().then(() => {
        setListLoading(false);
      });
    } else {
      setList([]);
    }
  }, [form.values.type]);

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při úpravě pokoje nastala chyba."
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      {fetching ? (
        <div className="flex h-full w-full flex-col items-center justify-center py-8">
          <Loader />
        </div>
      ) : (
        <>
          <Group gap="sm" grow={1} className="w-full" mb="sm">
            <TextInput label="Název pokoje" {...form.getInputProps("name")} />
            <Select
              label="Patro"
              data={groups?.map((group) => ({
                label: `${group.name}`,
                value: String(group.id),
              }))}
              {...form.getInputProps("group_id")}
            />
            {room?.capacity && (
              <NumberInput
                label="Počet míst"
                min={form?.values?.occupation?.length}
                {...form.getInputProps("capacity")}
              />
            )}
          </Group>

          <Select
            label="Typ pokoje"
            description="Pro změnu typu nesmí být na pokoji nikdo ubytovaný."
            data={roomTypes}
            disabled={canEditRoomType}
            mb="sm"
            {...form.getInputProps("type")}
          />

          <Checkbox
            label="Propsat změny i do budoucích turnusů"
            {...form.getInputProps("update_future_sessions", { type: "checkbox" })}
          />

          {form.values.type !== "sickroom" && (
            <MultiSelect
              className="grow pt-2"
              searchable
              maxValues={form?.values?.capacity}
              disabled={listLoading}
              rightSection={listLoading ? <Loader size="xs" /> : null}
              data={list}
              {...form.getInputProps("occupation")}
            />
          )}

          <Button
            disabled={!form.values.name || !form.values?.capacity || listLoading}
            fullWidth
            type="submit"
            loading={loading}
            mt="sm">
            Uložit
          </Button>
        </>
      )}
    </form>
  );
}
