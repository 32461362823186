import { forwardRef, useEffect, useState } from "react";
import { Alert, Button, Group, Loader, Select, Text, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useRevalidator } from "react-router-dom";
import { IconAlertCircle, IconBus, IconNote } from "@tabler/icons-react";
import { useFetch } from "../../helpers/useFetch";
import { toast } from "react-hot-toast";

const getChildren = async () => {
  const res = await useFetch("/children/list/transport");
  return res.data;
};

const SelectItem = forwardRef(({ color, label, note, ...others }, ref) => (
  <div ref={ref} {...others}>
    <Text className="flex items-center gap-2" color={color}>
      {label} {note && <IconNote size={16} stroke={1.5} />}
    </Text>
  </div>
));

export default function TransportBuyModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);
  const [children, setChildren] = useState([]);
  const [cities, setCities] = useState([]);
  const revalidator = useRevalidator();
  const childId = innerProps?.childId;

  const form = useForm({
    initialValues: {
      city: "",
      type: "odjezd",
      child_id: childId,
    },
    validate: {
      text: (value) => (value === "" ? "Musíš napsat text" : null),
    },
  });

  const getData = async () => {
    setFetching(true);
    useFetch("/merch/transport/cities").then((res) => {
      if (res.data) {
        setCities(
          res.data.map((city) => ({
            label: city,
            value: city,
          }))
        );
        if (res.data.length === 1) {
          form.setValues({ city: res.data[0] });
        }
        setFetching(false);
      }
    });
  };

  useEffect(() => {
    getData();

    getChildren().then((childrenLocal) => {
      setChildren(childrenLocal);
      form.setValues({ child_id: childId });
    });
  }, [innerProps]);

  useEffect(() => {
    if (form.values.child_id) {
      /* form.setFieldValue("city", children.find((child) => child.id === form.values.child_id).transportCity);
      form.setFieldValue("type", children.find((child) => child.id === form.values.child_id).transportType); */
    }
  }, [form.values.child_id]);

  const handleSubmit = async (values) => {
    setLoading(true);
    useFetch(`/children/${values.child_id}/transport`, "PUT", values).then((res) => {
      if (res.status === "ok") {
        toast.success("Doprava změněna");
        setLoading(false);
        context.closeModal(id);
      } else {
        setLoading(false);
        toast.error("Nastala chyba při změně dopravy.");
      }
    });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title={`Při požadavku nastala chyba.`}
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      {fetching ? (
        <div className="flex h-full w-full flex-col items-center justify-center py-8">
          <Loader />
        </div>
      ) : (
        <>
          {!childId && (
            <Select
              data={children.map((child) => ({
                label: `${child.firstName} ${child.lastName} (${child.team}, ${child.age} let)`,
                value: String(child.id),
                color: child.arriveByBus ? "teal.6" : "white",
                note: child.note,
              }))}
              mb="sm"
              itemComponent={SelectItem}
              required
              searchable
              hoverOnSearchChange={true}
              label="Dítě"
              placeholder="Vyber dítě..."
              {...form.getInputProps("child_id")}
            />
          )}

          <Select
            label="Město"
            placeholder="Vyber město"
            mb="sm"
            required
            data={cities}
            {...form.getInputProps("city")}
          />

          <Select
            label="Typ"
            placeholder="Vyber typ dopravy"
            required
            data={[
              { value: "departure", label: "Odjezd" },
              { value: "arrival", label: "Příjezd" },
              { value: "", label: "Příjezd a odjezd" },
              { value: "custom", label: "Po vlastní ose" },
            ]}
            mb="sm"
            {...form.getInputProps("type")}
          />

          <Button fullWidth type="submit" loading={loading} mt="lg">
            Potvrzení dokoupení dopravy
          </Button>
        </>
      )}
    </form>
    // </Modal>
  );
}
