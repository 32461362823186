import { Button, Center, Stack, Text, Title, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { useNavigate } from "react-router";

export const UnauthorizedPage = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const errorImage =
    "https://cdn.discordapp.com/attachments/358690425394954240/1095393087590776973/930446087905493012.webp";

  return (
    <Center className="h-screen">
      <Stack justify="center" align="center" gap="sm">
        <img className="mb-2 rounded-lg" width={400} src={errorImage} alt="spink" />

        <Title
          className="font-headline"
          order={1}
          color={colorScheme === "dark" ? theme.colors[theme.primaryColor][4] : theme.colors[theme.primaryColor][7]}>
          Sem nemáš přístup
        </Title>
        <Text mb="lg">
          Na tuto stránku nemáš přístup. Pokud si myslíš, že někde nastala chyba, tak kontaktuj svého hlavního
          vedoucího.
        </Text>
        <Stack align="center" gap="xs">
          <Button w="fit-content" onClick={() => navigate("/")}>
            Zpět na hlavní stránku
          </Button>
        </Stack>
      </Stack>
    </Center>
  );
};
