import { useLoaderData } from "react-router-dom";
import Page from "../../components/layout/Page";
import BoardingOverviewTable from "../../components/table/BoardingOverviewTable";
import { useFetch } from "../../helpers/useFetch";
import useTitle from "../../hooks/useTitle";

export async function loader() {
  let children = await useFetch(`children/list/accreditation`);
  children.data = children.data.sort((a, b) => (a.hasLeft ? 1 : -1));
  return { data: children };
}

export default function BoardingOverview() {
  let accreditationList = useLoaderData().data;
  useTitle("Přehled akreditace");

  const stats = accreditationList.stats;

  return (
    <Page
      title="Přehled akreditace"
      subtitleDown={"Přijelo: " + stats.arrived + "/" + stats.total + ", odjelo: " + stats.left + "/" + stats.total}
    >
      <BoardingOverviewTable accreditationList={accreditationList.data} />
    </Page>
  );
}
