import React, { useEffect } from "react";
import { getLastDraw } from "../../helpers/useRoulette";
import dayjs, { Dayjs } from "dayjs";
import { Link, useLoaderData } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useFetch } from "../../helpers/useFetch";
import Page from "../../components/layout/Page";
import { Button, Group, Paper, Stack, Text, Textarea } from "@mantine/core";

export const loader = async () => {
  const duties = await getLastDraw("duties");
  const activities = await getLastDraw("activities");
  const staff = await useFetch("staff");
  return {
    staff: staff.data.map((person) => person.firstName + " " + person.lastName).join("\n"),
    duties: duties.data,
    activities: activities.data,
    meta: {
      dutiesDate: duties.data?.length > 0 && dayjs(duties.data[0]?.date).format("D. M. YYYY"),
      activitiesDate: activities.data?.length > 0 && dayjs(activities.data[0]?.date).format("D. M. YYYY"),
      dutyFile: duties?.file?.name,
      activitiesFile: activities?.file?.name,
    },
  };
};

export default function RoulettePage() {
  const data = useLoaderData();

  const form = useForm({
    initialValues: {
      personal: data.staff,
      duties: localStorage.getItem("duties")?.length > 0 ? localStorage.getItem("duties") : "",
      activities: localStorage.getItem("activities")?.length > 0 ? localStorage.getItem("activities") : "",
    },
  });

  useEffect(() => {
    const keys = Object.keys(form.values);
    keys.map((key) => {
      localStorage.setItem(key, form.values[key]);
    });
  }, [form.values]);

  return (
    <Page title={"Losování"}>
      <div className="RoulettePage">
        <Group gap={"lg"} grow mb={"md"}>
          <Textarea {...form.getInputProps("personal")} label="Personál" rows={10} />
          <Textarea {...form.getInputProps("duties")} label="Služby" rows={10} />
          <Textarea {...form.getInputProps("activities")} label="Aktivity" rows={10} />
        </Group>
        <Group h={"40vh"} grow>
          <Paper withBorder radius={"md"} className="flex h-full flex-col items-center justify-center gap-4">
            <h3 className="text-3xl">Losování služeb</h3>
            <Button className="w-[81%] py-1 text-lg font-bold" component={Link} to="sluzby">
              Losovat
            </Button>
            {data.duties?.length > 0 && (
              <Stack h={"50%"} gap={"6px"}>
                <Stack gap={"2px"} className="max-h-[70%] overflow-auto">
                  {data.duties.map((duty) => {
                    return (
                      <Text className="font-bold">
                        {duty.dutyName}
                        {" - "}
                        <Text span className="font-normal">
                          {duty.personName}
                        </Text>
                      </Text>
                    );
                  })}
                </Stack>
                <Button
                  className="mt-4 bg-gray-300 text-lg"
                  component="a"
                  download
                  href={import.meta.env.VITE_DCREATIVE_URL + "resource/4camps-losovani/" + data.meta.dutyFile}>
                  Stáhnout tabulku{" " + data.meta.dutiesDate}
                </Button>
              </Stack>
            )}
          </Paper>
          <Paper withBorder radius={"md"} className="flex h-full grow flex-col items-center justify-center gap-4">
            <h3 className="text-3xl">Losování aktivit</h3>
            <Button className="w-[81%] py-1 text-lg font-bold" component={Link} to="aktivity">
              Losovat
            </Button>
            {data.activities?.length > 0 && (
              <Stack h={"50%"} gap={"6px"}>
                <Stack gap={"2px"} className="max-h-[70%] overflow-auto">
                  {data.activities.map((activity) => {
                    return (
                      <Text className="font-bold">
                        {activity.activityName}
                        {" - "}
                        <Text span className="font-normal">
                          {activity.personName}
                        </Text>
                      </Text>
                    );
                  })}
                </Stack>
                <Button
                  className="mt-4 bg-gray-300 text-lg"
                  component="a"
                  download
                  href={import.meta.env.VITE_DCREATIVE_URL + "resource/4camps-losovani/" + data.meta.activitiesFile}>
                  Stáhnout tabulku{" " + data.meta.activitiesDate}
                </Button>
              </Stack>
            )}
          </Paper>
        </Group>
      </div>
    </Page>
  );
}
