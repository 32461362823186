import { Alert, Button, Group, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconAlertCircle, IconAt } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import LogoSymbol from "../../components/LogoSymbol.jsx";
import logoText from "../../assets/logo-text.svg";
import { Link, useNavigate } from "react-router-dom";
import useTitle from "../../hooks/useTitle.js";
import emailImage from "../../assets/email.webp";

export default function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  useTitle(`Zapomenuté heslo`);

  const form = useForm({
    initialValues: {
      email: "",
    },
  });

  useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/");
    }
  }, []);

  const sendRequest = async (values) => {
    const { email, password } = values;
    setLoading(true);
    const result = await fetch(new URL("/change-password", import.meta.env.VITE_API_URL), {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: {
        "Content-Type": "application/json",
        //"User-Agent": agent,
      },
    });

    let data = await result.json();

    if (result.status !== 200) {
      setError(data.message);
      setLoading(false);
      return;
    }

    setSuccess(true);
  };

  return (
    <div className="w-100 flex h-screen items-center justify-center overflow-hidden">
      {success ? (
        <Stack gap={0} className="mt-4 max-h-screen max-w-[900px] items-center" p="md">
          <Group wrap="nowrap" gap="sm" className="mb-6 flex h-16 px-4" align="center">
            <LogoSymbol height={48} />
            <img src={logoText} height={48} alt="Logo" />
          </Group>
          <div className="mt-8 flex flex-wrap items-center gap-12 md:flex-nowrap">
            <img className="rounded-lg" src={emailImage} alt="Úspěch" />
            <div>
              <h1>Požadavek odeslán!</h1>
              <p className="mt-4">Nyní počkej prosím na e-mail s dalšími instrukcemi.</p>
              <p className="mt-2">
                Dej prosím poslání chvilku. Kdyby ti náhodou e-mail nepřišel, zkontroluj svoji složku se SPAMem. Kdybys
                ho ani tak nenašel/la, napiš do #support na Discordu.
              </p>
            </div>
          </div>
        </Stack>
      ) : (
        <Stack gap={0} className="max-w-[25rem] items-center">
          <Group wrap="nowrap" gap="sm" className="flex h-16 px-4" align="center">
            <LogoSymbol height={48} />
            <img src={logoText} height={48} alt="Logo" />
          </Group>
          <p className="mt-6 text-center">
            Zapomněl jsi heslo? Napiš svůj e-mail a my ti pošleme instrukce, jak ho obnovit.
          </p>
          <form
            className="w-full"
            onSubmit={form.onSubmit((values) => {
              sendRequest(values);
            })}>
            {error && (
              <Alert
                icon={<IconAlertCircle size={16} stroke={1.5} />}
                title="Ve formuláři nastala chyba."
                color="red"
                mb="lg"
                className="w-full"
                withCloseButton
                closeButtonLabel="Zavřít upozornění"
                onClose={() => setError(false)}>
                {error}
              </Alert>
            )}

            <>
              <TextInput
                required
                disabled={loading}
                type="email"
                leftSection={<IconAt stroke={1.5} size={16} />}
                placeholder="test@gmail.com"
                label="E-mail"
                mb="xs"
                mt="lg"
                {...form.getInputProps("email")}
              />
              <Button type="submit" loading={loading} variant="filled" fullWidth>
                Poslat e-mail pro obnovu hesla
              </Button>
              <Button mt="xs" fullWidth variant="subtle" component={Link} to="/prihlaseni">
                Už jsi si vzpomněl? Přihlas se!
              </Button>
            </>
          </form>
        </Stack>
      )}
    </div>
  );
}
