import { useEffect, useState } from "react";
import Page from "../../components/layout/Page";
import MedicalTable from "../../components/table/MedicalTable";
import { useFetch } from "../../helpers/useFetch";
import useTitle from "../../hooks/useTitle";

export default function MedicalHealthOverviewPage() {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [teams, setTeams] = useState(null);

  useTitle("Přehled zdraví");

  useEffect(() => {
    if (!teams) {
      useFetch("/teams")
        .then((data) => {
          setTeams(data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  return (
    <Page title="Přehled zdraví">
      <MedicalTable shouldFetch={shouldFetch} teams={teams} setShouldFetch={setShouldFetch} />
    </Page>
  );
}
