import { useFetch } from "../../helpers/useFetch.js";
import { useLoaderData } from "react-router-dom";
import useTitle from "../../hooks/useTitle.js";
import { EarlyLeavingTable } from "../../components/table/EarlyLeavingTable.jsx";
import Page from "../../components/layout/Page.jsx";
import { IconDoorExit } from "@tabler/icons-react";
import { openEarlyLeaveModal } from "../../helpers/modals.js";

export async function loader() {
  return useFetch(`children/early-leavings`);
}

export default function EarlyLeavingsPage() {
  const earlyLeavings = useLoaderData().data;
  useTitle(`Předčasné odjezdy`);

  const actionIcons = [
    {
      name: "Vytvořit předčasný odjezd",
      onTrigger: (e) => {
        e.stopPropagation();
        openEarlyLeaveModal("Vytvoření předčasného odjezdu");
      },
      icon: IconDoorExit,
    },
  ];

  return (
    <Page actionIcons={actionIcons} title="Předčasné odjezdy">
      <EarlyLeavingTable earlyLeavings={earlyLeavings} />
    </Page>
  );
}
