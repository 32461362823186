import { useFetch } from "../../helpers/useFetch.js";
import { useLoaderData } from "react-router-dom";
import DocumentsTable from "../../components/table/DocumentsTable.jsx";
import useTitle from "../../hooks/useTitle.js";
import Page from "../../components/layout/Page.jsx";

export function loader() {
  return useFetch(`documents`);
}

export default function DocumentsPage() {
  const documents = useLoaderData().data;
  useTitle(`Dokumenty`);

  return (
    <Page title="Dokumenty">
      <DocumentsTable documents={documents} />
    </Page>
  );
}
