import classNames from "classnames";
import styles from "../../pages.module.scss";
import useTitle from "../../hooks/useTitle.js";
import { Group, Paper, Text } from "@mantine/core";
import { IconBed, IconChevronDown, IconDownload, IconHistory, IconTool, IconUsers } from "@tabler/icons-react";
import { useFetch } from "../../helpers/useFetch.js";
import { useLoaderData, useNavigate } from "react-router-dom";
import RoomGroupList from "../../components/accommodation/room-management/RoomGroupList.jsx";
import Page from "../../components/layout/Page.jsx";
import RoomIssuesTable from "../../components/table/RoomIssuesTable.jsx";

export async function loader() {
  const res = await useFetch(`accommodation/rooms/issues/archive`);

  return res;
}

export default function RoomIssuesArchivePage() {
  const rooms = useLoaderData().data;
  const navigate = useNavigate();
  useTitle(`Archiv škod`);

  const actionButtons = [
    {
      name: "Správa pokojů",
      onTrigger: (e) => {
        e.stopPropagation();
        navigate("/ubytovani/pokoje");
      },
      icon: IconBed,
    },
    {
      name: "Přehled škod",
      onTrigger: (e) => {
        e.stopPropagation();
        navigate("/ubytovani/skody");
      },
      icon: IconTool,
      primary: true,
    },
  ];

  return (
    <Page title="Archiv škod" actionIcons={actionButtons}>
      <RoomIssuesTable rooms={rooms} />
    </Page>
  );
}
