import {
  IconDownload,
  IconFilePlus,
  IconId,
  IconIdBadge2,
  IconListDetails,
  IconPill,
  IconReportMedical,
  IconVirus,
  IconX,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import ChildrenTable from "../../components/table/ChildrenTable.jsx";
import useTitle from "../../hooks/useTitle.js";
import Page from "../../components/layout/Page.jsx";
import { ActionIcon, Button, Group, Skeleton, Tooltip } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import NewMedicalReportDrawer from "../../components/medical/NewMedicalReportDrawer.jsx";
import { openNewMedicationModal } from "../../helpers/modals.js";
import { useFetch } from "../../helpers/useFetch.js";
import { parseFullName } from "../../helpers/helpers.js";
import { useUser } from "../../providers/UserProvider.jsx";

const MedicalOverviewPage = () => {
  const [openedFilters, setOpenedFilters] = useState(false);
  const [newReport, setNewReport] = useState(false);
  const [recordChild, setRecordChild] = useState(null);
  const [teams, setTeams] = useState(null);
  const [filteringTeam, setFilteringTeam] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false);
  useTitle(`Přehled dětí`);

  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    if (!teams) {
      useFetch("/teams")
        .then((data) => {
          setTeams(data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  const actionButtons = [
    {
      name: "Exportovat",
      type: "dropdown",
      icon: IconDownload,
      children: [
        {
          name: "Exportovat KP",
          onTrigger: (e) => {
            e.stopPropagation();
            const url = import.meta.env.VITE_API_URL + "/export/documents?types=insurance_card";
            const downloadLink = document.createElement("a");
            downloadLink.target = "_blank";
            downloadLink.href = new URL(url + "&_token=" + user.token, import.meta.env.VITE_API_URL).href;
            downloadLink.click();
          },
          icon: IconIdBadge2,
        },
        {
          name: "Exportovat LP",
          onTrigger: (e) => {
            e.stopPropagation();
            const url = import.meta.env.VITE_API_URL + "/export/documents?types=medical_certificate";
            const downloadLink = document.createElement("a");
            downloadLink.target = "_blank";
            downloadLink.href = new URL(url + "&_token=" + user.token, import.meta.env.VITE_API_URL).href;
            downloadLink.click();
          },
          icon: IconFilePlus,
          primary: openedFilters,
        },
        {
          name: "Exportovat bezinfekčnosti",
          onTrigger: (e) => {
            e.stopPropagation();
            const url = import.meta.env.VITE_API_URL + "/export/documents?types=infectiousness";
            const downloadLink = document.createElement("a");
            downloadLink.target = "_blank";
            downloadLink.href = new URL(url + "&_token=" + user.token, import.meta.env.VITE_API_URL).href;
            downloadLink.click();
          },
          icon: IconVirus,
          primary: openedFilters,
        },
      ],
    },
    {
      name: "Vytvořit nové ošetření",
      onTrigger: (e) => {
        e.stopPropagation();
        setNewReport(true);
      },
      icon: IconReportMedical,
    },
    {
      name: "Přidat pravidelný lék",
      onTrigger: (e) => {
        e.stopPropagation();
        openNewMedicationModal("Přidat pravidelný lék", null, null, null, null, setShouldFetch);
      },
      icon: IconPill,
    },
  ];

  const actionButtonsTable = (child) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Přidat nový lék">
          <ActionIcon
            variant="light"
            onClick={(e) => {
              e.stopPropagation();
              openNewMedicationModal(`Přidat pravidelný lék`, child, parseFullName(child), null, null, setShouldFetch);
            }}>
            <IconPill stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Vytvořit nové ošetření">
          <ActionIcon
            variant="light"
            onClick={(e) => {
              e.stopPropagation();
              setRecordChild(child);
              setNewReport(true);
            }}>
            <IconReportMedical stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Seznam ošetření">
          <ActionIcon
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                "/zdravotnik/osetreni/?filter[ucastnik_jmeno]=" +
                  child.firstName +
                  "&filter[ucastnik_prijmeni]=" +
                  child.lastName
              );
            }}
            variant="light">
            <IconListDetails stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Link to={"/deti/" + child.id}>
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Karta dítěte">
            <ActionIcon variant="light">
              <IconId stroke={1.5} size={18} />
            </ActionIcon>
          </Tooltip>
        </Link>
      </Group>
    );
  };

  return (
    <Page title="Přehled dětí" actionIcons={actionButtons}>
      <Group gap="sm" className="w-full" mb="lg">
        <Group gap="sm" className="flex-grow" grow>
          {teams
            ? teams.map((team) => (
                <Button
                  onClick={() => (filteringTeam === team.id ? setFilteringTeam(null) : setFilteringTeam(team.id))}
                  className="min-w-fit"
                  variant={filteringTeam === team.id ? "filled" : "light"}
                  key={team.id}>
                  {team.shortcut}
                </Button>
              ))
            : [...Array(9)].map((_, i) => <Skeleton key={i} radius="sm" height={36} />)}
        </Group>
        <ActionIcon size={36} className="grow-0" variant="light" color="red" onClick={() => setFilteringTeam("")}>
          <IconX stroke={1.5} size={24} />
        </ActionIcon>
      </Group>

      <ChildrenTable
        shouldFetch={shouldFetch}
        setShouldFetch={setShouldFetch}
        team={filteringTeam}
        showFilters={openedFilters}
        actionButtons={actionButtonsTable}
      />
      <NewMedicalReportDrawer
        opened={newReport}
        onClose={() => {
          setRecordChild(null);
          setNewReport(false);
        }}
        setShouldFetch={setShouldFetch}
        child={recordChild}
      />
    </Page>
  );
};

export default MedicalOverviewPage;
