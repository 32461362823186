import LoginPage from "../pages/login/LoginPage.jsx";
import { Layout } from "../pages/Layout.jsx";
import Dashboard from "../pages/Dashboard.jsx";
import ChildrenManagePage from "../pages/children/ChildrenManagePage.jsx";
import EarlyLeavingsPage, { loader as childEarlyLeavingsLoader } from "../pages/children/EarlyLeavingsPage.jsx";
import ChildDetailPage, { loader as childDetailLoader } from "../pages/children/DetailPage.jsx";
import ChildEditPage, { loader as childEditLoader } from "../pages/children/ChildEditPage.jsx";
import DietaryChildsPage, { loader as dietaryChildsLoading } from "../pages/children/DietaryChildsPage.jsx";
import TeamsManagePage, { loader as teamsManageLoader } from "../pages/teams/TeamsManagePage.jsx";
import MyTeamPage, { loader as myTeamLoader } from "../pages/teams/MyTeamPage.jsx";
import TeamPage, { loader as teamLoader } from "../pages/teams/TeamPage.jsx";
import TeamsSetupPage, { loader as teamsSetupLoader } from "../pages/teams/TeamsSetupPage.jsx";
import TicketsPage, { loader as ticketsLoader } from "../pages/teams/TicketsPage.jsx";
import DocumentsPage, { loader as documentsLoader } from "../pages/teams/DocumentsPage.jsx";
import BoardingPage, { loader as boardingAccommodationLoader } from "../pages/accommodation/BoardingPage.jsx";
import RootErrorBoundary from "../components/RootErrorBoundary.jsx";
import RoomsManagePage, { loader as roomsManageLoader } from "../pages/accommodation/RoomsManagePage.jsx";
import MyTicketsPage, { loader as myTicketsLoader } from "../pages/teams/MyTicketsPage.jsx";
import AccommodationOverview, { loader as accDashLoader } from "../pages/accommodation/AccommodationOverview.jsx";
import RoomIssuesPage, { loader as roomIssuesLoader } from "../pages/accommodation/RoomIssuesPage.jsx";
import RoomsPointsPage, { loader as roomsPointsLoader } from "../pages/accommodation/RoomsPointsPage.jsx";
import RoomIssuesArchivePage, {
  loader as roomsIssuesArchiveLoader,
} from "../pages/accommodation/RoomIssuesArchivePage.jsx";
import MedicalOverviewPage from "../pages/medical/MedicalOverviewPage.jsx";
import MedicalRecordsPage from "../pages/medical/MedicalRecordsPage.jsx";
import TodayChecksPage, { loader as todayCheckLoader } from "../pages/medical/TodayChecksPage.jsx";
import MedicationsPage, { loader as medicationsLoader } from "../pages/medical/MedicationsPage.jsx";
import MedicationDocuments, { loader as medicationDocumentsLoader } from "../pages/medical/MedicalDocumentsPage.jsx";
import ManualPage from "../pages/manual/ManualPage.jsx";
import MedicalRecordsTrashPage from "../pages/medical/MedicalRecordsTrashPage.jsx";
import BoardingOverview, { loader as boardingOverviewLoader } from "../pages/boarding/BoardingOverview.jsx";
import BoardingLayout from "../pages/boarding/BoardingLayout.jsx";
import BoardingMerch from "../pages/boarding/BoardingMerch.jsx";
import BoardingDocuments from "../pages/boarding/BoardingDocuments.jsx";
import BoardingMedical from "../pages/boarding/BoardingMedical.jsx";
import BoardingPocketMoney from "../pages/boarding/BoardingPocketMoney.jsx";
import BoardingDelegateArrive from "../pages/boarding/BoardingDelegateArrive.jsx";
import PermissionsPage, { loader as permissionsLoader } from "../pages/staff/PermissionsPage.jsx";
import ChildrenMoneyPage from "../pages/children/ChildrenMoneyPage.jsx";
import RegistrationPage from "../pages/login/RegistrationPage.jsx";
import RegistrationSuccess from "../pages/login/RegistrationSuccess.jsx";
import BoardingOutgoing from "../pages/boarding/BoardingOutgoing.jsx";
import ForgotPasswordPage from "../pages/login/ForgotPasswordPage.jsx";
import ChangePasswordPage from "../pages/login/ChangePasswordPage.jsx";
import UserSettingsPage, { loader as userSettingsLoader } from "../pages/user/UserSettingsPage.jsx";
import ContractsPage, { loader as myContractsLoader } from "../pages/user/ContractsPage.jsx";
import { UnauthorizedPage } from "../pages/errors/UnauthorizedPage.jsx";
import BoardingDelegateOutgoing from "../pages/boarding/BoardingDelegateOutgoing.jsx";
import StaffOverviewPage, { loader as staffOverviewLoader } from "../pages/staff/StaffOverviewPage.jsx";
import FlashOverview, { loader as flashOverviewLoader } from "../pages/photographer/FlashOverview.jsx";
import ContractsOverview, { loader as contractsLoader } from "../pages/staff/ContractsOverview.jsx";
import { MaintenancePage } from "../pages/errors/MaintenancePage.jsx";
import ContractsSignPage, { loader as contractsSignLoader } from "../pages/user/ContractsSignPage.jsx";
import RoomsOverviewPage, { loader as roomsOverviewLoader } from "../pages/pcapp/RoomsOverviewPage.jsx";
import GameGroupsPage, { loader as gameGroupsLoader } from "../pages/pc/GameGroupsPage.jsx";
import ComputersOverviewPage, { loader as computersOverviewLoader } from "../pages/pcapp/ComputersOverviewPage.jsx";
import TestPage from "../pages/Test.jsx";
import PcMinecraftAdminWhitelistPage, {
  loader as PcMinecraftAdminWhitelistLoader,
} from "../pages/pc/PcMinecraftAdminWhitelistPage.jsx";
import FormsSetupPage from "../pages/pcapp/FormsSetupPage.jsx";
import PcChildrenPage from "../pages/pc/PcChildrenPage.jsx";
import RoulettePage, { loader as rouletteLoader } from "../pages/staff/RoulettePage.jsx";
import RouletteRollPage from "../pages/staff/RouletteRollPage.jsx";
import MedicalHealthOverviewPage from "../pages/medical/MedicalHealthOverviewPage.jsx";
import ImpersonationPage from "../pages/login/ImpersonationPage.jsx";
import DepotPage from "../pages/depot/DepotPage.jsx";
import CheckoutPage, { loader as checkoutLoader } from "../pages/buffet/CheckoutPage.jsx";
import LogsPage from "../pages/logs/LogsPage.jsx";
import DepotLogsPage from "../pages/depot/DepotLogsPage.jsx";
import { DepotHandoversPage } from "../pages/depot/DepotHandoversPage.jsx";
import PcQuestsPage from "../pages/pc/PcQuestsPage.jsx";
import PcQuestsFillPage from "../pages/pc/PcQuestsFillPage.jsx";
import ScheduledMessagesPage from "../pages/discord/ScheduledMessagesPage.jsx";
import SuburbanLeavings, { loader as suburbanLeavingsLoader } from "../pages/boarding/SuburbanLeavings.jsx";
import OverviewPage, { loader as buffetOverviewLoader } from "../pages/buffet/OverviewPage.jsx";

const routes = [
  {
    path: "/prihlaseni",
    children: [
      {
        path: "impersonace",
        element: <ImpersonationPage />,
      },
      {
        index: true,
        element: <LoginPage />,
      },
    ],
  },
  {
    path: "/registrace",
    children: [
      {
        path: "uspech",
        element: <RegistrationSuccess />,
      },
      {
        index: true,
        element: <RegistrationPage />,
      },
    ],
  },
  {
    path: "/heslo",
    children: [
      {
        path: "zapomenute",
        element: <ForgotPasswordPage />,
      },
      {
        path: "obnova/:token",
        element: <ChangePasswordPage />,
      },
    ],
  },
  {
    path: "/probiha-udrzba",
    element: <MaintenancePage />,
  },
  {
    path: "/",
    exact: true,
    element: <Layout />,
    errorElement: <RootErrorBoundary />,
    children: [
      {
        errorElement: <RootErrorBoundary />,
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
          {
            path: "/deti",
            children: [
              {
                path: "prehled",
                element: <ChildrenManagePage />,
              },
              {
                path: "odjezdy",
                element: <EarlyLeavingsPage />,
                loader: childEarlyLeavingsLoader,
              },
              {
                path: "dietari",
                element: <DietaryChildsPage />,
                loader: dietaryChildsLoading,
              },
              {
                path: "penize/:category",
                element: <ChildrenMoneyPage />,
              },
              {
                path: ":childId",
                children: [
                  {
                    index: true,
                    element: <ChildDetailPage />,
                    loader: childDetailLoader,
                  },
                  {
                    path: "edit",
                    element: <ChildEditPage />,
                    loader: childEditLoader,
                  },
                ],
              },
              {
                path: "dokumenty",
                element: <MedicationDocuments />,
                loader: medicationDocumentsLoader,
              },
            ],
          },
          {
            path: "/ubytovani",
            children: [
              {
                path: "prehled",
                element: <AccommodationOverview />,
                loader: accDashLoader,
              },
              {
                path: "prirazeni",
                element: <BoardingPage />,
                loader: boardingAccommodationLoader,
              },
              {
                path: "pokoje",
                element: <RoomsManagePage />,
                loader: roomsManageLoader,
              },
              {
                path: "skody",
                children: [
                  {
                    index: true,
                    element: <RoomIssuesPage />,
                    loader: roomIssuesLoader,
                  },
                  {
                    path: "archiv",
                    element: <RoomIssuesArchivePage />,
                    loader: roomsIssuesArchiveLoader,
                  },
                ],
              },
              {
                path: "bodovani",
                element: <RoomsPointsPage />,
                loader: roomsPointsLoader,
              },
            ],
          },
          {
            path: "/oddily",
            children: [
              {
                path: "prehled",
                element: <TeamsManagePage />,
                loader: teamsManageLoader,
              },
              {
                path: "muj",
                element: <MyTeamPage />,
                loader: myTeamLoader,
              },
              {
                path: ":teamId",
                element: <TeamPage />,
                loader: teamLoader,
              },
              {
                path: "vytvoreni",
                element: <TeamsSetupPage />,
                loader: teamsSetupLoader,
              },
              {
                path: "pozadavky",
                element: <TicketsPage />,
                loader: ticketsLoader,
              },
              {
                path: "moje-pozadavky",
                element: <MyTicketsPage />,
                loader: myTicketsLoader,
              },
            ],
          },
          {
            path: "/zdravotnik",
            children: [
              {
                path: "prehled",
                element: <MedicalOverviewPage />,
              },
              {
                path: "osetreni",
                children: [
                  {
                    index: true,
                    element: <MedicalRecordsPage />,
                  },
                  {
                    path: "smazana",
                    element: <MedicalRecordsTrashPage />,
                  },
                ],
              },
              {
                path: "dnesni-kontroly",
                element: <TodayChecksPage />,
                loader: todayCheckLoader,
              },
              {
                path: "pravidelne-leky",
                element: <MedicationsPage />,
                loader: medicationsLoader,
              },
              {
                path: "prehled-zdravi",
                element: <MedicalHealthOverviewPage />,
              },
            ],
          },
          {
            path: "fotograf",
            children: [
              {
                path: "flashky",
                element: <FlashOverview />,
                loader: flashOverviewLoader,
              },
            ],
          },
          {
            path: "dokumenty",
            element: <DocumentsPage />,
            loader: documentsLoader,
          },
          {
            path: "navody",
            children: [
              {
                path: "*",
                element: <ManualPage />,
                index: true,
              },
            ],
          },
          {
            path: "akreditace",
            children: [
              {
                path: "prehled",
                element: <BoardingOverview />,
                loader: boardingOverviewLoader,
              },
              {
                path: "prehled-odjezdu",
                element: <SuburbanLeavings />,
                loader: suburbanLeavingsLoader,
              },
              {
                path: "",
                element: <BoardingLayout />,
                exact: true,
                errorElement: <RootErrorBoundary />,
                children: [
                  {
                    path: "merch",
                    element: <BoardingMerch />,
                  },
                  {
                    path: "rady-a-kauce",
                    element: <BoardingDocuments />,
                  },
                  {
                    path: "zdravotnik",
                    element: <BoardingMedical />,
                  },
                  {
                    path: "kapesne",
                    element: <BoardingPocketMoney />,
                  },
                  {
                    path: "delegat-prijezd",
                    element: <BoardingDelegateArrive />,
                  },
                  {
                    path: "delegat-odjezd",
                    element: <BoardingDelegateOutgoing />,
                  },
                  {
                    path: "odjezdy",
                    element: <BoardingOutgoing />,
                  },
                  /* {
                    path: "ubytovani",
                    element: <BoardingMerch />,
                  }, */
                ],
              },
            ],
          },
          {
            path: "personal",
            children: [
              {
                path: "prehled",
                element: <StaffOverviewPage />,
                loader: staffOverviewLoader,
              },
              {
                path: "prava",
                element: <PermissionsPage />,
                loader: permissionsLoader,
              },
              {
                path: "smlouvy",
                element: <ContractsOverview />,
                loader: contractsLoader,
              },
              {
                path: "losovani",
                children: [
                  {
                    index: true,
                    element: <RoulettePage />,
                    loader: rouletteLoader,
                  },
                  {
                    path: ":type",
                    element: <RouletteRollPage />,
                  },
                ],
              },
            ],
          },
          {
            path: "logy",
            element: <LogsPage />,
          },
          {
            path: "bufet",
            children: [
              {
                path: "kasa",
                element: <CheckoutPage />,
                loader: checkoutLoader,
              },
              {
                path: "sklad",
                element: <DepotPage sellData={true} />,
              },
              {
                path: "prehled",
                element: <OverviewPage />,
                loader: buffetOverviewLoader,
              },
            ],
          },
          {
            path: "sklad",
            children: [
              {
                path: "prehled",
                element: <DepotPage />,
              },
              {
                path: "logy",
                element: <DepotLogsPage />,
              },
              {
                path: "predavaci-protokoly",
                element: <DepotHandoversPage />,
              },
            ],
          },
          {
            path: "nastaveni",
            element: <UserSettingsPage />,
            loader: userSettingsLoader,
          },

          {
            path: "smlouvy",
            children: [
              {
                index: true,
                element: <ContractsPage />,
                loader: myContractsLoader,
              },
              {
                path: ":contractId",
                element: <ContractsSignPage />,
                loader: contractsSignLoader,
              },
            ],
          },
          {
            path: "pc-program",
            children: [
              {
                path: "deti",
                element: <PcChildrenPage />,
              },
              {
                path: "mistnosti",
                element: <RoomsOverviewPage />,
                loader: roomsOverviewLoader,
              },
              {
                path: "herni-skupiny",
                element: <GameGroupsPage />,
                loader: gameGroupsLoader,
              },
              {
                path: "prehled",
                element: <ComputersOverviewPage />,
                loader: computersOverviewLoader,
              },
              {
                path: "mc-admin-whitelist",
                element: <PcMinecraftAdminWhitelistPage />,
                loader: PcMinecraftAdminWhitelistLoader,
              },
              {
                path: "dotazniky",
                element: <FormsSetupPage />,
              },
              {
                path: "questy-editace",
                element: <PcQuestsPage />,
              },
              {
                path: "questy-plneni",
                element: <PcQuestsFillPage />,
              },
            ],
          },
          {
            path: "discord",
            children: [
              /* {
                index: true,
                element: <ContractsPage />,
                loader: myContractsLoader,
              }, */
              {
                path: "zpravy",
                element: <ScheduledMessagesPage />,
              },
            ],
          },

          {
            path: "/test",
            element: <TestPage />,
          },
          // {
          //   path: "*",
          //   element: <ErrorNotFound />,
          // },
        ],
      },
    ],
  },
  {
    path: "/neautorizovano",
    element: <UnauthorizedPage />,
  },
];

export default routes;
