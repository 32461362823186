import { IconCash } from "@tabler/icons-react";
import Drawer from "../drawer/Drawer";
import { useContext, useState } from "react";
import { CartContext } from "./CartContext";
import { Accordion, Button, Divider, Group, Stack, Text } from "@mantine/core";
import { CheckoutCalculator } from "./CheckoutCalculator";
import { useFetch } from "../../helpers/useFetch";
import toast from "react-hot-toast";
import { useRevalidator } from "react-router-dom";

export const CheckoutDrawer = ({ opened, onClose, products }) => {
  const { cart, getOverallPrice, clearCart } = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  const revalidate = useRevalidator();

  const sendOrder = async () => {
    const data = cart.map((cartItem) => ({
      product_id: cartItem.id,
      quantity: cartItem.quantity,
    }));
    setLoading(true);
    const res = await useFetch("depot/orders", "POST", { items: data });
    setLoading(false);
    if (res.status === "ok") {
      toast.success("Nákup dokončen");
      onClose();
      clearCart();
      revalidate.revalidate();
      return;
    }
    toast.error("Nákup se nezdařil, zkuste to znovu");
  };

  return (
    <Drawer opened={opened} onClose={onClose} title={"Dokončení nákupu"} icon={IconCash}>
      <Stack gap={"md"} pt={"md"}>
        <Text fw={"bold"} size={"1.5rem"}>
          Košík
        </Text>
        <Stack gap={8}>
          {cart.map((cartItem, index) => {
            return (
              <Stack gap={0} key={cartItem.id}>
                <Group key={cartItem.id} align="center" justify="space-between" px={"sm"}>
                  <Text fw={"bold"} size="lg" className="grow">
                    {index + 1}. {products.find((p) => p.id === cartItem.id)?.name}
                  </Text>
                  <Text w={"1rem"} ta={"center"}>
                    {cartItem.quantity}
                  </Text>
                  <Text w={"4rem"} ta={"right"}>
                    {cartItem.quantity * products.find((p) => p.id === cartItem.id)?.sellPrice} Kč
                  </Text>
                </Group>
                {index === cart.length - 1 ? null : <Divider orientation="horizontal" variant={"dashed"} />}
              </Stack>
            );
          })}
          <Divider orientation="horizontal" size={"md"} />
          <Group align="center" justify="space-between" px={"sm"}>
            <Text fw={"bold"} size="lg">
              Celková cena:
            </Text>
            <Text fw={"bold"} size="lg">
              {getOverallPrice(products)} Kč
            </Text>
          </Group>
        </Stack>
        <Accordion chevronPosition="right" variant="contained" mt={"xl"}>
          <Accordion.Item value="checkout">
            <Accordion.Control>
              <Text fw={"bold"} size={"lg"}>
                Kalkulačka
              </Text>
            </Accordion.Control>
            <Accordion.Panel>
              <CheckoutCalculator overallPrice={getOverallPrice(products)} />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
        <Button
          fullWidth
          onClick={() => {
            sendOrder();
          }}
          mt={"xl"}
          size="lg"
          loading={loading}>
          Dokončit platbu
        </Button>
      </Stack>
    </Drawer>
  );
};
