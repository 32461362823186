import { useEffect, useState } from "react";
import { useFetch } from "../../helpers/useFetch";
import { useForm } from "@mantine/form";
import { Button, Group, Input, Loader, NumberInput, Select, TextInput, Textarea } from "@mantine/core";
import toast from "react-hot-toast";

const getData = async () => {
  const categories = await useFetch("depot/categories");
  return { categories: categories.data };
};

const eanPossibilities = "+ěščřžýáíé";

export const NewArticleModal = ({ innerProps: { setShouldFetch, sellData }, context, id }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const form = useForm({
    initialValues: {
      name: "",
      sell_price: null,
      category_id: null,
      note: null,
      ean: null,
      ean2: null,
    },
  });

  const handleEanChange = (ean) => {
    if (ean.length === 0) return "";
    return ean
      .split("")
      .map((key) => {
        if (isNaN(key) && eanPossibilities.includes(key)) {
          let digit = eanPossibilities.indexOf(key) > 8 ? 0 : eanPossibilities.indexOf(key) + 1;
          return digit;
        } else if (!isNaN(key)) {
          return key;
        } else {
          return "";
        }
      })
      .join("");
  };

  const eansDigitialize = (ean, type) => {
    if (!ean) return true;
    const newEan = handleEanChange(ean);
    if (newEan.length !== ean.length) return false;
    if (type === "ean") form.setValues({ ean: newEan });
    return newEan;
  };

  const handleSubmit = async () => {
    if (form.values.sell_price === 0) form.setValues({ ...form.values, sell_price: null });
    const ean = eansDigitialize(form.values.ean);
    const ean2 = eansDigitialize(form.values.ean2);
    if (sellData && (!ean || !ean2)) return toast.error("Jeden z EANů se nepodařilo digitalizovat");
    else if (sellData) {
      form.values.ean = ean;
      form.values.ean2 = ean2;
    }
    setLoading(true);
    const res = await useFetch("depot/products", "POST", form.values);
    setLoading(false);
    if (res.status === "ok") {
      toast.success("Artikl byl úspěšně přidán");
      if (setShouldFetch) setShouldFetch(true);
      return context.closeModal(id);
    }
    console.error(res);
    toast.error("Nastala chyba při přidávání artiklu");
    return;
  };

  useEffect(() => {
    setFetching(true);
    getData().then((res) => {
      setCategories(res.categories);
      setFetching(false);
    });
  }, []);

  return (
    <>
      {fetching ? (
        <div className="flex h-full w-full flex-col items-center justify-center py-8">
          <Loader />
        </div>
      ) : (
        <form onSubmit={form.onSubmit(handleSubmit)} className="flex flex-col gap-2">
          <Input.Wrapper label="Název" withAsterisk>
            <TextInput {...form.getInputProps("name")} placeholder="Název" required disabled={loading} />
          </Input.Wrapper>
          <Select
            label="Kategorie"
            data={categories.map((category) => ({ label: category.name, value: category.id.toString() }))}
            {...form.getInputProps("category_id")}
            placeholder="Vyber kategorii"
            searchable
            disabled={loading}
          />
          <Textarea
            label="Poznámka"
            placeholder="Poznámka"
            {...form.getInputProps("note")}
            disabled={loading}
            minRows={3}
            maxRows={5}
          />
          {sellData ? (
            <>
              <Input.Wrapper label="Prodejní cena" withAsterisk>
                <NumberInput
                  {...form.getInputProps("sell_price")}
                  placeholder="Prodejní cena"
                  disabled={loading}
                  min={0}
                  required
                />
              </Input.Wrapper>
              <Group grow gap={"md"}>
                <Input.Wrapper label="EAN">
                  <TextInput {...form.getInputProps("ean")} placeholder="EAN" disabled={loading} />
                </Input.Wrapper>
                <Input.Wrapper label="EAN 2">
                  <TextInput {...form.getInputProps("ean2")} placeholder="EAN2" disabled={loading} />
                </Input.Wrapper>
              </Group>
            </>
          ) : null}
          <Button type="submit" variant="filled" mt={"lg"} loading={loading} className="w-full">
            Přidat artikl
          </Button>
        </form>
      )}
    </>
  );
};
