export async function useSocketFetch(endpoint, method = "GET", data, useJson = true) {
  let key = import.meta.env.VITE_SOCKET_TOKEN;
  const userItem = localStorage.getItem("user");
  const user = JSON.parse(userItem);

  let endpointLocal = endpoint.replace(/&area/g, user?.area);

  const optionsDefault = {
    method,
    body: data ? (useJson ? JSON.stringify(data) : data) : undefined,
    headers: {},
  };

  if (useJson) {
    optionsDefault.headers["Content-Type"] = "application/json";
    optionsDefault.headers["Accept"] = "application/json";
  }

  let res = await fetch(new URL(endpointLocal + "?key=" + key, import.meta.env.VITE_SOCKET_URL), optionsDefault);

  if (res.status === 403) {
    return (window.location.href = "/neautorizovano");
  }
  if (res.status === 503 && window.location.href !== "/probiha-udrzba") {
    return (window.location.href = "/probiha-udrzba");
  } /* else if (res.status !== 200) {
    return "error";
  } */
  return res.json();
}
