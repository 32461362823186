import { ActionIcon } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import classNames from "classnames";
import man from "../../assets/medical/man.svg";
import woman from "../../assets/medical/woman.svg";

export default function SmallFigure({ gender, points, onClick }) {
  return (
    <div
      onClick={onClick}
      className={classNames("group relative flex w-full max-w-full items-center justify-center", {
        "cursor-pointer": onClick,
      })}>
      {onClick && (
        <ActionIcon
          className="absolute z-10 opacity-0 duration-150 group-hover:opacity-100"
          style={{ transform: "translateY(-50%)" }}
          size="xl"
          variant="filled">
          <IconEdit stroke={1.5} size={28} />
        </ActionIcon>
      )}

      <div className="relative flex w-fit max-w-[75%] grow justify-center">
        <div className="relative">
          <div className="absolute h-full w-full">
            {points?.map((point, i) => (
              <div
                key={i}
                className="group absolute box-content select-none overflow-hidden rounded-full border-4 border-solid border-indigo-400/50"
                style={{
                  top: `${point.y * 100}%`,
                  left: `${point.x * 100}%`,
                  transform: "translate(-50%, -50%)",
                }}>
                <div className="flex h-4 w-4 items-center justify-center bg-indigo-400"></div>
              </div>
            ))}
          </div>
          <img
            src={gender === "Chlapec" ? man : woman}
            className="max-h-full w-full max-w-full"
            alt="Postavička chlapce"
          />
        </div>
      </div>
    </div>
  );
}
