import {
  ActionIcon,
  Alert,
  Button,
  Checkbox,
  Group,
  Input,
  NumberInput,
  Paper,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useFetch } from "../../helpers/useFetch";
import { useUser } from "../../providers/UserProvider";
import "dayjs/locale/cs";
import dayjs from "dayjs";
import Drawer from "../drawer/Drawer";
import Figure from "./Figure";
import {
  IconCheck,
  IconEdit,
  IconReportMedical,
  IconFileText,
  IconTrash,
  IconX,
  IconAlertCircle,
} from "@tabler/icons-react";
import SmallFigure from "./SmallFigure";
import { toast } from "react-hot-toast";
import { Dropzone, PDF_MIME_TYPE, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { createAbbreviation, getMode, parseFullName } from "../../helpers/helpers";
import RichSelect from "../RichSelect";

const getChildren = async () => {
  const res = await useFetch("/children/list");
  return res.data;
};

const getStaff = async () => {
  const res = await useFetch("/staff");
  return res.data;
};

const NewMedicalReportDrawer = ({ child, opened, onClose, resetTable, reports, setReports, setShouldFetch, type }) => {
  const [loading, setLoading] = useState(true);
  const [types, setTypes] = useState([]);
  const [userList, setUserList] = useState([]);
  const [fetchList, setFetchList] = useState([]);
  const [medications, setMedications] = useState([]);
  const [currentMedication, setCurrentMedication] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [date, setDate] = useState(null);
  const [figureGender, setFigureGender] = useState(null);
  const [points, setPoints] = useState([]);
  const [figureOpened, setFigureOpened] = useState(false);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [listType, setListType] = useState("child"); // "child" or "staff" [default: child]
  const { user } = useUser();

  const createUserList = async () => {
    setLoading(true);
    const fetchListLocal = listType === "child" ? await getChildren() : await getStaff();
    setFetchList(fetchListLocal);
    const userListLocal = fetchListLocal.map((userLocal) => ({
      label: `${parseFullName(userLocal)} (${userLocal.age} let${
        userLocal?.position ? `, ${createAbbreviation(userLocal?.position)}` : ""
      }${userLocal.team ? ", oddíl " + userLocal.team : ""})`,
      value: String(userLocal.id),
    }));

    setUserList(userListLocal);
    setLoading(false);
  };

  useEffect(() => {
    if (child) {
      setFigureGender(child.gender);
    }
    Promise.all([
      useFetch("medical/medications-list").then((res) => {
        setMedications(res.map((medication) => ({ label: medication, value: medication })));
      }),
      useFetch("medical/records-types").then((res) => {
        setTypes(res.map((type) => ({ label: type, value: type })));
      }),
    ]).then((values) => {
      setLoading(false);
    });
  }, []);

  const form = useForm({
    initialValues: {
      userId: child && child.id,
      type: type ? type : "",
      treatment: "",
      description: "",
      materials: [],
      check_at: "",
      created_at: new Date(),
      is_isolated: child ? child.isIsolated : false,
    },
  });

  useEffect(() => {
    if (opened) {
      form.setFieldValue("userId", child && child.id);
      form.setFieldValue("created_at", new Date());
      form.setFieldValue("is_isolated", child && child.isIsolated);
      createUserList();
      setDate(null);
      setMaterials([]);
      setPoints([]);
    }
  }, [opened]);

  useEffect(() => {
    setFigureGender(fetchList?.filter((userLocal) => userLocal.id === Number(form.values.userId))[0]?.gender);
  }, [form.values.userId]);

  useEffect(() => {
    form.setFieldValue("userId", null);
    createUserList();
  }, [listType]);

  const handleSubmit = async (values) => {
    setLoading(true);
    let valuesCopy = { ...values };
    if (child) valuesCopy.userId = child.id;
    if (date) valuesCopy.check_at = dayjs(date).format("YYYY-MM-DD HH:mm");
    valuesCopy.created_at = dayjs(valuesCopy.created_at).format("YYYY-MM-DD HH:mm");
    valuesCopy.materials = materials;
    valuesCopy.figure_points = points;
    valuesCopy.is_isolated = valuesCopy.is_isolated ? true : false;
    const res = await useFetch(
      (listType === "child" ? "children/" : "staff/") + valuesCopy.userId + "/medical-record",
      "POST",
      valuesCopy
    );
    if (res.status === "ok") {
      if (files.length > 0) {
        files.forEach(async (file) => {
          let fileFormData = new FormData();
          fileFormData.append("name", file.name);
          fileFormData.append("file", file);
          await useFetch("medical/records/attachment/" + res.data.id, "POST", fileFormData, false);
          if (res.status !== "ok") {
            toast.error(`Nepodařilo se nahrát soubor ${file.name}.`);
          }
        });
      }
      setLoading(false);
      if (!child && resetTable) resetTable();
      if (reports && setReports) setReports([res.data, ...reports]);
      form.reset();
      if (setShouldFetch) setShouldFetch(true);
      toast.success("Ošetření bylo vytvořeno.");
      return onClose(true);
    } else {
      setLoading(false);
      setError(res.message);
    }
  };

  const theme = useMantineTheme();

  return (
    <>
      <Figure
        gender={figureGender}
        points={points}
        opened={figureOpened}
        setOpened={setFigureOpened}
        setPoints={setPoints}
      />
      <Drawer
        opened={opened}
        onClose={onClose}
        className="overflow-visible"
        title="Nový záznam ošetření"
        smallTitle={child && child.firstName + " " + child.lastName}
        icon={IconReportMedical}>
        {error && (
          <Alert
            icon={<IconAlertCircle size={16} stroke={1.5} />}
            title="Při vytváření ošetření nastala chyba."
            color="red"
            mb="md"
            mt="lg"
            withCloseButton
            closeButtonLabel="Zavřít upozornění"
            onClose={() => setError(false)}>
            {error}
          </Alert>
        )}
        <SegmentedControl
          mb="sm"
          color={getMode().theme.primaryColor}
          fullWidth
          value={listType}
          onChange={setListType}
          data={[
            { label: "Dítě", value: "child" },
            { label: "Vedoucí", value: "staff" },
          ]}
        />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          {!child && (
            <Select
              label={listType === "child" ? "Dítě" : "Vedoucí"}
              mb={"sm"}
              data={userList}
              disabled={loading}
              {...form.getInputProps("userId")}
              searchable
              hoverOnSearchChange={true}
              required
              placeholder={listType === "child" ? "Vyber dítě..." : "Vyber vedoucího..."}
            />
          )}

          <div className="mb-4 grid w-full gap-4 md:grid-cols-2">
            <TextInput
              {...form.getInputProps("type")}
              placeholder="Klíště..."
              disabled={!form.values.userId || loading}
              required
              label="Typ zranění"
            />
            <DateTimePicker
              minDate={dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD").toDate()}
              maxDate={dayjs(user?.currentCampSession.dateTo, "YYYY-MM-DD").toDate()}
              initialMonth={dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD").toDate()}
              locale="cs"
              hideOutsideDates
              label="Datum a čas ošetření"
              valueFormat="DD.MM.YYYY HH:mm"
              allowDeselect
              disabled={!form.values.userId || loading}
              {...form.getInputProps("created_at")}
            />
          </div>

          <div className="mb-4 grid w-full gap-4 md:grid-cols-2">
            <div className="max-w-none">
              <Input.Wrapper label="Léčba" mb="sm" withAsterisk>
                <TextInput
                  disabled={!form.values.userId || loading}
                  {...form.getInputProps("treatment")}
                  loading={loading}
                  required
                  placeholder="Léčba/ošetření..."
                />
              </Input.Wrapper>
              <Textarea
                minRows={4}
                maxRows={10}
                autosize
                disabled={!form.values.userId || loading}
                label="Popis ošetření"
                placeholder="Dítě dostalo míčem do břicha..."
                {...form.getInputProps("description")}
              />
            </div>
            <div>
              <RichSelect
                name="Podaná medikace"
                data={medications}
                {...form.getInputProps("materials")}
                placeholder="Vyber lék nebo zadej nový..."
                searchable
                className="mb-2"
                hoverOnSearchChange={true}
                creatable
                value={currentMedication}
                onChange={(item) => {
                  setCurrentMedication(null);
                  if (materials.find((material) => material.name === item)) {
                    return toast.error("Lék již byl přidán.");
                  } else {
                    setMaterials([...materials, { name: item, value: 1 }]);
                  }
                }}
                label={(data) => <Text>{data?.label}</Text>}
                disabled={!form.values.userId || loading}
                getCreateLabel={(query) => `Zadat jiný lék: ${query}`}
                onCreate={(query) => {
                  setCurrentMedication(null);
                  const item = { label: query, value: query };
                  setMedications([...medications, item]);
                  if (materials.find((material) => material.name === item.value)) {
                    toast.error("Lék již byl přidán.");
                  } else {
                    setMaterials([...materials, { name: item.label, value: 1 }]);
                  }
                  return item;
                }}
              />
              {materials.length >= 1 && (
                <Stack mb="sm" gap="sm">
                  {materials.map((material, index) => (
                    <div key={index} className="flex w-full items-center justify-between">
                      <Text size="sm" fw="bold">
                        {material.name}
                      </Text>
                      <Group gap="sm">
                        <NumberInput
                          min={0}
                          max={100}
                          step={0.5}
                          precision={1}
                          removeTrailingZeros
                          value={material.value}
                          disabled={!form.values.userId || loading}
                          onChange={(value) => {
                            const newMaterials = [...materials];
                            newMaterials[index].value = value;
                            setMaterials(newMaterials);
                          }}
                        />
                        <ActionIcon
                          variant="light"
                          size={36}
                          onClick={() => setMaterials(materials.filter((_, i) => i !== index))}
                          color="red"
                          disabled={!form.values.userId || loading}>
                          <IconTrash size={24} stroke={1.5} />
                        </ActionIcon>
                      </Group>
                    </div>
                  ))}
                </Stack>
              )}

              <div className="flex gap-2">
                <DateTimePicker
                  className="flex-1"
                  minDate={dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD").toDate()}
                  maxDate={dayjs(user?.currentCampSession.dateTo, "YYYY-MM-DD").toDate()}
                  initialMonth={dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD").toDate()}
                  locale="cs"
                  hideOutsideDates
                  label="Datum a čas další kontroly"
                  allowDeselect
                  value={date}
                  onChange={setDate}
                  disabled={!form.values.userId || loading}
                />
                <Button
                  onClick={() => setDate(null)}
                  variant="light"
                  color="red"
                  disabled={!form.values.userId || date === null || loading}
                  className="mt-auto flex h-9 w-9 items-center justify-center">
                  <IconX stroke={1.5} size={20} />
                </Button>
              </div>
              <Checkbox
                disabled={!form.values.userId || loading}
                label="Je v izolaci"
                className="mt-3"
                {...form.getInputProps("is_isolated", { type: "checkbox" })}
              />
            </div>
          </div>
          {form.values.userId ? (
            <div className="mb-4 grid w-full gap-4 md:grid-cols-2">
              <div>
                <h3 className="mb-3 mt-4 flex items-center justify-between text-xl">
                  Místo ošetření
                  <ActionIcon onClick={() => setFigureOpened(true)} variant="light">
                    <IconEdit stroke={1.5} size={20} />
                  </ActionIcon>
                </h3>
                <SmallFigure onClick={() => setFigureOpened(true)} points={points} gender={figureGender} />
              </div>
              <div>
                <h3 className="mb-3 mt-4 flex items-center justify-between text-xl">Lékařské zprávy</h3>
                <Dropzone
                  p="sm"
                  onDrop={(filesLocal) => {
                    setFiles([...files, ...filesLocal]);
                  }}
                  accept={[...PDF_MIME_TYPE, ...IMAGE_MIME_TYPE]}>
                  <Group gap="sm">
                    <Dropzone.Accept>
                      <IconCheck color={theme.colors[theme.primaryColor][4]} size={20} stroke={1.5} />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                      <IconX color={theme.colors.red[4]} size={20} stroke={1.5} />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                      <IconFileText size={20} stroke={1.5} />
                    </Dropzone.Idle>

                    <Text>Klikni pro nahrání souboru</Text>
                  </Group>
                </Dropzone>
                {files?.map((file, i) => (
                  <Paper
                    mih={52}
                    key={i}
                    mt="sm"
                    bg="dark.6"
                    withBorder
                    px="sm"
                    py={4}
                    className="flex items-center justify-between gap-2">
                    <Text>{file.name}</Text>
                    <ActionIcon color="red" variant="light" onClick={() => setFiles(files.filter((f) => f !== file))}>
                      <IconTrash size={20} stroke={1.5} />
                    </ActionIcon>
                  </Paper>
                ))}
              </div>
            </div>
          ) : null}

          <Button disabled={!form.values.userId} fullWidth type="submit" loading={loading} mt="sm">
            Vytvořit ošetření
          </Button>
        </form>
      </Drawer>
    </>
  );
};

export default NewMedicalReportDrawer;
