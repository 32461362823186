import { useEffect, useState } from "react";
import { useFetch } from "../../helpers/useFetch";
import { useForm } from "@mantine/form";
import { Button, Group, Input, Loader, NumberInput, Select, Textarea } from "@mantine/core";
import toast from "react-hot-toast";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";

const getData = async () => {
  const depots = await useFetch("depot/depots");
  return { depots: depots.data };
};

const moveOptions = [
  { label: "Přeskladnění", value: "transfer" },
  { label: "Naskladnění", value: "in" },
  { label: "Vyskladnění", value: "out" },
];

export const MoveArticleModal = ({ innerProps: { setShouldFetch, article, sellData }, context, id }) => {
  const [depots, setDepots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [selectedDepot, setSelectedDepot] = useState(null);

  const handleSubmit = async () => {
    if (form.values.amount === 0) return toast.error("Množství musí být větší než 0");
    if (form.values.depot_from === form.values.depot_to)
      return toast.error("Sklad odkud a sklad kam nemůžou být stejné");
    setLoading(true);
    const data = {
      type: form.values.type,
      note: form.values.note,
      moves: [
        {
          amount: form.values.amount,
          expiration_date: form.values.expiration_date
            ? dayjs(form.values.expiration_date).add(4, "hours").format("YYYY-MM-DD")
            : null,
          note: form.values.note,
          product_id: article.id,
        },
      ],
    };
    if (form.values.type === "transfer" || form.values.type === "out") {
      data.moves[0].depot_from = article.depots.find(
        (depot) => depot.depot.id === parseInt(form.values.depot_from)
      ).depot.id;
    }
    if (form.values.type === "transfer" || form.values.type === "in") {
      data.moves[0].depot_to = form.values.depot_to;
    }
    const res = await useFetch("depot/move-packs", "POST", data);
    setLoading(false);
    if (res.status === "ok") {
      toast.success(moveOptions.find((option) => option.value === form.values.type).label + " bylo úspěšně provedeno");
      if (setShouldFetch) setShouldFetch(true);
      form.reset();
      return context.closeModal(id);
    }
    console.error(res);
    toast.error(moveOptions.find((option) => option.value === form.values.type).label + " se nezdařilo");
    return;
  };

  const form = useForm({
    initialValues: {
      type: "",
      note: "",
      depot_from: null,
      depot_to: null,
      amount: null,
      expiration_date: null,
    },
  });

  useEffect(() => {
    setFetching(true);
    getData().then((res) => {
      setDepots(res.depots);
      setFetching(false);
    });
  }, []);

  useEffect(() => {
    setSelectedDepot(null);
    form.setValues({ ...form.values, depot_from: null, depot_to: null });
    if (article.depots.length === 1 && form.values.type !== "in") {
      form.setValues({ ...form.values, depot_from: article.depots[0].depot.id.toString() });
      setSelectedDepot(article.depots[0]);
    }
  }, [form.values.type]);

  useEffect(() => {
    if (form.values.depot_from) {
      const selectedLocal = article.depots.find((depot) => depot.depot.id === parseInt(form.values.depot_from));
      if (selectedLocal?.expirationDate) {
        form.setValues({ ...form.values, expiration_date: dayjs(selectedLocal.expirationDate).toDate() });
      }
      setSelectedDepot(selectedLocal);
    }
  }, [form.values.depot_from]);

  return (
    <>
      {fetching ? (
        <div className="flex h-full w-full flex-col items-center justify-center py-8">
          <Loader />
        </div>
      ) : (
        <form onSubmit={form.onSubmit(handleSubmit)} className="flex flex-col gap-2">
          <Select
            label="Typ pohybu"
            data={moveOptions}
            {...form.getInputProps("type")}
            placeholder="Vyber typ pohybu"
            searchable
            disabled={loading}
            required
          />
          <Group grow gap={"md"}>
            {(form.values.type === "transfer" || form.values.type === "out") && (
              <Select
                label="Sklad odkud"
                data={article.depots
                  .filter((depot) => {
                    if (sellData) {
                      return depot.depot.isForSell;
                    }
                    return !depot.depot.isForSell;
                  })
                  .map((depot) => {
                    return {
                      depot_id: depot.depot.id,
                      depot_name: depot.depot.name,
                      isForSell: depot.depot.isForSell,
                      expiration_date: depot.expirationDate,
                      quantity: depot.quantity,
                    };
                  })
                  .reduce((prev, curr) => {
                    if (prev.find((depot) => depot.depot_id === curr.depot_id)) {
                      return prev;
                    }
                    return [...prev, curr];
                  }, [])
                  .map((depot) => {
                    return {
                      label:
                        depot.depot_name +
                        (depot.expirationDate ? " (" + dayjs(depot.expirationDate).format("D. M. YYYY") + ")" : ""),
                      value: depot.depot_id.toString(),
                    };
                  })}
                {...form.getInputProps("depot_from")}
                placeholder="Vyber sklad odkud"
                searchable
                disabled={loading}
                readOnly={article.depots.length === 1}
              />
            )}
            {(form.values.type === "transfer" || form.values.type === "in") && (
              <Select
                label="Sklad umístění"
                data={depots
                  .filter((depot) => {
                    if (sellData) {
                      return depot.isForSell;
                    }
                    return !depot.isForSell;
                  })
                  .map((depot) => ({ label: depot.name, value: depot.id.toString() }))}
                {...form.getInputProps("depot_to")}
                placeholder="Vyber sklad umístění"
                searchable
                disabled={loading}
              />
            )}
          </Group>
          <Input.Wrapper
            label="Množství"
            description={
              selectedDepot
                ? selectedDepot.quantity === 0
                  ? "Zde nic není"
                  : form.values.type === "transfer"
                  ? "Aktuálně je možné přesunout 1 - " + selectedDepot.quantity + " ks."
                  : "Aktuálně je možné vyskladnit 1 - " + selectedDepot.quantity + " ks."
                : ""
            }
            withAsterisk
            inputWrapperOrder={["label", "input", "description"]}>
            <NumberInput
              {...form.getInputProps("amount")}
              placeholder="Množství"
              disabled={loading || !form.values.type}
              required
              min={1}
              max={selectedDepot?.quantity || 10000}
            />
          </Input.Wrapper>
          {sellData && (
            <Input.Wrapper label="Datum expirace" withAsterisk>
              <DatePickerInput
                {...form.getInputProps("expiration_date")}
                placeholder="Datum expirace"
                disabled={loading || !form.values.type || selectedDepot?.expirationDate}
                locale="cs"
                required
              />
            </Input.Wrapper>
          )}
          <Textarea
            label="Poznámka"
            placeholder="Poznámka"
            {...form.getInputProps("note")}
            disabled={loading || !form.values.type}
            minRows={3}
            maxRows={5}
          />
          <Button
            type="submit"
            variant="filled"
            mt={"lg"}
            loading={loading}
            disabled={!form.values.type}
            className="w-full">
            {moveOptions.find((option) => option.value === form.values.type)?.label || "Pohyb"} artiklu
          </Button>
        </form>
      )}
    </>
  );
};
