import Table from "./core/Table.jsx";

import { ActionIcon, Group, Tooltip } from "@mantine/core";
import {IconCheck, IconCross, IconSignature, IconX} from "@tabler/icons-react";
import { parseFullName } from "../../helpers/helpers.js";
import { Link, useNavigate, useRevalidator } from "react-router-dom";
import { useState } from "react";
import DocumentViewer from "../boarding/DocumentViewer.jsx";
import ContractInfoCol from "../ContractInfoCol.jsx";
import { sortStaff } from "./sort.js";
import { useFetch } from "../../helpers/useFetch.js";
import toast from "react-hot-toast";

export default function SuburbanLeavingsTable({leavings, handleDocumentView}) {
  const revalidator = useRevalidator();
  const [loading, setLoading] = useState(false);
  const [viewerOpened, setViewerOpened] = useState(false);
  const [viewingDocument, setViewingDocument] = useState("");
  const navigate = useNavigate();



  const columns = [
    {
      accessor: "name",
      title: "Jméno",
      render: (leaving) => parseFullName(leaving),
    },
    {
      accessor: "leavings",
      title: "Odchody",
      render: (leaving) => {
          return (
              <Group gap={6} align="center" wrap="nowrap">
                  {leaving.leavings.map(item => (
              item.file_path ?
                  <ActionIcon color="teal">
                    <IconCheck stroke={1.5} size={18} />
                  </ActionIcon>
                  :
                  <ActionIcon color="red">
                    <IconX stroke={1.5} size={18} />
                  </ActionIcon>
          ))}
              </Group>)
      }
    }
  ];

  return (
    <>
      <Table
        columns={columns}
        records={leavings}
      />
      {viewerOpened && <DocumentViewer document={viewingDocument} setOpened={setViewerOpened} />}
    </>
  );
}
