import { useLoaderData } from "react-router-dom";
import Page from "../../components/layout/Page";
import BoardingOverviewTable from "../../components/table/BoardingOverviewTable";
import { useFetch } from "../../helpers/useFetch";
import useTitle from "../../hooks/useTitle";
import ContractsTable from "../../components/table/ContractsTable.jsx";
import SuburbanLeavingsTable from "../../components/table/SuburbanLeavingsTable.jsx";
import {useState} from "react";

export async function loader() {
  let leavings = await useFetch(`children/suburban-leavings`);
  return { data: leavings.leavings };
}

export default function SuburbanLeavings() {
  let leavings = useLoaderData().data;
  useTitle("Přehled odjezdů");
  const [viewerOpened, setViewerOpened] = useState(false);
  const [viewingDocument, setViewingDocument] = useState("");
  const data = useLoaderData();

  const handleDocumentView = (document) => {
    setViewingDocument(document);
    setViewerOpened(true);
  };

  return (
    <Page
      title="Přehled odjezdů"
    >
      <SuburbanLeavingsTable handleDocumentView={handleDocumentView} leavings={leavings} />
    </Page>
  );
}
