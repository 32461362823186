import { useEffect, useState } from "react";
import { Alert, Button, MultiSelect, Select, TextInput } from "@mantine/core";
//import { DatePicker, TimeInput } from "@mantine/dates";
//import { IconDoorExit } from "@tabler/icons-react";
//import { useUser } from "../../providers/UserProvider.jsx";
//import dayjs from "dayjs";
//import Modal from "./Modal";
import { useForm } from "@mantine/form";
import "dayjs/locale/cs";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch.js";
import { useRevalidator } from "react-router-dom";
import { IconAlertCircle } from "@tabler/icons-react";
import { createAbbreviation } from "../../helpers/helpers.js";

export default function TeamEditModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const teams = innerProps.teams;
  const staff = innerProps.staff;
  const selectedTeam = innerProps.team;
  const revalidator = useRevalidator();

  const form = useForm({
    initialValues: {
      leader_id: false,
      custom_name: null,
      additional_staff: [],
    },
  });

  const staffLocal = staff.map((leader) => {
    let position = createAbbreviation(leader.position);

    return {
      label: `${leader.firstName} ${leader.lastName} (${position}, ${leader.age} let)`,
      value: String(leader.id),
      disabled: teams.filter((team) => team.leader && team.leader.id === leader.id).length > 0,
    };
  });

  let additionalStaff = staff.length
    ? staff.map((leader) => {
        let position = createAbbreviation(leader.position);

        return {
          label: `${leader.firstName} ${leader.lastName} (${position}, ${leader.age} let)`,
          value: String(leader.id),
          disabled: form.values.leader_id ? form.values.leader_id === leader.id : false,
        };
      })
    : [];

  useEffect(() => {
    if (innerProps) {
      form.setValues({
        custom_name: selectedTeam.customName,
        leader_id: selectedTeam.leader && String(selectedTeam.leader.id),
        additional_staff: selectedTeam.additionalStaff && selectedTeam.additionalStaff.map((staff) => String(staff.id)),
      });
    } else {
      form.reset();
    }
  }, [innerProps]);

  async function handleSubmit(values) {
    setLoading(true);
    const selectedTeam = innerProps?.team;
    const res = await useFetch(`/teams/${selectedTeam.id}`, "PUT", { ...values });

    if (!res.errors) {
      context.closeModal(id);
      //setOpened(false);
      form.reset();
      setLoading(false);
      toast.success("Oddíl upraven.");
      revalidator.revalidate();
      //refresh();
    } else {
      console.error(res);
      setLoading(false);
      setError(res.message);
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při úpravě oddílu nastala chyba."
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      <TextInput
        label="Vlastní název oddílu"
        mb="sm"
        loading={loading}
        placeholder="Název oddílu"
        {...form.getInputProps("custom_name")}
      />
      <Select
        clearable
        mb="sm"
        searchable
        hoverOnSearchChange={true}
        label="Oddílový vedoucí"
        loading={loading}
        data={staffLocal}
        placeholder="Vyber vedoucího..."
        {...form.getInputProps("leader_id")}
      />
      <MultiSelect
        clearable
        searchable
        hoverOnSearchChange={true}
        label="Další personál"
        loading={loading}
        data={additionalStaff}
        placeholder="Vyber vedoucí..."
        {...form.getInputProps("additional_staff")}
      />
      <Button fullWidth type="submit" loading={loading} mt="lg">
        Upravit oddíl
      </Button>
    </form>
    //</Modal>
  );
}
