import { ActionIcon, Group, Loader, Paper, Stack, Text, Tooltip } from "@mantine/core";
import { IconClick, IconCloudCheck, IconEye, IconFileText, IconSignature, IconStarFilled } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { optimize } from "svgo";
import { openFileSelectModal } from "../../helpers/modals";
import { useFetch } from "../../helpers/useFetch";
import DocumentSign from "./DocumentSign";
import classNames from "classnames";
import { getMode } from "../../helpers/helpers";
import DocumentViewer from "./DocumentViewer";

const DocumentsList = ({ documents, childId, outgoing, setViewerParentOpened, isSlovak }) => {
  const [documentsLocal, setDocumentsLocal] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [viewingDocument, setViewingDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [viewerOpened, setViewerOpened] = useState(false);
  const navigate = useNavigate();
  const signatureRef = useRef();
  const [shouldFetch, setShouldFetch] = useState(false);

  const handleDocumentClick = (document) => {
    setSelectedDocument(document);
    if (document.preview && !document.allowPaper) {
      openSignature();
      return;
    }
    openFileSelectModal(
      "Záznam dokumentu – " + document.name,
      document,
      openSignature,
      handoverDocument,
      childId,
      documentsLocal,
      setDocumentsLocal,
      setShouldFetch
    );
  };

  const openSignature = () => {
    if (setViewerParentOpened) setViewerParentOpened(true);
    setOpened(true);
  };

  const handoverDocument = (document, source) => {
    setDocumentsLoading(true);
    useFetch(`/children/${childId}/documents`, "POST", {
      type: document.type,
      status: "paper",
      filePath: null,
      original: source === "original",
    })
      .then((res) => {
        setDocumentsLoading(false);
        if (res.status === "ok") {
          if (document.type === "child_leave") {
            Promise.all([
              useFetch(`/children/${childId}/money/deposit/reset`, "PUT", { note: "Odjezd" }).catch((err) => {
                console.error(err);
                toast.error("Nastala chyba při odstranění kauce");
              }),
              useFetch(`/children/${childId}/money/pocketMoney/reset`, "PUT", { note: "Odjezd" }).catch((err) => {
                console.error(err);
                toast.error("Nastala chyba při odstranění kapesného");
              }),
            ]).catch((err) => {
              toast.error("Nastala chyba při podpisu dokumentu a byla nahlášena.");
            });
          }
          toast.success("Dokument úspěšně podepsán.");
          setShouldFetch(true);
        } else {
          toast.error("Nastala chyba při předávání dokumentu a byla nahlášena.");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Nastala chyba při předávání dokumentu a byla nahlášena.");
      });
  };

  useEffect(() => {
    setLoading(true);
    if (documents.length > 0) {
      useFetch(`/children/${childId}/documents?accreditation=1&types=${documents.join(",")}`)
        .then((res) => {
          setDocumentsLocal(res.data);
          setLoading(false);
          setShouldFetch(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  }, [childId, documents]);

  useEffect(() => {
    if (shouldFetch) {
      if (documents.length > 0) {
        useFetch(`/children/${childId}/documents?accreditation=1&types=${documents.join(",")}`)
          .then((res) => {
            setDocumentsLocal(res.data);
            setLoading(false);
            setShouldFetch(false);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      }
    }
  }, [shouldFetch, childId, documents]);

  const handleDocumentView = (document) => {
    setViewingDocument(document);
    setViewerOpened(true);
  };

  const signDocument = async (fields) => {
    const svgElement = document.createElement("svg");
    const trimElement = signatureRef.current;

    const bBox = trimElement.getBBox();
    svgElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svgElement.setAttribute("viewBox", `${bBox.x} ${bBox.y} ${bBox.width} ${bBox.height}`);
    svgElement.setAttribute("width", trimElement.getBBox().width);
    svgElement.setAttribute("height", trimElement.getBBox().height);
    svgElement.appendChild(trimElement.cloneNode(true));

    const result = optimize(svgElement.outerHTML, { multipass: true, floatPrecision: 2 });

    let parser = new DOMParser();
    let doc = parser.parseFromString(result.data, "image/svg+xml");
    let optimizedSvg = doc.querySelector("svg");
    optimizedSvg.setAttribute(
      "viewBox",
      `${Math.round(bBox.x)} ${Math.round(bBox.y)} ${Math.round(bBox.width)} ${Math.round(bBox.height)}`
    );
    optimizedSvg.setAttribute("width", Math.round(bBox.width));
    optimizedSvg.setAttribute("height", Math.round(bBox.height));

    const serialized = new XMLSerializer().serializeToString(optimizedSvg);

    const payload = btoa(serialized);

    useFetch(`/children/${childId}/documents`, "POST", {
      type: selectedDocument.type,
      status: "signed",
      fields:
        fields &&
        Object.keys(fields).map((field) => {
          let value = fields[field];
          if (dayjs(value).isValid()) {
            value = dayjs(value).format("D. M. YYYY");
          }
          return {
            variable: field,
            value: value,
          };
        }),
      svg: payload,
    })
      .then((res) => {
        if (res.status === "ok") {
          if (selectedDocument.type === "child_leave") {
            Promise.all([
              useFetch(`/children/${childId}/money/deposit/reset`, "PUT", { note: "Odjezd" }).catch((err) => {
                console.error(err);
                toast.error("Nastala chyba při odstranění kauce");
              }),
              useFetch(`/children/${childId}/money/pocketMoney/reset`, "PUT", { note: "Odjezd" }).catch((err) => {
                console.error(err);
                toast.error("Nastala chyba při odstranění kapesného");
              }),
            ]).catch((err) => {
              toast.error("Nastala chyba při podpisu dokumentu a byla nahlášena.");
            });
          }
          toast.success("Dokument úspěšně podepsán.");
          setShouldFetch(true);
        } else {
          toast.error("Nastala chyba při podpisu dokumentu a byla nahlášena.");
        }
        setViewerParentOpened(false);
        setOpened(false);
      })
      .catch((err) => {
        toast.error("Nastala chyba při podpisu dokumentu a byla nahlášena.");
        setOpened(false);
        console.error(err);
      });
  };

  const { mode, theme } = getMode();

  return (
    <>
      <Paper
        p="lg"
        className={classNames("mb-4", {
          [`border-red-200`]: isSlovak,
        })}
        withBorder
        radius="lg">
        <h2 className="mb-4">Dokumenty</h2>
        {loading && (
          <div className="mb-4 flex justify-center">
            <Loader />
          </div>
        )}
        <div className="flex flex-wrap gap-4 sm:columns-2 lg:columns-3">
          {!loading &&
            documentsLocal?.map((document) => {
              return (
                <Group
                  className={classNames(
                    "min-w-[300px] grow justify-between gap-0 gap-y-2 rounded-md border-2 border-solid border-dark-500 bg-dark-300 p-2",
                    {
                      "border-dark-500 bg-dark-300": mode === "dark",
                      "border-gray-500 bg-white-900": mode === "light",
                    }
                  )}
                  key={document.type}>
                  <Stack gap={0}>
                    <Group gap={"6px"}>
                      <IconFileText stroke={1.5} size={20} />
                      <Text fw="bold">{document.name}</Text>
                    </Group>
                    <Text c={"dimmed"} className="mt-1 text-sm">
                      {document.status === "none" && "Dokument nepředán"}
                      {document.status === "core" && "Dokument máme v cloudu"}
                      {document.status === "paper" && "Dokument předán papírově"}
                      {document.status === "signed" && "Dokument podepsán elektronicky"}
                      {document.original && " (originál)"}
                    </Text>
                  </Stack>
                  <Group gap="xs">
                    {document.filePath && (
                      <Tooltip label={"Zobrazit dokument"}>
                        <ActionIcon variant="filled" onClick={() => handleDocumentView(document.filePath)}>
                          <IconEye stroke={1.5} size={20} />
                        </ActionIcon>
                      </Tooltip>
                    )}
                    {document.status === "none" && (
                      <Tooltip label={document.preview ? "Otevřít výběr typu předání" : "Potvrdit předání papírově"}>
                        <ActionIcon
                          loading={documentsLoading}
                          variant="filled"
                          color="red"
                          className={classNames("overflow-hidden")}
                          onClick={() => handleDocumentClick(document)}>
                          {document.original && (
                            <IconStarFilled
                              stroke={1.5}
                              size={16}
                              className="absolute -left-[8px] -top-[8px] text-yellow-100 drop-shadow-md"
                            />
                          )}
                          {document.preview ? (
                            <IconClick stroke={1.5} size={20} />
                          ) : (
                            <IconFileText stroke={1.5} size={20} />
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                    {document.status === "core" && (
                      <Tooltip label="Dokument nahrán předem v CORE">
                        <ActionIcon
                          loading={documentsLoading}
                          variant="filled"
                          onClick={() => handleDocumentClick(document)}
                          className={"overflow-visible"}
                          color={document.status === "paper" ? "yellow" : "teal"}>
                          {document.original && (
                            <IconStarFilled
                              stroke={1.5}
                              size={16}
                              className="absolute -left-[8px] -top-[8px] text-yellow-100 drop-shadow-md"
                            />
                          )}
                          <IconCloudCheck stroke={1.5} size={20} />
                        </ActionIcon>
                      </Tooltip>
                    )}
                    {document.status === "paper" && (
                      <Tooltip label="Dokument předán papírově">
                        <ActionIcon
                          loading={documentsLoading}
                          variant="filled"
                          color={document.status === "paper" ? "yellow" : "teal"}
                          className={"overflow-visible"}
                          onClick={() => handleDocumentClick(document)}>
                          {document.original && (
                            <IconStarFilled
                              stroke={1.5}
                              size={16}
                              className="absolute -left-[8px] -top-[8px] text-yellow-100 drop-shadow-md"
                            />
                          )}
                          <IconFileText stroke={1.5} size={20} />
                        </ActionIcon>
                      </Tooltip>
                    )}
                    {document.status === "signed" && (
                      <Tooltip label="Dokument podepsán elektronicky">
                        <ActionIcon
                          loading={documentsLoading}
                          variant="filled"
                          color="teal"
                          className={"overflow-visible"}
                          onClick={() => handleDocumentClick(document)}>
                          {document.original && (
                            <IconStarFilled
                              stroke={1.5}
                              size={16}
                              className="absolute -left-[8px] -top-[8px] text-yellow-100 drop-shadow-md"
                            />
                          )}
                          <IconSignature stroke={1.5} size={20} />
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </Group>
                </Group>
              );
            })}
        </div>
      </Paper>
      {opened && (
        <DocumentSign
          signDocument={signDocument}
          signatureRef={signatureRef}
          document={selectedDocument}
          setOpened={setOpened}
          setViewerParentOpened={setViewerParentOpened}
        />
      )}
      {viewerOpened && <DocumentViewer document={viewingDocument} setOpened={setViewerOpened} />}
    </>
  );
};

export default DocumentsList;
