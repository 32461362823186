import { useEffect, useState } from "react";
import { Alert, Button, Group, Loader, Select, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import "dayjs/locale/cs";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch.js";
import { IconAlertCircle } from "@tabler/icons-react";
import { useRevalidator } from "react-router-dom";

export default function AddPcChildAccountModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [gameGroups, setGameGroups] = useState(null);
  const [loginIdentifier, setLoginIdentifier] = useState(null);

  useEffect(() => {
    getGameGroups();
  }, [innerProps]);

  function getGameGroups() {
    setFetching(true);
    useFetch(`/pc/game-group`).then((res) => {
      if (res.data) {
        setGameGroups(
          res.data.map((gameGroup) => ({
            label: gameGroup.name,
            value: String(gameGroup.id),
            login_identifier: gameGroup.loginIdentifier,
          }))
        );
        setFetching(false);
      }
    });
  }

  const form = useForm({
    initialValues: {
      game_group: "",
      username: "",
      email: "",
      password: "",
      note: "",
      relation_type: "ticket",
      relation_id: innerProps?.child.id,
    },
  });

  useEffect(() => {
    const gameAccount = innerProps?.gameAccount;

    if (gameAccount) {
      form.setValues({
        game_group: String(gameAccount.gameGroup?.id),
        username: gameAccount.username,
        email: gameAccount.email,
        password: gameAccount.password,
        note: gameAccount.note,
        relation_type: "ticket",
        relation_id: innerProps.child.id,
      });
    } else {
      form.reset();
    }
  }, [innerProps]);

  useEffect(() => {
    // w8 for gameGroups to load
    if (!gameGroups) return;
    if (form.values.game_group) {
      //console.log(form.values.game_group)
      const selectedGroup = gameGroups.find((group) => group.value === form.values.game_group);
      console.log(selectedGroup);
      setLoginIdentifier(selectedGroup?.login_identifier || null);
    }
  }, [form.values.game_group, gameGroups]);

  async function handleSubmit(values) {
    setLoading(true);

    const gameAccount = innerProps?.gameAccount;

    const filteredValues = {
      ...values,
      username: values.username.trim() === "" ? undefined : values.username,
      email: values.email.trim() === "" ? undefined : values.email,
    };

    const res = await useFetch(
      `/pc/game-account${gameAccount ? "/" + gameAccount.id : ""}`,
      gameAccount ? "PUT" : "POST",
      filteredValues
    );

    if (res.status === "ok") {
      context.closeModal(id);
      form.reset();
      setLoading(false);
      toast.success("Herní účet byl uložen");
    } else {
      setLoading(false);
      setError(res.message || res.error);
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při uložení herních údajů došlo k chybě."
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      <Select
        required
        mb="sm"
        label="Typ účtu"
        placeholder="Vyber typ účtu..."
        data={gameGroups}
        searchable
        disabled={loading}
        hoverOnSearchChange={true}
        {...form.getInputProps("game_group")}
      />
      <Group gap="sm" mt="lg" grow={1} className="w-full">
        <TextInput
          required={loginIdentifier === "username"}
          label="Uživatelské jméno"
          placeholder="Zadej uživatelské jméno..."
          {...form.getInputProps("username")}
        />
        <TextInput
          required={loginIdentifier === "email"}
          type="email"
          label="Email"
          placeholder="Zadej email..."
          {...form.getInputProps("email")}
        />
      </Group>

      <TextInput required label="Heslo" placeholder="Zadej heslo..." {...form.getInputProps("password")} />
      <Textarea
        label="Poznámka"
        placeholder="Zadej poznámku..."
        maxRows={3}
        minRows={3}
        {...form.getInputProps("note")}
      />

      <Button fullWidth type="submit" loading={loading} mt="md">
        Uložit herní účet
      </Button>
    </form>
    // </Modal>
  );
}
