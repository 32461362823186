import { ActionIcon, Button, Divider, Group, Paper, Stack, Text } from "@mantine/core";
import { useContext } from "react";
import { CartContext } from "./CartContext";
import { IconCash, IconMinus, IconPlus, IconTrash } from "@tabler/icons-react";
import { CheckoutDrawer } from "./CheckoutDrawer";

export const CartOverview = ({ products }) => {
  const {
    cart,
    addToCart,
    removeFromCart,
    getOverallPrice,
    clearCart,
    checkoutDrawerOpened,
    openCheckoutDrawer,
    closeCheckoutDrawer,
  } = useContext(CartContext);

  return (
    <>
      <Paper withBorder radius={"md"} p={"md"} display={"flex"} className="flex-col justify-between">
        <Stack gap={"md"}>
          <Text fw={"bold"} size={"2rem"}>
            Košík
          </Text>
          <Stack gap={8}>
            {cart.map((cartItem, index) => {
              return (
                <>
                  <Group key={cartItem.id} align="center" justify="space-between" px={"sm"} wrap="nowrap">
                    <Text
                      fw={"bold"}
                      size="lg"
                      className="w-[50%] grow overflow-hidden text-ellipsis whitespace-nowrap">
                      {index + 1}. {products.find((p) => p.id === cartItem.id)?.name}
                    </Text>
                    <Group align="center" justify="space-between" gap={"sm"} wrap="nowrap">
                      <ActionIcon
                        variant="filled"
                        color="red"
                        size={32}
                        onClick={() => removeFromCart(products.find((p) => p.id === cartItem.id))}>
                        <IconMinus stroke={1.5} />
                      </ActionIcon>
                      <Text ta={"center"} size="xl">
                        {cartItem.quantity}
                      </Text>
                      <ActionIcon
                        variant="filled"
                        color="teal"
                        size={32}
                        onClick={() => addToCart(products.find((p) => p.id === cartItem.id))}>
                        <IconPlus stroke={1.5} />
                      </ActionIcon>
                    </Group>
                    <Text w="3.5rem" ta={"right"}>
                      {cartItem.quantity * products.find((p) => p.id === cartItem.id)?.sellPrice} Kč
                    </Text>
                  </Group>
                  {index === cart.length - 1 ? null : <Divider orientation="horizontal" variant={"dashed"} />}
                </>
              );
            })}
            <Divider orientation="horizontal" size={"md"} />
            <Group align="center" justify="space-between" px={"sm"}>
              <Text fw={"bold"} size="lg">
                Celková cena:
              </Text>
              <Text fw={"bold"} size="lg">
                {getOverallPrice(products)} Kč
              </Text>
            </Group>
          </Stack>
        </Stack>
        <Stack gap={"sm"} align="center" justify="center">
          <Button
            onClick={openCheckoutDrawer}
            variant="filled"
            w={"100%"}
            leftSection={<IconCash size={18} />}
            disabled={!cart.length || cart.length === 0}>
            Dokončit nákup
          </Button>
          <Button onClick={clearCart} color="red" variant="light" w={"100%"} leftSection={<IconTrash size={18} />}>
            Vyprázdnit košík
          </Button>
        </Stack>
      </Paper>
      <CheckoutDrawer opened={checkoutDrawerOpened} onClose={closeCheckoutDrawer} products={products} />
    </>
  );
};
