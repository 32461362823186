import { Loader, Paper, Text, Transition, useMantineTheme } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useRevalidator } from "react-router-dom";

const SavingChangesBar = ({ show, text }) => {
  const theme = useMantineTheme();
  const revalidator = useRevalidator();

  return (
    <Transition mounted={show} transition="slide-up" duration={200}>
      {(styles) => (
        <Paper
          className={"sticky bottom-8 z-50 flex w-full items-center justify-between text-white-900 shadow-md"}
          style={styles}
          py={"md"}
          px={"lg"}
          radius="lg"
          bg={theme.colors[theme.primaryColor][6]}
        >
          <Text size={"md"} ml="xs">
            {text}
          </Text>
          {revalidator.state === "loading" ? (
            <Loader color="white" size={"sm"} />
          ) : (
            <IconCheck size={24} stroke={1.5} color="white" />
          )}
        </Paper>
      )}
    </Transition>
  );
};

export default SavingChangesBar;
