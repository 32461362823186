import {
  ActionIcon,
  Textarea,
  TextInput,
  Input,
  Group,
  Paper,
  useMantineTheme,
  Text,
  Loader,
  Alert,
  Select,
  Stack,
  NumberInput,
  Checkbox,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  IconAlertCircle,
  IconCheck,
  IconDeviceFloppy,
  IconDownload,
  IconEdit,
  IconFileText,
  IconReportMedical,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Drawer from "../drawer/Drawer";
import "dayjs/locale/cs";
import { useUser } from "../../providers/UserProvider";
import { useFetch } from "../../helpers/useFetch";
import SmallFigure from "./SmallFigure";
import Figure from "./Figure";
import { toast } from "react-hot-toast";
import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from "@mantine/dropzone";
import { useRevalidator } from "react-router-dom";
import { getMode } from "../../helpers/helpers";
import RichSelect from "../RichSelect";

const MedicalReportDetailDrawer = ({ opened, onClose, report, resetTable, reports, setReports, setShouldFetch }) => {
  const [reportData, setReportData] = useState(report);
  const [loading, setLoading] = useState(false);
  const [editReport, setEditReport] = useState(false);
  const [date, setDate] = useState();
  const [figureGender, setFigureGender] = useState(null);
  const [points, setPoints] = useState([]);
  const [figureOpened, setFigureOpened] = useState(false);
  const [files, setFiles] = useState([]);
  const [loadingFile, setLoadingFile] = useState(null);
  const [filesLoading, setFilesLoading] = useState(false);
  const [medicationList, setMedicationList] = useState([]);
  const [currentMedication, setCurrentMedication] = useState(null);
  const [error, setError] = useState(null);
  const [types, setTypes] = useState([]);
  const [materials, setMaterials] = useState([]);
  const { user } = useUser();
  const [update, setUpdate] = useState(false);
  const revalidator = useRevalidator();

  const form = useForm({
    initialValues: {
      type: report?.type,
      treatment: report?.treatment,
      description: report?.description,
      figurePoints: report?.figurePoints,
      materials: report?.materials,
      created_at: dayjs(report?.createdAt).toDate(),
      is_isolated: report?.isIsolated,
    },
  });

  const updateData = (type, treatment, description, materialsForm, check_at, updateReport, createdAt, isIsolated) => {
    form.setFieldValue("type", type ? type : report?.type);
    form.setFieldValue("treatment", treatment ? treatment : report?.treatment);
    form.setFieldValue("description", description ? description : report?.description);
    form.setFieldValue("figurePoints", report?.figurePoints);
    form.setFieldValue("materials", materialsForm ? materialsForm : report?.materials);
    form.setFieldValue("is_isolated", isIsolated ? isIsolated : report?.isIsolated);
    form.setFieldValue("created_at", createdAt ? createdAt : dayjs(report?.createdAt).toDate());
    setDate(check_at ? check_at : report?.checkAt);
    setPoints(report?.figurePoints);
    setFigureGender(report?.child?.gender);
    setEditReport(false);
    setFiles(report?.attachments);
    if (updateReport) {
      let reportTmp = report;
      type ? (reportTmp.type = type) : null;
      treatment ? (reportTmp.treatment = treatment) : null;
      description ? (reportTmp.description = description) : null;
      materialsForm ? (reportTmp.materials = materialsForm) : null;
      check_at ? (reportTmp.checkAt = check_at) : null;
      setReportData(reportTmp);
    }
    setUpdate(!update);
  };

  useEffect(() => {
    updateData(null, null, null, null, null, true);
  }, [opened]);

  useEffect(() => {
    let typesTmp = types;
    let medicationListTmp = medicationList;
    let materialsTmp = [];
    report?.materials?.map((material) => {
      materialsTmp.push({ name: material.name, value: material.value });
      if (!medicationListTmp.find((medication) => medication.label === material.name) && report?.materials) {
        medicationListTmp.push({ label: material.name, value: material.name });
      }
    });
    if (!types.find((type) => type.value === report?.type) && report?.type)
      typesTmp.push({ label: report?.type, value: report?.type });
    setMaterials(materialsTmp);
    setTypes(typesTmp);
    setMedicationList(medicationListTmp);
  }, [report]);

  useEffect(() => {
    useFetch("medical/medications-list").then((res) => {
      let medicationListTml = res.map((medication) => ({ label: medication, value: medication }));
      setMedicationList(medicationListTml);
    });
    useFetch("medical/records-types").then((res) => {
      let typesTmp = res.map((type) => ({ label: type, value: type }));
      setTypes(typesTmp);
    });
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    let valuesCopy = { ...values };
    if (date) valuesCopy.check_at = dayjs(date).format("YYYY-MM-DD HH:mm");
    valuesCopy.materials = materials;
    valuesCopy.created_at = dayjs(valuesCopy.created_at).format("YYYY-MM-DD HH:mm");

    const res = await useFetch("medical/records/" + report.id, "PUT", valuesCopy);
    setLoading(false);

    if (res.status === "ok") {
      updateData(
        values.type,
        values.treatment,
        values.description,
        materials,
        values.check_at,
        true,
        values.created_at,
        values.is_isolated
      );
      if (setShouldFetch) setShouldFetch(true);
      if (resetTable) resetTable();
      if (reports && setReports) {
        setReports(
          reports.map((r) => {
            if (r.id === report.id) {
              return {
                ...r,
                type: values.type,
                treatment: values.treatment,
                description: values.description,
                figurePoints: values.figurePoints,
                materials: values.materials,
                checkAt: values.check_at,
                createdAt: values.created_at,
              };
            }
            return r;
          })
        );
        revalidator.revalidate();
      }

      if (editReport) {
        setEditReport(false);
      }

      toast.success("Ošetření bylo úspěšně upraveno");
      return;
    } else {
      setError(res.message);
    }
  };

  const handlePointsSubmit = async (setFigureLoading) => {
    setFigureLoading(true);
    const res = await useFetch("medical/records/" + report.id, "PUT", { figure_points: points });
    if (res.status === "ok") {
      setFigureOpened(false);
      setFigureLoading(false);
      toast.success("Místo ošetření bylo úspěšně upraveno.");
    } else {
      console.error(res);
      setError(res.message);
      setFigureLoading(false);
    }
  };

  const uploadFile = async (file) => {
    file = file[0];
    if (!file) return;
    setLoadingFile(file.name);
    let formData = new FormData();
    formData.append("name", file.name);
    formData.append("file", file);

    const res = await useFetch("medical/records/attachment/" + report.id, "POST", formData, false);

    if (res.status === "ok") {
      toast.success("Soubor byl úspěšně nahrán.");
      setLoadingFile(null);
      setFiles([
        ...files,
        {
          id: res.data.id,
          name: file.name,
          url: res.data.url,
        },
      ]);
    } else {
      setError(res.message);
      setLoadingFile(null);
    }
  };

  const deleteFile = async (file) => {
    setFilesLoading(true);
    const res = await useFetch("medical/records/attachment/" + file, "DELETE");

    setFilesLoading(false);
    if (res.status === "ok") {
      toast.success("Soubor byl úspěšně smazán.");
      setFiles(files.filter((f) => f.id !== file));
    } else {
      toast.error("Soubor se nepodařilo smazat.");
    }
  };

  const { mode, theme } = getMode();

  return (
    <>
      <Figure
        gender={figureGender}
        points={points}
        opened={figureOpened}
        handleSubmit={handlePointsSubmit}
        setOpened={setFigureOpened}
        setPoints={setPoints}
      />
      <Drawer
        opened={opened}
        onClose={() => {
          if (figureOpened) return setFigureOpened(false);
          onClose();
        }}
        title={"Detail ošetření"}
        icon={IconReportMedical}>
        <div className="w-full">
          <h3 className="mb-3 flex items-center gap-4 text-xl">
            Základní informace
            <ActionIcon
              variant={editReport ? "filled" : "light"}
              loading={loading}
              onClick={() => {
                if (editReport) {
                  handleSubmit(form.values);
                } else {
                  setEditReport(true);
                }
              }}>
              {editReport ? <IconDeviceFloppy size={20} stroke={1.5} /> : <IconEdit size={20} stroke={1.5} />}
            </ActionIcon>
          </h3>
          {!editReport ? (
            <div className="mb-4 grid w-full gap-4 md:grid-cols-2">
              <div className="w-full">
                <p>
                  <strong>Jméno dítěte: </strong>
                  {reportData?.child?.firstName} {reportData?.child?.lastName}
                </p>
                <p>
                  <strong>Typ zranění: </strong>
                  {reportData?.type}
                </p>
                <div>
                  <strong>Podaná medikace: </strong>
                  <ul className="m-0">
                    {reportData?.materials?.map((material) => {
                      return <li key={material.value}>{material.name}</li>;
                    })}
                  </ul>
                </div>
                <p>
                  <strong>Ošetření/léčba: </strong>
                  {reportData?.treatment}
                </p>
                <p>
                  <strong>V izolaci: </strong>
                  {form?.values.is_isolated ? "Ano" : "Ne"}
                </p>
              </div>
              <div className="w-full">
                <p>
                  <strong>Datum a čas: </strong>
                  {reportData?.createdAt && dayjs(form.values.created_at).format("D. M. YYYY HH:mm")}
                </p>
                <p>
                  <strong>Ošetřující: </strong>
                  {reportData?.createdBy?.firstName} {reportData?.createdBy?.lastName}
                </p>
                <p>
                  <strong>Další kontrola: </strong>
                  {reportData?.checkAt
                    ? dayjs(reportData?.checkAt).format("D. M. YYYY") + " " + dayjs(reportData?.checkAt).format("HH:mm")
                    : "--"}
                </p>
                <p>
                  <strong>Popis: </strong>
                  {reportData?.description}
                </p>
              </div>
            </div>
          ) : (
            <form onSubmit={form.onSubmit(handleSubmit)} className="w-full">
              {error && (
                <Alert
                  icon={<IconAlertCircle size={16} stroke={1.5} />}
                  title="Při úpravě ošetření nastala chyba."
                  color="red"
                  mb="md"
                  mt="lg"
                  withCloseButton
                  closeButtonLabel="Zavřít upozornění"
                  onClose={() => setError(false)}>
                  {error}
                </Alert>
              )}
              <div className="mb-4 grid w-full gap-4 md:grid-cols-2">
                <div className="w-full">
                  <p>
                    <strong>Jméno dítěte: </strong>
                    {report?.child?.firstName} {report?.child?.lastName}
                  </p>
                  <TextInput {...form.getInputProps("type")} placeholder="Klíště..." required label="Typ zranění" />

                  <DateTimePicker
                    mb="sm"
                    minDate={dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD").toDate()}
                    maxDate={dayjs(user?.currentCampSession.dateTo, "YYYY-MM-DD").toDate()}
                    initialMonth={dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD").toDate()}
                    locale="cs"
                    hideOutsideDates
                    required
                    label="Datum a čas ošetření"
                    allowDeselect
                    {...form.getInputProps("created_at")}
                  />

                  <RichSelect
                    name="Podaná medikace"
                    data={medicationList}
                    {...form.getInputProps("materials")}
                    placeholder="Vyber lék nebo zadej nový..."
                    searchable
                    className="mb-2"
                    hoverOnSearchChange={true}
                    creatable
                    value={currentMedication}
                    onChange={(item) => {
                      setCurrentMedication(null);
                      if (materials.find((material) => material.name === item)) {
                        return toast.error("Lék již byl přidán.");
                      } else {
                        setMaterials([...materials, { name: item, value: 1 }]);
                      }
                    }}
                    label={(data) => <Text>{data?.label}</Text>}
                    getCreateLabel={(query) => `Zadat jiný lék: ${query}`}
                    onCreate={(query) => {
                      setCurrentMedication(null);
                      const item = { label: query, value: query };
                      setMedicationList([...medicationList, item]);
                      if (materials.find((material) => material.name === item.value)) {
                        toast.error("Lék již byl přidán.");
                      } else {
                        setMaterials([...materials, { name: item.label, value: 1 }]);
                      }
                      return item;
                    }}
                  />
                  {materials.length >= 1 && (
                    <Stack mb="sm" gap="sm">
                      {materials.map((material, index) => (
                        <div key={index} className="flex w-full items-center justify-between">
                          <Text size="sm" fw="bold">
                            {material.name}
                          </Text>
                          <Group gap="sm">
                            <NumberInput
                              min={0}
                              max={100}
                              step={0.5}
                              precision={1}
                              removeTrailingZeros
                              value={material.value}
                              onChange={(value) => {
                                const newMaterials = [...materials];
                                newMaterials[index].value = value;
                                setMaterials(newMaterials);
                              }}
                            />
                            <ActionIcon
                              variant="light"
                              size={36}
                              onClick={() => setMaterials(materials.filter((_, i) => i !== index))}
                              color="red">
                              <IconTrash size={24} stroke={1.5} />
                            </ActionIcon>
                          </Group>
                        </div>
                      ))}
                    </Stack>
                  )}
                  <Input.Wrapper label="Ošetření/léčba" withAsterisk mb={"sm"}>
                    <TextInput {...form.getInputProps("treatment")} required />
                  </Input.Wrapper>
                </div>
                <div className="w-full">
                  <p>
                    <strong>Datum a čas: </strong>
                    {dayjs(report?.createdAt).format("D. M. YYYY")} {dayjs(report?.createdAt).format("HH:mm")}
                  </p>
                  <p>
                    <strong>Ošetřující: </strong>
                    {report?.createdBy?.firstName} {report?.createdBy?.lastName}
                  </p>
                  <DateTimePicker
                    minDate={dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD").toDate()}
                    maxDate={dayjs(user?.currentCampSession.dateTo, "YYYY-MM-DD").toDate()}
                    locale="cs"
                    hideOutsideDates
                    label="Datum a čas další kontroly"
                    allowDeselect
                    value={date && dayjs(date).toDate()}
                    onChange={setDate}
                    valueFormat={"D. M. YYYY HH:mm"}
                  />
                  <Textarea
                    minRows={4}
                    maxRows={10}
                    autosize
                    label="Popis ošetření"
                    required
                    placeholder="Dítě dostalo míčem do břicha..."
                    mb="sm"
                    {...form.getInputProps("description")}
                  />
                  <Checkbox
                    label="Je v izolaci"
                    className="mt-3"
                    {...form.getInputProps("is_isolated", { type: "checkbox" })}
                  />
                </div>
              </div>
            </form>
          )}
          <div className="mb-4 grid w-full gap-4 md:grid-cols-2">
            <div>
              <h3 className="mb-3 flex items-center gap-4 text-xl">
                Místo ošetření
                <ActionIcon onClick={() => setFigureOpened(true)} variant="light">
                  <IconEdit stroke={1.5} size={20} />
                </ActionIcon>
              </h3>
              <SmallFigure onClick={() => setFigureOpened(true)} points={points} gender={figureGender} />
            </div>
            <div>
              <h3 className="mb-3 flex items-center justify-between text-xl">Lékařské zprávy</h3>
              <Dropzone
                p="sm"
                onDrop={(file) => {
                  uploadFile(file);
                }}
                multiple={false}
                accept={[...PDF_MIME_TYPE]}>
                <Group gap="sm">
                  <Dropzone.Accept>
                    <IconCheck color={theme.colors[theme.primaryColor][4]} size={20} stroke={1.5} />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <IconX color={theme.colors.red[4]} size={20} stroke={1.5} />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <IconFileText size={20} stroke={1.5} />
                  </Dropzone.Idle>

                  <Text>Klikni pro nahrání souboru</Text>
                </Group>
              </Dropzone>
              {files?.map((file, i) => (
                <Paper
                  mih={52}
                  key={i}
                  mt="sm"
                  withBorder
                  px="sm"
                  py={4}
                  bg={mode === "dark" ? "dark.6" : "#fff"}
                  className="flex items-center justify-between gap-2">
                  <Text className="overflow-hidden overflow-ellipsis">{file.name}</Text>
                  <Group gap="xs" className="flex-shrink-0">
                    <ActionIcon loading={filesLoading} color="red" variant="light" onClick={() => deleteFile(file.id)}>
                      <IconTrash size={20} stroke={1.5} />
                    </ActionIcon>
                    <ActionIcon component="a" target="_blank" loading={filesLoading} href={file.url} variant="light">
                      <IconDownload size={20} stroke={1.5} />
                    </ActionIcon>
                  </Group>
                </Paper>
              ))}
              {loadingFile && (
                <Paper
                  h={52}
                  mt="sm"
                  bg={mode === "dark" ? "dark.6" : "#fff"}
                  withBorder
                  px="sm"
                  className="flex items-center justify-between">
                  <Text>{loadingFile}</Text>
                  <Loader size={20} />
                </Paper>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default MedicalReportDetailDrawer;
