import React, { useEffect, useState } from "react";
import winner from "../../assets/audio/winner.wav";
import spining from "../../assets/audio/spining.mp3";
import dayjs from "dayjs";
import "./Roulette.scss";
import { RouletteItem } from "./RouletteItem";
import { Button } from "@mantine/core";
import { getMode } from "../../helpers/helpers";

const winnerAudio = new Audio(winner);

const langs = {
  sluzby: {
    first: "služba",
    second: "služby",
    third: "službou",
    fourth: "službu",
    multiple: "služby",
  },
  aktivity: {
    first: "aktivita",
    second: "aktivity",
    third: "aktivitou",
    fourth: "aktivitu",
    multiple: "aktivity",
  },
};

export const Roullete = ({ personalData, data, type, results, setResults }) => {
  const [items, setItems] = useState();
  const [winner, setWinner] = useState();
  const [showWinner, setShowWinner] = useState(false);
  const [rolling, setRolling] = useState(false);
  const [currentDuty, setCurrentDuty] = useState(0);
  const [personal, setPersonal] = useState(personalData);
  const { mode, theme } = getMode();

  const createItems = () => {
    const itemsLocal = [];
    for (let i = 0; i < 210; i++) {
      const random = Math.floor(Math.random() * personal.length);
      itemsLocal.push(personal[random]);
    }
    setItems(itemsLocal);
  };

  const reset = () => {
    const itemsLocal = [];
    for (let i = 0; i < 210; i++) {
      const random = Math.floor(Math.random() * personal.length);
      itemsLocal.push(personal[random]);
    }
    setItems(itemsLocal);
    return itemsLocal;
  };

  const completeReset = () => {
    const itemsLocal = [];
    for (let i = 0; i < 210; i++) {
      const random = Math.floor(Math.random() * personal.length);
      itemsLocal.push(personal[random]);
    }
    setResults([]);
    setRolling(false);
    setWinner();
    setShowWinner(false);
    setPersonal(personal);
    setCurrentDuty(0);
    setItems(itemsLocal);
  };

  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  };

  const startRoll = () => {
    const itemsLocal = reset();
    const spiningAudio = new Audio(spining);
    setRolling(true);
    const rand = random(10000, 40000);
    const winner = Math.floor(rand / 200 + 3);
    let margin = rand;
    if (rand % 200 < 100) {
      margin += 100;
    }
    const card = document.querySelectorAll(".card")[0];
    card.animate(
      {
        marginLeft: -margin + "px",
      },
      {
        duration: 6000,
        easing: "cubic-bezier(.27,0,0,.99)",
        fill: "forwards",
      }
    );
    spiningAudio.play();
    setTimeout(() => {
      spiningAudio.pause();
      winnerAudio.play();
    }, 6000);
    setTimeout(() => {
      const date = dayjs(new Date()).format("YYYY-MM-DD");
      const newDuty = { personName: itemsLocal[winner], date: date };
      if (type === "sluzby") {
        newDuty.dutyName = data[currentDuty];
      } else {
        newDuty.activityName = data[currentDuty];
      }
      setResults((prev) => [...prev, newDuty]);
      setRolling(false);
      setWinner(itemsLocal[winner]);
      setShowWinner(true);
      setPersonal(
        personal.filter((person) => {
          return person !== itemsLocal[winner];
        })
      );
      setCurrentDuty(currentDuty + 1);
    }, 7000);
  };

  useEffect(() => createItems(), []);

  return (
    <div className="flex flex-col items-center justify-center">
      {data.length !== results.length && (
        <>
          {!showWinner ? (
            <>
              <h2 className="mb-8 text-center font-normal">
                Losování {langs[type.toLowerCase()].second}: <b className="lowercase">{data[currentDuty]}</b>
              </h2>
              <div className={mode === "dark" ? "darkroullete" : "lightroullete"}>
                <div className="wrapper">
                  {items?.map((item, i) => {
                    return <RouletteItem item={item} key={i} />;
                  })}
                </div>
              </div>
            </>
          ) : (
            <div className="bg-transparent-100 flex h-[150px] w-[400px] flex-col items-center justify-center rounded-lg p-4 text-center">
              <p className="text-lg">Vítězem o {langs[type.toLowerCase()].fourth}</p>
              <p className="mb-2 text-xl">
                <b>{data[currentDuty - 1]}</b> je:
              </p>
              <h2>{winner}</h2>
            </div>
          )}
          {!showWinner ? (
            <Button className="mt-8 max-w-[50%] text-lg" onClick={startRoll} disabled={rolling}>
              Losovat
            </Button>
          ) : (
            <Button
              className="mt-8 max-w-[50%] text-lg"
              variant="light"
              onClick={() => {
                setShowWinner(false);
                setWinner(null);
              }}>
              Pokračovat
            </Button>
          )}
        </>
      )}
    </div>
  );
};
