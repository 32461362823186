import { ActionIcon, Tooltip } from "@mantine/core";
import { IconLoader2, IconX } from "@tabler/icons-react";
import { IconCheck } from "@tabler/icons-react";
import { openFillQuestModal } from "../../helpers/modals";
import { useEffect, useState } from "react";

const QuestCheckbox = ({ quest, team, setShouldFetch, done, changed, setChanged }) => {
  const questData = { ...quest, done: done };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (changed && changed.questId === quest.id && changed.teamId === team.id) {
      setLoading(true);
    } else setLoading(false);
  }, [changed]);

  return (
    <>
      <Tooltip
        label={quest.title}
        position="bottom"
        onClick={() => {
          openFillQuestModal(questData, team, setShouldFetch, setChanged);
        }}>
        <ActionIcon
          variant="filled"
          className="duration-200 ease-in-out"
          color={!loading ? (questData.done ? "teal" : "red") : "gray"}>
          {!loading ? (
            questData.done === true ? (
              <IconCheck stroke={1.5} size={18} />
            ) : (
              <IconX stroke={1.5} size={18} />
            )
          ) : (
            <IconLoader2 stroke={1.5} className="animate-spin" size={18} />
          )}
        </ActionIcon>
      </Tooltip>
    </>
  );
};

export default QuestCheckbox;
