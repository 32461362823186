import { useOs } from "@mantine/hooks";
import { ActionIcon, Burger, Group, Input, Kbd, Text, Transition, useMantineTheme } from "@mantine/core";
import {
  IconArrowLeft,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
  IconSearch,
  IconSettings,
} from "@tabler/icons-react";
import styles from "../../pages.module.scss";
import classNames from "classnames";
import { useState } from "react";
import NavigationLeft from "./NavigationLeft";
import UserMenu from "./UserMenu";
import { openSpotlight } from "@mantine/spotlight";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../providers/UserProvider.jsx";
import logoText from "../../assets/logo-text.svg";
import { getMode } from "../../helpers/helpers";
import { useFeatureFlagEnabled } from "posthog-js/react";
import LogoSymbol from "../LogoSymbol.jsx";

const scaleY = {
  in: { opacity: 1, transform: "scaleY(1)" },
  out: { opacity: 0, transform: "scaleY(0)" },
  common: { transformOrigin: "top" },
  transitionProperty: "transform, opacity",
};

export default function NavigationTop({ session, campSessions, minifyMenu, setMinifyMenu }) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const topNav = useFeatureFlagEnabled("top-navbar");
  // const [notificationsVisible, setNotificationsVisible] = useState(false);
  const title = setShowMobileMenu ? "Zavřít navigaci" : "Otevřít navigaci";
  const os = useOs();
  const navigate = useNavigate();
  let pathname = useLocation().pathname;
  const { user } = useUser();
  const { mode: colorMode, theme } = getMode();

  const rightSection = (
    <div className="flex w-full items-center justify-end pr-3">
      <Kbd>{os === "macos" ? "⌘" : "Ctrl"} + K</Kbd>
    </div>
  );

  return (
    <>
      {topNav && <p>top</p>}
      <div
        className={classNames(
          "px-md-5 fixed z-10 flex h-16 w-full items-center justify-between border-0 border-b-2 border-solid px-2 md:relative md:pl-4 md:pr-8",
          styles.NavigationTop
        )}
        style={{
          borderBottomColor: colorMode === "dark" ? theme.colors.dark[5] : theme.colors.gray[3],
          backgroundColor: colorMode === "dark" ? theme.colors.dark[8] : theme.colors.gray[0],
        }}>
        <Group gap="md">
          {pathname !== "/" && (
            <ActionIcon className="flex md:hidden" onClick={() => navigate(-1)} size="lg" variant="subtle">
              <IconArrowLeft size={24} stroke={1.5} />
            </ActionIcon>
          )}

          <Link className="block md:hidden" to="/">
            <Group wrap="nowrap" gap="sm" className="h-16" align="center">
              <LogoSymbol height={32} />
              <img
                src={logoText}
                style={{
                  filter: colorMode === "light" && "invert(1)",
                }}
                height={32}
                alt="Logo"
              />
            </Group>
          </Link>

          <ActionIcon className="hidden md:flex" onClick={() => setMinifyMenu(!minifyMenu)} size="lg" variant="subtle">
            {minifyMenu ? (
              <IconLayoutSidebarLeftExpand size={24} stroke={1.5} />
            ) : (
              <IconLayoutSidebarLeftCollapse size={24} stroke={1.5} />
            )}
          </ActionIcon>

          <div className="flex flex-col">
            {user.currentCampSession && (
              <>
                <Text>
                  {user.currentCampSession.name}{" "}
                  {!user.currentCampSession.isActual && (
                    <Text span c="red.7" className="hidden sm:inline">
                      (Není aktuální)
                    </Text>
                  )}
                </Text>
                <Text className="hidden text-xs sm:inline" span c="dimmed">
                  {user.currentCampSession.area}
                </Text>
              </>
            )}
          </div>
        </Group>

        <Group gap="xs" align="center" className="flex md:hidden">
          <ActionIcon
            onClick={() => {
              openSpotlight();
              setShowMobileMenu(false);
            }}
            size="lg"
            variant="subtle">
            <IconSearch size={24} stroke={2} />
          </ActionIcon>
          <ActionIcon size="lg" variant="subtle" onClick={() => setShowMobileMenu((o) => !o)}>
            <Burger
              color={colorMode === "dark" ? theme.colors[theme.primaryColor][2] : theme.primaryColor}
              opened={showMobileMenu}
              size={24}
              title={title}
              component="div"
              className="flex flex-wrap content-center justify-center"
            />
          </ActionIcon>
        </Group>

        <Group gap={16} className="hidden md:flex">
          {user.currentCampSession && (
            <>
              <Input
                leftSection={<IconSearch size={16} stroke={1.5} />}
                w={275}
                rightSectionWidth={160}
                pointer
                onClick={() => openSpotlight()}
                rightSection={rightSection}
                styles={{ rightSection: { pointerEvents: "none" } }}
                component="button">
                <Input.Placeholder>Hledat...</Input.Placeholder>
              </Input>
            </>
          )}

          <UserMenu session={user} campSessions={campSessions} />
        </Group>
      </div>
      <Transition mounted={showMobileMenu} transition={scaleY} duration={200} timingFunction="ease-in-out">
        {(stylesLocal) => {
          return (
            <div style={{ ...stylesLocal }} className="fixed left-0 top-[4rem] z-10 block w-full md:hidden">
              <NavigationLeft
                setShowMobileMenu={setShowMobileMenu}
                session={session}
                campSessions={campSessions}
                className={classNames("w-full flex-col", styles.NavigationLeftMobile, {
                  "border-dark-400 bg-dark-50": colorMode === "dark",
                  "border-gray-600 bg-gray-900": colorMode === "light",
                })}
              />
            </div>
          );
        }}
      </Transition>
    </>
  );
}
