import Table from "./core/Table.jsx";

import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import { IconCheck, IconDoor, IconEdit, IconTrash, IconX } from "@tabler/icons-react";
import { parseFullName } from "../../helpers/helpers.js";
import { useRevalidator } from "react-router-dom";
import styles from "../../pages.module.scss";
import { useState } from "react";
import RoomManageDrawer from "../pcapp/RoomManageDrawer.jsx";
import { openConfirmModal } from "@mantine/modals";
import { useFetch } from "../../helpers/useFetch.js";
import { modalTitle } from "../modals/Modal.jsx";
import { toast } from "react-hot-toast";
import { openGameGroupModal } from "../../helpers/modals.js";

export default function GameGroupsTable({ groups }) {
  const revalidator = useRevalidator();
  const [loading, setLoading] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const deleteGroup = async (id) => {
    openConfirmModal({
      title: modalTitle(IconDoor, "Smazat herní skupinu"),
      children: <Text size="sm">Opravdu chceš smazat tuto herní skupinu?</Text>,
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
      onCancel: () => setLoading(false),
      onConfirm: () => {
        toast.promise(useFetch(`/pc/game-group/${id}`, "DELETE"), {
          loading: "Odstraňuji herní skupinu...",
          success: (res) => {
            revalidator.revalidate();
            setLoading(false);
            return <p>Herní skupina odstraněna</p>;
          },
          error: () => {
            setLoading(false);
            return <p>Nastala chyba při odstranění herní skupiny a byla nahlášena.</p>;
          },
        });
      },
      onClose: () => {
        setLoading(false);
      },
    });
  };

  const actionButtons = (gameGroup) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Smazat herní skupinu">
          <ActionIcon
            variant="light"
            loading={loading}
            color="red"
            onClick={(e) => {
              e.stopPropagation();
              setLoading(true);
              deleteGroup(gameGroup.id);
            }}>
            <IconTrash size={18} stroke={1.5} />
          </ActionIcon>
        </Tooltip>

        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Upravit herní skupinu">
          <ActionIcon
            variant="light"
            loading={loading}
            onClick={(e) => {
              e.stopPropagation();
              openGameGroupModal(gameGroup);
            }}>
            <IconEdit size={18} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  function parseIdentifier(identifier) {
    switch (identifier) {
      case "username":
        return "Uživatelské jméno";
      case "email":
        return "E-mail";
      default:
        return identifier;
    }
  }

  const columns = [
    {
      accessor: "name",
      title: "Název",
      render: (group) => group.name,
      sortable: true,
    },
    {
      accessor: "login_identifier",
      title: "Identifikátor",
      render: (group) => parseIdentifier(group.loginIdentifier),
      sortable: true,
    },
    {
      accessor: "note",
      title: "Poznámka",
      render: (group) => group.note || "-",
      sortable: true,
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (group) => actionButtons(group),
      narrow: true,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        records={groups}
        id="game-groups"
        //sortFunction={sortRoomIssues}
      />
    </>
  );
}
