import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import {
  IconCameraCheck,
  IconClipboardHeart,
  IconDeviceDesktop,
  IconFileText,
  IconGavel,
  IconPrison,
  IconShieldCheck,
  IconTent,
  IconVirusOff,
} from "@tabler/icons-react";
import { getMode } from "../helpers/helpers";
import { openConfirmModal } from "@mantine/modals";
import { modalTitle } from "./modals/Modal";

const ContractInfoCol = ({ contract, isButtons, handleDocumentView, loading, handleDocumentDelete }) => {
  const { mode, theme } = getMode();

  let icons = {
    contract: IconFileText,
    medical_certificate: IconClipboardHeart,
    criminalRecord: IconGavel,
    infectiousness: IconVirusOff,
    gdpr_1: IconCameraCheck,
    gdpr_2: IconCameraCheck,
    bozp_1: IconShieldCheck,
    bozp_2: IconShieldCheck,
    criminal_record: IconPrison,
    camp_rules: IconTent,
    bozp_pc: IconDeviceDesktop,
  };

  let Component;

  if (isButtons) {
    Component = ActionIcon;
  } else {
    Component = Text;
  }

  const defaultColor = mode === "dark" ? "white" : "gray.9";

  return (
    <Group gap={6} align="center" wrap="nowrap">
      {contract.documents.map((document) => {
        let Icon = icons[document.type];
        let color = document.status === "signed" ? "teal.6" : "red.6";

        return (
          <Tooltip
            withArrow
            key={document.id}
            label={
              document.status === "signed"
                ? `Dokument "${document.name}" předán`
                : `Dokument "${document.name}" nepředán`
            }>
            <Component
              span
              className="h-[20px]"
              variant="filled"
              color={color}
              loading={loading}
              onClick={
                isButtons && document.status === "signed"
                  ? (e) => {
                      e.stopPropagation();
                      if (document.type === "medical_certificate" || document.type === "criminal_record") {
                        openConfirmModal({
                          title: modalTitle(IconFileText, "Chceš si zobrazit dokument nebo ho smazat?"),
                          children: <Text size="sm">Chceš si tento dokument zobrazit nebo ho smazat?</Text>,
                          labels: { confirm: "Zobrazit", cancel: "Smazat" },
                          onCancel: () => handleDocumentDelete(document, contract.id),
                          onConfirm: () => handleDocumentView(document.filePath),
                          groupProps: { grow: true },
                        });
                      } else {
                        handleDocumentView(document.filePath);
                      }
                    }
                  : null
              }>
              <Icon stroke={1.5} size={20} />
            </Component>
          </Tooltip>
        );
      })}
    </Group>
  );
};

export default ContractInfoCol;
