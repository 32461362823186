import { Badge, Center, Group, Kbd, Loader, Text } from "@mantine/core";
import { Spotlight } from "@mantine/spotlight";
import {
  IconArrowDown,
  IconArrowUp,
  IconBed,
  IconCornerDownLeft,
  IconFlag,
  IconSearch,
  IconUser,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../helpers/useFetch.js";

function ActionsWrapper({ children, loading }) {
  return (
    <div>
      {loading && (
        <div className="flex w-full items-center justify-center py-8">
          <Loader />
        </div>
      )}
      {!loading && children}
      <Group
        justify="space-between"
        px={15}
        py="sm"
        sx={(theme) => ({
          borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
        })}>
        <Text className="select-none" size="xs" gap="xs" component={Group} color="dimmed">
          <span>
            <Kbd>
              <IconCornerDownLeft size={10} stroke={3} />
            </Kbd>{" "}
            Výběr akce
          </span>{" "}
          <span>
            <Kbd>
              <IconArrowDown size={10} stroke={3} />
            </Kbd>{" "}
            <Kbd>
              <IconArrowUp size={10} stroke={3} />
            </Kbd>{" "}
            Navigace
          </span>{" "}
          <span>
            <Kbd>Esc</Kbd> Ukončení vyhledávání
          </span>
        </Text>
      </Group>
    </div>
  );
}

export default function SpotlightProvider({ children }) {
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [latestQuery, setLatestQuery] = useState("");
  const [realQuery, setRealQuery] = useState("");
  const navigate = useNavigate();

  let timeOut;

  const getActions = async (query) => {
    setLoading(true);
    if (timeOut) clearTimeout(timeOut);
    if (query === "") {
      setLoading(false);
      setActions([]);
      return;
    }
    timeOut = setTimeout(() => {
      setLatestQuery(query);
    }, 271);
    setLoading(false);
  };

  useEffect(() => {
    if (latestQuery === "" || latestQuery.length < 4) return setActions([]);
    async function getQuery() {
      setLoading(true);
      const data = await useFetch(`search?q=${latestQuery}`);

      setLoading(false);

      if (opened) {
        const children = data.children.data.map((child) => ({
          label: `${child.name}`,
          description: `Přejít na kartu dítěte`,
          group: "Děti",
          leftSection: <IconUser stroke={1.5} />,
          hasLeft: child.hasLeft,
          onClick: () => navigate(`/deti/${child.id}`),
        }));

        const teams = data.teams.data.map((team) => ({
          label: `${team.name}`,
          description: `Přejít na seznam dětí`,
          group: "Oddíly",
          leftSection: <IconFlag stroke={1.5} />,
          onClick: () => navigate(`/oddily/${team.id}`),
        }));

        const rooms = data.rooms.data.map((room) => ({
          label: `${room.name}`,
          description: `Přejít na pokoj`,
          group: "Pokoje",
          leftSection: <IconBed stroke={1.5} />,
          onClick: () => navigate(`/ubytovani/prehled/?room=${room.id}`),
        }));

        setActions([...children, ...teams, ...rooms]);
      } else {
        setActions([]);
      }
    }
    getQuery();
  }, [latestQuery]);

  return (
    <>
      <Spotlight.Root
        onQueryChange={(query) => {
          getActions(query);
          setRealQuery(query);
        }}
        closeOnActionTrigger
        shortcut={["mod + k"]}
        centered
        filter={(query, actions) => actions.filter((action) => true)}
        onSpotlightClose={() => setOpened(false)}
        onSpotlightOpen={() => setOpened(true)}
        styles={(theme) => ({
          content: {
            borderRadius: theme.radius.lg,
          },
        })}>
        <Spotlight.Search
          placeholder="Hledej dítě, oddíl, pokoj..."
          leftSection={<IconSearch stroke={1.5} size={18} />}
        />
        {realQuery !== "" && (
          <Spotlight.ActionsList>
            {loading && (
              <div className="flex w-full items-center justify-center py-8">
                <Loader />
              </div>
            )}
            {actions.length > 0 ? (
              loading ? null : (
                actions.map((action) => (
                  <Spotlight.Action key={action.label} onClick={action.onClick}>
                    <Group wrap="nowrap" w="100%">
                      <Center>{action.leftSection}</Center>

                      <div style={{ flex: 1 }}>
                        <div className="flex items-center gap-2">
                          <Text>{action.label}</Text>
                          {action.hasLeft && <Badge className="bg-red-100">Odjel/a</Badge>}
                        </div>

                        <Text opacity={0.6} size="xs">
                          {action.description}
                        </Text>
                      </div>
                    </Group>
                  </Spotlight.Action>
                ))
              )
            ) : loading ? null : (
              <Spotlight.Empty>Nic jsme nenašli. Zde máš kočku. 🐈</Spotlight.Empty>
            )}
          </Spotlight.ActionsList>
        )}

        <Spotlight.Footer>
          <Group
            justify="space-between"
            sx={(theme) => ({
              borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
            })}>
            <Text className="select-none" size="xs" gap="xs" component={Group} color="dimmed">
              <span>
                <Kbd>
                  <IconCornerDownLeft size={10} stroke={3} />
                </Kbd>{" "}
                Výběr akce
              </span>{" "}
              <span>
                <Kbd>
                  <IconArrowDown size={10} stroke={3} />
                </Kbd>{" "}
                <Kbd>
                  <IconArrowUp size={10} stroke={3} />
                </Kbd>{" "}
                Navigace
              </span>{" "}
              <span>
                <Kbd>Esc</Kbd> Ukončení vyhledávání
              </span>
            </Text>
          </Group>
        </Spotlight.Footer>
      </Spotlight.Root>
      {children}
    </>
  );
}
