import { getMode } from "../../helpers/helpers";

export const RouletteItem = ({ item }) => {
  const { mode, theme } = getMode();
  return (
    <div className={mode === "dark" ? "darkcard card" : "lightcard card"}>
      <div>
        <p>{item}</p>
      </div>
    </div>
  );
};
