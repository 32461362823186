import { Paper, Skeleton, Title } from "@mantine/core";
import { getMode } from "../../../helpers/helpers";
import { Group } from "react-konva";
import classNames from "classnames";

export default function RoomSkeleton({ roomPesonCount, title }) {
  const { mode, theme } = getMode();

  return (
    <Paper
      mih={150}
      bg={mode === "dark" ? "dark.7" : "gray.0"}
      p={0}
      className="relative overflow-hidden"
      radius="md"
      withBorder>
      <Group justify="space-between" gap={0}>
        <div className={classNames("w-full bg-gray-200 py-2 pl-4 pr-3")}>
          <Title order={3}>{title}</Title>
        </div>

        <div className="flex w-full flex-col gap-2 px-3 py-3">
          {[...Array(roomPesonCount)].map((_, i) => (
            <div key={i} className="flex h-9 w-full gap-2">
              <Skeleton radius="sm" className="w-9" />
              <Skeleton radius="sm" className="flex-1" />
            </div>
          ))}
        </div>
      </Group>
    </Paper>
  );
}
