import { Alert, Button, Group, Input, NumberInput, Select, Text, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { openConfirmModal } from "@mantine/modals";
import { IconAlertCircle, IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useRevalidator } from "react-router-dom";
import { useFetch } from "../../helpers/useFetch";
import { modalTitle } from "./Modal";
import { dosageAllOptions as dosageOptions } from "../../helpers/dosages";

const MedicationEditModal = ({ innerProps, context, id }) => {
  const { medications, child, selectedMed, setMedications } = innerProps;
  const [childrenList, setChildrenList] = useState([]);
  const [selectedChild, setSelectedChild] = useState(child ? child.id : 0);
  const [childMedicationList, setChildMedicationList] = useState([]);
  const [selectedMedication, setSelectedMedication] = useState(selectedMed ? selectedMed.id : 0);
  const [dosage, setDosage] = useState([]);
  const [mode, setMode] = useState("edit");
  const [update, setUpdate] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const revalidator = useRevalidator();

  useEffect(() => {
    let children = [];
    if (!child) {
      medications.map((child) => {
        children.push({
          value: String(child.id),
          label: child.firstName + " " + child.lastName,
        });
      });
    } else {
      children.push({
        value: String(child.id),
        label: child.firstName + " " + child.lastName,
      });
    }
    setChildrenList(children);
  }, [medications]);

  useEffect(() => {
    if (!selectedChild) return;
    let medicationsList = [];
    const childLocal = child || medications.find((child) => String(child.id) === selectedChild);
    childLocal.medicalMedications.map((medication) => {
      medicationsList.push({
        value: String(medication.id),
        label: medication.name,
      });
    });
    setChildMedicationList(medicationsList);
    setSelectedMedication(selectedMed ? selectedMed.id : 0);
    setDosage([]);
    form.reset();
  }, [selectedChild]);

  useEffect(() => {
    if (!selectedChild || !selectedMedication) return;
    const childLocal = child || medications.find((child) => String(child.id) === selectedChild);
    const medication = childLocal.medicalMedications.find((med) => {
      return String(med.id) === String(selectedMedication);
    });
    form.setFieldValue("name", medication.name);
    form.setFieldValue("description", medication.description);
    let dosageTmp = [];
    dosageOptions.map((dose) => {
      if (medication[dose.type].amount) {
        dosageTmp.push({ label: dose.type, value: String(medication[dose.type].amount) });
      }
    });
    setDosage(dosageTmp);
    setUpdate(!update);
  }, [selectedMedication]);

  const findDosage = (label) => {
    return dosage.find((dose) => dose?.label === label);
  };

  const getIndex = (label) => {
    return dosage.findIndex((dose) => dose?.label === label);
  };

  const handleDosageChange = (value, type) => {
    let dosageTmp = dosage;
    const id = getIndex(type);
    if (findDosage(type) && value > 0) {
      dosageTmp[id].value = value;
    } else if (findDosage(type) && value === 0) {
      dosageTmp.splice(id, 1);
    } else {
      dosageTmp.push({ label: type, value: value });
    }
    setDosage(dosageTmp);
    setUpdate(!update);
  };

  const form = useForm({
    initialValues: {
      name: "",
      description: "",
    },
  });

  const handleEdit = async (values) => {
    if (dosage.length < 1) {
      setLoading(false);
      return setError("Nebylo vybráno žádné dávkování");
    }
    dosage.forEach((dose) => {
      values[dose.label] = dose.value;
    });
    const res = await useFetch("/medical/child-medications/" + selectedMedication, "PUT", values);
    setLoading(false);
    if (res.status === "ok") {
      if (setMedications && child) {
        setMedications(
          child.medicalMedications.map((med) => {
            if (med.id === selectedMedication) {
              return res.data;
            }
            return med;
          })
        );
      }
      form.reset();
      setDosage([]);
      revalidator.revalidate();

      toast.success("Lék uspěšně upraven");
      return context.closeModal(id);
    }
    setError("Nastala chyba při úpravě léku");
  };

  const handleRemove = async () => {
    openConfirmModal({
      title: modalTitle(IconTrash, "Smazat pravidelný lék"),
      children: <Text size="sm">Opravdu chceš smazat tento lék?</Text>,
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
      onCancel: () => setLoading(false),
      onConfirm: () => {
        toast.promise(useFetch("/medical/child-medications/" + selectedMedication, "DELETE"), {
          loading: "Odstraňuji lék...",
          success: (res) => {
            form.reset();
            setDosage([]);
            revalidator.revalidate();
            context.closeModal(id);
            setLoading(false);
            return <p>Lék odstraněn</p>;
          },
          error: () => {
            context.closeModal(id);
            setLoading(false);
            return <p>Nastala chyba při odstranění léku a byla nahlášena.</p>;
          },
        });
      },
      onClose: () => {
        setLoading(false);
        setSelectedChild(selectedChild);
        setSelectedMedication(selectedMedication);
      },
    });
  };

  const handleSubmit = (values) => {
    const formValues = {
      name: values.name || null,
      description: values.description || null,
      s_m: values?.s_m || null,
      s_p: values?.s_p || null,
      o_m: values?.o_m || null,
      o_p: values?.o_p || null,
      v_m: values?.v_m || null,
      v_p: values?.v_p || null,
      pp: values?.pp || null,
    };
    setLoading(true);
    if (mode === "edit") return handleEdit(formValues);
    return handleRemove();
  };

  return (
    <>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při přidání léku nastala chyba"
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {!child && (
          <Select
            label="Dítě"
            mb={"sm"}
            data={childrenList}
            value={selectedChild}
            onChange={(value) => {
              setSelectedChild(value);
            }}
            searchable
            hoverOnSearchChange={true}
            required
            loading={loading}
            placeholder="Vyber dítě, u kterého chceš upravit či smazat lék"
          />
        )}
        {!selectedMed && (
          <Select
            label="Vyber lék"
            mb={"sm"}
            data={childMedicationList}
            value={selectedMedication}
            onChange={(value) => {
              setSelectedMedication(value);
            }}
            required
            disabled={!selectedChild || loading}
            loading={loading}
            placeholder="Vyber lék, který chceš upravit nebo smazat"
          />
        )}
        <Input.Wrapper label="Název léku" withAsterisk mt="sm">
          <TextInput
            disabled={!selectedMedication || loading}
            loading={loading}
            placeholder="Název léku"
            {...form.getInputProps("name")}
          />
        </Input.Wrapper>
        <Text className="mb-1 mt-3 text-sm font-bold">
          Dávkování<span className="ml-1 text-red-200">*</span>
        </Text>
        <Group grow>
          {dosageOptions.map((dose) => {
            return (
              <>
                <Button
                  type="button"
                  variant="light"
                  className={findDosage(dose.type) ? "hidden" : "font-bold"}
                  disabled={!selectedMedication || loading}
                  loading={loading}
                  onClick={() => {
                    handleDosageChange(1, dose.type);
                  }}>
                  {dose.text}
                </Button>
                <Input.Wrapper className={!findDosage(dose.type) ? "hidden w-1/4" : "w-1/7"}>
                  <NumberInput
                    min={0}
                    max={100}
                    step={0.5}
                    precision={1}
                    removeTrailingZeros
                    value={findDosage(dose.type)?.value}
                    disabled={!selectedMedication || loading}
                    loading={loading}
                    onChange={(value) => {
                      handleDosageChange(value, dose.type);
                    }}
                  />
                </Input.Wrapper>
              </>
            );
          })}
        </Group>
        <Textarea
          label="Poznámka"
          minRows={2}
          maxRows={4}
          mt={4}
          {...form.getInputProps("description")}
          disabled={!selectedMedication || loading}
          loading={loading}
          placeholder="Poznámka například k užívání..."
        />
        <Button
          type="submit"
          onClick={() => {
            setMode("edit");
          }}
          variant="filled"
          mt="lg"
          disabled={!selectedChild || !selectedMedication || loading}
          loading={loading}
          fullWidth>
          Upravit lék
        </Button>
        <Button
          type="submit"
          onClick={() => {
            setMode("remove");
          }}
          variant="light"
          mt="sm"
          disabled={!selectedChild || !selectedMedication || loading}
          loading={loading}
          fullWidth>
          Smazat lék
        </Button>
      </form>
    </>
  );
};

export default MedicationEditModal;
