import { useLoaderData } from "react-router-dom";
import Page from "../../components/layout/Page";
import { useFetch } from "../../helpers/useFetch";
import useTitle from "../../hooks/useTitle";
import RoomComputers from "../../components/pcapp/RoomComputers";
import { ActionIcon, Button, Text } from "@mantine/core";
import {
  IconArrowBack,
  IconClockCancel,
  IconPower,
  IconRadar,
  IconRefresh,
  IconRotate,
  IconShield,
  IconShieldOff,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useSocketFetch } from "../../helpers/useSocketFetch";
import classNames from "classnames";
import { modalTitle } from "../../components/modals/Modal";
import { openConfirmModal } from "@mantine/modals";
import { toast } from "react-hot-toast";

export function loader() {
  return useFetch("/pcapp/room");
}

export default function ComputersOverviewPage() {
  const rooms = useLoaderData().data;
  useTitle("Přehled počítačů");
  const [fetching, setFetching] = useState(false);
  const [socketComputers, setSocketComputers] = useState([]);
  const [socketError, setSocketError] = useState(false);

  const fetchSockets = () => {
    useSocketFetch("clients/&area").then((data) => {
      if (data === "error") {
        console.log("error");
        setSocketError(true);
        return;
      }
      setSocketError(false);
      setSocketComputers(data.data);
      setFetching(false);
    });
  };

  const turnOffAll = () => {
    openConfirmModal({
      title: modalTitle(IconPower, "Vypnutí všech počítačů"),
      children: (
        <Text size="sm">
          Opravdu chceš vypnout všechny počítače? Tento krok prováděj pouze na konci programu případně v nouzi.
        </Text>
      ),
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
      onConfirm: () => {
        useSocketFetch("/shutdown/start/60/&area/all", "POST").then((data) => {
          setTimeout(() => {
            toast.success("Počítače se vypnou za minutu.");
            fetchSockets();
          }, 100);
        });
      },
    });
  };

  const abortTurnOfAll = () => {
    useSocketFetch("/shutdown/abort/&area/all", "POST").then((data) => {
      setTimeout(() => {
        toast.success("Vypnutí počítačů zrušeno.");
        fetchSockets();
      }, 100);
    });
  };

  const rebootAll = () => {
    openConfirmModal({
      title: modalTitle(IconRotate, "Restart všech počítačů"),
      children: <Text size="sm">Opravdu chceš restartovat všechny počítače? Tento krok prováděj pouze v nouzi.</Text>,
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
      onConfirm: () => {
        useSocketFetch("/reboot/start/60/&area/all", "POST").then((data) => {
          setTimeout(() => {
            toast.success("Počítače se restartují za minutu.");
            fetchSockets();
          }, 100);
        });
      },
    });
  };

  const abortRebootAll = () => {
    useSocketFetch("/reboot/abort/&area/all", "POST").then((data) => {
      setTimeout(() => {
        toast.success("Restart počítačů zrušen.");
        fetchSockets();
      }, 100);
    });
  };

  const blockAll = () => {
    useSocketFetch("/block/&area/all", "POST").then((data) => {
      setTimeout(() => {
        toast.success("Počítače zablokovány.");
        fetchSockets();
      }, 100);
    });
  };

  const unblockAll = () => {
    useSocketFetch("/unblock/&area/all", "POST").then((data) => {
      setTimeout(() => {
        toast.success("Počítače odblokovány.");
        fetchSockets();
      }, 100);
    });
  };

  const pingAll = () => {
    useSocketFetch("/ping/&area/all", "POST").then((data) => {
      setTimeout(() => {
        toast.success("Ping na počítače odeslán.");
        fetchSockets();
      }, 100);
    });
  };

  const endProgramme = () => {
    useSocketFetch("/programme-end/&area/all", "POST").then((data) => {
      setTimeout(() => {
        toast.success("Program ukončen.");
        fetchSockets();
      }, 100);
    });
  };

  useEffect(() => {
    setFetching(true);
    fetchSockets();
    const interval = setInterval(() => {
      setFetching(true);
      fetchSockets();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Page title="Přehled počítačů">
      <div className="mb-4 grid gap-4 md:grid-cols-5">
        {socketComputers.find((c) => c.status !== "blocked") || socketComputers.length === 0 ? (
          <Button variant="light" leftSection={<IconShield stroke={1.5} size={20} />} onClick={blockAll}>
            Zablokovat všechny
          </Button>
        ) : (
          <Button
            variant="filled"
            leftSection={<IconShieldOff stroke={1.5} size={20} />}
            color="red"
            onClick={unblockAll}>
            Odblokovat všechny
          </Button>
        )}

        {socketComputers.find((c) => c.status !== "shuttingDown") || socketComputers.length === 0 ? (
          <Button variant="light" leftSection={<IconPower stroke={1.5} size={20} />} onClick={turnOffAll}>
            Vypnout všechny
          </Button>
        ) : (
          <Button
            variant="filled"
            leftSection={<IconArrowBack stroke={1.5} size={20} />}
            color="yellow"
            onClick={abortTurnOfAll}>
            Zrušit vypnutí
          </Button>
        )}

        {socketComputers.find((c) => c.status !== "rebooting") || socketComputers.length === 0 ? (
          <Button variant="light" leftSection={<IconRotate stroke={1.5} size={20} />} onClick={rebootAll}>
            Restartovat všechny
          </Button>
        ) : (
          <Button
            variant="filled"
            leftSection={<IconArrowBack stroke={1.5} size={20} />}
            color="yellow"
            onClick={abortRebootAll}>
            Zrušit restart
          </Button>
        )}
        <Button variant="light" leftSection={<IconClockCancel stroke={1.5} size={20} />} onClick={endProgramme}>
          Konec programu
        </Button>
        {socketComputers.find((c) => c.status !== "pinging") || socketComputers.length === 0 ? (
          <Button variant="light" leftSection={<IconRadar stroke={1.5} size={20} />} onClick={pingAll}>
            Ping na všechny
          </Button>
        ) : (
          <Button variant="filled" leftSection={<IconRadar stroke={1.5} size={20} />} color="pink">
            Ping na všechny
          </Button>
        )}
      </div>
      {socketError && <Text c="red">Chyba při získávání data.</Text>}
      <div className="mb-4 flex items-center justify-end gap-1">
        <Text c="dimmed">Automatická obnova probíhá každých 5 sekund.</Text>
        <ActionIcon variant="subtle" color="dimmed" size="md" onClick={fetchSockets}>
          <IconRefresh className={classNames({ "animate-spin duration-200": fetching })} size={18} stroke={1.5} />
        </ActionIcon>
      </div>
      {rooms.map((room) => (
        <RoomComputers
          fetchSockets={fetchSockets}
          key={room.id}
          socketComputers={socketComputers.filter((c) => c.group_id === room.id)}
          room={room}
        />
      ))}
    </Page>
  );
}
