import { ActionIcon, Group, Paper, Text, Tooltip } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { IconDownload, IconReportMedical, IconId, IconTrash, IconTrashOff, IconBiohazard } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { parseFullName } from "../../helpers/helpers";
import { useFetch } from "../../helpers/useFetch";
import { modalTitle } from "../modals/Modal";
import Table from "./core/Table";

const MedicalRecordsTable = ({ setReportDetail, filters, trash, shouldFetch, setShouldFetch }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userItem = localStorage.getItem("user");
  const user = JSON.parse(userItem);

  const deleteRecord = (record) => {
    openConfirmModal({
      title: modalTitle(IconTrash, "Smazání záznamu ošetření"),
      children: (
        <>
          <Text size="sm" mb="sm">
            Opravdu chceš smazat tento záznam?
          </Text>
          <Paper withBorder py="xs" px="sm">
            <Text fw="bold" mb={4}>
              {parseFullName(record.child)}
            </Text>
            <Text>
              <strong>Typ:</strong> {record.type}
              <br />
              <strong>Ošetřující:</strong> {parseFullName(record.createdBy)} <br />
              {record.description && (
                <Text>
                  <strong>Popis:</strong> {record.description}
                </Text>
              )}
            </Text>
          </Paper>
        </>
      ),
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
      onCancel: () => setLoading(false),
      onConfirm: () => {
        setLoading(true);
        toast.promise(useFetch("/medical/records/" + record.id, "DELETE"), {
          loading: "Odstraňuji záznam...",
          success: (res) => {
            setShouldFetch(true);
            setLoading(false);
            return <p>Záznam odstraněn</p>;
          },
          error: () => {
            setLoading(false);
            return <p>Nastala chyba při odstranění záznamu a byla nahlášena.</p>;
          },
        });
      },
      onClose: () => {
        setLoading(false);
      },
    });
  };

  const restoreRecord = (record) => {
    setLoading(true);
    toast.promise(useFetch("/medical/records/" + record.id + "/restore", "PUT"), {
      loading: "Obnovuji záznam...",
      success: (res) => {
        setShouldFetch(true);
        setLoading(false);
        return <p>Záznam obnoven</p>;
      },
      error: () => {
        setLoading(false);
        return <p>Nastala chyba při obnovení záznamu a byla nahlášena.</p>;
      },
    });
  };

  const childName = (child, isIsolated) => {
    return (
      <div>
        <strong>
          {child.firstName}&nbsp;{child.lastName} <span className="mobileAge">({child.age})</span>
        </strong>
        {isIsolated && (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="V izolaci">
            <Text h={18} span color="green.6">
              <IconBiohazard stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        )}
      </div>
    );
  };

  const recordDate = (givenDate) => {
    const date = dayjs(givenDate).format("D. M. YYYY") + " - " + dayjs(givenDate).format("HH:mm");

    return <div>{date}</div>;
  };

  const treatment = (record) => {
    return <div>{record.type}</div>;
  };

  const medicator = (record) => {
    return (
      <div>
        {record.createdBy.firstName} {record.createdBy.lastName}
      </div>
    );
  };

  const actionButtons = (record) => {
    return (
      <Group wrap="nowrap" gap="xs">
        {!trash && (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Stažení">
            <ActionIcon
              variant="light"
              color="gray"
              loading={loading}
              onClick={(e) => {
                e.stopPropagation();
                const url = import.meta.env.VITE_API_URL + "/export/pdf/child-medical-records/" + record.child.id;
                const downloadLink = document.createElement("a");
                downloadLink.target = "_blank";
                downloadLink.href = new URL(url + "?_token=" + user.token, import.meta.env.VITE_API_URL).href;
                downloadLink.click();
              }}>
              <IconDownload stroke={1.5} size={18} />
            </ActionIcon>
          </Tooltip>
        )}

        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Karta dítěte">
          <ActionIcon
            variant="light"
            color="gray"
            loading={loading}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/deti/${record.child.id}`);
            }}>
            <IconId stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        {!trash && (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Smazat ošetření">
            <ActionIcon
              variant="light"
              color="red"
              loading={loading}
              onClick={(e) => {
                e.stopPropagation();
                deleteRecord(record);
              }}>
              <IconTrash stroke={1.5} size={18} />
            </ActionIcon>
          </Tooltip>
        )}
        {trash && (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Obnovit ošetření">
            <ActionIcon
              variant="filled"
              color="teal"
              loading={loading}
              onClick={(e) => {
                e.stopPropagation();
                restoreRecord(record);
              }}>
              <IconTrashOff stroke={1.5} size={18} />
            </ActionIcon>
          </Tooltip>
        )}
        {!trash && (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Otevřít detail ošetření">
            <ActionIcon
              variant="filled"
              loading={loading}
              onClick={(e) => {
                e.stopPropagation();
                setReportDetail(record);
              }}>
              <IconReportMedical stroke={1.5} size={18} />
            </ActionIcon>
          </Tooltip>
        )}
      </Group>
    );
  };

  const columns = [
    {
      accessor: "ucastnik_prijmeni",
      title: "Dítě",
      render: (record) =>
        record?.child?.firstName
          ? childName(record.child, record.isIsolated)
          : record?.staff?.firstName + " " + record?.staff?.lastName,
      sortable: true,
    },

    ...(trash
      ? [
          {
            accessor: "deleted_at",
            title: "Smazáno",
            render: (record) => recordDate(record.deletedAt),
            sortable: true,
          },
        ]
      : [
          {
            accessor: "created_at",
            title: "Datum",
            render: (record) => recordDate(record.createdAt),
            sortable: true,
          },
        ]),
    {
      accessor: "type",
      title: "Typ zranění",
      render: (record) => treatment(record),
      sortable: true,
    },
    {
      accessor: "room",
      title: "Pokoj",
      render: (record) =>
        record?.child ? (
          record.child.room ? (
            record.child.room.name
          ) : (
            <Text c="dimmed">—</Text>
          )
        ) : record?.staff?.room ? (
          record.staff.room.name
        ) : (
          <Text c="dimmed">—</Text>
        ),
      sortable: true,
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (record) => actionButtons(record),
      narrow: true,
    },
  ];

  /* const filterConfig = [
    ...(trash
      ? [
          {
            key: "trashed",
            label: "Pouze odstraněné",
            type: "text",
            unchangeable: true,
            defaultValue: "only",
          },
        ]
      : []),
  ]; */

  const filterConfig = [
    {
      key: "type",
      label: "Typ ošetření",
      type: "select",
      options: [
        { value: "staff", label: "Vedoucí" },
        { value: "children", label: "Děti" },
      ],
    },
  ];

  return (
    <Table
      filterConfig={filterConfig}
      useServer={true}
      id={`medical-records-${trash ? "trash" : "list"}`}
      serverUrl={"/medical/records"}
      columns={columns}
      shouldServerFetch={shouldFetch}
      setShouldServerFetch={setShouldFetch}
      onRowClick={(record) => setReportDetail(record)}
    />
  );
};

export default MedicalRecordsTable;
