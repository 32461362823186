import { Button, MultiSelect, Select } from "@mantine/core";
import Page from "../../components/layout/Page";
import { useFetch } from "../../helpers/useFetch";
import { useLoaderData } from "react-router-dom";
import { parseFullName } from "../../helpers/helpers";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { IconUser } from "@tabler/icons-react";
import useTitle from "../../hooks/useTitle";

export const loader = async () => {
  return Promise.all([useFetch(`staff/roles`), useFetch(`staff`)]).then(([roles, staff]) => {
    return { roles, staff };
  });
};

export default function PermissionsPage() {
  const roles = useLoaderData().roles.data;
  console.log(roles.map((group) => ({ label: group.name, value: String(group.id) })));
  const staff = useLoaderData().staff.data;

  const [user, setUser] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState(staff);

  useTitle("Správa práv");

  useEffect(() => {
    if (user) {
      let userLocal = staffList.find((userLocal) => userLocal.id === Number(user));
      console.log(userLocal.roles.map(Number));
      setUserRoles(userLocal.roles ? userLocal.roles.map(String) : []);
    }
  }, [user]);

  const submit = () => {
    setLoading(true);
    useFetch(`staff/${user}/roles`, "PUT", { roles: userRoles })
      .then((res) => {
        if (res.status === "ok") {
          toast.success("Role uživatele byly změněny.");
          setStaffList(
            staffList.map((userLocal) => {
              if (userLocal.id === user) {
                userLocal.roles = userRoles;
              }
              return userLocal;
            })
          );
        } else {
          toast.error("Nastala chyba při změně rolí uživatele.");
          console.error(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Nastala chyba při změně rolí uživatele.");
        console.error(res);
        setLoading(false);
      });
  };

  return (
    <Page title="Správa práv">
      <Select
        placeholder="Vyber uživatele..."
        value={user}
        onChange={setUser}
        label="Výběr uživatele"
        mb="sm"
        disabled={loading}
        icon={<IconUser size={16} stroke={1.5} />}
        searchable
        hoverOnSearchChange={true}
        data={staffList.map((user) => ({
          label: `${parseFullName(user)} (${user.position}, ${user.age} let)`,
          value: String(user.id),
        }))}
      />
      <MultiSelect
        placeholder="Vyber skupiny..."
        value={userRoles}
        onChange={setUserRoles}
        disabled={!user || loading}
        label="Seznam skupin"
        searchable
        data={roles.map((group) => ({ label: group.name, value: String(group.id) }))}
      />
      <Button mt="md" loading={loading} fullWidth disabled={!user} onClick={() => submit()}>
        Změnit role
      </Button>
    </Page>
  );
}
