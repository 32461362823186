import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import { useState } from "react";
import styles from "../../pages.module.scss";
import classNames from "classnames";
import {
  IconBiohazard,
  IconBrandDiscordFilled,
  IconCake,
  IconCalendarEvent,
  IconChevronDown,
  IconDoorExit,
  IconMessageShare,
  IconNote,
  IconPill,
  IconTrash,
} from "@tabler/icons-react";
import Table from "./core/Table.jsx";
import MyTeamMobile from "./responsive/MyTeamMobile.jsx";
import { openTicketModal } from "../../helpers/modals.js";
import { sortChildren } from "./sort.js";
import MedicalInfoCol from "../MedicalInfoCol";
import { parseFullName } from "../../helpers/helpers";
import { dosageAllOptions } from "../../helpers/dosages";
import dayjs from "dayjs";

export function MyTeamTable({ childrenData, rooms }) {
  const [openChildId, setOpenChildId] = useState(0);

  const expandChild = (child) => {
    if (openChildId === child.id) return setOpenChildId(0);
    setOpenChildId(child.id);
  };

  const childName = (child) => {
    let medications = child.medicalMedications;
    let hasTakenMeds = false;
    let isTakingMeds = false;
    let isTakingMedsCurrent = false;
    let hasTakenMedsArray = [];
    let hasToClean = false;

    let currentPartOfDay = new Date().getHours();

    if (currentPartOfDay <= 11) {
      currentPartOfDay = "s";
    } else if (currentPartOfDay <= 16) {
      currentPartOfDay = "o";
    } else {
      currentPartOfDay = "v";
    }

    medications?.forEach((med) => {
      if (med) {
        ["s", "o", "v"].map((period) => {
          if (med[period + "_m"].amount || med[period + "_p"].amount) {
            isTakingMeds = true;
            if (period === currentPartOfDay) isTakingMedsCurrent = true;
          }
        });
        if (med[currentPartOfDay + "_m"]?.amount || med[currentPartOfDay + "_p"]?.amount) {
          if (med[currentPartOfDay + "_m"].amount && med[currentPartOfDay + "_m"].taked) {
            hasTakenMedsArray.push(true);
          }
          if (med[currentPartOfDay + "_p"].amount && med[currentPartOfDay + "_p"].taked) {
            hasTakenMedsArray.push(true);
          }
        }
      }
    });

    if (hasTakenMedsArray.length > 0) {
      hasTakenMeds = hasTakenMedsArray.reduce((madA, madB) => madA && madB);
    }

    return (
      <div>
        <strong>
          {child.firstName}&nbsp;{child.lastName} <span className="mobileAge">({child.age})</span>
        </strong>
        {child.hasBirthday ? (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Narozeniny">
            <Text h={18} span color="yellow.6">
              <IconCake stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        ) : (
          ""
        )}
        {child.hasNameday ? (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Svátek">
            <Text h={18} span color="teal.6">
              <IconCalendarEvent stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        ) : (
          ""
        )}
        {child.permanentNote ? (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Historická poznámka">
            <Text h={18} span color="pink.6">
              <IconNote stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        ) : (
          ""
        )}
        {child.earlyLeaving ? (
          <Tooltip
            position="top"
            withArrow
            transition="fade"
            transitionDuration={200}
            label={
              child.earlyLeaving.leavingDate && child.earlyLeaving.leavingTime ? (
                <>
                  Předčasný odjezd {dayjs(child.earlyLeaving.leavingDate).format("D. M. YYYY")} v{" "}
                  {child.earlyLeaving.leavingTime}
                </>
              ) : child.earlyLeaving.leavingDate && !child.earlyLeaving.leavingTime ? (
                <>Předčasný odjezd {dayjs(child.earlyLeaving.leavingDate).format("D. M. YYYY")}</>
              ) : (
                <>Předčasný odjezd</>
              )
            }>
            <Text h={18} span color="red.6">
              <IconDoorExit stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        ) : (
          ""
        )}
        {isTakingMeds && isTakingMedsCurrent && (
          <Tooltip
            position="top"
            withArrow
            transition="fade"
            transitionDuration={200}
            label={hasTakenMeds ? "Vzalo si léky" : "Nevzalo si léky"}>
            <Text h={18} span color={hasTakenMeds ? "teal" : "red.6"}>
              <IconPill stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        )}
        {child.hasToClean && (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Musí uklízet">
            <Text h={18} span color="orange.6">
              <IconTrash stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        )}
        {child.isIsolated && (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="V izolaci">
            <Text h={18} span color="green.6">
              <IconBiohazard stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        )}
      </div>
    );
  };

  const actionButtons = (child) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label="Požadavek na hlavního vedoucího">
          <ActionIcon
            variant="light"
            onClick={(e) => {
              e.stopPropagation();
              openTicketModal(`Vytvořit požadavek`, child, null, parseFullName(child));
            }}>
            <IconMessageShare stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Rozbalit informace">
          <ActionIcon
            variant="light"
            className={classNames(styles.Expander, openChildId === child.id ? styles.Expanded : "")}>
            <IconChevronDown stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const expandContent = (child) => {
    const birthDate = new Date(child.birthDate);

    return (
      <div className={classNames(styles.ExpandedContent)}>
        <div className={classNames(styles.BasicInfo)}>
          <p>
            <strong>Datum narození: </strong>
            {birthDate.getDate()}. {birthDate.getMonth() + 1}. {birthDate.getFullYear()}
          </p>
          <p>
            <strong>Číslo pokoje: </strong>
            {child.roomName}
          </p>
          <p>
            <strong>Velikost trička: </strong>
            {child.tShirtSize}
          </p>
          <p className="flex whitespace-pre">
            <strong>Plavec: </strong>
            {child.canSwim ? <Text color="green">Ano</Text> : <Text color="red">Ne</Text>}
          </p>
          <p className="mt-2 flex items-center whitespace-pre">
            <IconBrandDiscordFilled size={18} className="ml-0 mr-1" />
            <strong>Discord kód: </strong> <span className="font-mono">{child.discord.code}</span>
          </p>
          <Text c="dimmed" className="text-sm">
            Ověření přes /verify
          </Text>
        </div>
        <div className={classNames(!child.permanentNote ? styles.Spread : "", styles.MediacalInfo)}>
          {(child.medicalInfo?.medical_restrictions || child.parentMedicalInfo?.medical_restrictions) && (
            <div>
              <p>
                <b>Zdravotní omezení:</b>
              </p>
              <p>
                {child.medicalInfo.medical_restrictions
                  ? child.medicalInfo.medical_restrictions
                  : child.parentMedicalInfo.medical_restrictions}
              </p>
            </div>
          )}

          {(child.medicalInfo?.allergies || child.parentMedicalInfo?.allergies) && (
            <div>
              <p>
                <b>Alergie:</b>
              </p>
              <p>{child.medicalInfo.allergies ? child.medicalInfo.allergies : child.parentMedicalInfo.allergies}</p>
            </div>
          )}

          {(child.medicalInfo?.dietary_restrictions || child.parentMedicalInfo?.dietary_restrictions) && (
            <div>
              <p>
                <b>Stravování:</b>
              </p>
              <p>
                {child.medicalInfo.dietary_restrictions
                  ? child.medicalInfo.dietary_restrictions
                  : child.parentMedicalInfo.dietary_restrictions}
              </p>
            </div>
          )}

          {child.medicalMedications.length >= 1 && (
            <div>
              <p>
                <b>Pravidelné léky:</b>
              </p>
              <Group mt={6} className="flex-col items-start">
                <ul className="my-0 pl-4">
                  {child.medicalMedications.map((medication) => {
                    return (
                      <li>
                        <div className="flex gap-4">
                          {medication.name}
                          <div className="flex gap-2">
                            {dosageAllOptions.map((dose) => {
                              return (
                                medication[dose.type].amount && (
                                  <ActionIcon variant="light" className="cursor-default">
                                    <strong>{dose.text}</strong>
                                  </ActionIcon>
                                )
                              );
                            })}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </Group>
            </div>
          )}
        </div>
        {child.permanentNote || child.parentNote ? (
          <div className={classNames(styles.PernanentNote)}>
            {child.permanentNote && (
              <>
                <p>
                  <b>Historická poznámka od HV: </b>
                </p>
                <p>{child.permanentNote}</p>
              </>
            )}
            {child.parentNote && (
              <>
                <p>
                  <b>Poznámka od rodiče: </b>
                </p>
                <p>{child.parentNote}</p>
              </>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const columns = [
    {
      accessor: "name",
      title: "Jméno",
      render: (child) => childName(child),
      sortable: true,
    },
    {
      accessor: "age",
      title: "Věk",
      sortable: true,
      render: (child) => child.age,
      visibleMediaQuery: (theme) => `(min-width: 420px)`,
    },
    {
      accessor: "medicals",
      title: "Zdraví",
      render: (child) => {
        return <MedicalInfoCol child={child} />;
      },
      visibleMediaQuery: (theme) => `(min-width: 300px)`,
      sortable: true,
      // firstSort: "desc",
    },
    {
      accessor: "roomName",
      title: "Pokoj",
      render: (child) => child.roomName,
      visibleMediaQuery: (theme) => `(min-width: 660px)`,
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (child) => actionButtons(child),
      narrow: true,
    },
  ];

  return (
    <Table
      columns={columns}
      id="muj-oddil"
      records={childrenData}
      responsiveComponent={<MyTeamMobile />}
      onRowClick={(record) => {
        expandChild(record);
      }}
      rowExpansion={{
        collapseProps: {
          transitionDuration: 300,
          transitionTimingFunction: "ease",
        },
        content: ({ record }) => expandContent(record),
      }}
      sortFunction={sortChildren}
    />
  );
}
