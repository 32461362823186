import { useLoaderData } from "react-router-dom";
import { OverviewStats } from "../../components/buffet/OverviewStats";
import Page from "../../components/layout/Page";
import { useFetch } from "../../helpers/useFetch";

export const loader = async () => {
  return useFetch("depot/orders-summary");
};

const OverviewPage = () => {
  const data = useLoaderData();

  return (
    <Page title={"Přehled"}>
      <OverviewStats stats={data.productStats} revenue={data.totalRevenue} />
    </Page>
  );
};

export default OverviewPage;
