import { MDXProvider } from "@mdx-js/react";
import React from "react";
import { useParams } from "react-router-dom";
import DynamicImport from "../../components/manual/DynamicImport";

export default function ManualPage() {
  const params = useParams();

  let path = params["*"];

  return (
    <MDXProvider>
      <DynamicImport path={path} />
    </MDXProvider>
  );
}
