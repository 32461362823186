import { ActionIcon } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { getStroke } from "perfect-freehand";
import { useEffect, useRef, useState } from "react";

export function getSvgPathFromStroke(stroke) {
  if (!stroke.length) return "";

  const d = stroke.reduce(
    (acc, [x0, y0], i, arr) => {
      const [x1, y1] = arr[(i + 1) % arr.length];
      acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
      return acc;
    },
    ["M", ...stroke[0], "Q"]
  );

  d.push("Z");
  return d.join(" ");
}

const options = {
  size: 8,
  thinning: 0.3,
  smoothing: 0.5,
  streamline: 0.5,
  easing: (t) => t,
  start: {
    taper: 0,
    easing: (t) => t,
    cap: true,
  },
  end: {
    taper: 0,
    easing: (t) => t,
    cap: true,
  },
};

export default function SignaturePad({ signatureRef, setPathsCount }) {
  const [points, setPoints] = useState([]);
  const [paths, setPaths] = useState([]);
  const svgRef = useRef();

  const handlePointerDown = (e) => {
    e.target.setPointerCapture(e.pointerId);
    let x = e.pageX - svgRef.current.getBoundingClientRect().left;
    let y = e.pageY - svgRef.current.getBoundingClientRect().top;
    setPoints([[x, y, e.pressure]]);
  };

  const handlePointerMove = (e) => {
    if (e.buttons !== 1) return;
    let x = e.pageX - svgRef.current.getBoundingClientRect().left;
    let y = e.pageY - svgRef.current.getBoundingClientRect().top;
    setPoints([...points, [x, y, e.pressure]]);
  };

  const handlePointerUp = (e) => {
    e.target.releasePointerCapture(e.pointerId);
    setPaths([...paths, points]);
    setPoints([]);
  };

  const clearPad = () => {
    setPaths([]);
  };

  useEffect(() => {
    setPathsCount(paths.length);
  }, [paths]);

  const stroke = getStroke(points, options);
  const pathData = getSvgPathFromStroke(stroke);

  return (
    <div className="relative grow">
      <ActionIcon onClick={clearPad} size="lg" className="absolute left-4 top-4" variant="filled" color="red">
        <IconTrash stroke={1.5} size={24} />
      </ActionIcon>
      <svg
        ref={svgRef}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        className="h-[350px] w-full touch-none rounded-md bg-white-900"
      >
        <g ref={signatureRef}>
          {paths.map((points, i) => {
            const stroke = getStroke(points, options);
            const pathData = getSvgPathFromStroke(stroke);
            return <path key={i} d={pathData} />;
          })}
          {pathData && <path d={pathData} />}
        </g>
      </svg>
    </div>
  );
}
