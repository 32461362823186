import Table from "./core/Table.jsx";

import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import { IconCheck, IconDoor, IconEdit, IconTrash, IconX } from "@tabler/icons-react";
import { parseFullName } from "../../helpers/helpers.js";
import { useRevalidator } from "react-router-dom";
import styles from "../../pages.module.scss";
import { useState } from "react";
import RoomManageDrawer from "../pcapp/RoomManageDrawer.jsx";
import { openConfirmModal } from "@mantine/modals";
import { useFetch } from "../../helpers/useFetch.js";
import { modalTitle } from "../modals/Modal.jsx";
import { toast } from "react-hot-toast";

export default function RoomOverviewTable({ rooms }) {
  const revalidator = useRevalidator();
  const [loading, setLoading] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const deleteRoom = async (id) => {
    openConfirmModal({
      title: modalTitle(IconDoor, "Smazat místnost"),
      children: <Text size="sm">Opravdu chceš smazat tuto místnost?</Text>,
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
      onCancel: () => setLoading(false),
      onConfirm: () => {
        toast.promise(useFetch(`/pcapp/room/${id}`, "DELETE"), {
          loading: "Odstraňuji místnost...",
          success: (res) => {
            revalidator.revalidate();
            setLoading(false);
            return <p>Místnost odstraněna</p>;
          },
          error: () => {
            setLoading(false);
            return <p>Nastala chyba při odstranění místnosti a byla nahlášena.</p>;
          },
        });
      },
      onClose: () => {
        setLoading(false);
      },
    });
  };

  const actionButtons = (room) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Smazat místnost">
          <ActionIcon
            variant="light"
            loading={loading}
            color="red"
            onClick={(e) => {
              e.stopPropagation();
              setLoading(true);
              deleteRoom(room.id);
            }}>
            <IconTrash size={18} stroke={1.5} />
          </ActionIcon>
        </Tooltip>

        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Upravit místnost">
          <ActionIcon
            variant="light"
            loading={loading}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRoom(room.id);
              setIsOpen(true);
            }}>
            <IconEdit size={18} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const columns = [
    {
      accessor: "name",
      title: "Název",
      render: (room) => room.name,
      sortable: true,
    },
    {
      accessor: "count",
      title: "Počet PC",
      render: (room) => room.computers.length,
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (room) => actionButtons(room),
      narrow: true,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        records={rooms}
        //sortFunction={sortRoomIssues}
      />
      <RoomManageDrawer
        room={selectedRoom}
        rooms={rooms}
        isOpen={isOpen}
        onClose={() => {
          setSelectedRoom(null);
          setIsOpen(false);
        }}
      />
    </>
  );
}
