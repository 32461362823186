import { useEffect, useState } from "react";
import { Alert, Button, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch.js";
import { useRevalidator } from "react-router-dom";
import { IconAlertCircle } from "@tabler/icons-react";

export default function TicketModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const revalidator = useRevalidator();

  async function handleSubmit(values) {
    await setLoading(true);

    const ticket = innerProps?.ticket;
    const res = await useFetch(`tickets${ticket ? "/" + ticket.id : ""}`, ticket ? "PUT" : "POST", { ...values });

    if (res.status === "ok") {
      context.closeModal(id);
      form.reset();
      setLoading(false);
      toast.success(`Požadavek ${ticket ? "upraven" : "vytvořen"}.`);
      revalidator.revalidate();
    } else {
      console.error(res);
      setLoading(false);
      setError(res.message);
    }
  }

  const form = useForm({
    initialValues: {
      text: "",
      child_id: innerProps?.child?.id,
    },
    validate: {
      text: (value) => (value === "" ? "Musíš napsat text" : null),
    },
  });

  useEffect(() => {
    if (innerProps?.ticket) {
      form.setValues({ text: innerProps?.ticket?.text });
    } else {
      form.reset();
    }
  }, [innerProps]);

  return (
    // <Modal
    //     title={ticket?.child ? `Požadavek – ${ticket?.child.firstName} ${ticket?.child.lastName}` : "Požadavek"}
    //     opened={opened}
    //     setOpened={setOpened}
    //     icon={IconMessageShare}
    //     description="Zadej text požadavku na hlavního vedoucího."
    // >
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title={`Při ${ticket ? "vytváření" : "úpravě"} požadavku nastala chyba.`}
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}
        >
          {error}
        </Alert>
      )}
      <Textarea
        minRows={2}
        maxRows={4}
        disabled={loading}
        placeholder="Zadej text požadavku..."
        autosize
        {...form.getInputProps("text")}
      />

      <Button fullWidth type="submit" loading={loading} mt="md">
        Odeslat požadavek
      </Button>
    </form>
    // </Modal>
  );
}
