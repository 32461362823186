import { Button, MultiSelect, Select, Text } from "@mantine/core";
import Page from "../../components/layout/Page";
import { useFetch } from "../../helpers/useFetch";
import { useLoaderData } from "react-router-dom";
import { getMode, parseFullName } from "../../helpers/helpers";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  IconBookmark,
  IconBus,
  IconDoorExit,
  IconDownload,
  IconEdit,
  IconListDetails,
  IconSwitchHorizontal,
  IconUser,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import Table from "../../components/table/core/Table.jsx";
import { openChildTransferModal, openEarlyLeaveModal, openTransportBuyModal } from "../../helpers/modals.js";
import useTitle from "../../hooks/useTitle";
import { useUser } from "../../providers/UserProvider";
import { sortStaff } from "../../components/table/sort";

export const loader = async () => {
  return useFetch(`staff`);
};

export default function StaffOverviewPage() {
  const staff = useLoaderData().data;
  const { user } = useUser();
  const { mode, theme } = getMode();

  const actionIcons = [
    {
      name: "Stáhnout přehled personálu",
      onTrigger: (e) => {
        e.stopPropagation();
        const url = import.meta.env.VITE_API_URL + "/export/pdf/staff";
        const downloadLink = document.createElement("a");
        downloadLink.target = "_blank";
        downloadLink.href = new URL(url + "?_token=" + user.token, import.meta.env.VITE_API_URL).href;
        downloadLink.click();
      },
      primary: false,
      icon: IconDownload,
    },
  ];

  const columns = [
    {
      accessor: "name",
      title: "Jméno",
      render: (user) => parseFullName(user),
      sortable: true,
    },
    {
      accessor: "phone",
      title: "Telefon",
      render: (user) => (
        <Text className="w-fit hover:underline" color={theme.colors[theme.primaryColor][4]}>
          <a href={`tel:${user.phone}`}>{user.phone}</a>
        </Text>
      ),
    },
    {
      accessor: "position",
      title: "Funkce",
      render: (user) => user.position,
      sortable: true,
    },
    {
      accessor: "age",
      title: "Věk",
      render: (user) => user.age,
      sortable: true,
    },
  ];

  const filterConfig = [
    {
      key: "jmeno",
      label: "Jméno",
      type: "search",
      function: (user, value) => {
        return parseFullName(user).toLowerCase().includes(value.toLowerCase());
      },
    },
  ];

  useTitle("Přehled personálu");

  return (
    <Page title="Přehled personálu" actionIcons={actionIcons}>
      <Table filterConfig={filterConfig} columns={columns} records={staff} id="staff" sortFunction={sortStaff} />
    </Page>
  );
}
