import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch.js";
import { closeModal } from "@mantine/modals";
import { useRevalidator } from "react-router-dom";
import { Button, Group, NumberInput, Select, Text } from "@mantine/core";
import classNames from "classnames";
import { useForm } from "@mantine/form";

export default function MoneyRemoveModal({ context, id, innerProps }) {
  const revalidate = useRevalidator();
  const { setDeposits, transaction, childId, deposits, category } = innerProps;
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [childrenList, setChildrenList] = useState([]);

  const reasons = ["Chyba při akreditaci", "Nákup v bufetu", "Nákup jinde", "Cesta"].map((reason) => ({
    label: reason,
    value: reason,
  }));

  const form = useForm({
    initialValues: {
      child: childId,
      category: transaction?.category || category,
      reason: null,
      amount: transaction?.amount,
      currency: transaction?.currency,
    },
  });

  useEffect(() => {
    if (form.values.reason) setDisabled(false);
    else setDisabled(true);
  }, [form.values.reason]);

  useEffect(() => {
    if (childId) {
      setChildrenList([{ label: `${childId}`, value: String(childId) }]);
    } else {
      useFetch("/children/list", "GET").then((res) => {
        if (res.data) {
          setChildrenList(
            res.data.map((child) => ({
              label: `${child.firstName} ${child.lastName} (${child.team}, ${child.age} let)`,
              value: String(child.id),
            }))
          );
        }
      });
    }
  }, []);

  const submit = (values) => {
    setLoading(true);
    useFetch(`/children/${values.child}/money/${values.category}/withdraw`, "PUT", {
      amount: values.amount,
      currency: values.currency,
      note: values.reason,
    }).then((res) => {
      if (res.status === "ok") {
        if (values.currency === "CZK") {
          if (setDeposits) {
            setDeposits({
              transactions: [...deposits.transactions, res.data],
              amount: { ...deposits.amount, czk: deposits.amount.czk - values.amount },
            });
          }
        } else {
          if (setDeposits) {
            setDeposits({
              transactions: [...deposits.transactions, res.data],
              amount: { ...deposits.amount, eur: deposits.amount.eur - values.amount },
            });
          }
        }
        toast.success(values.category === "deposit" ? "Kauce stržena!" : "Kapesné vráceno!");
        setLoading(false);
        context.closeModal(id);
      } else {
        setLoading(false);
        toast.error("Nastala chyba při stržení peněz.");
      }
    });
  };

  return (
    <div>
      <form onSubmit={form.onSubmit(submit)}>
        <Select
          mb="sm"
          label="Dítě"
          placeholder="Vyber dítě..."
          className={classNames({ hidden: childId })}
          data={childrenList}
          searchable
          hoverOnSearchChange={true}
          {...form.getInputProps("child")}
        />
        <Select
          mb="sm"
          label="Kasa"
          placeholder="Vyber kasu..."
          {...form.getInputProps("category")}
          className={classNames({ hidden: transaction })}
          data={[
            {
              label: "Kauce",
              value: "deposit",
            },
            { label: "Kapesné", value: "pocketMoney" },
          ]}
        />
        <Group className={classNames({ hidden: transaction })}>
          <NumberInput
            min={0}
            precision={0}
            className="grow basis-[250px]"
            mb="sm"
            label="Částka"
            placeholder="Zadej částku..."
            {...form.getInputProps("amount")}
          />
          <Select
            mb="sm"
            className="grow basis-[250px]"
            label="Měna"
            placeholder="Vyber měnu..."
            data={[
              {
                label: "CZK",
                value: "CZK",
              },
              { label: "EUR", value: "EUR" },
            ]}
            {...form.getInputProps("currency")}
          />
        </Group>
        <Select
          label="Důvod stržení"
          placeholder="Vyber důvod stržení"
          searchable
          creatable
          description="Můžeš také zadat vlastní důvod."
          getCreateLabel={(query) => (
            <Text span>
              <Text span fw="bold">
                Vlastní důvod:{" "}
              </Text>
              {query}
            </Text>
          )}
          data={reasons}
          {...form.getInputProps("reason")}
        />

        <Group gap="sm" mt="lg" grow={1} className="w-full">
          <Button variant="light" onClick={() => context.closeModal(id)}>
            Zrušit
          </Button>
          <Button
            loading={loading}
            disabled={
              !form.values.reason ||
              !form.values.amount ||
              !form.values.child ||
              !form.values.category ||
              !form.values.currency
            }
            type="submit">
            Strhnout {transaction?.category === "deposit" ? "kauci" : "kapesné"}
          </Button>
        </Group>
      </form>
    </div>
  );
}
