import { Group, Paper, Stack, Text } from "@mantine/core";

export const OverviewStats = ({ stats, revenue }) => {
  return (
    <Stack>
      <Text>Celková tržba: {revenue} Kč</Text>
      <Group>
        {Object.entries(stats).map(([name, value]) => (
          <Paper key={name} w={"20%"} p="md" radius="md">
            <Text size="lg" weight={500}>
              {name}
            </Text>
            <Text size="md" weight={500}>
              Prodáno: {value}ks
            </Text>
          </Paper>
        ))}
      </Group>
    </Stack>
  );
};
