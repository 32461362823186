import { useEffect, useState } from "react";
import { useFetch } from "../../helpers/useFetch";
import Drawer from "../drawer/Drawer";
import { IconCake } from "@tabler/icons-react";
import Table from "../table/core/Table";
import dayjs from "dayjs";
import "dayjs/locale/cs";
import { SegmentedControl, Text } from "@mantine/core";
import { getMode } from "../../helpers/helpers";
import { sortBirthDays, sortNameDays } from "../table/sort";

dayjs.locale("cs");

export const NamedayBirthdayDrawer = ({ opened, onClose }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("birthday");

  const getData = async () => {
    const res = await useFetch("/children/name-birth-days-overview");
    setData(res);
  };

  useEffect(() => {
    getData();
  }, []);

  const columnsBirths = [
    {
      accessor: "name",
      title: "Jméno",
      render: (child) => (
        <Text weight={"bold"}>
          {child.firstName} {child.lastName}
        </Text>
      ),
    },
    {
      accessor: "birthDay",
      title: "Datum narození",
      firstSort: "asc",
      render: (child) => <Text>{dayjs(child.birthDate).year(new Date().getFullYear()).format("D. M. (dddd)")}</Text>,
    },
  ];

  const columnsNameday = [
    {
      accessor: "name",
      title: "Jméno",
      render: (child) => (
        <Text weight={"bold"}>
          {child.firstName} {child.lastName}
        </Text>
      ),
    },
    {
      accessor: "nameDay",
      title: "Svátek",
      firstSort: "asc",
      render: (child) => (child.nameday ? <Text>{dayjs(child.nameday).format("D. M. (dddd)")}</Text> : <Text>—</Text>),
    },
  ];

  return (
    <Drawer opened={opened} onClose={onClose} title="Přehled narozenin a svátků" icon={IconCake}>
      <SegmentedControl
        mb="sm"
        fullWidth
        color={getMode().theme.primaryColor}
        value={selectedType}
        onChange={setSelectedType}
        data={[
          {
            label: <Text className="flex items-center justify-center gap-1">Narozeniny</Text>,
            value: "birthday",
          },
          {
            label: <Text className="flex items-center justify-center gap-1">Svátky</Text>,
            value: "nameday",
          },
        ]}
      />
      {selectedType === "birthday" ? (
        <Table
          id="merch-birthday"
          columns={columnsBirths}
          records={data?.birthdays}
          sortFunction={sortBirthDays}
          key={"birthday"}
        />
      ) : (
        <Table
          id="merch-nameday"
          columns={columnsNameday}
          records={data?.namedays}
          key={"nameday"}
          sortFunction={sortNameDays}
        />
      )}
    </Drawer>
  );
};
