export const sortChildren = (sortBy, sortDirection, records) => {
  let data = records;
  data = data.sort((childA, childB) => {
    return childA.lastName.localeCompare(childB.lastName);
  });
  if (sortBy === "name") {
    data = data.sort((childA, childB) => {
      return childA.lastName.localeCompare(childB.lastName);
    });
  } else if (sortBy === "age") {
    data = data.sort((childA, childB) => childA.age - childB.age);
  } else if (sortBy === "medicals") {
    data = data.sort((childA, childB) => childA.medicalInfo.weight - childB.medicalInfo.weight);
  } else if (sortBy === "room") {
    data = data.sort((childA, childB) => (childA.room && childB.room ? childA.room.localeCompare(childB.room) : -1));
  }
  if (sortDirection === "desc") return data.reverse();
  return data;
};

export const sortNameDays = (sortBy, sortDirection, records) => {
  let data = records;
  if (sortBy === "name") {
    data = data.sort((childA, childB) => {
      return childA.child.lastName.localeCompare(childB.child.lastName);
    });
  } else if (sortBy === "nameDay") {
    data = data.sort((childA, childB) => new Date(childA.nameday) - new Date(childB.nameday));
  }
  if (sortDirection === "desc") return data.reverse();
  return data;
};

export const sortBirthDays = (sortBy, sortDirection, records) => {
  let data = records;
  if (sortBy === "name") {
    data = data.sort((childA, childB) => {
      return childA.child.lastName.localeCompare(childB.child.lastName);
    });
  } else if (sortBy === "birthDay") {
    data = data.sort(
      (childA, childB) =>
        new Date(childA.birthDate).setFullYear(new Date().getFullYear()) -
        new Date(childB.birthDate).setFullYear(new Date().getFullYear())
    );
  }
  if (sortDirection === "desc") return data.reverse();
  return data;
};

export const sortEarlyLeavings = (sortBy, sortDirection, records) => {
  let data = records;
  if (sortBy === "name") {
    data = data.sort((childA, childB) => {
      return childA.child.lastName.localeCompare(childB.child.lastName);
    });
  } else if (sortBy === "leaving_date") {
    data = records.sort((leavingA, leavingB) => new Date(leavingA.leavingDate) - new Date(leavingB.leavingDate));
  } else if (sortBy === "hasLeft") {
    data = records.sort((leavingA, leavingB) =>
      (leavingA.hasLeft ? "A" : "Z").localeCompare(leavingB.hasLeft ? "A" : "Z")
    );
  }
  if (sortDirection === "desc") return data.reverse();
  return data;
};

export const sortTeams = (sortBy, sortDirection, records) => {
  let data;
  data = records.sort((teamA, teamB) => teamA.number - teamB.number);
  if (sortBy === "name") {
    data = records.sort((teamA, teamB) => teamA.number - teamB.number);
  } else if (sortBy === "childrenCount") {
    data = records.sort((teamA, teamB) => teamA.childrenCount - teamB.childrenCount);
  } else if (sortBy === "leader") {
    data = records.sort((teamA, teamB) => {
      const aLeader = teamA.leader ? teamA.leader.lastName : "zzzzz";
      const bLeader = teamB.leader ? teamB.leader.lastName : "zzzzz";
      return aLeader.localeCompare(bLeader);
    });
  }
  if (sortDirection === "desc") return data.reverse();
  return data;
};

export const sortTickets = (sortBy, sortDirection, records) => {
  // TODO: chceme vubec razeni u požadavků?
  // let data;
  // data = records.sort((a, b) => a.id - b.id);
  // if (sortBy === 'child') {
  //   data = records.sort((teamA, teamB) => teamA.number - teamB.number);
  // } else if (sortBy === 'date_add') {
  //   data = records.sort((teamA, teamB) => teamA.childrenCount - teamB.childrenCount);
  // } else if (sortBy === 'leader') {
  //   data = records.sort((teamA, teamB) => {
  //     const aLeader = teamA.leader ? teamA.leader.lastName : 'zzzzz';
  //     const bLeader = teamB.leader ? teamB.leader.lastName : 'zzzzz';
  //     return aLeader.localeCompare(bLeader);
  //   });
  // }
  // if (sortDirection === 'desc') return data.reverse();
  // return data;
};

export const sortRoomIssues = (sortBy, sortDirection, records) => {
  let data;
  data = records.sort((teamA, teamB) => new Date(teamA.createdAt) - new Date(teamB.createdAt));
  if (sortBy === "room") {
    data = records.sort((roomA, roomB) => roomA.room.fullName.localeCompare(roomB.room.fullName));
  } else if (sortBy === "createdAt") {
    data = records.sort((teamA, teamB) => new Date(teamA.createdAt) - new Date(teamB.createdAt));
  } else if (sortBy === "creator") {
    data = records.sort((iA, iB) => {
      const aLeader = iA.createdBy ? iA.createdBy.lastName : "zzzzz";
      const bLeader = iB.createdBy ? iB.createdBy.lastName : "zzzzz";
      return aLeader.localeCompare(bLeader);
    });
  }
  if (sortDirection === "desc") return data.reverse();
  return data;
};

export const sortDocuments = (sortBy, sortDirection, records) => {
  let data;
  data = records;
  if (sortBy === "name") {
    data = records.sort((docA, docB) => docA.lastName.localeCompare(docB.lastName));
  } else if (sortBy === "age") {
    data = records.sort((docA, docB) => docA.age - docB.age);
  } else {
    data = records.sort((docA, docB) => {
      const aDoc = docA.documents[sortBy] ? docA.documents[sortBy].status : "zzzzz";
      const bDoc = docB.documents[sortBy] ? docB.documents[sortBy].status : "zzzzz";
      return aDoc.localeCompare(bDoc);
    });
  }
  if (sortDirection === "desc") return data.reverse();
  return data;
};

export const sortPoints = (sortBy, sortDirection, records) => {
  let data = records;
  if (sortBy === "count") {
    if (records[0]?.pointsTotal) {
      data = records.sort((docA, docB) => docB.pointsTotal - docA.pointsTotal);
    } else {
      data = records.sort((docA, docB) => docA.room.pointsTotal - docB.room.pointsTotal);
    }
  } else if (sortBy === "name") {
    data = data.sort((childA, childB) => {
      return childA.lastName.localeCompare(childB.lastName);
    });
  } else if (sortBy === "room") {
    data = data.sort((childA, childB) => (childA.room && childB.room ? childA.room.localeCompare(childB.room) : -1));
  } else if (sortBy === "team") {
    data = data.sort((childA, childB) => {
      const teamA = childA.team ? childA.team.slice(2) : "zzzzz";
      const teamB = childB.team ? childB.team.slice(2) : "zzzzz";
      return teamA.localeCompare(teamB);
    });
  }
  if (sortDirection === "desc") return data.reverse();
  return data;
};

export const sortStaff = (sortBy, sortDirection, records) => {
  let data = records;
  if (sortBy === "name") {
    data = records.sort((personA, personB) => personA.lastName.localeCompare(personB.lastName));
  } else if (sortBy === "staff") {
    data = records.sort((personA, personB) => personA.user.lastName.localeCompare(personB.user.lastName));
  } else if (sortBy === "age") {
    data = records.sort((personA, personB) => personA.age - personB.age);
  } else if (sortBy === "position") {
    data = records.sort((personA, personB) => personA.position.localeCompare(personB.position));
  }
  if (sortDirection === "desc") return data.reverse();
  return data;
};
