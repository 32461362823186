import { Button, Center, Stack, Title, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { useEffect } from "react";
import { useFetch } from "../../helpers/useFetch";

export const MaintenancePage = () => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const data = await useFetch("/");

      if (data.status === "ok") {
        document.location.href = "/";
        clearInterval(intervalId); // stop the interval when condition is met
      }
    }, 5000);

    return () => clearInterval(intervalId);
  });

  return (
    <Center className="h-screen">
      <Stack justify="center" align="center" gap="sm">
        {/*<img className="mb-2 rounded-lg" width={400} src={errorImage} alt="spink" />*/}

        <Title
          className="font-headline"
          order={1}
          color={colorScheme === "dark" ? theme.colors[theme.primaryColor][4] : theme.colors[theme.primaryColor][7]}>
          Probíhá údržba systému
        </Title>

        <div className="max-w-5xl">
          <p className="mb-3">
            Momentálně probíhá plánovaná údržba našeho systému, která je nezbytná pro zajištění jeho optimálního výkonu
            a bezproblémového fungování. Během této doby může dojít k dočasné nedostupnosti některých funkcí nebo
            omezenému přístupu k určitým částem systému.
          </p>
          <p className="mb-3">
            Omlouváme se za jakékoliv nepříjemnosti, které by vám tato údržba mohla způsobit. Snažíme se co nejrychleji
            dokončit veškeré potřebné úpravy a aktualizace, abychom vám mohli opět poskytovat plný rozsah našich služeb.
          </p>
          <p>
            Děkujeme za vaši trpělivost a pochopení. Jsme si vědomi důležitosti naší platformy pro vás a jsme odhodláni
            vám poskytovat nejlepší možný uživatelský zážitek.
          </p>
        </div>

        <Stack align="center" gap="xs">
          <Button w="fit-content" onClick={() => (document.location.href = "/")}>
            Zpět na hlavní stránku
          </Button>
        </Stack>
      </Stack>
    </Center>
  );
};
