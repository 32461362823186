import { useEffect, useRef, useState } from "react";
import { Alert, Center, Loader, Rating, Text } from "@mantine/core";
import "dayjs/locale/cs";
import { useUser } from "../../providers/UserProvider.jsx";
import { useRevalidator } from "react-router-dom";
import dayjs from "dayjs";
import { useFetch } from "../../helpers/useFetch.js";
import { toast } from "react-hot-toast";
import { IconAlertCircle } from "@tabler/icons-react";
import classNames from "classnames";
import { getMode } from "../../helpers/helpers.js";

export default function RoomRateModal({ context, id, innerProps }) {
  const [points, setPoints] = useState(innerProps?.room?.points?.[innerProps?.day]?.points + 1);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState(null);
  const { user } = useUser();
  const revalidator = useRevalidator();
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    sendPoints();
  }, [points]);

  function sendPoints() {
    if (points !== 0 && !loading) {
      setLoading(true);
      const room = innerProps?.room;
      const day = innerProps?.day;
      const realPoints = points - 1;

      useFetch(`accommodation/rooms/points/${room.id}`, "POST", { day: day, points: realPoints }).then((res) => {
        if (res.status === "ok") {
          setLoading(false);
          toast.success(`Pokoj ${room.name} byl ohodnocen ${realPoints} body.`);
          context.closeModal(id);
          revalidator.revalidate();
        } else {
          console.error(res);
          setLoading(false);
          setError(res.message);
        }
      });
    }
  }

  const { mode } = getMode();

  const getEmptyIcon = (points) => {
    const defaultClasses =
      "w-12 mx-1 h-12 rounded-full flex justify-center items-center text-3xl font-bold" +
      (mode === "dark" ? " bg-dark-300 text-dark-700" : " bg-gray-700 text-gray-200");

    switch (points) {
      case 1:
        return <p className={classNames(defaultClasses)}>0</p>;
      case 2:
        return <p className={classNames(defaultClasses)}>1</p>;
      case 3:
        return <p className={classNames(defaultClasses)}>2</p>;
      case 4:
        return <p className={classNames(defaultClasses)}>3</p>;
      case 5:
        return <p className={classNames(defaultClasses)}>4</p>;
      case 6:
        return <p className={classNames(defaultClasses)}>5</p>;
      default:
        return;
    }
  };

  const getFullIcon = (points) => {
    const defaultClasses = "w-12 mx-1 h-12 rounded-full flex justify-center items-center text-3xl font-bold";

    switch (points) {
      case 1:
        return <p className={classNames(defaultClasses, "bg-red-50/25 text-red-50")}>0</p>;
      case 2:
        return <p className={classNames(defaultClasses, "bg-orange-100/25 text-orange-100")}>1</p>;
      case 3:
        return <p className={classNames(defaultClasses, "bg-yellow-200/25 text-yellow-200")}>2</p>;
      case 4:
        return <p className={classNames(defaultClasses, "bg-lime-200/25 text-lime-200")}>3</p>;
      case 5:
        return <p className={classNames(defaultClasses, "bg-green-100/25 text-green-100")}>4</p>;
      case 6:
        return <p className={classNames(defaultClasses, "bg-green-50/25 text-green-50")}>5</p>;
      default:
        return;
    }
  };

  return (
    <>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při uložení bodování nastala chyba."
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      {fetching ? (
        <div className="flex h-full w-full flex-col items-center justify-center py-8">
          <Loader />
        </div>
      ) : (
        <>
          <Text c="dimmed">Klikni na číslo pro uložení hodnocení pokoje.</Text>
          <Center>
            <Rating
              value={points}
              onChange={setPoints}
              mt="md"
              emptySymbol={getEmptyIcon}
              fullSymbol={getFullIcon}
              count={6}
              highlightSelectedOnly
              readOnly={loading}
            />
          </Center>

          {/*
            <Button disabled={loading} fullWidth type="submit" loading={loading} mt="sm">
              Přehled škod
            </Button>

            <Group gap="sm" mt="sm" grow={1} className="w-full">
              <Button disabled={loading} variant="light" loading={loading}>
                Předchozí pokoj
              </Button>
              <Button disabled={loading} variant="light" loading={loading}>
                Další pokoj
              </Button>
            </Group>
            */}
        </>
      )}
    </>
  );
}
