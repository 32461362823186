import { Loader, Modal, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useUser } from "../../providers/UserProvider";

function audio(url) {
  const audio = new Audio(url);
  audio.play();
}

export default function LukPanekJoke({ opened, setOpened }) {
  const [loading, setLoading] = useState(true);
  const [joke, setJoke] = useState(null);
  const { user } = useUser();

  useEffect(() => {
    setLoading(true);
    if (opened) {
      fetch(`https://lpvtip.dev.dobes.pw/api.php?user=${user.id}`)
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          setJoke(res.text);
          audio(res.file);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [opened]);

  return (
    <Modal opened={opened} radius="lg" padding="xl" onClose={() => setOpened(false)} withCloseButton={false}>
      {loading ? (
        <Loader />
      ) : (
        <Text className="leading-tight" fw="bold" size="2rem" align="center">
          {joke}
        </Text>
      )}
    </Modal>
  );
}
