import { useMantineTheme } from "@mantine/core";
import React from "react";

export default function LogoSymbol({ height }) {
  const theme = useMantineTheme();

  return (
    <svg
      width={height}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      className="shrink-0"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H36C42.6274 0 48 5.37258 48 12V36C48 42.6274 42.6274 48 36 48H12C5.37258 48 0 42.6274 0 36V12Z"
        fill={theme.colors[theme.primaryColor][6]}
      />
      <g clipPath="url(#clip0_1945_4247)">
        <g clipPath="url(#clip1_1945_4247)">
          <path
            d="M13.5 15.5L21 23L13.5 30.5M24 33.5H34.5"
            stroke="#ECEEF2"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1945_4247">
          <rect width="36" height="36" fill="white" transform="translate(6 4.5)" />
        </clipPath>
        <clipPath id="clip1_1945_4247">
          <rect width="36" height="36" fill="white" transform="translate(6 4.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
