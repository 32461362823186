import { openContextModal } from "@mantine/modals";
import {
  IconBed,
  IconBox,
  IconBrush,
  IconBuildingWarehouse,
  IconBus,
  IconCalendarEvent,
  IconCashOff,
  IconCategory,
  IconDeviceGamepad,
  IconDoorExit,
  IconEdit,
  IconEditCircle,
  IconFilePlus,
  IconFileText,
  IconHome,
  IconMessagePlus,
  IconNote,
  IconNotebook,
  IconPencil,
  IconPill,
  IconPlus,
  IconSignature,
  IconStar,
  IconSwitchHorizontal,
  IconTool,
  IconTransfer,
  IconTrash,
  IconUserStar,
} from "@tabler/icons-react";
import { modalTitle } from "../components/modals/Modal.jsx";
import { parseFullName } from "./helpers.js";
import { IconCameraUp } from "@tabler/icons-react";
import { IconCopy } from "@tabler/icons-react";

export function openTicketModal(title, child, ticket, smallTitle) {
  openContextModal({
    modal: "ticket",
    title: modalTitle(IconNote, title, smallTitle),
    innerProps: {
      child,
      ticket,
    },
  });
}

export function openHistoricalNoteModal(title, child, smallTitle) {
  openContextModal({
    modal: "historicalNote",
    title: modalTitle(IconNote, title, smallTitle),
    innerProps: {
      child,
    },
  });
}

export function openEarlyLeaveModal(title, child, earlyLeaving, smallTitle, setShouldFetch) {
  openContextModal({
    modal: "earlyLeave",
    title: modalTitle(IconDoorExit, title, smallTitle),
    innerProps: {
      child,
      earlyLeaving,
      setShouldFetch,
    },
  });
}

export function openSessionSelectModal(title) {
  openContextModal({
    modal: "changeSession",
    title: modalTitle(IconCalendarEvent, title),
    innerProps: {},
  });
}

export function openAreaSelectModal(title) {
  openContextModal({
    modal: "changeArea",
    title: modalTitle(IconHome, title),
    innerProps: {},
  });
}

export function openTeamEditModal(title, team, teams, staff, setShouldFetch) {
  openContextModal({
    modal: "teamEdit",
    title: modalTitle(IconEdit, title),
    innerProps: {
      team,
      teams,
      staff,
    },
  });
}

export function openChildTransferModal(title, child, smallTitle, setShouldFetch) {
  openContextModal({
    modal: "childTransfer",
    title: modalTitle(IconSwitchHorizontal, title, smallTitle),
    innerProps: {
      child,
      setShouldFetch,
    },
  });
}

export function openRoomEditModal(title, room, smallTitle) {
  openContextModal({
    modal: "roomEdit",
    title: modalTitle(IconBed, title, smallTitle),
    innerProps: {
      room,
    },
  });
}

export function openRoomIssueModal(title, room, smallTitle) {
  openContextModal({
    modal: "roomIssue",
    title: modalTitle(IconTool, title, smallTitle),
    innerProps: {
      room,
    },
  });
}

export function openRoomRateModal(title, room, day) {
  openContextModal({
    modal: "roomRate",
    title: modalTitle(IconStar, title),
    innerProps: {
      room,
      day,
    },
  });
}

export function openNewMedicationModal(title, child, smallTitle, medications, setMedications, setShouldFetch) {
  openContextModal({
    modal: "newMedication",
    title: modalTitle(IconPill, title, smallTitle),
    innerProps: {
      child,
      medications,
      setMedications,
      setShouldFetch,
    },
  });
}

export function openMedicationTakeModal(title, child, dose, date) {
  openContextModal({
    modal: "medicationTake",
    title: modalTitle(IconPill, title),
    description: "Zaznamenej užití pravidelného léku",
    innerProps: {
      child,
      period: dose,
      date,
    },
  });
}

export function openMedicationEditModal(title, medications, child, selectedMedication, setMedications) {
  openContextModal({
    modal: "medicationEdit",
    title: modalTitle(IconPill, title),
    innerProps: {
      medications,
      child,
      selectedMed: selectedMedication,
      setMedications,
    },
  });
}

export function openFileSelectModal(
  title,
  document,
  openSignature,
  handoverDocument,
  childId,
  documents,
  setDocuments,
  setShouldFetch
) {
  openContextModal({
    modal: "fileSelect",
    title: modalTitle(IconFileText, title),
    innerProps: {
      document,
      openSignature,
      handoverDocument,
      childId,
      documents,
      setDocuments,
      setShouldFetch,
    },
  });
}

export function openMoneyRemoveModal(title, transaction, childId, deposits, setDeposits, category) {
  openContextModal({
    modal: "moneyRemove",
    title: modalTitle(IconCashOff, title),
    innerProps: {
      transaction,
      deposits,
      setDeposits,
      childId,
      category,
    },
  });
}

export function openMoneyChangeModal(title, transaction, child, currentMoney, refreshData, category) {
  openContextModal({
    modal: "moneyChange",
    title: modalTitle(IconCashOff, title),
    innerProps: {
      transaction,
      currentMoney,
      refreshData,
      child,
      category,
    },
  });
}

export function openAppearanceModal() {
  openContextModal({
    modal: "appearance",
    title: modalTitle(IconBrush, "Úprava vzhledu Panelu"),
  });
}

export function openTransportBuyModal(childId) {
  openContextModal({
    modal: "transportBuy",
    title: modalTitle(IconBus, "Změna dopravy"),
    innerProps: {
      childId,
    },
  });
}

export function openUserSignatureUpdateModal() {
  openContextModal({
    modal: "userSignatureUpdate",
    title: modalTitle(IconSignature, "Nastavení podpisu"),
  });
}

export function openCreateRoomModal() {
  openContextModal({
    modal: "createRoom",
    title: modalTitle(IconHome, "Vytvoření místnosti"),
  });
}

export function openFormStartModal(computer) {
  openContextModal({
    modal: "formStart",
    title: modalTitle(IconEditCircle, "Spuštění dotazníku"),
    innerProps: {
      computer,
    },
  });
}

export function openNewPcMinecraftAdminWhitelistModal(user) {
  openContextModal({
    modal: "newPcMinecraftAdminWhitelist",
    title: modalTitle(IconUserStar, "Přidat Minecraft admina"),
    innerProps: {
      user,
    },
  });
}

export function openMcNicknameModal(child, setShouldFetch) {
  openContextModal({
    modal: "mcNickname",
    title: modalTitle(IconNote, "Změna Minecraft nicku", parseFullName(child)),
    innerProps: {
      child,
      setShouldFetch,
    },
  });
}

export function openPcNoteModal(child, setShouldFetch) {
  openContextModal({
    modal: "pcNote",
    title: modalTitle(IconNotebook, "Počítačová poznámka", parseFullName(child)),
    innerProps: {
      child,
      setShouldFetch,
    },
  });
}

export function openNewArticleModal(setShouldFetch, sellData) {
  openContextModal({
    modal: "newArticle",
    title: modalTitle(IconBox, "Nový artikl"),
    innerProps: {
      setShouldFetch,
      sellData,
    },
  });
}

export function openEditArticleModal(setShouldFetch, article, sellData) {
  openContextModal({
    modal: "editArticle",
    title: modalTitle(IconEdit, "Editace artiklu", article.name),
    innerProps: {
      setShouldFetch,
      article,
      sellData,
    },
  });
}

export function openMoveArticleModal(setShouldFetch, article, sellData) {
  openContextModal({
    modal: "moveArticle",
    title: modalTitle(IconTransfer, "Pohyb artiklu", article.name),
    innerProps: {
      setShouldFetch,
      article,
      sellData,
    },
  });
}

export function openArticleCategoriesModal(setShouldFetch) {
  openContextModal({
    modal: "articleCategories",
    title: modalTitle(IconCategory, "Kategorie artiklů"),
    innerProps: {
      setShouldFetch,
    },
  });
}

export function openDepotsManageModal(setShouldFetch) {
  openContextModal({
    modal: "depotsManage",
    title: modalTitle(IconBuildingWarehouse, "Skladové místnosti"),
    innerProps: {
      setShouldFetch,
    },
  });
}

export function openFilePhotoModal(child, type, closeParent, setShouldFetch) {
  openContextModal({
    modal: "filePhoto",
    title: modalTitle(IconCameraUp, "Focení dokumentů"),
    innerProps: {
      child,
      type,
      closeParent,
      setShouldFetch,
    },
  });
}

export function openGameGroupModal(gameGroup) {
  openContextModal({
    modal: "gameGroup",
    title: modalTitle(IconDeviceGamepad, "Herní skupiny"),
    innerProps: {
      gameGroup,
    },
  });
}

export function openPcChildAccounts(child, setShouldFetch) {
  openContextModal({
    modal: "pcChildAccount",
    title: modalTitle(IconNotebook, "Herní účty", parseFullName(child)),
    innerProps: {
      child,
      setShouldFetch,
    },
  });
}

export function openDepotHandoverCreateModal(setShouldFetch, article) {
  openContextModal({
    modal: "depotHandoverCreate",
    title: modalTitle(IconFilePlus, "Nový předávací protokol"),
    innerProps: {
      setShouldFetch,
      article,
    },
  });
}

export const openEditQuestModal = (quest, camps, days, setShouldFetch) => {
  openContextModal({
    modal: "pcEditQuest",
    title: modalTitle(IconPencil, "Editace questů"),
    innerProps: {
      quest,
      camps,
      days,
      setShouldFetch,
    },
  });
};

export const openNewQuestModal = (camp, camps, days, setShouldFetch) => {
  openContextModal({
    modal: "pcNewQuest",
    title: modalTitle(IconPlus, "Nový quest"),
    innerProps: {
      camp,
      camps,
      days,
      setShouldFetch,
    },
  });
};

export const openDeleteQuestModal = (quest, setShouldFetch) => {
  console.log(quest);
  openContextModal({
    modal: "pcDeleteQuest",
    title: modalTitle(IconTrash, "Opravdu chcete smazat tento úkol?"),
    innerProps: {
      quest,
      setShouldFetch,
    },
  });
};

export const openFillQuestModal = (quest, team, setShouldFetch, setChanged) => {
  openContextModal({
    modal: "pcFillQuest",
    title: modalTitle(IconPencil, "Vyplnění úkolu"),
    innerProps: {
      quest,
      team,
      setShouldFetch,
      setChanged,
    },
  });
};

export const openCopyLastWeekQuestsModal = (setShouldFetch) => {
  openContextModal({
    modal: "pcCopyLastWeekQuests",
    title: modalTitle(IconCopy, "Kopírování úkolů z minulého týdne"),
    innerProps: {
      setShouldFetch,
    },
  });
};

export function openAddPcChildAccount(child, gameAccount) {
  openContextModal({
    modal: "addPcChildAccount",
    title: modalTitle(IconNotebook, "Herní účty", parseFullName(child)),
    innerProps: {
      child,
      gameAccount,
    },
  });
}

export const openDiscordScheduledMessageModal = (setShouldFetch, message) => {
  openContextModal({
    modal: "discordScheduledMessageModal",
    title: modalTitle(
      message ? IconEdit : IconMessagePlus,
      message ? "Editace naplánované zprávy" : "Vytvoření naplánované zprávy"
    ),
    innerProps: {
      setShouldFetch,
      message,
    },
  });
};
