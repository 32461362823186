import { Button, Group, Stack } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import useTitle from "../../hooks/useTitle.js";
import LogoSymbol from "../../components/LogoSymbol.jsx";
import logoText from "../../assets/logo-text.svg";
import successImage from "../../assets/success.webp";

export default function RegistrationSuccess() {
  useTitle(`Účet ověřen`);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <Stack gap={0} className="mt-4 max-h-screen max-w-[900px] items-center" p="md">
        <Group wrap="nowrap" gap="sm" className="mb-6 flex h-16 px-4" align="center">
          <LogoSymbol height={48} />
          <img src={logoText} height={48} alt="Logo" />
        </Group>
        <div className="text-center">
          <h1>Účet ověřen!</h1>
          <p className="mb-4 mt-2">Tvůj e-mail byl úspěšně ověřen. Nyní se můžeš přihlásit do aplikace.</p>
          <Button component={Link} to="/prihlaseni" mt="xl">
            Přejít na přihlášení
          </Button>
        </div>
      </Stack>
    </div>
  );
}
