import { Button, Group, Paper, Text, useMantineTheme } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { useEffect, useState, useRef } from "react";
import man from "../../assets/medical/man.svg";
import woman from "../../assets/medical/woman.svg";
import { getMode } from "../../helpers/helpers";
import classNames from "classnames";

export default function Figure({ gender, points, setPoints, setOpened, opened, handleSubmit }) {
  const [loading, setLoading] = useState(false);
  const [pointsOld, setPointsOld] = useState(null);
  const photoRef = useRef(null);
  const parentDivRef = useRef(null);
  const pointsRef = useRef(null);
  const [photoSize, setPhotoSize] = useState({ width: 0, height: 0 });
  const [gaps, setGaps] = useState({ left: 0, top: 0 });

  useEffect(() => {
    if (opened) {
      setPointsOld(points);
    }
  }, [opened]);

  const updatePoints = () => {
    if (photoRef.current && parentDivRef.current) {
      const { height: photoHeight, width: photoWidth } = photoRef.current.getBoundingClientRect();
      const { height: parentHeight } = parentDivRef.current.getBoundingClientRect();
      const mobilJeJeblej = photoHeight * ratioHtW > photoWidth;
      setPhotoSize({
        width: mobilJeJeblej ? photoWidth : photoHeight * ratioHtW,
        height: mobilJeJeblej ? photoWidth * ratioWtH : photoHeight,
      });
      const topGap = parentHeight - photoWidth * ratioWtH > 0 ? parentHeight - photoWidth * ratioWtH : 0;
      setGaps((prevGaps) => ({ ...prevGaps, top: topGap }));
    }
  };

  useEffect(() => {
    if (photoRef.current && pointsRef.current) {
      const { width: pointsWidth } = pointsRef.current.getBoundingClientRect();
      const { width: photoWidth } = photoRef.current.getBoundingClientRect();
      setGaps((prevGaps) => ({ ...prevGaps, left: (photoWidth - pointsWidth) / 2 }));
    }
  }, [photoSize]);

  useEffect(() => {
    updatePoints();

    window.addEventListener("resize", updatePoints);
    window.addEventListener("orientationchange", updatePoints);

    return () => {
      window.removeEventListener("resize", updatePoints);
      window.removeEventListener("orientationchange", updatePoints);
    };
  }, []);

  const ratioWtH = 746 / 476;
  const ratioHtW = 476 / 746;

  const handleClick = (e) => {
    const { x, y, width, height } = e.target.getBoundingClientRect();
    const clickX = e.clientX - x;
    const clickY = e.clientY - y;

    const point = {
      x: clickX / width,
      y: clickY / height,
    };

    setPoints([...points, point]);
  };

  const save = () => {
    if (handleSubmit) {
      handleSubmit(setLoading);
    } else {
      setOpened(false);
    }
  };

  const close = () => {
    setPoints(pointsOld);
    setOpened(false);
  };

  const { mode, theme } = getMode();

  return (
    <>
      {opened && (
        <div
          className={classNames("fixed left-0 top-0 z-[210] flex h-full w-full items-center justify-center", {
            "bg-dark-200": mode === "dark",
            "bg-gray-900": mode === "light",
          })}>
          <div className="relative flex h-full w-full grow items-stretch justify-center p-8">
            <div className="grid w-full grid-rows-figure justify-items-center gap-6">
              <div className="max-w-screen relative h-full" ref={parentDivRef}>
                <div
                  onClick={handleClick}
                  className="absolute"
                  ref={pointsRef}
                  style={{
                    width: photoSize.width,
                    height: photoSize.height,
                    transform: `translateY(${gaps.top / 2}px) translateX(${gaps.left}px)`,
                  }}>
                  {points.map((point, i) => (
                    <div
                      key={i}
                      className="group absolute box-content cursor-pointer select-none overflow-hidden rounded-full border-4 border-solid border-indigo-400/50 transition-colors duration-150 hover:border-red-200/50"
                      style={{
                        top: `${point.y * photoSize.width * ratioWtH}px`,
                        left: `${point.x * photoSize.width}px`,
                        transform: "translate(-50%, -50%)",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setPoints(points.filter((_, index) => index !== i));
                      }}>
                      <div className="flex h-4 w-4 items-center justify-center bg-indigo-400 duration-150 group-hover:h-6 group-hover:w-6 group-hover:bg-red-200 ">
                        <IconX
                          stroke={1.5}
                          size={18}
                          className="scale-0 opacity-0 duration-150 group-hover:scale-100 group-hover:opacity-100"
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <img
                  src={gender === "Chlapec" ? man : woman}
                  className="h-full w-full object-contain"
                  alt="Postavička"
                  ref={photoRef}
                  onLoad={updatePoints}
                />
              </div>
              <Paper
                className={"z-50 mt-auto flex h-fit w-full items-center justify-between text-white-900"}
                py={"md"}
                px={"lg"}
                radius="lg"
                bg={theme.colors[theme.primaryColor][6]}>
                <Text size={"md"} ml="xs">
                  Klikni na postavičku pro určení bodů ošetření.
                </Text>
                <Group>
                  <Button type="reset" variant="subtle" onClick={close} loading={loading}>
                    Zrušit změny
                  </Button>
                  <Button
                    type="submit"
                    variant="filled"
                    color={theme.colors[theme.primaryColor][5]}
                    className="w-fit md:w-[200px]"
                    onClick={save}
                    loading={loading}>
                    Uložit změny
                  </Button>
                </Group>
              </Paper>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
