import { Loader } from "@mantine/core";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useTitle from "../../hooks/useTitle.js";
import { useFetch } from "../../helpers/useFetch.js";

export default function ImpersonationPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [message, setMessage] = useState(null);

  useTitle(`Impersonace`);

  useEffect(() => {
    const impersonate = async () => {
      const token = searchParams.get("token");
      if (token) {
        const res = await useFetch(`/login/impersonate`, "POST", { token });

        if (!res.token) {
          setMessage(res.message);
        } else {
          localStorage.setItem("user", JSON.stringify(res));
          window.location.href = "/";
        }
      }
    };

    impersonate();
  }, []);

  return (
    <div className="w-100 flex h-screen items-center justify-center overflow-hidden">
      {message ? message : <Loader size="xl" />}
    </div>
  );
}
