import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import {
  IconCake,
  IconCalendarEvent,
  IconCloudCheck,
  IconDoorExit,
  IconFile,
  IconNote,
  IconSignature,
  IconX,
  IconNotebook,
  IconCheck,
} from "@tabler/icons-react";
import Table from "./core/Table.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../providers/UserProvider.jsx";
import DocumentViewer from "../boarding/DocumentViewer.jsx";
import { useState } from "react";
import classNames from "classnames";

export default function PcChildrenTable({
  fetching,
  showFilters,
  actionButtons,
  team,
  name,
  shouldFetch,
  setShouldFetch,
  teams,
  serverFetching,
  setServerFetching,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const [viewerOpened, setViewerOpened] = useState(false);
  const [viewingDocument, setViewingDocument] = useState("");

  const handleDocumentView = (document) => {
    setViewingDocument(document);
    setViewerOpened(true);
  };

  const childName = (child) => {
    return (
      <div>
        <strong>
          {child.firstName}&nbsp;{child.lastName} <span className="mobileAge">({child.age})</span>
        </strong>
        {child.pcNote ? (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Počítačová poznámka">
            <Text h={18} span color="pink.6">
              <IconNotebook stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    );
  };

  const childTeam = (child) => {
    return (
      <Text span color={!child?.team?.number && "red"}>
        {child?.team?.number ? child.team.number : "Nepřidělen"}
      </Text>
    );
  };

  const columns = [
    {
      accessor: "ucastnik_prijmeni",
      title: "Jméno",
      disableHiding: true,
      render: (child) => childName(child),
      sortable: true,
    },
    {
      accessor: "oddil",
      title: "Oddíl",
      render: (child) => childTeam(child),
      sortable: true,
    },
    {
      accessor: "ucastnik_date_narozeni",
      title: "Věk",
      sortable: true,
      visibleMediaQuery: (theme) => `(min-width: 600px)`,
      render: (x) => x.age,
    },
    {
      accessor: "discord",
      title: "Discord kód",
      sortable: false,
      render: (x) => (
        <Text className="flex items-center gap-1">
          {x.discord.id ? (
            <IconCheck size={18} stroke={1.5} className="text-teal-300" />
          ) : (
            <IconX size={18} stroke={1.5} className="text-red-300" />
          )}
          {x.discord.code}
        </Text>
      ),
    },
    {
      accessor: "mc_nickname",
      title: "Minecraft nick",
      sortable: true,
      render: (x) => x.mcNickname,
    },
    {
      accessor: "id",
      title: "ID",
      render: (x) => x.id,
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (child) => actionButtons(child),
      narrow: true,
    },
  ];

  const filterConfig = [
    {
      key: "jmeno",
      label: "Jméno",
      type: "search",
    },
    {
      key: "oddil",
      label: "Oddíl",
      type: "select",
      options: teams?.map((team) => {
        return { value: String(team.id), label: `${team.name} ${team.number}` };
      }),
    },
  ];

  return (
    <>
      <Table
        id="pc-deti-prehled-v3"
        columns={columns}
        useServer={true}
        serverUrl={"/pc/children-list"}
        shouldServerFetch={shouldFetch}
        setShouldServerFetch={setShouldFetch}
        fetching={fetching}
        filtersParent={{
          oddil: team ? team : "",
        }}
        filterConfig={filterConfig}
        serverFetching={serverFetching}
        setServerFetching={setServerFetching}
      />
      {viewerOpened && <DocumentViewer document={viewingDocument} setOpened={setViewerOpened} />}
    </>
  );
}
