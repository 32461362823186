import { ActionIcon, Badge, Group, Paper, Text, Tooltip } from "@mantine/core";
import {
  IconApple,
  IconArrowBackUp,
  IconBreadOff,
  IconCake,
  IconCalendarEvent,
  IconCalendarTime,
  IconCheck,
  IconChevronDown,
  IconClock,
  IconDoor,
  IconDoorExit,
  IconEdit,
  IconHeartbeat,
  IconId,
  IconMessageShare,
  IconNote,
  IconShirt,
  IconSwimming,
  IconUser,
  IconVaccineBottle,
} from "@tabler/icons-react";
import classNames from "classnames";
import dayjs from "dayjs";
import { useState } from "react";
import styles from "./TicketsMobile.module.scss";
import { Link } from "react-router-dom";
import { openTicketModal } from "../../../helpers/modals.js";

export default function TicketsMobile({ records, openTicketEditModal, toggleTicketStatus }) {
  const list = records.map((record) => (
    <TicketsMobileRecord
      key={record.id}
      record={record}
      openTicketEditModal={openTicketEditModal}
      toggleTicketStatus={toggleTicketStatus}
    />
  ));

  return <div>{list}</div>;
}

function TicketsMobileRecord({ record, openTicketEditModal, toggleTicketStatus }) {
  const [opened, setOpened] = useState(false);

  return (
    <Paper pb="sm" radius="md" pt="xs" mb="sm" px="xs" onClick={() => setOpened(!opened)} key={record.id} withBorder>
      <div className="flex justify-between">
        <strong>
          <Text span fw="bold" color={record.closedAt ? "dimmed" : "white"}>
            {record.child ? record.child.firstName + " " + record.child.lastName : "–"}
          </Text>
        </strong>
        <Group wrap="nowrap" gap="xs" position={"right"}>
          {record?.child ? (
            <Link to={"/deti/" + record.child?.id}>
              <ActionIcon variant="light">
                <IconId stroke={1.5} size={18} />
              </ActionIcon>
            </Link>
          ) : (
            ""
          )}

          <ActionIcon
            variant="light"
            onClick={() => setOpened(!opened)}
            /* className={classNames(styles.Expander, openChildId === child.id ? styles.Expanded : "")} */
          >
            <IconChevronDown
              className={classNames(
                { [`${styles.MyTeamMobileChildChevronOpened}`]: opened },
                styles.MyTeamMobileChildChevron
              )}
              size={18}
              stroke={1.5}
            />
          </ActionIcon>
        </Group>
      </div>
      <div className="mt-1 flex justify-between">
        <Group gap={2} align="center">
          <IconClock stroke={1.5} size={18} color={record.closedAt ? "gray" : "white"} />{" "}
          <Text size="sm" color={record.closedAt ? "dimmed" : "white"}>
            {record.createdAt}
          </Text>
        </Group>
        <Group gap="xs" align="center">
          <Text span size="sm" color={record.closedAt ? "dimmed" : "white"}>
            {record.closedAt
              ? `${record.closedAt} (${record.resolver.firstName} ${record.resolver.lastName})`
              : "Čeká na vyřešení"}
          </Text>{" "}
        </Group>
      </div>

      <div className={classNames({ [`${styles.MyTeamMobileChildOpened}`]: opened }, styles.MyTeamMobileChildAccordion)}>
        <div className="mt-3 border-0 border-t-2 border-solid border-dark-300 pt-3">{record.text}</div>
        <div className="mt-3 flex justify-end gap-x-2 border-0 border-t-2 border-solid border-dark-300 pt-3">
          <ActionIcon
            variant="light"
            onClick={(e) => {
              e.stopPropagation();
              openTicketModal("Úprava požadavku", null, record);
            }}>
            <IconEdit stroke={1.5} size={18} />
          </ActionIcon>
          <ActionIcon
            variant="light"
            color={record.closedAt === null ? "teal" : "red"}
            onClick={(e) => {
              e.stopPropagation();
              toggleTicketStatus(record);
            }}>
            {record.closedAt === null ? (
              <IconCheck stroke={1.5} size={18} />
            ) : (
              <IconArrowBackUp stroke={1.5} size={18} />
            )}
          </ActionIcon>
        </div>
      </div>
    </Paper>
  );
}
