const dosage = [
  {
    type: "s_m",
    text: "S-",
  },
  {
    type: "s_p",
    text: "S+",
  },
  {
    type: "o_m",
    text: "O-",
  },
  {
    type: "o_p",
    text: "O+",
  },
  {
    type: "v_m",
    text: "V-",
  },
  {
    type: "v_p",
    text: "V+",
  },
  {
    type: "pp",
    text: "PP",
  },
];

const shortDosage = [
  {
    type: "s_m",
    text: "S-",
  },
  {
    type: "s_p",
    text: "S+",
  },
  {
    type: "o_m",
    text: "O-",
  },
  {
    type: "o_p",
    text: "O+",
  },
  {
    type: "v_m",
    text: "V-",
  },
  {
    type: "v_p",
    text: "V+",
  },
  {
    type: "pp",
    text: "PP",
  },
];

export { dosage as dosageAllOptions };
export { shortDosage as dosageOptions };
