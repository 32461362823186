import { ActionIcon, Alert, Checkbox, Group, Input, Paper, Select, Text, Textarea, Tooltip } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import "dayjs/locale/cs";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { useFetch } from "../../helpers/useFetch.js";
import { useLoaderData, useNavigate, useRevalidator } from "react-router-dom";
import useTitle from "../../hooks/useTitle.js";
import Page from "../../components/layout/Page.jsx";
import { getMode, parseFullName } from "../../helpers/helpers.js";
import {
  IconAlertCircle,
  IconCalendar,
  IconDeviceFloppy,
  IconEdit,
  IconId,
  IconPill,
  IconPlus,
  IconReportMedical,
  IconTrash,
} from "@tabler/icons-react";
import NewMedicalReportDrawer from "../../components/medical/NewMedicalReportDrawer.jsx";
import MedicalReportDetailDrawer from "../../components/medical/MedicalReportDetailDrawer.jsx";
import { openMedicationEditModal, openNewMedicationModal } from "../../helpers/modals.js";
import { openConfirmModal } from "@mantine/modals";
import { modalTitle } from "../../components/modals/Modal.jsx";
import SaveChangesBar from "../../components/SaveChangesBar.jsx";
import SavingChangesBar from "../../components/SavingChangesBar.jsx";
import classNames from "classnames";

export function loader({ params }) {
  return Promise.all([
    useFetch("/children/" + params.childId),
    useFetch("/teams"),
    useFetch("/accommodation/free-rooms"),
  ]);
}

const prepareFreeRooms = (freeRooms, child) => {
  const roomsTmp = freeRooms.map((room) => {
    return { value: String(room.id), label: room.fullName };
  });
  if (!roomsTmp.find((room) => room.value === String(child?.room?.id)) && child?.room?.id) {
    roomsTmp.unshift({ value: String(child.room.id), label: child.room.name });
  }
  return roomsTmp;
};

export default function ChildEditPage() {
  const data = useLoaderData();
  const child = data[0].data;
  const teams = data[1].data;
  const freeRooms = data[2].data;

  const [medications, setMedications] = useState(child.medicalMedications);
  const [medicationList, setMedicationList] = useState([]);
  const [newReport, setNewReport] = useState(false);
  const [reportDetail, setReportDetail] = useState(null);
  const [reports, setReports] = useState(child.medicalRecords);
  const [reportsLoading, setReportsLoading] = useState(false);
  const [medicationLoading, setMedicationLoading] = useState(false);
  useTitle(`${child.firstName} ${child.lastName} – Editace karty dítěte`);
  const navigation = useNavigate();
  const [error, setError] = useState(null);
  const revalidator = useRevalidator();

  const { theme, mode } = getMode();

  const [date, setDate] = useState(new Date(child.birthDate));
  const [loading, setLoading] = useState(false);
  const genders = [
    { value: "Chlapec", label: "Chlapec" },
    { value: "Dívka", label: "Dívka" },
  ];
  const countries = [
    { value: "Česká Republika", label: "Česká republika" },
    {
      value: "Slovenská Republika",
      label: "Slovenská republika",
    },
  ];
  const tShirts = [
    {
      value: "S",
      label: "S",
    },
    {
      value: "M",
      label: "M",
    },
    {
      value: "L",
      label: "L",
    },
    {
      value: "XL",
      label: "XL",
    },
  ];

  const teamsList = teams.map((team) => {
    return { value: String(team.id), label: team.name + " #" + team.number };
  });

  const freeRoomsList = prepareFreeRooms(freeRooms, child);

  const form = useForm({
    initialValues: {
      ucastnik_jmeno: child.firstName,
      ucastnik_prijmeni: child.lastName,
      ucastnik_date_narozeni: child.birthDate,
      ucastnik_tricko: child.tShirtSize,
      ucastnik_pohlavi: child.gender,
      permanent_note: child.permanentNote,
      telefon: child.tel1,
      telefon2: child.tel2,
      email: child.emailParent,
      ucastnik_email: child.emailChild,
      ulice: child.address.street,
      mesto: child.address.city,
      psc: child.address.zip,
      stat: child.address.country,
      team_id: String(child?.team?.id),
      room_id: String(child?.room?.id),
    },
  });

  const medicalForm = useForm({
    initialValues: {
      medical_restrictions: child?.medicalInfo?.medical_restrictions,
      is_medical_restrictions_important: child?.medicalInfo?.is_medical_restrictions_important,
      allergies: child?.medicalInfo?.allergies,
      is_allergies_important: child?.medicalInfo?.is_allergies_important,
      dietary_restrictions: child?.medicalInfo?.dietary_restrictions,
      is_dietary_restrictions_important: child.medicalInfo.is_dietary_restrictions_important,
      can_swim: String(child.canSwim),
      has_diet: child.medicalInfo.has_diet,
    },
  });

  let dosages = {
    s_m: "S-",
    s_p: "S+",
    o_m: "O-",
    o_p: "O+",
    v_m: "V-",
    v_p: "V+",
    pp: "PP",
  };

  const handleReportRemove = async (id) => {
    setReportsLoading(true);
    openConfirmModal({
      title: modalTitle(IconTrash, "Smazat záznam ošetření"),
      children: <Text size="sm">Opravdu chceš smazat tento záznam?</Text>,
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
      onCancel: () => setReportsLoading(false),
      onConfirm: () => {
        toast.promise(useFetch("/medical/records/" + id, "DELETE"), {
          loading: "Mažu záznam...",
          success: (res) => {
            setReportsLoading(false);
            setReports(reports.filter((report) => report.id !== id));
            return <p>Záznam smazán</p>;
          },
          error: <p>Nastala chyba při mazání záznamu a byla nahlášena.</p>,
        });
      },
      onClose: () => setReportsLoading(false),
    });
  };

  const handleMedicationRemove = async (id, name) => {
    setMedicationLoading(true);
    openConfirmModal({
      title: modalTitle(IconTrash, "Smazat pravidelný lék"),
      children: <Text size="sm">Opravdu chceš smazat lék {name}?</Text>,
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
      onCancel: () => setMedicationLoading(false),
      onConfirm: () => {
        toast.promise(useFetch("/medical/child-medications/" + id, "DELETE"), {
          loading: "Odstraňuji lék...",
          success: (res) => {
            setMedications(medications.filter((med) => med.id !== id));
            setMedicationLoading(false);
            return <p>Lék odstraněn</p>;
          },
          error: <p>Nastala chyba při odstranění léku a byla nahlášena.</p>,
        });
      },
      onClose: () => setMedicationLoading(false),
    });
  };

  useEffect(() => {
    const medicationFunction = async () => {
      let medicationLocal = [];
      await medications.forEach(async (med) => {
        let medicationDosages = "";
        await Object.keys(dosages).forEach((key) => {
          if (key.includes("_")) {
            if (med[key].amount !== null) {
              medicationDosages = `${medicationDosages}${dosages[key]} ${med[key].amount}x, `;
            }
          }
          if (key === "pp") {
            if (med[key].amount !== null) {
              medicationDosages = `${medicationDosages}${dosages[key]} ${med[key].amount}x`;
            }
          }
        });
        medicationLocal.push({
          name: med.name,
          dosage: medicationDosages.replace(/,\s*$/, ""),
          id: med.id,
        });
      });
      setMedicationList(medicationLocal);
    };
    medicationFunction();
  }, [medications]);

  const resetForm = () => {
    form.reset();
    medicalForm.reset();
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    values.ucastnik_date_narozeni = dayjs(date).format("YYYY-MM-DD");
    values.team_id = values.team_id === "undefined" ? null : values.team_id;
    values.room_id = values.room_id === "undefined" ? null : values.room_id;
    const medicalValues = { ...medicalForm.values, can_swim: medicalForm.values.can_swim === "true" };
    Promise.all([
      useFetch("/children/" + child.id, "PUT", { ...values }),
      useFetch("/children/" + child.id + "/medical-info", "POST", { ...medicalValues }),
    ])
      .then((res) => {
        if (res[0].status === "ok" && res[1].status === "ok") {
          toast.success("Informace o dítěti upraveny");
          form.resetDirty(form.values);
          medicalForm.resetDirty(medicalForm.values);
          setLoading(false);
        } else {
          toast.error("Nastala chyba v úpravě informací a byla nahlášena.");
          setError(res[0].message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Nastala chyba v úpravě informací a byla nahlášena.");
        setLoading(false);
      });
  };

  const actionIcons = [
    {
      name: "Zpět na kartu dítěte",
      icon: IconId,
      onTrigger: () => navigation("/deti/" + child.id),
      primary: false,
      loading: loading,
    },
    {
      name: "Uložit změny",
      icon: IconDeviceFloppy,
      onTrigger: () => form.onSubmit(handleSubmit(form.values)),
      primary: true,
      loading: loading,
    },
  ];

  return (
    <Page subtitle="Úprava dítěte" actionIcons={actionIcons} title={parseFullName(child)}>
      <NewMedicalReportDrawer
        opened={newReport}
        onClose={() => {
          setNewReport(false);
        }}
        child={child}
        reports={reports}
        setReports={setReports}
      />
      <MedicalReportDetailDrawer
        reports={reports}
        setReports={setReports}
        opened={reportDetail}
        onClose={() => setReportDetail(null)}
        report={reportDetail}
      />
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při úpravě dítěte nastala chyba"
          color="red"
          mb="md"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Paper p="lg" className="mb-4" withBorder radius="lg">
          <h2 className="mb-4">Základní informace</h2>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div className="flex w-full flex-col gap-y-3">
              <Input.Wrapper label="Křestní jméno" withAsterisk>
                <Input {...form.getInputProps("ucastnik_jmeno")} disabled={loading} required />
              </Input.Wrapper>

              <DateInput
                locale="cs"
                label="Datum narození"
                value={date}
                disabled={loading}
                onChange={setDate}
                valueFormat="D. M. YYYY"
                clearable={false}
                leftSection={<IconCalendar size={16} stroke={1.5} />}
                required
              />
              <Select
                label="Pokoj"
                data={freeRoomsList}
                {...form.getInputProps("room_id")}
                disabled={loading}
                required
              />
              <div className="flex justify-between gap-4">
                <p className="grow">
                  <strong>Finance</strong>
                  <ul className="my-0 pl-4">
                    <li>
                      <strong>Kauce:</strong>{" "}
                      {child.money.deposit.amount.czk > 0 || child.money.deposit.amount.eur > 0 ? (
                        <>
                          {child.money.deposit.amount.czk > 0 &&
                            child.money.deposit.amount.eur > 0 &&
                            `${child.money.deposit.amount.czk} CZK + ${child.money.deposit.amount.eur}`}
                          {child.money.deposit.amount.czk > 0 &&
                            child.money.deposit.amount.eur === 0 &&
                            `${child.money.deposit.amount.czk} CZK`}
                          {child.money.deposit.amount.czk === 0 &&
                            child.money.deposit.amount.eur > 0 &&
                            `${child.money.deposit.amount.eur} EUR`}
                        </>
                      ) : (
                        <Text c="dimmed" span>
                          —
                        </Text>
                      )}
                    </li>
                    <li>
                      <strong>Kapesné:</strong>{" "}
                      {child.money.pocketMoney.amount.czk > 0 || child.money.pocketMoney.amount.eur > 0 ? (
                        <>
                          {child.money.pocketMoney.amount.czk > 0 &&
                            child.money.pocketMoney.amount.eur > 0 &&
                            `${child.money.pocketMoney.amount.czk} CZK + ${child.money.pocketMoney.amount.eur} EUR`}
                          {child.money.pocketMoney.amount.czk > 0 &&
                            child.money.pocketMoney.amount.eur === 0 &&
                            `${child.money.pocketMoney.amount.czk} CZK`}
                          {child.money.pocketMoney.amount.czk === 0 &&
                            child.money.pocketMoney.amount.eur > 0 &&
                            `${child.money.pocketMoney.amount.eur} EUR`}
                        </>
                      ) : (
                        <Text c="dimmed" span>
                          —
                        </Text>
                      )}
                    </li>
                  </ul>
                </p>
                <p className="grow">
                  <strong>Zakoupené doplňky</strong>
                  {child.merch.length >= 1 ? (
                    <ul className="my-0 pl-4">
                      {child.merch.map((merch) => (
                        <li key={merch.id}>
                          <Text span className="w-fit">
                            <strong>{merch.name}:</strong> {merch.count}x{merch.size ? `, ${merch.size}` : ""}
                          </Text>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <Text c="dimmed">—</Text>
                  )}
                </p>
              </div>
            </div>
            <div className="flex w-full flex-col gap-y-3">
              <Input.Wrapper label="Příjmení" withAsterisk>
                <Input {...form.getInputProps("ucastnik_prijmeni")} disabled={loading} required />
              </Input.Wrapper>
              <Select
                label="Pohlaví"
                data={genders}
                {...form.getInputProps("ucastnik_pohlavi")}
                disabled={loading}
                required
              />
              <Select label="Oddíl" data={teamsList} {...form.getInputProps("team_id")} disabled={loading} required />
              <Select
                label="Velikost trička"
                data={tShirts}
                {...form.getInputProps("ucastnik_tricko")}
                disabled={loading}
                required
              />
            </div>
          </div>
          <Textarea
            className="mt-3"
            minRows={2}
            autosize
            label="Historická poznámka"
            disabled={loading}
            {...form.getInputProps("permanent_note")}
          />
        </Paper>
        <Paper p="lg" className="mb-4" withBorder radius="lg">
          <h2 className="mb-6">Zdravotní informace</h2>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div>
              <Textarea
                minRows={2}
                autosize
                label="Zdravotní omezení od zdravotníka"
                disabled={loading}
                {...medicalForm.getInputProps("medical_restrictions")}
              />
              <Text c="dimmed" size="sm" className="mt-1">
                <strong>Zdravotní omezení od rodičů:</strong>{" "}
                {child.parentMedicalInfo.medical_restrictions ? child.parentMedicalInfo.medical_restrictions : "–"}
              </Text>
              <Checkbox
                label="Jsou zdravotní omezení závažná? (Mohou vést k zranění, je nutné na ně dávat velký pozor...)"
                className="mt-2"
                {...medicalForm.getInputProps("is_medical_restrictions_important", { type: "checkbox" })}
                disabled={loading}
              />
            </div>
            <div>
              <Textarea
                minRows={2}
                autosize
                label="Alergie od zdravotníka"
                disabled={loading}
                {...medicalForm.getInputProps("allergies")}
              />
              <Text c="dimmed" size="sm" className="mt-1">
                <strong>Alergie od rodičů:</strong>{" "}
                {child.parentMedicalInfo.allergies ? child.parentMedicalInfo.allergies : "–"}
              </Text>
              <Checkbox
                label="Jsou alergie žávažné? (Silná alergická reakce, nutné podání epipenu...)"
                className="mt-2"
                {...medicalForm.getInputProps("is_allergies_important", { type: "checkbox" })}
                disabled={loading}
              />
            </div>

            <div>
              <Textarea
                minRows={2}
                autosize
                label="Stravování od zdravotníka"
                disabled={loading}
                {...medicalForm.getInputProps("dietary_restrictions")}
              />
              <Text c="dimmed" size="sm" className="mt-1">
                <strong>Stravování od rodičů:</strong>{" "}
                {child.parentMedicalInfo.dietary_restrictions ? child.parentMedicalInfo.dietary_restrictions : "–"}
              </Text>
              <Checkbox
                label="Jsou informace o stravování závažné? (Životu nebezpečná alergie...)"
                className="mt-2"
                {...medicalForm.getInputProps("is_dietary_restrictions_important", { type: "checkbox" })}
                disabled={loading}
              />
              <Checkbox
                label="Je dietář pro jídelnu?"
                className="mt-2"
                {...medicalForm.getInputProps("has_diet", { type: "checkbox" })}
                disabled={loading}
              />

              <Text c="dimmed" size="sm" className="mt-3">
                <strong>Poznámka od rodičů v objednávce:</strong>{" "}
                {child.parentNote !== "" && child.parentNote !== null ? child.parentNote : "–"}
              </Text>
            </div>
            <div>
              <Select
                label="Plavec"
                data={[
                  { label: "Ano", value: String(true) },
                  { label: "Ne", value: String(false) },
                ]}
                {...medicalForm.getInputProps("can_swim")}
                disabled={loading}
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div>
              <h3 className="mb-3 mt-5 flex items-center gap-4 font-normal">
                <strong>Pravidelné léky od zdravotníka</strong>
                <Tooltip label="Přidat lék">
                  <ActionIcon
                    onClick={() =>
                      openNewMedicationModal(
                        "Přidat pravidelný lék",
                        child,
                        parseFullName(child),
                        medications,
                        setMedications
                      )
                    }
                    variant="light"
                    loading={medicationLoading}
                    disabled={revalidator.state === "loading"}>
                    <IconPlus size={20} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </h3>
              {medicationList.length > 0 ? (
                medicationList.map((record) => (
                  <div
                    className={classNames("mb-2 rounded-md border-2 border-solid p-2", {
                      "border-dark-500 bg-dark-300": mode === "dark",
                      "border-gray-500 bg-white-900": mode === "light",
                    })}>
                    <Group className="w-full" justify="space-between">
                      <Group gap="xs" align="center">
                        <IconPill size={24} stroke={1.5} />
                        <Text className="leading-5">
                          <strong>{record.name}</strong>
                          <br />
                          <Text span size="sm" color="dimmed">
                            {record.dosage}
                          </Text>
                        </Text>
                      </Group>

                      <Group gap="xs">
                        <Tooltip label="Upravit lék">
                          <ActionIcon
                            onClick={() => openMedicationEditModal("Úprava léku", null, child, record, setMedications)}
                            loading={medicationLoading}
                            variant="light"
                            disabled={revalidator.state === "loading"}>
                            <IconEdit size={20} stroke={1.5} />
                          </ActionIcon>
                        </Tooltip>
                        <Tooltip label="Smazat lék">
                          <ActionIcon
                            loading={medicationLoading}
                            onClick={() => handleMedicationRemove(record.id, record.name)}
                            color="red"
                            variant="light"
                            disabled={revalidator.state === "loading"}>
                            <IconTrash size={20} stroke={1.5} />
                          </ActionIcon>
                        </Tooltip>
                      </Group>
                    </Group>
                  </div>
                ))
              ) : (
                <Text className="flex items-center" c="dimmed">
                  🤔 Dítě nemá žádné pravidelné léky.
                </Text>
              )}
              <Text c="dimmed" size="sm" className="mt-1">
                <strong>Léky od rodičů:</strong>{" "}
                {child.parentMedicalInfo.medications ? child.parentMedicalInfo.medications : "–"}
              </Text>
            </div>
            <div>
              <h3 className="mb-3 mt-5 flex items-center gap-4 font-normal">
                <strong>Seznam ošetření</strong>
                <Tooltip label="Vytvořit ošetření">
                  <ActionIcon
                    onClick={() => setNewReport(true)}
                    variant="light"
                    disabled={revalidator.state === "loading"}>
                    <IconReportMedical size={20} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </h3>
              {reports.length > 0 ? (
                reports.map((record) => (
                  <div
                    className={classNames("mb-2 rounded-md border-2 border-solid p-2", {
                      "border-dark-500 bg-dark-300": mode === "dark",
                      "border-gray-500 bg-white-900": mode === "light",
                    })}>
                    <Group className="w-full" justify="space-between">
                      <Group gap="xs" align="center">
                        <IconReportMedical size={24} stroke={1.5} />
                        <Text className="leading-5">
                          <strong>{record.type}</strong>
                          <br />
                          <Text span size="sm" color="dimmed">
                            {dayjs(record.createdAt).format("D. M. YYYY HH:mm")}
                          </Text>
                        </Text>
                      </Group>

                      <Group gap="xs">
                        <Tooltip label="Detail ošetření">
                          <ActionIcon
                            onClick={() => setReportDetail(record)}
                            variant="light"
                            disabled={revalidator.state === "loading"}>
                            <IconReportMedical size={20} stroke={1.5} />
                          </ActionIcon>
                        </Tooltip>
                        <Tooltip label="Smazat ošetření">
                          <ActionIcon
                            onClick={() => handleReportRemove(record.id)}
                            color="red"
                            variant="light"
                            disabled={revalidator.state === "loading"}>
                            <IconTrash size={20} stroke={1.5} />
                          </ActionIcon>
                        </Tooltip>
                      </Group>
                    </Group>
                  </div>
                ))
              ) : (
                <Text className="flex items-center" color="dimmed">
                  🤔 Dítě nemá žádné vytvořené ošetření.
                </Text>
              )}
            </div>
          </div>
        </Paper>
        <Paper p="lg" className="mb-4" withBorder radius="lg">
          <h2 className="mb-4">Kontaktní údaje</h2>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Input.Wrapper label="Telefon - rodič 1" withAsterisk>
              <Input
                {...form.getInputProps("telefon")}
                required
                type="tel"
                disabled={loading}
                pattern="\+(420|421)[0-9]{9}"
              />
            </Input.Wrapper>
            <Input.Wrapper label="Telefon - rodič 2">
              <Input {...form.getInputProps("telefon2")} type="tel" disabled={loading} pattern="\+(420|421)[0-9]{9}" />
            </Input.Wrapper>
            <Input.Wrapper label="E-mail zákonného zástupce" withAsterisk>
              <Input {...form.getInputProps("email")} required disabled={loading} type="email" />
            </Input.Wrapper>
            <Input.Wrapper label="E-mail dítěte">
              <Input {...form.getInputProps("ucastnik_email")} disabled={loading} type="email" />
            </Input.Wrapper>
          </div>
        </Paper>
        <Paper p="lg" className="mb-4" withBorder radius="lg">
          <h2 className="mb-4">Adresa</h2>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Input.Wrapper label="Ulice a číslo popisné" withAsterisk>
              <Input {...form.getInputProps("ulice")} disabled={loading} required />
            </Input.Wrapper>
            <Input.Wrapper label="Město" withAsterisk>
              <Input {...form.getInputProps("mesto")} disabled={loading} required />
            </Input.Wrapper>
            <Input.Wrapper label="PSČ" withAsterisk>
              <Input {...form.getInputProps("psc")} disabled={loading} required pattern="[0-9]{5}" />
            </Input.Wrapper>
            <Select label="Stát" data={countries} {...form.getInputProps("stat")} disabled={loading} required />
          </div>
        </Paper>
      </form>
      <SaveChangesBar
        text="V kartě dítěte nemáš uložené změny."
        show={form.isDirty() || medicalForm.isDirty()}
        onConfirm={() => {
          handleSubmit(form.values);
        }}
        onCancel={resetForm}
        loading={loading}
      />
      <SavingChangesBar
        show={revalidator.state === "loading"}
        text={"Probíhá stahování nových dat, prosíme čekejte a neprovádějte žádné nové změny."}
      />
    </Page>
  );
}
