import { Group, Text, Tooltip } from "@mantine/core";
import { IconHeartbeat, IconPill, IconSwimming, IconToolsKitchen2, IconVirus } from "@tabler/icons-react";
import classNames from "classnames";
import styles from "../pages.module.scss";
import { getMode } from "../helpers/helpers";

const MedicalInfoCol = ({ child, mobile }) => {
  let medications = child.medicalMedications;
  let hasTakenMeds = false;
  let isTakingMeds = false;
  let isTakingMedsCurrent = false;
  let hasTakenMedsArray = [];

  let currentPartOfDay = new Date().getHours();

  if (currentPartOfDay <= 11) {
    currentPartOfDay = "s";
  } else if (currentPartOfDay <= 16) {
    currentPartOfDay = "o";
  } else {
    currentPartOfDay = "v";
  }

  medications?.forEach((med) => {
    if (med) {
      ["s", "o", "v"].map((period) => {
        if (med[period + "_m"].amount || med[period + "_p"].amount) {
          isTakingMeds = true;
          if (period === currentPartOfDay) isTakingMedsCurrent = true;
        }
      });
      if (med[currentPartOfDay + "_m"]?.amount || med[currentPartOfDay + "_p"]?.amount) {
        if (med[currentPartOfDay + "_m"].amount && med[currentPartOfDay + "_m"].taked) {
          hasTakenMedsArray.push(true);
        }
        if (med[currentPartOfDay + "_p"].amount && med[currentPartOfDay + "_p"].taked) {
          hasTakenMedsArray.push(true);
        }
      }
    }
  });

  if (hasTakenMedsArray.length > 0) {
    hasTakenMeds = hasTakenMedsArray.reduce((madA, madB) => madA && madB);
  }

  const { mode, theme } = getMode();

  const defaultColor = mode === "dark" ? "white" : "gray.9";

  if (mobile) {
    return (
      <Group gap={2} align="center">
        {child.medicalInfo.medical_restrictions !== "" ? (
          <Text
            span
            className="h-[20px]"
            color={child.medicalInfo.is_medical_restrictions_important ? "red.6" : defaultColor}>
            <IconHeartbeat stroke={1.5} size={20} />
          </Text>
        ) : (
          <span className={classNames(styles.EmptyCell)}></span>
        )}
        {child.medicalInfo.allergies !== "" ? (
          <Text span className="h-[20px]" color={child.medicalInfo.is_allergies_important ? "red.6" : defaultColor}>
            <IconVirus stroke={1.5} size={20} />
          </Text>
        ) : (
          <span className={classNames(styles.EmptyCell)}></span>
        )}
        {child.medicalInfo.dietary_restrictions !== "" ? (
          <Text
            span
            className="h-[20px]"
            color={child.medicalInfo.is_dietary_restrictions_important ? "red.6" : defaultColor}>
            <IconToolsKitchen2 stroke={1.5} size={20} />
          </Text>
        ) : (
          <span className={classNames(styles.EmptyCell)}></span>
        )}
        {child.medicalMedications.length > 0 ? (
          <Text
            span
            className="h-[20px]"
            color={isTakingMeds && isTakingMedsCurrent ? (hasTakenMeds ? "teal" : "red.6") : defaultColor}>
            <IconPill size={20} stroke={1.5} />
          </Text>
        ) : (
          <span className={classNames(styles.EmptyCell)}></span>
        )}
        {!child.canSwim ? (
          <Text span color="red" className="h-[20px]">
            <IconSwimming stroke={1.5} size={20} />
          </Text>
        ) : (
          <span className={classNames(styles.EmptyCell)}></span>
        )}
      </Group>
    );
  }

  return (
    <Group gap="xs" className={classNames(styles.MedicalLogos)}>
      {child.medicalInfo.medical_restrictions !== "" ? (
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={child.medicalInfo.is_medical_restrictions_important ? "Vážné zdravotní omezení" : "Zdravotní omezení"}>
          <Text span color={child.medicalInfo.is_medical_restrictions_important ? "red.6" : defaultColor}>
            <IconHeartbeat stroke={1.5} size={20} />
          </Text>
        </Tooltip>
      ) : (
        <span className={classNames(styles.EmptyCell)}></span>
      )}
      {child.medicalInfo.allergies !== "" ? (
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={child.medicalInfo.is_allergies_important ? "Vážné alergie" : "Alergie"}>
          <Text span color={child.medicalInfo.is_allergies_important ? "red.6" : defaultColor}>
            <IconVirus stroke={1.5} size={20} />
          </Text>
        </Tooltip>
      ) : (
        <span className={classNames(styles.EmptyCell)}></span>
      )}
      {child.medicalInfo.dietary_restrictions !== "" ? (
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={child.medicalInfo.is_dietary_restrictions_important ? "Důležitá dietní omezení" : "Dietní omezení"}>
          <Text span color={child.medicalInfo.is_dietary_restrictions_important ? "red.6" : defaultColor}>
            <IconToolsKitchen2 stroke={1.5} size={20} />
          </Text>
        </Tooltip>
      ) : (
        <span className={classNames(styles.EmptyCell)}></span>
      )}
      {child.medicalMedications.length > 0 ? (
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Pravidelné léky">
          <Text span color={isTakingMeds && isTakingMedsCurrent ? (hasTakenMeds ? "teal" : "red.6") : defaultColor}>
            <IconPill size={20} stroke={1.5} />
          </Text>
        </Tooltip>
      ) : (
        <span className={classNames(styles.EmptyCell)}></span>
      )}
      {!child.canSwim ? (
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Neplavec">
          <Text span color="red">
            <IconSwimming stroke={1.5} size={20} />
          </Text>
        </Tooltip>
      ) : (
        <span className={classNames(styles.EmptyCell)}></span>
      )}
    </Group>
  );
};

export default MedicalInfoCol;
