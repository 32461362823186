import { useEffect, useState } from "react";
import { Alert, Button, Checkbox, Group, Select, Text, Textarea } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconAlertCircle, IconCalendar } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import "dayjs/locale/cs";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useFetch } from "../../helpers/useFetch.js";
import { useUser } from "../../providers/UserProvider.jsx";
import { useRevalidator } from "react-router-dom";
import { useEditor } from "@tiptap/react";
import { RichTextEditor } from "@mantine/tiptap";
import { StarterKit } from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import "@mantine/tiptap/styles.css";
import { Markdown } from "tiptap-markdown";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function DiscordScheduledMessageModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const setShouldFetch = innerProps.setShouldFetch;
  const message = innerProps.message;
  const [date, setDate] = useState(null);
  const [error, setError] = useState(false);
  const { user } = useUser();
  const revalidator = useRevalidator();

  let editorMessage = message?.message ?? "";
  if (editorMessage) {
    editorMessage = editorMessage.replace(/__(.*?)__/g, "<u>$1</u>");
  }
  const editor = useEditor({
    extensions: [StarterKit, Markdown, Underline],
    onUpdate(props, editor) {
      // const content =
      // form.setFieldValue('message', content);
    },
    content: editorMessage,
  });

  const form = useForm({
    initialValues: {
      send_date: null,
      send_time: null,
      message: "",
    },
  });

  useEffect(() => {
    if (message) {
      console.log(message);

      form.setValues({
        message: message.message,
        send_date: dayjs(message.sendDate).toDate(),
        send_time: message.sendTime,
        send_now: false,
      });
    } else {
      form.reset();
      form.setValues({ message: "", send_date: null, send_time: null, send_now: false });
    }
  }, [innerProps]);

  async function handleSubmit(values) {
    setLoading(true);

    let res;
    let localValues = { ...values };

    if (values.send_date) {
      localValues.send_date = dayjs.tz(values.send_date, "Europe/Prague").format("YYYY-MM-DD");
    }

    localValues.message = editor.storage.markdown.getMarkdown();
    localValues.message = localValues.message.replace(/<u>(.*?)<\/u>/g, "__$1__");
    if (message) {
      res = await useFetch(`/discord/scheduled-messages/${message.id}`, "PUT", { ...localValues });
    } else {
      res = await useFetch(`/discord/scheduled-messages`, "POST", { ...localValues });
    }

    if (res.status === "ok") {
      revalidator.revalidate();
      setShouldFetch(true);
      context.closeModal(id);
      form.reset();
      setLoading(false);
      toast.success("Zpráva naplánována.");
    } else {
      console.error(res);
      setLoading(false);
      setError(res.error);
    }
  }

  function generateTimeSlots() {
    const startTime = new Date();
    startTime.setHours(7, 0, 0, 0); // nastaví čas na 7:00

    const endTime = new Date();
    endTime.setHours(22, 0, 0, 0); // nastaví čas na 22:00

    const timeSlots = [];
    const interval = 15 * 60 * 1000; // 15 minut v milisekundách

    for (let time = startTime.getTime(); time <= endTime.getTime(); time += interval) {
      const date = new Date(time);
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      timeSlots.push(`${hours}:${minutes}`);
    }

    return timeSlots;
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title={message ? "Chyba při úpravě naplánované zprávy" : "Chyba při vytváření naplánované zprávy"}
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}

      <Group gap="sm" grow={1} className="w-full" mb="sm">
        <DatePickerInput
          label="Datum odeslání"
          leftSection={<IconCalendar size={18} stroke={1.5} />}
          minDate={dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD").toDate()}
          maxDate={dayjs(user?.currentCampSession.dateTo, "YYYY-MM-DD").toDate()}
          initialMonth={dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD").toDate()}
          valueFormat="D. M. YYYY"
          placeholder="--. --. ----"
          locale="cs"
          {...form.getInputProps("send_date")}
        />

        <Select
          label="Čas odeslání"
          data={generateTimeSlots()}
          placeholder="--:--"
          {...form.getInputProps("send_time")}
        />
      </Group>

      <Text size="sm" className="mb-1 font-bold">
        Text zprávy
      </Text>
      <RichTextEditor editor={editor}>
        <RichTextEditor.Toolbar sticky stickyOffset={80}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>

      <Text c="dimmed" size="sm" mt="xs" mb="sm">
        Zpráva se v určené datum a čas odešle do aktuálního turnusového kanálu. Při odeslání může dojít k menšímu
        zpoždění.
      </Text>

      <Checkbox label="Odeslat nyní" {...form.getInputProps("send_now")} />

      <Button
        disabled={!form.values.send_time || !form.values.send_date} // TODO: validovat ten markdown editor
        fullWidth
        type="submit"
        loading={loading}
        mt="md">
        {message ? "Upravit naplánovanou zprávu" : "Vytvořit naplánovanou zprávu"}
      </Button>
    </form>
    // </Modal>
  );
}
