import { Alert, Box, Button, Group, PasswordInput, Popover, Progress, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconAlertCircle, IconCheck, IconEye, IconEyeOff, IconLock, IconX } from "@tabler/icons-react";
import { useState } from "react";
import LogoSymbol from "../../components/LogoSymbol.jsx";
import logoText from "../../assets/logo-text.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import useTitle from "../../hooks/useTitle.js";
import successImage from "../../assets/success.webp";

function PasswordRequirement({ meets, label }) {
  return (
    <Text color={meets ? "teal" : "red"} sx={{ display: "flex", alignItems: "center" }} mt={7} size="sm">
      {meets ? <IconCheck stroke={1.5} size="0.9rem" /> : <IconX stroke={1.5} size="0.9rem" />}{" "}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}

function getStrength(password) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const requirements = [
  { re: /[0-9]/, label: "Obsahuje číslo" },
  { re: /[a-z]/, label: "Obsahuje malé písmeno" },
  { re: /[A-Z]/, label: "Obsahuje velké písmeno" },
];

export default function ChangePasswordPage() {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  useTitle(`Nové heslo`);

  const form = useForm({
    initialValues: {
      pass: "",
      pass_confirmation: "",
    },
  });

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(form.values.pass)} />
  ));

  const strength = getStrength(form.values.pass);
  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";

  const sendRequest = async (values) => {
    if (values.pass === values.pass_confirmation) {
      if (getStrength(values.pass) === 100) {
        setError(null);
      } else {
        return setError("Heslo je příliš slabé.");
      }
    } else {
      return setError("Hesla se neshodují.");
    }

    const { pass, pass_confirmation } = values;
    setLoading(true);
    const result = await fetch(new URL("/reset-password", import.meta.env.VITE_API_URL), {
      method: "POST",
      body: JSON.stringify({ token, pass, pass_confirmation }),
      headers: {
        "Content-Type": "application/json",
        //"User-Agent": agent,
      },
    });

    let data = await result.json();

    if (result.status !== 200) {
      setError(data.message);
      setLoading(false);
      return;
    }
    setSuccess(true);
  };

  return (
    <div className="w-100 flex h-screen items-center justify-center overflow-hidden">
      {success ? (
        <Stack gap={0} className="mt-4 max-h-screen max-w-[900px] items-center" p="md">
          <Group wrap="nowrap" gap="sm" className="mb-6 flex h-16 px-4" align="center">
            <LogoSymbol height={48} />
            <img src={logoText} height={48} alt="Logo" />
          </Group>
          <div className="mt-8 flex flex-wrap items-center gap-12 md:flex-nowrap">
            <img className="rounded-lg" src={successImage} alt="Úspěch" />
            <div>
              <h1>Heslo změněno!</h1>
              <p className="mt-4">Nyní se můžeš přihlásit pomocí nového hesla.</p>

              <Button mt={8} fullWidth variant="subtle" component={Link} to="/prihlaseni">
                Přihlášení
              </Button>
            </div>
          </div>
        </Stack>
      ) : (
        <Stack gap={0} className="max-w-[25rem] items-center">
          <Group wrap="nowrap" gap="sm" className="flex h-16 px-4" align="center">
            <LogoSymbol height={48} />
            <img src={logoText} height={48} alt="Logo" />
          </Group>
          <p className="mt-6 text-center">Pro změnu hesla už stačí jenom napsat nové heslo.</p>
          <form
            className="w-full"
            onSubmit={form.onSubmit((values) => {
              sendRequest(values);
            })}>
            {error && (
              <Alert
                icon={<IconAlertCircle size={16} stroke={1.5} />}
                title="Ve formuláři nastala chyba."
                color="red"
                mb="lg"
                className="w-full"
                withCloseButton
                closeButtonLabel="Zavřít upozornění"
                onClose={() => setError(false)}>
                {error}
              </Alert>
            )}

            <>
              <Popover position="top" opened={popoverOpened} width="target" transitionProps={{ transition: "pop" }}>
                <Popover.Target>
                  <div onFocusCapture={() => setPopoverOpened(true)} onBlurCapture={() => setPopoverOpened(false)}>
                    <PasswordInput
                      required
                      leftSection={<IconLock stroke={1.5} size={16} />}
                      //disabled={loading}
                      placeholder="TajneHeslo"
                      label="Heslo"
                      mb="xs"
                      visibilityToggleIcon={({ reveal, size }) =>
                        reveal ? <IconEyeOff stroke={1.5} size={size} /> : <IconEye stroke={1.5} size={size} />
                      }
                      {...form.getInputProps("pass")}
                    />
                  </div>
                </Popover.Target>
                <Popover.Dropdown p="lg">
                  <Progress color={color} value={strength} size={5} mb="md" />
                  <PasswordRequirement label="Obsahuje 8 znaků" meets={form.values.pass.length > 7} />
                  {checks}
                  <Text size="sm" mt="sm" color="dimmed">
                    Musíš splnit všechny požadavky.
                  </Text>
                </Popover.Dropdown>
              </Popover>
              <PasswordInput
                required
                leftSection={<IconLock stroke={1.5} size={16} />}
                //disabled={loading}
                placeholder="TajneHeslo"
                label="Zopakování hesla"
                mb="lg"
                visibilityToggleIcon={({ reveal, size }) =>
                  reveal ? <IconEyeOff stroke={1.5} size={size} /> : <IconEye stroke={1.5} size={size} />
                }
                {...form.getInputProps("pass_confirmation")}
              />
              <Button type="submit" loading={loading} variant="filled" fullWidth>
                Změnit heslo
              </Button>
            </>
          </form>
        </Stack>
      )}
    </div>
  );
}
