import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import { useUser } from "./UserProvider.jsx";

class PermissionTree {
  constructor(permissions = {}) {
    this.permissions = permissions;
  }

  isAllowed(path) {
    const segments = path.split(".");
    return this._checkPermission(this.permissions, segments, path === "modules.children");
  }

  areAllowed(paths, logic = "AND") {
    if (!Array.isArray(paths) || paths.length === 0) {
      throw new Error("Paths must be a non-empty array.");
    }

    if (logic === "AND") {
      return paths.every((path) => this.isAllowed(path));
    } else if (logic === "OR") {
      return paths.some((path) => this.isAllowed(path));
    } else {
      throw new Error('Invalid logic type. Use "AND" or "OR".');
    }
  }

  _checkPermission(node, segments, log = false) {
    if (!node) {
      return false;
    }

    const key = segments[0];

    if (typeof node[key] === "boolean") {
      return node[key];
    }

    if (node["*"] !== undefined) {
      if (typeof node["*"] === "boolean") {
        return node["*"];
      }
      if (segments.length > 1) {
        return this._checkPermission(node["*"], segments.slice(1));
      }
    }

    if (segments.length > 1) {
      return this._checkPermission(node[key], segments.slice(1));
    }

    return false;
  }
}

const PermissionsContext = createContext(null);

export const PermissionsProvider = ({ children }) => {
  const [permissionsTree, setPermissionsTree] = useState(null);

  const { user } = useUser();

  useEffect(() => {
    setPermissionsTree(new PermissionTree(user.permissions));
  }, []);

  const hasPermission = useMemo(
    () => (path) => {
      if (!permissionsTree) return false;
      return permissionsTree.isAllowed(path);
    },
    [permissionsTree]
  );

  const hasMultiplePermissions = useMemo(
    () =>
      (paths, logic = "AND") => {
        if (!permissionsTree) return false;
        return permissionsTree.areAllowed(paths, logic);
      },
    [permissionsTree]
  );

  return (
    <PermissionsContext.Provider value={{ hasPermission, hasMultiplePermissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error("usePermissions must be user inside PermissionsProvider");
  }
  return context;
};
