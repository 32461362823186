import { Tooltip } from "@mantine/core";
import {
  IconBrandFortnite,
  IconBrandMinecraft,
  IconDeviceGamepad2,
  IconHexagonLetterR,
  IconQuestionMark,
} from "@tabler/icons-react";

const CampIcon = ({ camp }) => {
  const campShortcut = camp.shortcut;
  const name = camp.name.replace("Camp", "");
  if (!campShortcut) return;
  switch (campShortcut) {
    case "cc":
      return (
        <Tooltip label={name} position="top">
          <IconBrandMinecraft stroke={1.5} />
        </Tooltip>
      );
    case "pc":
      return (
        <Tooltip label={name} position="top">
          <IconDeviceGamepad2 stroke={1.5} />
        </Tooltip>
      );
    case "fc":
      return (
        <Tooltip label={name} position="top">
          <IconBrandFortnite stroke={1.5} />
        </Tooltip>
      );
    case "rc":
      return (
        <Tooltip label={name} position="top">
          <IconHexagonLetterR stroke={1.5} />
        </Tooltip>
      );
    default:
      return (
        <Tooltip label={name} position="top">
          <IconQuestionMark stroke={1.5} />
        </Tooltip>
      );
  }
};

export default CampIcon;
