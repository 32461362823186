import useTitle from "../../hooks/useTitle.js";
import { LoadingOverlay, Text } from "@mantine/core";
import { useFetch } from "../../helpers/useFetch.js";
import { useLoaderData, useRevalidator } from "react-router-dom";
import RoomGroupList from "../../components/accommodation/room-management/RoomGroupList.jsx";
import Page from "../../components/layout/Page.jsx";
import { openConfirmModal } from "@mantine/modals";
import { modalTitle } from "../../components/modals/Modal.jsx";
import { IconUsers } from "@tabler/icons-react";
import { toast } from "react-hot-toast";

export async function loader() {
  const res = await useFetch(`accommodation`);

  return res;
}

export default function RoomsManagePage() {
  const groups = useLoaderData().data;
  const revalidator = useRevalidator();

  const actionIcons = [
    {
      name: "Importovat ubytování vedoucích z minulého turnusu",
      icon: IconUsers,
      onTrigger: (e) => {
        openConfirmModal({
          title: modalTitle(IconUsers, "Import ubytování vedoucích"),
          children: (
            <Text size="sm">
              Opravdu chceš importovat ubytování vedoucích z minulého turnusu? Tento krok přepíše ubytování v pokojích,
              kde byli minulý turnus vedoucí ubytováni.
            </Text>
          ),
          labels: { confirm: "Potvrdit", cancel: "Zrušit" },
          onConfirm: () => {
            toast.promise(useFetch("/accommodation/import-staff-from-last-session", "POST"), {
              loading: "Importuji ubytování...",
              success: (res) => {
                if (res.status !== "ok") {
                  throw res.error;
                }
                revalidator.revalidate();
                return "Ubytování úspěšně importováno.";
              },
              error: (err) => {
                return <p>{err ? err : "Nastala chyba při importu vedoucích a byla nahlášena"}</p>;
              },
            });
          },
        });
      },
    },
  ];

  useTitle(`Správa pokojů`);

  return (
    <Page actionIcons={actionIcons} title="Správa pokojů">
      <LoadingOverlay visible={revalidator.state === "loading"} overlayOpacity={0.5} />

      <RoomGroupList groups={groups} />
    </Page>
  );
}
