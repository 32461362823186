import Page from "../../components/layout/Page";
import { useState } from "react";
import { ActionIcon, Button, Group, SegmentedControl, Skeleton, Tooltip } from "@mantine/core";
import {
  openCopyLastWeekQuestsModal,
  openDeleteQuestModal,
  openEditQuestModal,
  openNewQuestModal,
} from "../../helpers/modals.js";
import { IconCopy, IconPencil } from "@tabler/icons-react";
import { useEffect } from "react";
import { useFetch } from "../../helpers/useFetch.js";
import { IconPlus } from "@tabler/icons-react";
import { IconTrash } from "@tabler/icons-react";
import PcQuestTable from "../../components/table/PcQuestTable.jsx";

const PcQuestsPage = () => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [campFilter, setCampFilter] = useState(null);
  const [camps, setCamps] = useState(null);
  const [quests, setQuests] = useState();

  useEffect(() => {
    if (shouldFetch || !quests) {
      useFetch("/pc/quests")
        .then((data) => {
          setQuests(data.data);
          setShouldFetch(false);
          const questsRaw = data.data;
          const questsDaily = [[], [], [], [], [], []];
          questsRaw.forEach((quest) => {
            questsDaily[quest.day].push(quest);
          });
          setQuests(questsDaily);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [shouldFetch]);

  useEffect(() => {
    if (!camps) {
      useFetch("/public/camps")
        .then((data) => {
          let campsData = data.data.filter(
            (camp) =>
              camp.shortcut !== "xc" &&
              camp.shortcut !== "vc" &&
              camp.shortcut !== "hc" &&
              camp.shortcut !== "vi" &&
              camp.shortcut !== "primestsky"
          );
          campsData = campsData.map((camp) => {
            return {
              label: camp.name.replace("Camp", ""),
              id: camp.id,
              shortcut: camp.shortcut,
            };
          });
          setCamps(campsData);
        })
        .catch((err) => {
          console.error(err);
        }, []);
    }
  });

  const days = [
    { label: "Neděle", value: "0" },
    { label: "Pondělí", value: "1" },
    { label: "Úterý", value: "2" },
    { label: "Středa", value: "3" },
    { label: "Čtvrtek", value: "4" },
    { label: "Pátek", value: "5" },
  ];

  const tableActionButtons = (quest) => {
    if (!camps) return;
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Editace questu">
          <ActionIcon
            variant="light"
            color="gray"
            onClick={(e) => {
              e.stopPropagation();
              openEditQuestModal(quest, camps, days, setShouldFetch);
            }}>
            <IconPencil stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Smazání questu">
          <ActionIcon
            variant="light"
            color="gray"
            onClick={(e) => {
              e.stopPropagation();
              openDeleteQuestModal(quest, setShouldFetch);
            }}>
            <IconTrash stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const pageActionsIcons = [
    {
      name: "Zkopírování z minulého týdne",
      icon: IconCopy,
      onTrigger: (e) => {
        openCopyLastWeekQuestsModal(setShouldFetch);
      },
    },
    {
      name: "Nový quest",
      icon: IconPlus,
      onTrigger: (e) => {
        openNewQuestModal(campFilter, camps, days, setShouldFetch);
      },
    },
  ];
  return (
    <Page title="Questy" actionIcons={pageActionsIcons}>
      <Group gap="sm" className="flex-grow" mb="lg" grow>
        {camps
          ? camps.map((camp) => (
              <Button
                onClick={() => {
                  campFilter === camp.id ? setCampFilter(null) : setCampFilter(camp.id);
                }}
                className="min-w-fit"
                variant={campFilter === camp.id ? "filled" : "light"}
                key={camp.id}>
                {camp.label}
              </Button>
            ))
          : [...Array(5)].map((_, i) => <Skeleton key={i} radius="sm" height={36} />)}
      </Group>
      {quests && camps && (
        <PcQuestTable
          setShouldFetch={setShouldFetch}
          shouldFetch={shouldFetch}
          quests={quests}
          camp={campFilter}
          days={days}
          actionButtons={tableActionButtons}
        />
      )}
    </Page>
  );
};

export default PcQuestsPage;
