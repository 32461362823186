import { useEffect, useRef, useState } from "react";
import {
  ActionIcon,
  Alert,
  Button,
  Checkbox,
  Loader,
  Paper,
  SegmentedControl,
  Select,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import "dayjs/locale/cs";
import { useUser } from "../../providers/UserProvider.jsx";
import { useRevalidator } from "react-router-dom";
import { useFetch } from "../../helpers/useFetch.js";
import { toast } from "react-hot-toast";
import { IconAlertCircle, IconUpload, IconX } from "@tabler/icons-react";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { getMode } from "../../helpers/helpers.js";

export default function RoomIssueModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [error, setError] = useState(null);
  const { user } = useUser();
  const revalidator = useRevalidator();
  const [update, setUpdate] = useState(false);
  const [type, setType] = useState("room"); // room or common

  const [images, setImages] = useState([]);

  const handleFileInput = useRef(null);

  const handleImageChange = (files) => {
    setImages((prev) => [
      ...prev,
      {
        imagePreview: URL.createObjectURL(files[0]),
        imageFile: files[0],
      },
    ]);
  };

  const getRooms = async () => {
    setFetching(true);
    useFetch("/accommodation/rooms").then((res) => {
      if (res.data) {
        setRooms(res.data);
        if (innerProps?.room?.id) {
          form.setValues({ room_id: innerProps?.room?.id });
        }
        setFetching(false);
      }
    });
  };

  useEffect(() => {
    getRooms();
  }, [innerProps]);

  async function handleSubmit(values) {
    setLoading(true);

    //const room = innerProps?.room;

    const roomId = values.room_id;

    let formData = new FormData();
    images.map((image) => {
      formData.append("images[]", image.imageFile);
    });
    formData.append("text", values.text);
    formData.append("is_permanent", values.is_permanent ? "true" : "false"); // form data neumí boolean
    if (values.room_id) formData.append("room_id", values.room_id);
    if (values.place) formData.append("place", values.place);
    const res = await useFetch(`/accommodation/rooms/issues${roomId ? "/" + roomId : ""}`, "POST", formData, false);

    if (res.status === "ok") {
      context.closeModal(id);
      form.reset();
      setLoading(false);
      toast.success("Škoda přidána.");
      revalidator.revalidate();
    } else {
      console.error(res);
      setError(res.message);
      setLoading(false);
    }
  }

  const removeImage = (index) => {
    images.splice(index, 1);
    setImages(images);
    setUpdate(!update);
  };

  const form = useForm({
    initialValues: {
      text: "",
      is_permanent: false,
      room_id: "",
      place: "",
    },
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při vytváření škody nastala chyba."
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      <SegmentedControl
        fullWidth
        mb="sm"
        color={getMode().theme.primaryColor}
        disabled={loading}
        onChange={(value) => setType(value)}
        value={type}
        data={[
          {
            label: "Pokoj",
            value: "room",
          },
          {
            label: "Společné prostory",
            value: "common",
          },
        ]}
      />
      {fetching ? (
        <div className="flex h-full w-full flex-col items-center justify-center py-8">
          <Loader />
        </div>
      ) : (
        <>
          {type === "room" && (
            <Select
              label="Pokoj"
              mb="sm"
              disabled={loading}
              searchable
              data={rooms?.map((group) => ({
                label: `${group.fullName}`,
                value: String(group.id),
              }))}
              required
              {...form.getInputProps("room_id")}
            />
          )}

          {type === "common" && (
            <TextInput
              label="Místo"
              mb="sm"
              required
              disabled={loading}
              placeholder="Chodba na 5. patře..."
              {...form.getInputProps("place")}
            />
          )}

          <Textarea
            label="Popis"
            mb="sm"
            placeholder="Zničené panty na dveřích..."
            disabled={loading}
            minRows={2}
            autosize
            {...form.getInputProps("text")}
            required></Textarea>

          <Text fw="bold" mb="xs" size="sm">
            Fotky
          </Text>
          <label>
            <input
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              capture="environment"
              ref={handleFileInput}
              onChange={handleImageChange}
            />
          </label>
          <div className="mb-3 flex flex-wrap gap-2">
            <Dropzone
              onDrop={handleImageChange}
              loading={loading}
              accept={IMAGE_MIME_TYPE}
              className="flex h-[100px] w-[100px] cursor-pointer items-center justify-center rounded-md duration-100 hover:scale-105">
              <Dropzone.Idle>
                <Text>
                  <IconUpload stroke={1.5} size={48} />
                </Text>
              </Dropzone.Idle>
              <Dropzone.Accept>
                <Text>
                  <IconUpload stroke={1.5} size={48} />
                </Text>
              </Dropzone.Accept>
              <Dropzone.Reject>
                <Text color="red">
                  <IconX stroke={1.5} size={48} />
                </Text>
              </Dropzone.Reject>
            </Dropzone>
            {images.length > 0 &&
              images.map((image, index) => (
                <Paper className="relative h-[100px] w-[100px] overflow-hidden rounded-md" withBorder>
                  <ActionIcon
                    variant="filled"
                    className="absolute left-2 top-2"
                    color="red"
                    onClick={() => {
                      removeImage(index);
                    }}>
                    <IconX size={16} stroke={1.5} />
                  </ActionIcon>
                  <img key={index} className="h-full w-full object-cover object-center" src={image.imagePreview} />
                </Paper>
              ))}
          </div>

          <div>
            <Checkbox
              label="Škoda je trvalá"
              disabled={loading}
              {...form.getInputProps("is_permanent", { type: "checkbox" })}
            />
          </div>

          <Button
            disabled={!form.values.text || (!form.values.room_id && !form.values.place)}
            fullWidth
            type="submit"
            loading={loading}
            mt="lg">
            Uložit
          </Button>
        </>
      )}
    </form>
  );
}
