import Table from "./core/Table.jsx";

import { ActionIcon, Group, Tooltip } from "@mantine/core";
import { IconSignature } from "@tabler/icons-react";
import { parseFullName } from "../../helpers/helpers.js";
import { Link, useNavigate, useRevalidator } from "react-router-dom";
import { useState } from "react";
import DocumentViewer from "../boarding/DocumentViewer.jsx";
import ContractInfoCol from "../ContractInfoCol.jsx";
import { sortStaff } from "./sort.js";
import { useFetch } from "../../helpers/useFetch.js";
import toast from "react-hot-toast";

export default function ContractsTable({ contracts, handleDocumentView, myContracts, filterConfig }) {
  const revalidator = useRevalidator();
  const [loading, setLoading] = useState(false);
  const [viewerOpened, setViewerOpened] = useState(false);
  const [viewingDocument, setViewingDocument] = useState("");
  const navigate = useNavigate();

  const actionButtons = (contract) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Přejít k vyplnění">
          <ActionIcon
            variant="filled"
            component={Link}
            to={`/smlouvy/${contract.id}`}
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <IconSignature size={18} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const handleDocumentDelete = async (document, contractId) => {
    setLoading(true);
    const res = await useFetch(`staff/contracts/${contractId}/${document.type}`, "DELETE");
    setLoading(false);
    if (res.status === "ok") {
      toast.success("Dokument smazán");
      revalidator.revalidate();
      return;
    }
    toast.error("Nastala chyba při mazání dokumentu");
  };

  const columns = [
    ...(contracts[0]?.user
      ? [
          {
            accessor: "staff",
            title: "Zaměstnanec",
            render: (contract) => parseFullName(contract.user),
            sortable: true,
          },
        ]
      : []),
    {
      accessor: "position",
      title: "Pozice",
      render: (contract) => contract.position,
      sortable: true,
    },
    {
      accessor: "session",
      title: "Turnusy",
      render: (contract) =>
        contract.sessions.map((session) => (contract.area === "vikend" ? session : session.split(".")[0])).join(", "),
    },
    ...(contracts[0]?.user
      ? []
      : [
          {
            accessor: "year",
            title: "Rok",
            render: (contract) => `${contract.createdAt.split("-")[0]}`,
          },
        ]),
    ...(contracts[0]?.user
      ? []
      : [
          {
            accessor: "area",
            title: "Areál",
            render: (contract) => `${contract.areaLabel}`,
          },
        ]),
    {
      accessor: "documents",
      title: "Dokumenty",
      render: (contract) => (
        <ContractInfoCol
          handleDocumentView={handleDocumentView}
          isButtons={contracts[0]?.user}
          contract={contract}
          loading={loading}
          handleDocumentDelete={handleDocumentDelete}
        />
      ),
      narrow: true,
    },
    ...(contracts[0]?.user
      ? []
      : [
          {
            accessor: "actions",
            title: "Akce",
            render: (contract) => actionButtons(contract),
            narrow: true,
          },
        ]),
  ];

  return (
    <>
      <Table
        filterConfig={filterConfig}
        columns={columns}
        records={contracts}
        sortFunction={sortStaff}
        onRowClick={(contract) => {
          if (myContracts) navigate(`/smlouvy/${contract.id}`);
        }}
      />
      {viewerOpened && <DocumentViewer document={viewingDocument} setOpened={setViewerOpened} />}
    </>
  );
}
