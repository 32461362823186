import { useEffect, useState } from "react";
import { Alert, Button, Loader, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useFetch } from "../../helpers/useFetch";
import { useNavigate } from "react-router";
import { useUser } from "../../providers/UserProvider.jsx";
import { IconAlertCircle } from "@tabler/icons-react";

export default function SessionSelectModal() {
  const [sessionsElements, setSessionsElements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user } = useUser();
  const [campSessions, setCampSessions] = useState([]);

  useEffect(() => {
    async function fetchSessions() {
      setFetching(true);
      const res = await useFetch(`/camp-sessions`);
      if (res.data) {
        setCampSessions(res.data);
      }
      setFetching(false);
    }

    fetchSessions();
  }, []);

  const form = useForm({
    initialValues: {
      current_camp_session_id: "",
    },
  });

  const areaForm = useForm({
    initialValues: {
      area: user.area,
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true);

    const resSession = await useFetch(`/camp-sessions/change`, "PUT", values);
    if (resSession.data) {
      await navigate(0);
    } else {
      setLoading(false);
      console.error(resSession);
      setError(resSession.message);
    }
  };

  useEffect(() => {
    let actualSession = campSessions.find((session) => session.id === user.currentCampSession?.id);
    if (actualSession) {
      actualSession = {
        group: "Aktuální turnus",
        items: [
          {
            label: actualSession.alias,
            value: String(actualSession.id),
          },
        ],
      };
    }

    let sessions = campSessions.filter((sessionLocal) => sessionLocal.id !== user.currentCampSession?.id);

    const otherSessions = [
      {
        group: "Ostatní turnusy",
        items: sessions.map((session) => ({
          label: session.alias,
          value: String(session.id),
        })),
      },
    ];
    let displaySessions = otherSessions;

    if (actualSession) {
      displaySessions = [actualSession, ...otherSessions];
    }

    setSessionsElements([...displaySessions]);
  }, [campSessions]);

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {error && (
          <Alert
            icon={<IconAlertCircle size={16} stroke={1.5} />}
            title="Při změně turnusu nastala chyba."
            color="red"
            mb="md"
            mt="lg"
            withCloseButton
            closeButtonLabel="Zavřít upozornění"
            onClose={() => setError(false)}>
            {error}
          </Alert>
        )}
        {fetching ? (
          <div className="flex h-full w-full flex-col items-center justify-center py-8">
            <Loader />
          </div>
        ) : (
          <>
            {/*<Select*/}
            {/*  mb="sm"*/}
            {/*  searchable*/}
            {/*  label="Areál"*/}
            {/*  disabled={loading}*/}
            {/*  data={[*/}
            {/*    { value: "dvur-kralove", label: "Dvůr Králové" },*/}
            {/*    { value: "velesin", label: "Velešín" },*/}
            {/*    { value: "velmez", label: "Velké Meziříčí" },*/}
            {/*  ]}*/}
            {/*  placeholder="Vyber areál..."*/}
            {/*  {...areaForm.getInputProps("area")}*/}
            {/*/>*/}
            <Select
              mb="sm"
              label="Turnus"
              disabled={loading}
              data={sessionsElements}
              placeholder="Vyber turnus..."
              {...form.getInputProps("current_camp_session_id")}
            />

            <Button fullWidth type="submit" loading={loading} mt="md">
              Změnit turnus
            </Button>
          </>
        )}
      </form>
    </>
  );
}
