import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch.js";
import { useRevalidator } from "react-router-dom";
import { Button, Group, NumberInput, Select, Text } from "@mantine/core";
import classNames from "classnames";
import { useForm } from "@mantine/form";

export const MoneyChangeModal = ({ context, id, innerProps }) => {
  const revalidate = useRevalidator();
  const { refreshData, transaction, child, currentMoney } = innerProps;
  const [childMoney, setChildMoney] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [childrenList, setChildrenList] = useState([]);
  const [reasons, setReasons] = useState(
    ["Nákup v bufetu", "Nákup jinde", "Cesta", "Strhnutí z kauce", "Vrácení"].map((reason) => ({
      label: reason,
      value: reason,
    }))
  );
  const category = window.location.pathname.includes("kauce") ? "deposit" : "pocketMoney";

  const form = useForm({
    initialValues: {
      child: child?.id,
      category: transaction?.category || category,
      reason: null,
      amount: 0,
      currency: category === "pocketMoney" ? "CZK" : !child ? "CZK" : child?.money.amount.czk !== 0 ? "CZK" : "EUR",
    },
  });

  useEffect(() => {
    if (!child) {
      form.values.amount = 0;
      setLoading(true);
      useFetch(`/children/${form.values.child}/money/${category}`).then((res) => {
        setLoading(false);
        if (res.data) {
          const childLocal = res.data;
          if (category === "deposit") {
            const currencyLocal = childLocal.amount.czk !== 0 ? "CZK" : childLocal.amount.eur === 0 ? "CZK" : EUR;
            form.values.currency = currencyLocal;
          }
          setChildMoney(
            window.location.pathname.includes("kapesne")
              ? childLocal.amount.czk
              : childLocal.amount.czk !== 0
              ? childLocal.amount.czk
              : childLocal.amount.eur
          );
        }
      });
    } else if (child) {
      setChildMoney(
        window.location.pathname.includes("kapesne")
          ? child?.money.amount.czk
          : child?.money.amount.czk !== 0
          ? child?.money.amount.czk
          : child?.money.amount.eur
      );
    }
  }, [form.values.child, child]);

  useEffect(() => {
    if (form.values.reason) setDisabled(false);
    else setDisabled(true);
  }, [form.values.reason]);

  useEffect(() => {
    if (category === "pocketMoney") {
      form.setValues({ currency: "CZK" });
    }
    if (child?.id) {
      setChildrenList([{ label: `${child?.id}`, value: String(child?.id) }]);
    } else {
      useFetch("/children/list", "GET").then((res) => {
        if (res.data) {
          setChildrenList(
            res.data.map((child) => ({
              label: `${child.firstName} ${child.lastName} (${child.team}, ${child.age} let)`,
              value: String(child.id),
            }))
          );
        }
      });
    }
  }, []);

  const submit = (values) => {
    setLoading(true);
    useFetch(
      `/children/${values.child}/money/${values.category}/${values.amount > 0 ? "deposit" : "withdraw"}`,
      "PUT",
      {
        amount: values.amount < 0 ? values.amount * -1 : values.amount,
        currency: values.currency,
        note: values.reason,
      }
    ).then((res) => {
      if (res.status === "ok") {
        refreshData();
        toast.success(values.category === "deposit" ? "Kauce změněna" : "Kapesné změněno");
        setLoading(false);
        context.closeModal(id);
      } else {
        setLoading(false);
        toast.error("Nastala chyba při změně stavu peněz.");
      }
    });
  };

  return (
    <div>
      <form onSubmit={form.onSubmit(submit)}>
        <Select
          mb="sm"
          label="Dítě"
          placeholder="Vyber dítě..."
          className={classNames({ hidden: child?.id })}
          data={childrenList}
          searchable
          hoverOnSearchChange={true}
          {...form.getInputProps("child")}
        />
        <Select
          mb="sm"
          label="Kasa"
          placeholder="Vyber kasu..."
          {...form.getInputProps("category")}
          className={classNames({ hidden: transaction })}
          data={[
            { label: "Kauce", value: "deposit" },
            { label: "Kapesné", value: "pocketMoney" },
          ]}
          readOnly
        />
        <Group className={classNames({ hidden: transaction })} align="flex-start">
          <NumberInput
            precision={0}
            className="grow basis-[250px]"
            mb="sm"
            label="Relativní částka (může být i záporná)"
            placeholder="Zadej částku..."
            {...form.getInputProps("amount")}
            min={-1 * childMoney}
            description={"Negativní částka max. " + -1 * childMoney}
            inputWrapperOrder={["label", "input", "description"]}
          />
          <Select
            mb="sm"
            className="grow basis-[250px]"
            label="Měna"
            placeholder="Vyber měnu..."
            defaultValue={category === "pocketMoney" ? "CZK" : child?.money.amount.czk !== 0 ? "CZK" : "EUR"}
            readOnly={category === "pocketMoney"}
            data={[
              { label: "CZK", value: "CZK" },
              { label: "EUR", value: "EUR" },
            ]}
            {...form.getInputProps("currency")}
          />
        </Group>
        <Select
          label="Důvod změny"
          placeholder="Vyber důvod změny"
          searchable
          creatable
          description="Můžeš také zadat vlastní důvod."
          getCreateLabel={(query) => (
            <Text span>
              <Text span fw="bold">
                Vlastní důvod:{" "}
              </Text>
              {query}
            </Text>
          )}
          data={reasons}
          onCreate={(query) => {
            const item = { label: query, value: query };
            setReasons([...reasons, item]);
            return item;
          }}
          {...form.getInputProps("reason")}
        />

        <Group gap="sm" mt="lg" grow={1} className="w-full">
          <Button variant="light" onClick={() => context.closeModal(id)}>
            Zrušit
          </Button>
          <Button
            loading={loading}
            disabled={!form.values.reason || !form.values.amount || !form.values.currency}
            type="submit">
            Změnit stav {category === "deposit" ? "kauce" : "kapesného"}
          </Button>
        </Group>
      </form>
    </div>
  );
};
