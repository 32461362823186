import {
  Group,
  Pagination,
  Table as MantineTable,
  Text,
  Select,
  Button,
  Popover,
  TextInput,
  Checkbox,
  ScrollArea,
  Switch,
} from "@mantine/core";
import classNames from "classnames";
import styles from "../../../pages.module.scss";
import {
  IconArrowDown,
  IconArrowsMoveVertical,
  IconArrowUp,
  IconColumns3,
  IconFilter,
  IconSearch,
  IconX,
} from "@tabler/icons-react";
import React, { Fragment, cloneElement, useEffect, useRef, useState } from "react";
import { useDebouncedState, useLocalStorage, useMediaQuery } from "@mantine/hooks";
import TableLoader from "./TableLoader.jsx";
import TableEmptyState from "./TableEmptyState.jsx";
import TableMobileSort from "./TableMobileSort.jsx";
import { useFetch } from "../../../helpers/useFetch.js";
import { useRevalidator, useSearchParams } from "react-router-dom";
import { useRowExpansion } from "./hooks.js";
import TableRowExpansion from "./TableRowExpansion.jsx";
import { getMode } from "../../../helpers/helpers";

export default function Table({
  id,
  columns,
  records,
  useServer = false,
  fetching,
  serverUrl,
  responsiveComponent,
  rowExpansion,
  filterConfig,
  idAccessor = "id",
  sortFunction,
  filtersParent,
  onRowClick,
  shouldServerFetch,
  setShouldServerFetch,
  serverFetching,
  setServerFetching,
}) {
  if (!records) records = [];
  const [currentSortColumn, setCurrentSortColumn] = useState(null);
  const [currentSortDirection, setCurrentSortDirection] = useState("");
  const [recordItems, setRecordItems] = useState(records);
  const [recordsLength, setRecordsLength] = useState(null);
  const [currentMeta, setCurrentMeta] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const mobile = useMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(fetching);
  const revalidator = useRevalidator();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) ? parseInt(searchParams.get("page")) : 1
  );
  const [columnsLocal, setColumnsLocal] = useState(columns);
  const [search, setSearch] = useDebouncedState("", 300);
  const [filtersChanged, setFiltersChanged] = useState(false);
  const [shownColumns, setShownColumns] = useLocalStorage({
    key: "table-columns-" + id,
    defaultValue: columns.map((column) => column.accessor),
  });
  const [filters, setFilters] = useState(() => {
    let filterLocal = {};
    filterConfig?.map((filterItem) => {
      filterLocal[filterItem.key] = filterItem.defaultValue ? filterItem.defaultValue : "";
    });
    return filterLocal;
  });
  const [oldFiltersParent, setOldFiltersParent] = useState(filtersParent);

  const [firstSortColumn, setFirstSortColumn] = useState(null);

  const searchRef = useRef();

  const clearSearch = () => {
    setSearch("");
    searchRef.current.value = "";
    searchRef.current.focus();
  };

  const initializeTable = () => {
    if (useServer) {
      fetchData(searchParams);
    }
    if (shownColumns.length > 0) {
      setColumnsLocal(
        columns.map((column) => ({
          ...column,
          hidden: !shownColumns.includes(column.accessor) || column.defaultHidden,
        }))
      );
    }
    setFilters({
      ...filters,
      ...filtersParent,
    });

    const firstSortCol = columns.find((col) => col.firstSort);
    if (firstSortCol) {
      setFirstSortColumn(firstSortCol);
    }
  };

  useEffect(() => {
    initializeTable();
  }, []);

  useEffect(() => {
    setColumnsLocal(
      columns.map((column) => ({
        ...column,
        hidden: !shownColumns.includes(column.accessor) || (column.defaultHidden && !column.showInCols),
      }))
    );
  }, [shownColumns]);

  const rowExpansionInfo = useRowExpansion({ rowExpansion, records, idAccessor });

  useEffect(() => {
    setLoading(revalidator.state === "loading");
    setColumnsLocal(
      columns.map((column) => ({
        ...column,
        hidden: !shownColumns.includes(column.accessor) || (column.defaultHidden && !column.showInCols),
      }))
    );
  }, [revalidator.state]);

  useEffect(() => {
    setLoading(fetching);
  }, [fetching]);

  useEffect(() => {
    if (shouldServerFetch) {
      fetchData();
      setShouldServerFetch(false);
    }
  }, [shouldServerFetch]);

  useEffect(() => {
    setRecordsLength(recordItems?.length);
  }, [recordItems]);

  useEffect(() => {
    if (useServer) return;
    setRecordItems(records);
  }, [records]);

  useEffect(() => {
    let searchFilter = filterConfig?.filter((filter) => filter.type === "search");
    if (searchFilter && searchFilter.length > 0) {
      searchFilter = searchFilter[0];
      if (searchFilter.key) {
        if (search) {
          setFilters({
            ...filters,
            [searchFilter.key]: search,
          });
        } else {
          setFilters({
            ...filters,
            [searchFilter.key]: "",
          });
        }
      }
    }
  }, [search]);

  const fetchData = async (params) => {
    if (!useServer) return;
    if (setServerFetching) setServerFetching(true);

    setLoading(true);

    let queryParams;

    if (params) {
      queryParams = params;
    } else {
      queryParams = new URLSearchParams();
    }

    if (currentPage > 0) {
      if (queryParams.get("page")) {
        queryParams.delete("page");
      }
      queryParams.append("page", currentPage.toString());
    }

    if (currentSortColumn) {
      if (queryParams.get("sort")) {
        queryParams.delete("sort");
      }
      queryParams.append("sort", (currentSortDirection === "asc" ? "" : "-") + currentSortColumn);
    }

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (value !== "") {
          if (queryParams.get(`filter[${key}]`)) {
            queryParams.delete(`filter[${key}]`);
          }
          queryParams.append(`filter[${key}]`, value);
        } else {
          if (queryParams.get(`filter[${key}]`)) {
            queryParams.delete(`filter[${key}]`);
          }
        }
      }
    }

    setSearchParams(queryParams);
    const data = await useFetch(serverUrl + "?" + queryParams.toString());

    setCurrentMeta(
      <Text>
        {data.meta.from} – {data.meta.to}
        <Text span c="dimmed">
          {" "}
          / {data.meta.total}
        </Text>
      </Text>
    );
    setPageCount(data.meta.last_page);
    setRecordItems(data.data);
    setLoading(false);
    if (setServerFetching) {
      setTimeout(() => {
        setServerFetching(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (useServer) {
      fetchData(searchParams);
    }
  }, [currentPage, currentSortDirection]);

  useEffect(() => {
    if (useServer) {
      setCurrentPage(1);
      fetchData(searchParams);
    } else {
      if (filters) {
        let filteredRecords = records.filter((record) => {
          for (const [key, value] of Object.entries(filters)) {
            if (value !== "") {
              if (!filterConfig.find((filter) => filter.key === key).function(record, value)) {
                return false;
              }
            }
          }
          return true;
        });

        setRecordItems(filteredRecords);
      }
    }
    let filtersChanged = false;
    Object.keys(filters).map((key) => {
      if (filters[key] !== "") {
        filtersChanged = true;
      }
    });
    setFiltersChanged(filtersChanged);
  }, [filters]);

  useEffect(() => {
    if (!filtersParent) return;
    if (Object.keys(filtersParent).length === 0) return;
    if (JSON.stringify(oldFiltersParent) === JSON.stringify(filtersParent)) return;
    setOldFiltersParent(filtersParent);
    Object.keys(filtersParent).map((key) => {
      if (filtersParent[key] !== filters[key]) {
        setFilters({
          ...filters,
          [key]: String(filtersParent[key]),
        });
      }
    });
  }, [filtersParent]);

  const handleSort = (column) => {
    let sortOrder = column.accessor === currentSortColumn && currentSortDirection === "asc" ? "desc" : "asc";

    if (column.accessor !== currentSortColumn) {
      if (column.firstSort) sortOrder = column.firstSort;
    }

    setCurrentSortColumn(column.accessor);
    setCurrentSortDirection(sortOrder);

    if (!useServer) {
      let filteredRecords = records.filter((record) => {
        for (const [key, value] of Object.entries(filters)) {
          if (value !== "") {
            if (!filterConfig.find((filter) => filter.key === key).function(record, value)) {
              return false;
            }
          }
        }
        return true;
      });

      setRecordItems(sortFunction(column.accessor, sortOrder, filteredRecords));
    }
  };

  useEffect(() => {
    if (firstSortColumn) {
      handleSort(firstSortColumn);
    }
  }, [firstSortColumn]);

  const mobileComponent = responsiveComponent ? cloneElement(responsiveComponent, { records: recordItems }) : undefined;

  const { mode, theme } = getMode();

  return (
    <>
      {mobile && responsiveComponent ? (
        <>
          <TableMobileSort
            columns={columns}
            handleSort={handleSort}
            currentSortColumn={currentSortColumn}
            currentSortDirection={currentSortDirection}
          />
          {mobileComponent}
        </>
      ) : (
        <div
          className={classNames("relative overflow-x-auto rounded-lg border-2 border-solid", {
            "border-dark-400": mode === "dark",
            "border-gray-600": mode === "light",
          })}>
          <ScrollArea>
            <MantineTable
              striped
              highlightOnHover
              captionSide="bottom"
              className={classNames(
                "max-w-full rounded-lg",
                styles.Table,
                recordItems.length === 0 ? styles.Empty : "",
                pageCount > 0 && "rounded-b-none"
              )}>
              <MantineTable.Thead className="border-b-2">
                <MantineTable.Tr>
                  <MantineTable.Th
                    colSpan="100"
                    className={classNames("border-0 border-b-2 border-solid px-2 py-2 font-normal", {
                      "border-dark-400": mode === "dark",
                      "border-gray-600": mode === "light",
                    })}>
                    <div className="flex justify-between gap-3">
                      <div className="w-full">
                        {filterConfig?.filter((filter) => filter.type === "search").length > 0 && (
                          <TextInput
                            placeholder="Hledat..."
                            onChange={(e) => setSearch(e.currentTarget.value)}
                            defaultValue={search}
                            className="w-full"
                            ref={searchRef}
                            rightSection={
                              search.length > 0 && !loading && <IconX onClick={clearSearch} size={16} stroke={1.5} />
                            }
                            leftSection={<IconSearch stroke={1.5} size={16} />}
                          />
                        )}
                      </div>
                      <div className="flex gap-3">
                        {filterConfig?.filter((filter) => filter.type !== "search" && !filter.defaultHidden).length >
                          0 && (
                          <Popover withinPortal position="bottom-end">
                            <Popover.Target>
                              <Button
                                leftSection={<IconFilter stroke={1.5} size="18" />}
                                variant="light"
                                color={filtersChanged ? undefined : "gray"}>
                                Filtry
                              </Button>
                            </Popover.Target>
                            <Popover.Dropdown bg={mode === "dark" ? "dark.8" : "#fff"} w={300}>
                              <div className="grid grid-cols-[min-content_1fr] items-center gap-x-8 gap-y-2">
                                {filterConfig
                                  .filter((filter) => filter.type !== "search")
                                  .map((filter, i) => {
                                    return (
                                      <Fragment key={i}>
                                        <Text size="sm" fw="bold" className="w-fit">
                                          {filter.label}
                                        </Text>
                                        {filter.type === "select" && (
                                          <Select
                                            size="sm"
                                            value={filters[filter.key]}
                                            onChange={(value) => {
                                              setFiltersChanged(true);
                                              setFilters({ ...filters, [filter.key]: value });
                                            }}
                                            defaultValue={""}
                                            data={
                                              filter?.options?.length > 0
                                                ? [{ label: "Vše", value: "" }, ...filter.options]
                                                : []
                                            }
                                            disabled={filter.unchangeable}
                                          />
                                        )}
                                        {filter.type === "switch" && (
                                          <Switch
                                            checked={filters[filter.key]}
                                            defaultChecked={filter.defaultValue}
                                            onChange={(event) => {
                                              setFiltersChanged(true);
                                              setFilters({
                                                ...filters,
                                                [filter.key]: event.currentTarget.checked ? true : "",
                                              });
                                            }}
                                            disabled={filter.unchangeable}
                                          />
                                        )}
                                        {filter.type === "text" && (
                                          <TextInput
                                            size="sm"
                                            value={filters[filter.key]}
                                            onChange={(e) => {
                                              setFiltersChanged(true);
                                              setFilters({ ...filters, [filter.key]: e.currentTarget.value });
                                            }}
                                            disabled={filter.unchangeable}
                                          />
                                        )}
                                      </Fragment>
                                    );
                                  })}
                              </div>
                              <Button
                                size="sm"
                                variant="light"
                                color="gray"
                                mt="sm"
                                fullWidth
                                onClick={() => {
                                  setFiltersChanged(false);
                                  const localSearchParams = new URLSearchParams(searchParams);
                                  searchParams.forEach((value, key) => {
                                    if (key.startsWith("filter")) {
                                      localSearchParams.delete(key);
                                    }
                                  });
                                  setSearchParams(localSearchParams);
                                  setFilters({});
                                }}>
                                Resetovat
                              </Button>
                            </Popover.Dropdown>
                          </Popover>
                        )}
                        <Popover withinPortal position="bottom-end">
                          <Popover.Target>
                            <Button
                              leftSection={<IconColumns3 stroke={1.5} size="18" />}
                              variant="light"
                              color="gray"
                              onClick={() => setFiltersOpen(!filtersOpen)}>
                              Sloupce
                            </Button>
                          </Popover.Target>
                          <Popover.Dropdown bg={mode === "dark" ? "dark.8" : "#fff"} miw={200}>
                            <div className="table-filters grid items-center gap-x-8 gap-y-2">
                              {columnsLocal.map((column, i) => {
                                if (!column.defaultHidden || column.showInCols) {
                                  return (
                                    <Fragment key={i}>
                                      <Text size="sm" fw="bold" className="w-fit">
                                        {column.subtitle ? column.subtitle : column.title}
                                      </Text>
                                      <Checkbox
                                        size="sm"
                                        checked={!column.hidden && shownColumns.includes(column.accessor)}
                                        disabled={column.disableHiding}
                                        onChange={(event) => {
                                          if (event.currentTarget.checked) {
                                            setShownColumns([...shownColumns, column.accessor]);
                                          } else {
                                            setShownColumns(shownColumns.filter((item) => item !== column.accessor));
                                          }
                                        }}
                                      />
                                    </Fragment>
                                  );
                                }
                              })}
                            </div>
                            <Button
                              size="sm"
                              variant="light"
                              color="gray"
                              mt="sm"
                              fullWidth
                              onClick={() => setShownColumns(columns.map((column) => column.accessor))}>
                              Resetovat
                            </Button>
                          </Popover.Dropdown>
                        </Popover>
                      </div>
                    </div>
                  </MantineTable.Th>
                </MantineTable.Tr>
                <MantineTable.Tr>
                  {columnsLocal
                    .filter((column) => !column.hidden && shownColumns.includes(column.accessor))
                    .map((column, i) => {
                      return (
                        <MantineTable.Th
                          key={i}
                          className={"px-6 py-2 font-bold " + (!column.sortable ? "cursor-default" : "cursor-pointer")}
                          onClick={() => {
                            column.sortable && handleSort(column);
                          }}>
                          <div className="flex items-center justify-between">
                            {column.title}
                            {column.sortable &&
                              (currentSortColumn === column.accessor ? (
                                currentSortDirection === "asc" ? (
                                  <IconArrowUp stroke={1.5} size="15" />
                                ) : (
                                  <IconArrowDown stroke={1.5} size="15" />
                                )
                              ) : (
                                <IconArrowsMoveVertical stroke={1.5} size="15" />
                              ))}
                          </div>
                        </MantineTable.Th>
                      );
                    })}
                </MantineTable.Tr>
              </MantineTable.Thead>
              <MantineTable.Tbody>
                <TableLoader fetching={loading} />
                <TableEmptyState active={!fetching && !recordsLength} />

                {recordItems.map((record, recordIndex) => {
                  return (
                    <React.Fragment key={recordIndex}>
                      <MantineTable.Tr
                        className={
                          onRowClick || rowExpansionInfo ? "cursor-pointer last:border-b-0" : "last:border-b-0"
                        }
                        onClick={(e) => {
                          if (rowExpansionInfo) {
                            const { isRowExpanded, expandOnClick, expandRow, collapseRow } = rowExpansionInfo;
                            if (expandOnClick) {
                              if (isRowExpanded(record)) {
                                collapseRow(record);
                              } else {
                                expandRow(record);
                              }
                            }
                          } else if (onRowClick) {
                            onRowClick(record);
                          }
                        }}>
                        {columnsLocal
                          .filter((column) => !column.hidden && shownColumns.includes(column.accessor))
                          .map((column, colIndex) => {
                            return (
                              <MantineTable.Td
                                className={classNames("px-6 py-2", {
                                  [`${styles.narrow}`]: column.narrow,
                                })}
                                key={colIndex}>
                                {column.render(record)}
                              </MantineTable.Td>
                            );
                          })}
                      </MantineTable.Tr>
                      {rowExpansionInfo && (
                        <TableRowExpansion
                          colSpan={columnsLocal.filter((c) => !c.hidden).length}
                          open={rowExpansionInfo.isRowExpanded(record)}
                          content={rowExpansionInfo.content(record, recordIndex)}
                          collapseProps={rowExpansionInfo.collapseProps}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </MantineTable.Tbody>
              {useServer && (
                <MantineTable.Caption className="mt-0">
                  <Group
                    justify="space-between"
                    pl="md"
                    pr="xs"
                    py="xs"
                    className={classNames("rounded-b-md border-0 border-t border-solid", {
                      "border-dark-400 bg-dark-100": mode === "dark",
                      "border-gray-600 bg-gray-800": mode === "light",
                    })}>
                    <Text>{currentMeta}</Text>
                    <Pagination
                      value={currentPage}
                      onChange={(page) => {
                        setCurrentPage(page);
                      }}
                      total={pageCount}
                    />
                  </Group>
                </MantineTable.Caption>
              )}
            </MantineTable>
          </ScrollArea>
        </div>
      )}
    </>
  );
}
