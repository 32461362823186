import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch.js";
import { useRevalidator } from "react-router-dom";
import { Button, Group } from "@mantine/core";
import { IconCamera, IconFile, IconFileStar, IconWriting } from "@tabler/icons-react";
import { openFilePhotoModal } from "../../helpers/modals.js";

export default function FileSelectModal({ context, id, innerProps }) {
  const revalidator = useRevalidator();
  const { document, childId, openSignature, handoverDocument, documents, setDocuments } = innerProps;
  console.log(innerProps.document);

  const handleClick = (type, source) => {
    context.closeModal(id);
    if (type === "handover") {
      handoverDocument(document, source);
    } else {
      openSignature(document);
    }
  };

  useEffect(() => {
    console.log(document.type);
  }, [document]);

  const deleteDocument = () => {
    context.closeModal(id);
    toast.promise(
      useFetch(`/children/${childId}/documents`, "POST", {
        type: document.type,
        status: "none",
        original: false,
        filePath: null,
      }),
      {
        loading: "Mazání dokumentu...",
        success: () => {
          innerProps?.setShouldFetch && innerProps.setShouldFetch(true);
          return "Dokument byl úspěšně odstraněn";
        },
        error: () => "Nastala chyba při mazání dokumentu",
      }
    );
  };

  let message;
  let photoMsg;

  if (document.type !== "identity_card") {
    if (document.preview) {
      message = "Chceš dokument podepsat elektronicky, nebo jsi ho dostal podepsaný na papíře?";
      photoMsg = "Pokuď máš na akre volnou chvíli můžeš ho následně vyfotit, jinak to nech na potom.";
    } else {
      if (document.status === "paper") {
        message = "Chceš dokument vyfotit nebo ho odstranit?";
      } else {
        message = "Převzal jsi dokument?";
        photoMsg = "Pokuď máš na akre volnou chvíli můžeš ho následně vyfotit, jinak to nech na potom.";
      }
    }
  } else {
    message = document.id ? "Chceš dokument odstranit?" : "Převzal jsi originál nebo kopii?";
  }

  return (
    <div>
      <p>{message}</p>
      {photoMsg && <p className="text-sm opacity-50">{photoMsg}</p>}
      <Group gap="sm" mt="lg" grow={1} className="w-full">
        {document.allowPaper && document.preview && (
          <Button onClick={() => handleClick("handover")}>
            <IconFile className="mr-1" size={20} stroke={1.5} /> Dostal jsem papír
          </Button>
        )}
        {!document.preview && document.status !== "paper" && document.type === "identity_card" && (
          <>
            <Button onClick={() => handleClick("handover", "original")}>
              <IconFileStar className="mr-1" size={20} stroke={1.5} /> Originál
            </Button>
            <Button onClick={() => handleClick("handover", "copy")}>
              <IconFile className="mr-1" size={20} stroke={1.5} /> Kopie
            </Button>
          </>
        )}
        {!document.preview && document.status !== "paper" && document.type !== "identity_card" && (
          <>
            <Button onClick={() => handleClick("handover", "takeover")}>
              <IconFile className="mr-1" size={20} stroke={1.5} /> Převzal jsem dokument
            </Button>
          </>
        )}
        {document.preview && (
          <Button onClick={() => handleClick("signature")}>
            <IconWriting className="mr-1" size={20} stroke={1.5} />
            Podepsat elektronicky
          </Button>
        )}
      </Group>
      {document.type !== "identity_card" && document.id && (
        <Button
          onClick={() =>
            openFilePhotoModal(
              {
                id: childId,
              },
              document.type,
              () => context.closeModal(id),
              innerProps?.setShouldFetch
            )
          }
          fullWidth
          mt="sm"
          variant="filled">
          <IconCamera className="mr-1" size={20} stroke={1.5} />
          Vyfotit dokument
        </Button>
      )}

      {document.status !== "none" && (
        <Button onClick={() => deleteDocument()} fullWidth mt="sm" color="red" variant="filled">
          Odstranit dokument
        </Button>
      )}
    </div>
  );
}
