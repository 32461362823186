import { useState, useEffect } from "react";
import useTitle from "../../hooks/useTitle";
import { Link } from "react-router-dom";
import styles from "../../pages.module.scss";
import classNames from "classnames";
import { Button, Loader, Stack, Text, Title } from "@mantine/core";
import { IconChevronRight, IconHome } from "@tabler/icons-react";

const FancyLink = ({ href, children }) => {
  return <Link to={href}>{children}</Link>;
};

function DynamicImport({ path }) {
  const [Component, setComponent] = useState(null);
  const [loading, setLoading] = useState(true);

  const [title, setTitle] = useState("");

  useTitle(title);

  useEffect(() => {
    async function importMdx() {
      const comps = import.meta.glob("../../pages/manual/**/*.mdx");

      let finalPath = path;

      if (finalPath?.length > 1) {
        finalPath = path;
      } else {
        finalPath = "index";
      }

      let match = comps[`../../pages/manual/${finalPath}.mdx`];

      if (!match) {
        match = comps[`../../pages/manual/${finalPath}/index.mdx`];
      }
      if (match) {
        const importedFile = await match();
        setLoading(false);
        const page = importedFile.default;
        const title = importedFile.title;
        setTitle(title);
        setComponent(() => page);
      } else {
        setComponent(() => null);
        setLoading(false);
      }
    }
    importMdx();
  }, [path]);

  if (loading) {
    return (
      <div className={classNames("pb-8", styles.Content)}>
        <div className="relative flex h-full items-center justify-center">
          <Loader />
        </div>
      </div>
    );
  }

  if (!Component) {
    return (
      <div className={classNames("pb-8", styles.Content)}>
        <div className="relative flex h-full items-center justify-center">
          <Stack justify="center" align="center" gap="sm">
            <Title className="font-headline" order={1} color="indigo.4">
              404
            </Title>
            <Text mb="lg">Tato stránka návodů neexistuje.</Text>
            <Button>
              <Link to="/navody">Zpět na domovskou stránku</Link>
            </Button>
          </Stack>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames("pb-8", styles.Content)}>
      {title && (
        <div
          className={classNames(
            "flex items-center justify-between px-4 py-3 md:px-12 md:py-8",
            styles.Content__header
          )}>
          <div>
            <Text
              className="mb-1 flex items-center gap-2 text-sm leading-none tracking-wider"
              transform="uppercase"
              color="dimmed">
              {/* Splitnout path, v každé složce bude index, ten importovat (když nebude, dát prostě název), převzít constatu title z každého */}
              <Link to="/navody" className="duration-150 hover:text-white-900">
                <IconHome size={16} stroke={1.5} />
              </Link>
              <IconChevronRight size={16} stroke={1.5} />
              <Link to="/deti" className="duration-150 hover:text-white-900">
                Děti
              </Link>
              <IconChevronRight size={16} stroke={1.5} />
              <Link to="/deti" className="duration-150 hover:text-white-900">
                Další stránka
              </Link>
            </Text>
            <h1>{title}</h1>
          </div>
        </div>
      )}

      <div className={classNames("mb-12 px-4 md:px-12", styles.Content__content)}>
        <Component components={{ a: FancyLink }} />
      </div>
    </div>
  );
}

export default DynamicImport;
