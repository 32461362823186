export const earlyLeaveReasons = [
  {
    value: "illness",
    label: "Nemoc nebo zranění",
  },
  {
    value: "expelled",
    label: "Nekázeň, šikana, alkohol, jiné porušení táborového řádu",
  },
  {
    value: "homesickness",
    label: "Stesk po domově",
  },
  {
    value: "family_reason",
    label: "Rodinné důvody",
  },
  {
    value: "no_arrive",
    label: "Nepřijel",
  },
];
