import { Grid, Loader, Text, Title } from "@mantine/core";
import { IconShirt, IconUser, IconListDetails, IconDoorEnter, IconDoorExit } from "@tabler/icons-react";
import { forwardRef, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import BoardingMerchOverviewDrawer from "../../components/boarding/BoardingMerchOverviewDrawer";
import Page from "../../components/layout/Page";
import { useFetch } from "../../helpers/useFetch";
import useTitle from "../../hooks/useTitle";
import DelegateOverviewDrawer from "../../components/boarding/DelegateOverviewDrawer";
import dayjs from "dayjs";
import classNames from "classnames";
import { getMode } from "../../helpers/helpers";
import RichSelect from "../../components/RichSelect";

const SelectItem = forwardRef(({ hasLeft, label, ...others }, ref) => {
  const { theme } = getMode();
  return (
    <div ref={ref} {...others}>
      <Text className="flex items-center gap-2">
        <Text span className={classNames({ "opacity-50": hasLeft })}>
          {label}
        </Text>{" "}
        {hasLeft && <IconDoorExit color={theme.colors.red[6]} size={16} stroke={1.5} />}
      </Text>
    </div>
  );
});

export default function BoardingLayout() {
  const [child, setChild] = useState(null);
  const [childrenList, setChildrenList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState(null);
  const [showSidebar, setShowSidebar] = useState(true);
  const [openMerchOverviewDrawer, setOpenMerchOverviewDrawer] = useState(false);
  const [openedDelegateDrawer, setOpenedDelegateDrawer] = useState(false);
  const [type, setType] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [apiResponse, setApiResponse] = useState(null);
  const [outgoingMoney, setOutgoingMoney] = useState(null);
  const [stats, setStats] = useState(null);
  let location = useLocation();
  const navigate = useNavigate();

  useTitle("Akreditace – " + title);

  useEffect(() => {
    let typeLocal = location.pathname.split("/")[2];
    let layout = layouts.find((layout) => layout.path === typeLocal);
    useFetch(
      layout.path === "delegat-prijezd" || layout.path === "delegat-odjezd"
        ? `children/list/transport?transportType=${layout.path === "delegat-prijezd" ? "arrival" : "departure"}`
        : "children/list/accreditation"
    ).then((res) => {
      setApiResponse(res.data);
      setStats(res.stats);
      setLoading(false);

      setChildrenList(
        res.data
          .filter((child) => {
            if (typeof layout.condition === "function") {
              return layout.condition(child);
            } else {
              return true;
            }
          })
          .map((child) => ({
            value: String(child.id),
            label: `${child.firstName} ${child.lastName} – ${child?.team ? child?.team + ", " : ""}${dayjs(
              child.birthDate
            ).format("D. M. YYYY")} (${child.age} let)`,
            hasLeft: child.hasLeft,
          }))
          .sort((a, b) => (a.lastName > b.lastName ? 1 : -1))
          .sort((a, b) => (a.hasLeft ? 1 : -1))
      );
    });
  }, [location]);

  useEffect(() => {
    if (child !== null) {
      setSearchParams({ child });
    }
  }, [child]);

  const getOutgoingMoney = async () => {
    const res = await useFetch("/money/deposit");
    setOutgoingMoney(res.data.area);
  };

  useEffect(() => {
    setType(location.pathname.split("/")[2]);
    if (location.pathname.split("/")[2] === "odjezdy") getOutgoingMoney();
  }, [location]);

  useEffect(() => {
    if (childrenList.length !== 0) {
      setLoading(false);
      if (searchParams.get("child")) {
        if (childrenList.find((child) => child.value === searchParams.get("child"))) {
          setChild(searchParams.get("child"));
        } else {
          setSearchParams(searchParams.delete("child"));
        }
      }
    }
  }, [childrenList]);

  const actionButtonsAll = [
    {
      name: "Hlavní přehled akreditace",
      onTrigger: () => navigate("/akreditace/prehled"),
      icon: IconListDetails,
    },
  ];

  const actionButtonsMerch = [
    {
      name: "Přehled celého merche",
      onTrigger: () => setOpenMerchOverviewDrawer(true),
      icon: IconShirt,
    },
    {
      name: "Hlavní přehled akreditace",
      onTrigger: () => navigate("/akreditace/prehled"),
      icon: IconListDetails,
    },
  ];

  const actionButtonsDelegate = [
    {
      name: "Přehled dopravy",
      onTrigger: () => setOpenedDelegateDrawer(true),
      icon: IconListDetails,
    },
    {
      name: "Hlavní přehled akreditace",
      onTrigger: () => navigate("/akreditace/prehled?type=bus"),
      icon: IconDoorEnter,
    },
  ];

  const layouts = [
    {
      name: "Merch",
      path: "merch",
      sidebar: false,
      condition: true,
      actionButtons: actionButtonsMerch,
    },
    {
      name: "Řády a kauce",
      path: "rady-a-kauce",
      sidebar: false,
      condition: true,
      actionButtons: actionButtonsAll,
    },
    {
      name: "Kapesné",
      path: "kapesne",
      sidebar: false,
      condition: true,
      actionButtons: actionButtonsAll,
    },
    {
      name: "Ubytování",
      path: "ubytovani",
      sidebar: false,
      condition: true,
      actionButtons: actionButtonsAll,
    },
    {
      name: "Zdravotník",
      path: "zdravotnik",
      sidebar: false,
      condition: true,
      actionButtons: actionButtonsAll,
    },
    {
      name: "Delegát – příjezd",
      path: "delegat-prijezd",
      sidebar: true,
      actionButtons: actionButtonsDelegate,
    },
    {
      name: "Delegát – odjezd",
      path: "delegat-odjezd",
      sidebar: true,
      actionButtons: actionButtonsDelegate,
    },
    {
      name: "Odjezdy",
      path: "odjezdy",
      sidebar: true,
      condition: true,
      actionButtons: actionButtonsAll,
    },
  ];

  useEffect(() => {
    setStats(null);
    if (type) {
      let layout = layouts.find((layout) => layout.path === type);
      setTitle(layout.name);
      setShowSidebar(layout.sidebar);
      if (apiResponse) {
        setChildrenList(
          apiResponse.map((child) => ({
            value: String(child.id),
            label: `${child.firstName} ${child.lastName} – ${child?.team ? child?.team + ", " : ""}${dayjs(
              child.birthDate
            ).format("D. M. YYYY")} (${child.age} let)`,
          }))
        );
      }
    }
  }, [type]);

  return (
    <Page
      title={title}
      subtitle="Akreditace"
      subtitleDown={
        type && type.includes("delegat")
          ? stats && type.includes("prijezd")
            ? `Přijelo: ${stats.arrived}/${stats.total}, zbývá: ${stats.total - stats.arrived} dětí`
            : stats && type.includes("odjezd")
            ? `Odjelo: ${stats.left}/${stats.total}, zbývá: ${stats.total - stats.left} dětí`
            : "Načítám statistiky..."
          : stats && type === "odjezdy"
          ? stats &&
            type &&
            `Odjelo: ${stats.left}/${stats.total}, zbývá: ${stats.total - stats.left} dětí, vydat kaucí: ${
              outgoingMoney?.czk
            } Kč a ${outgoingMoney?.eur} EUR`
          : stats
          ? `Přijelo: ${stats.arrived}/${stats.total}, zbývá: ${stats.total - stats.arrived} dětí`
          : "Načítám statistiky..."
      }
      actionIcons={layouts.find((layout) => layout.path === type)?.actionButtons}>
      {loading ? (
        <div className="flex h-full w-full items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <Grid>
            <Grid.Col span="auto">
              <RichSelect
                allowDeselect
                className="mb-4"
                defaultValue={searchParams.get("child")}
                value={child}
                onChange={setChild}
                placeholder="Vyber dítě..."
                leftSection={<IconUser stroke={1.5} size={16} />}
                searchable
                clearable
                onFocus={(e) => e.target.select()}
                hoverOnSearchChange={true}
                data={childrenList}
                label={SelectItem}
              />
              {child ? (
                <Outlet context={child} />
              ) : (
                <div className="mt-8 flex flex-col items-center">
                  <Title order={2}>Nemáš vybrané dítě</Title>
                  <Text c="dimmed">Vyber si ho v selectu nahoře.</Text>
                </div>
              )}
            </Grid.Col>

            {/* {layouts.find((layout) => layout.path === type)?.sidebar && (
              <Grid.Col span={3} className="hidden md:block">
                <ChildrenList />
              </Grid.Col>
            )} */}
          </Grid>
        </>
      )}
      {type === "merch" && (
        <BoardingMerchOverviewDrawer
          isOpen={openMerchOverviewDrawer}
          onClose={() => setOpenMerchOverviewDrawer(false)}
        />
      )}
      {(type === "delegat-prijezd" || type === "delegat-odjezd") && (
        <DelegateOverviewDrawer
          opened={openedDelegateDrawer}
          setOpened={setOpenedDelegateDrawer}
          type={type === "delegat-odjezd" ? "departure" : "arrival"}
        />
      )}
    </Page>
  );
}
