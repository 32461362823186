import LightGallery from "lightgallery/react";

// If you want you can use SCSS instead of css
import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";

// import plugins if you need
import lgZoom from "lightgallery/plugins/zoom";
import { Group, Text } from "@mantine/core";

export default function Gallery({ thumbClassname, images }) {
  /* const images = [
    {
      src: "https://picsum.photos/id/1018/1000/600/",
      thumb: "https://picsum.photos/id/1018/250/150/",
      description: "Test",
    },
    {
      src: "https://picsum.photos/id/1015/1000/600/",
      thumb: "https://picsum.photos/id/1015/250/150/",
      description: "Test",
    },
    {
      src: "https://picsum.photos/id/1019/1000/600/",
      thumb: "https://picsum.photos/id/1019/250/150/",
      description: "Test",
    },
  ]; */

  return images ? (
    <LightGallery elementClassNames="flex gap-3" speed={250} plugins={[lgZoom]}>
      {images.map((image, index) => (
        <a data-sub-html={image.description && image.description} href={image.src} key={index}>
          <img
            className={thumbClassname}
            src={image.thumb ? image.thumb : image.src}
            alt={image.description ? image.description : `Obrázek ${index + 1}`}
          />
        </a>
      ))}
    </LightGallery>
  ) : (
    <Text color="red">Žádné obrázky</Text>
  );
}
