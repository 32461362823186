import React, { useEffect } from "react";

import { Group, Text } from "@mantine/core";
import { IconBus, IconWalk } from "@tabler/icons-react";
import classNames from "classnames";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { getMode } from "../../helpers/helpers";
import styles from "./ChildrenList.module.scss";

/**
 * Renders a list based on the type provided.
 *
 * @param {Object[]} people - Array of people objects, can be children or staff.
 * @param {'room' | 'staff'} type - The type to determine rendering logic, between staff and children.
 * @param {'age' | 'name'} sortBy - The property to sort by, either age or name.
 * @returns {JSX.Element} A rendered list component.
 */
const PeopleList = ({ people, type, sortBy }) => {
  const { mode, theme } = getMode();
  const [peopleLocal, setPeopleLocal] = React.useState([]);

  function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    return {
      ...style,
    };
  }
  useEffect(() => {
    if (type === "staff") {
      console.log("xd");
    }
    const unsorted =
      type === "room"
        ? people.filter((person) => !person.isAccommodated || person.roomReservation)
        : people.filter((person) => !person.roomName);

    if (sortBy === "age") {
      setPeopleLocal(unsorted.sort((personA, personB) => personA.age - personB.age));
      console.log(sortBy, type);
    } else if (sortBy === "name") {
      setPeopleLocal(unsorted.sort((personA, personB) => personA.lastName.localeCompare(personB.lastName)));
      console.log(sortBy, type);
    }
  }, [people, type, sortBy]);

  return (
    <Droppable droppableId="childList">
      {(provided, snap) => (
        <div ref={provided.innerRef} {...provided.droppableProps} className="mt-2">
          {peopleLocal.map((person, i) => (
            <Draggable key={person.id} draggableId={`${person.id}`} index={i}>
              {(provided, snap) => (
                <Group
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getStyle(provided.draggableProps.style, snap)}
                  align="center"
                  wrap="nowrap"
                  p={4}
                  mb={4}
                  className={classNames(styles.Child, "whitespace-nowrap")}
                  justify="space-between"
                  key={person.id}>
                  <Text lineClamp={1} className={classNames("flex items-center", styles.nameDisplay)}>
                    {person.arriveByBus && (
                      <IconBus
                        color={person.roomReservation ? theme.colors.orange[5] : mode === "dark" ? "white" : "black"}
                        className="mr-2 shrink-0"
                        stroke={1.5}
                        size={20}
                      />
                    )}
                    {!person.arriveByBus && (
                      <IconWalk
                        color={person.roomReservation ? theme.colors.orange[5] : mode === "dark" ? "white" : "black"}
                        className="mr-2 shrink-0"
                        stroke={1.5}
                        size={20}
                      />
                    )}
                    <Text c={person.gender === "Dívka" ? "pink.6" : undefined} span>
                      {person.firstName} {person.lastName}
                    </Text>
                  </Text>
                  <Text c="dimmed">
                    {person.age}
                    {type === "room" ? (person.team ? ` ${person.team}` : "") : ""}
                  </Text>
                </Group>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default PeopleList;
