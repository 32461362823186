import { Group, NumberInput, Text, TextInput, Tooltip, ActionIcon, Textarea, Button, MultiSelect } from "@mantine/core";
import Drawer from "../drawer/Drawer";
import { IconDeviceDesktop, IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import Table from "../table/core/Table";
import { useFetch } from "../../helpers/useFetch";
import { toast } from "react-hot-toast";
import { useRevalidator } from "react-router-dom";
import { CreatableMultiSelect } from "../CreatableMultiSelect";

export default function RoomManageDrawer({ isOpen, onClose, room, rooms }) {
  const [computerLoading, setComputerLoading] = useState(false);
  const [roomSaveLoading, setRoomSaveLoading] = useState(false);
  const [roomLocal, setRoomLocal] = useState({});
  const [creatingComputers, setCreatingComputers] = useState([]);

  const revalidator = useRevalidator();

  const deleteComputer = async (id) => {
    setComputerLoading(true);
    const res = await useFetch(`/pcapp/room/computer/${id}`, "DELETE");

    if (res.status === "ok") {
      toast.success("Počítač byl úspěšně smazán");
      setComputerLoading(false);
      setRoomLocal({
        ...roomLocal,
        computers: roomLocal.computers.filter((computer) => computer.id !== id),
      });
      revalidator.revalidate();
    } else {
      toast.error("Něco se pokazilo při mazání počítače.");
      setComputerLoading(false);
      console.error(res);
    }
  };

  const actionButtons = (computer) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Odstranit počítač">
          <ActionIcon
            variant="light"
            color="red"
            onClick={(e) => {
              e.stopPropagation();
              deleteComputer(computer.id);
            }}>
            <IconTrash size={18} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const columns = [
    {
      accessor: "name",
      title: "Hostname",
      render: (computer) => computer.hostname,
    },
    {
      accessor: "akce",
      title: "Akce",
      render: (computer) => actionButtons(computer),
      narrow: true,
    },
  ];

  const form = useForm({
    initialValues: {
      name: roomLocal?.name,
      width: roomLocal?.width,
      height: roomLocal?.height,
    },
  });

  const formComputers = useForm({
    initialValues: {
      computers: [],
    },
  });

  useEffect(() => {
    if (isOpen) {
      setRoomLocal(rooms.find((r) => r.id === room));
    }
  }, [isOpen]);

  useEffect(() => {
    form.setValues({
      name: roomLocal?.name,
      width: roomLocal?.width,
      height: roomLocal?.height,
    });
    setCreatingComputers([]);
  }, [roomLocal]);

  const createComputers = async () => {
    setComputerLoading(true);
    let values = creatingComputers;
    const res = await useFetch(`/pcapp/room/computer`, "POST", {
      group_id: roomLocal.id,
      hostname: values,
      x: 0,
      y: 0,
    });
    if (res.status === "ok") {
      toast.success("Počítač byl úspěšně přidán.");
      setComputerLoading(false);
      setRoomLocal({
        ...roomLocal,
        computers: [
          ...roomLocal.computers,
          ...values.map((hostname) => ({ hostname, group_id: roomLocal.id, notes: "", x: 0, y: 0 })),
        ],
      });
      revalidator.revalidate();
    } else {
      setComputerLoading(false);
      toast.error("Něco se pokazilo při přidávání počítače.");
      console.error(res);
    }
  };

  const saveRoom = async (values) => {
    setRoomSaveLoading(true);
    const res = await useFetch(`/pcapp/room/${roomLocal.id}`, "PUT", values);
    if (res.status === "ok") {
      toast.success("Místnost byla úspěšně upravena.");
      setRoomSaveLoading(false);
      revalidator.revalidate();
    } else {
      setRoomSaveLoading(false);
      toast.error("Něco se pokazilo při ukládání místnosti.");
      console.error(res);
    }
  };

  return (
    <Drawer
      title={`Správa místnosti ${roomLocal?.name}`}
      opened={isOpen}
      onClose={onClose}
      icon={IconDeviceDesktop}
      size={800}>
      <form onSubmit={form.onSubmit((values) => saveRoom(values))}>
        <TextInput mb="md" label="Název" {...form.getInputProps("name")} />
        <div className="grid grid-cols-2 gap-4">
          <NumberInput disabled label="Šířka" {...form.getInputProps("width")} />
          <NumberInput disabled label="Výška" {...form.getInputProps("height")} />
        </div>
        <Button loading={roomSaveLoading} type="submit" mt="md" mb="lg" variant="light" fullWidth>
          Uložit
        </Button>
      </form>

      <h3 className="mb-3 mt-4 text-xl">Seznam počítačů</h3>
      <CreatableMultiSelect
        data={creatingComputers}
        value={creatingComputers}
        onChange={(value) => setCreatingComputers(value)}
        label="Přidat počítače"
        description="Zadej seznam hostnamů, jde kopírovat z Excelu."
        getCreateLabel={(value) => `Přidat počítač ${value}`}
        className="mb-3"
        placeholder={"Zadej seznam hostnamů"}
        onPaste={(e) => {
          e.preventDefault();
          setCreatingComputers([...creatingComputers, ...e.clipboardData.getData("text").split("\r\n")]);
        }}
      />

      <Button mb="lg" onClick={() => createComputers()} type="submit" variant="light" fullWidth>
        Přidat počítače
      </Button>
      {roomLocal?.computers && (
        <Table
          id="room-computers"
          fetching={computerLoading}
          columns={columns}
          records={roomLocal.computers.sort((a, b) => {
            return a.hostname.localeCompare(b.hostname);
          })}
        />
      )}
    </Drawer>
  );
}
