import { ActionIcon, Button, Group, MultiSelect, Select, Text, Tooltip } from "@mantine/core";
import Page from "../../components/layout/Page";
import { useFetch } from "../../helpers/useFetch";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { parseFullName } from "../../helpers/helpers";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  IconBookmark,
  IconBus,
  IconDoorExit,
  IconDownload,
  IconEdit,
  IconListDetails,
  IconMessageShare,
  IconSwitchHorizontal,
  IconTrash,
  IconUser,
  IconUserStar,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import Table from "../../components/table/core/Table.jsx";
import { openNewPcMinecraftAdminWhitelistModal } from "../../helpers/modals.js";
import useTitle from "../../hooks/useTitle";

export const loader = async () => {
  return useFetch(`pc/mc/global-whitelist`);
};

export default function PcMinecraftAdminWhitelistPage() {
  const admins = useLoaderData().data;
  const userItem = localStorage.getItem("user");
  const user = JSON.parse(userItem);
  const [loading, setLoading] = useState(false);

  const revalidator = useRevalidator();

  const actionIcons = [
    {
      name: "Přidat Minecraft admina",
      onTrigger: () => {
        openNewPcMinecraftAdminWhitelistModal(user);
      },
      icon: IconUserStar,
      primary: true,
    },
  ];

  const actionButtons = (id) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} color="red" label="Smazat">
          <ActionIcon
            variant="light"
            color={"red"}
            onClick={(e) => {
              e.stopPropagation();
              toast.promise(useFetch(`/pc/mc/global-whitelist/${id}`, "DELETE"), {
                loading: "Odstraňuji uživatele z globálního admin whitelistu...",
                success: (res) => {
                  revalidator.revalidate();
                  setLoading(false);
                  return <p>Uživatel odstraněn</p>;
                },
                error: () => {
                  setLoading(false);
                  return <p>Nastala chyba při odstranění uživatele a byla nahlášena.</p>;
                },
              });
            }}>
            <IconTrash stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const columns = [
    {
      accessor: "position",
      title: "Areál",
      render: (admin) => admin.user.area,
    },
    {
      accessor: "mcNickname",
      title: "Minecraft nick",
      render: (admin) => admin.mcNickname,
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (admin) => actionButtons(admin.id),
      narrow: true,
    },
  ];

  useTitle("Správa Minecraft adminů");

  return (
    <Page title="Správa Minecraft adminů" actionIcons={actionIcons}>
      <Table columns={columns} records={admins} id="minecraft-admins" />
    </Page>
  );
}
