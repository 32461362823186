import { Text } from "@mantine/core";
import classNames from "classnames";
import styles from "../../pages.module.scss";
import ActionIcons from "../ActionIcons.jsx";
import ChildrenTable from "../table/ChildrenTable.jsx";
import { getMode } from "../../helpers/helpers";

export default function Page({ title, subtitle, subtitleDown, children, actionIcons }) {
  const { mode, theme } = getMode();

  return (
    <div>
      <div
        className={classNames("flex items-center justify-between px-4 py-3 md:px-6 md:py-8", styles.Content__header, {
          [`${styles.Content__header_dark}`]: mode === "dark",
          [`${styles.Content__header_light}`]: mode === "light",
        })}>
        <div>
          {subtitle && (
            <Text className="text-sm uppercase leading-none tracking-wider" c="dimmed">
              {subtitle}
            </Text>
          )}
          <h1>{title}</h1>
          {subtitleDown && (
            <Text className="text-sm leading-none" c="dimmed">
              {subtitleDown}
            </Text>
          )}
        </div>
        {actionIcons && <ActionIcons actions={actionIcons} />}
      </div>
      <div className={classNames("mb-12 px-4 md:px-6", styles.Content__content)}>{children}</div>
    </div>
  );
}
