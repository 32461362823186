import { useFetch } from "../../helpers/useFetch.js";
import { useLoaderData } from "react-router-dom";
import TicketsTable from "../../components/table/TicketsTable.jsx";
import useTitle from "../../hooks/useTitle.js";
import { IconDownload } from "@tabler/icons-react";
import Page from "../../components/layout/Page.jsx";
import Table from "../../components/table/core/Table.jsx";
import { parseFullName } from "../../helpers/helpers.js";
import { Text } from "@mantine/core";
import { useUser } from "../../providers/UserProvider.jsx";

export function loader() {
  return useFetch(`children/list/canteen`);
}

export default function MyTicketsPage() {
  const children = useLoaderData().data;
  useTitle(`Dietáři`);

  const { user } = useUser();

  const actionButtons = [
    {
      name: "Stáhnout seznam",
      onTrigger: () => {
        const link = import.meta.env.VITE_API_URL + "/export/excel/canteen?_token=" + user.token;
        const anchor = window.document.createElement("a");
        anchor.href = link;
        anchor.download = link.split("/").pop();
        anchor.target = "_blank";
        anchor.click();
      },
      icon: IconDownload,
      primary: true,
    },
  ];

  const columns = [
    {
      accessor: "name",
      title: "Jméno",
      render: (child) => parseFullName(child),
    },
    {
      accessor: "dietary",
      title: "Stravování",
      render: (child) =>
        child.medicalInfo.dietary_restrictions ? child.medicalInfo.dietary_restrictions : <Text c="dimmed">–</Text>,
    },
    {
      accessor: "allergies",
      title: "Alergie",
      render: (child) => (child.medicalInfo.allergies ? child.medicalInfo.allergies : <Text c="dimmed">–</Text>),
    },
    {
      accessor: "team",
      title: "Oddíl",
      render: (child) => (child.team ? child.team : <Text c="dimmed">–</Text>),
    },
  ];

  const filterConfig = [
    {
      key: "jmeno",
      label: "Jméno",
      type: "search",
      function: (child, value) => {
        return parseFullName(child).toLowerCase().includes(value.toLowerCase());
      },
    },
  ];

  return (
    <Page title="Dietáři" actionIcons={actionButtons}>
      <Table columns={columns} filterConfig={filterConfig} records={children} id="flash" />
    </Page>
  );
}
