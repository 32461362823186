import { Text } from "@mantine/core";
import "dayjs/locale/cs";
import dayjs from "dayjs";
import { useState } from "react";
import { parsePointsColor, parseShortDay } from "../../../helpers/helpers.js";

export default function PointsSummary({ room, size = "md", className }) {
  let finalString = "";

  function parsePointsDay(day, points) {
    if (!points && points !== 0) {
      return (
        <Text span color="dimmed">
          {parseShortDay(day)}?
        </Text>
      );
    } else {
      return (
        <Text span color={parsePointsColor(points)}>
          {parseShortDay(day)}
          {points}
        </Text>
      );
    }
  }

  function parseShortSummary(room) {
    let points = room?.points;
    let items = [];

    for (let i = 0; i < 6; i++) {
      if (items.length) {
        items.push(<Text span> + </Text>);
      }
      items.push(parsePointsDay(i, points?.[i]?.points));

      if (i === 5) return items;
    }
  }

  return (
    <Text size={size} className={className}>
      {parseShortSummary(room)}
    </Text>
  );
}
