import { ActionIcon, Group, Paper, Text } from "@mantine/core";
import {
  IconBiohazard,
  IconBrandDiscordFilled,
  IconCake,
  IconCalendarEvent,
  IconChevronDown,
  IconDoor,
  IconDoorExit,
  IconHeartbeat,
  IconMessageShare,
  IconNote,
  IconPill,
  IconShirt,
  IconSwimming,
  IconToolsKitchen2,
  IconTrash,
  IconVirus,
} from "@tabler/icons-react";
import classNames from "classnames";
import dayjs from "dayjs";
import { useState } from "react";
import styles from "./MyTeamMobile.module.scss";
import { openTicketModal } from "../../../helpers/modals.js";
import MedicalInfoCol from "../../MedicalInfoCol";
import { parseFullName } from "../../../helpers/helpers";
import { dosageAllOptions } from "../../../helpers/dosages";

export default function MyTeamMobile({ records }) {
  const list = records.map((record) => <MyTeamMobileRecord record={record} />);

  return <div>{list}</div>;
}

function MyTeamMobileRecord({ record, openModal }) {
  const [opened, setOpened] = useState(false);

  return (
    <Paper
      p={0}
      radius="md"
      mb="sm"
      onClick={() => setOpened(!opened)}
      className="overflow-hidden"
      key={record.id}
      withBorder>
      <div className="flex justify-between px-3 pt-2">
        <p>
          <strong>
            {record.firstName} {record.lastName} ({record.age})
          </strong>
        </p>
        <Group gap={4} wrap="nowrap" ml="sm" align="center" className={classNames(styles.MedicalLogos)}>
          {record.hasBirthday && (
            <Text h={18} color="yellow.6">
              <IconCake stroke={1.5} size={18} />
            </Text>
          )}
          {record.hasNameday && (
            <Text h={18} color="teal.6">
              <IconCalendarEvent stroke={1.5} size={18} />
            </Text>
          )}
          {record.permanentNote && (
            <Text h={18} color="pink.6">
              <IconNote stroke={1.5} size={18} />
            </Text>
          )}
          {record.earlyLeaving && (
            <Text h={18} color="red.6">
              <IconDoorExit stroke={1.5} size={18} />
            </Text>
          )}
          {record.hasToClean && (
            <Text h={18} color="orange.6">
              <IconTrash stroke={1.5} size={18} />
            </Text>
          )}
          {record.isIsolated && (
            <Text h={18} color="green.6">
              <IconBiohazard stroke={1.5} size={18} />
            </Text>
          )}
        </Group>
      </div>
      <div className="mb-2 mt-2 flex justify-between px-3">
        <MedicalInfoCol child={record} mobile />
        <Group gap="xs" align="center">
          <IconDoor stroke={1.5} size={18} /> {record.roomName}
        </Group>
        <Group wrap="nowrap" gap="xs">
          <ActionIcon
            variant="light"
            onClick={(e) => {
              e.stopPropagation();
              openTicketModal(`Vytvořit požadavek`, record, null, parseFullName(record));
            }}>
            <IconMessageShare stroke={1.5} size={18} />
          </ActionIcon>
          <ActionIcon
            variant="light"
            onClick={() => setOpened(!opened)}
            /* className={classNames(styles.Expander, openrecordId === record.id ? styles.Expanded : "")} */
          >
            <IconChevronDown
              className={classNames(
                { [`${styles.MyTeamMobilerecordChevronOpened}`]: opened },
                styles.MyTeamMobilerecordChevron
              )}
              size={18}
              stroke={1.5}
            />
          </ActionIcon>
        </Group>
      </div>
      {record?.earlyLeaving && (
        <div className="w-full bg-red-100 py-1 text-center text-sm text-[#fff]">
          {record.earlyLeaving.leavingDate && !record.earlyLeaving.leavingTime && (
            <p>Předčasný odjezd dne {dayjs(record.earlyLeaving.leavingDate).format("D. M. YYYY")}</p>
          )}
          {record.earlyLeaving.leavingDate && record.earlyLeaving.leavingTime && (
            <p>
              Předčasný odjezd dne {dayjs(record.earlyLeaving.leavingDate).format("D. M. YYYY")} v{" "}
              {record.earlyLeaving.leavingTime}
            </p>
          )}
          {!record.earlyLeaving.leavingDate && !record.earlyLeaving.leavingTime && <p>Předčasný odjezd bez data</p>}
        </div>
      )}
      {opened && (
        <div
          className={classNames(
            { [`${styles.MyTeamMobilerecordOpened}`]: opened },
            styles.MyTeamMobilerecordAccordion
          )}>
          <div className="border-0 border-t-2 border-solid border-dark-300 px-3 pt-3">
            <div className="flex gap-4">
              <div className="grow basis-0">
                <Group gap="xs" align="center">
                  <IconCake stroke={1.5} size={18} /> {dayjs(record.birthDate, "YYYY-MM-DD").format("DD. M. YYYY")}
                </Group>
                <Group gap="xs" align="center">
                  <IconDoor stroke={1.5} size={18} /> {record.roomName}
                </Group>
                <Group gap="xs" align="center" className="mt-3">
                  <IconBrandDiscordFilled stroke={1.5} size={18} />{" "}
                  <span className="font-mono">{record.discord.code}</span>
                </Group>
                <Text c="dimmed" className="text-sm">
                  Ověření přes /verify
                </Text>
              </div>
              <div className="grow basis-0">
                <Group gap="xs" align="center">
                  <IconShirt stroke={1.5} size={18} /> {record.tShirtSize}
                </Group>
                <Group gap="xs" align="center">
                  <IconSwimming stroke={1.5} size={18} />{" "}
                  {record.canSwim ? <Text color="teal">Ano</Text> : <Text color="red">Ne</Text>}
                </Group>
              </div>
              <div className="basis-0"></div>
            </div>
          </div>
          <div className="mt-3 border-0 border-t-2 border-solid border-dark-300 px-3 pb-3 pt-3">
            {(record.medicalInfo?.medical_restrictions || record.parentMedicalInfo?.medical_restrictions) && (
              <p>
                <Text span className="relative top-[2px] mr-2 inline-flex w-fit items-center gap-2 font-bold">
                  <IconHeartbeat stroke={1.5} size={18} />
                  Zdravotní omezení:
                </Text>
                {record.medicalInfo.medical_restrictions
                  ? record.medicalInfo.medical_restrictions
                  : record.parentMedicalInfo.medical_restrictions}
              </p>
            )}

            {(record.medicalInfo?.allergies || record.parentMedicalInfo?.allergies) && (
              <p>
                <Text span className="relative top-[2px] mr-2 inline-flex w-fit items-center gap-2 font-bold">
                  <IconVirus stroke={1.5} size={18} />
                  Alergie:
                </Text>
                {record.medicalInfo.allergies ? record.medicalInfo.allergies : record.parentMedicalInfo.allergies}
              </p>
            )}

            {(record.medicalInfo?.dietary_restrictions || record.parentMedicalInfo?.dietary_restrictions) && (
              <p>
                <Text span className="relative top-[2px] mr-2 inline-flex w-fit items-center gap-2 font-bold">
                  <IconToolsKitchen2 stroke={1.5} size={18} />
                  Stravování:
                </Text>
                {record.medicalInfo.dietary_restrictions
                  ? record.medicalInfo.dietary_restrictions
                  : record.parentMedicalInfo.dietary_restrictions}
              </p>
            )}
            {record.medicalMedications.length > 0 && (
              <p className="mt-4">
                <Text span className="relative top-[2px] mr-2 inline-flex w-fit items-center gap-2 font-bold">
                  <IconPill stroke={1.5} size={18} />
                  Pravidelné léky:
                </Text>
                <ul className="my-0 pl-4">
                  {record.medicalMedications.map((medication) => {
                    return (
                      <li>
                        <div className="flex items-center gap-4">
                          {medication.name}
                          <div className="flex gap-2">
                            {dosageAllOptions.map((dose) => {
                              return (
                                medication[dose.type].amount && (
                                  <ActionIcon variant="light" className="cursor-default">
                                    <strong>{dose.text}</strong>
                                  </ActionIcon>
                                )
                              );
                            })}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </p>
            )}
            {record.permanentNote || record.parentNote ? (
              <div className={classNames(styles.PernanentNote, "mt-4")}>
                {record.permanentNote && (
                  <>
                    <p>
                      <Text span className="relative top-[2px] mr-2 inline-flex w-fit items-center gap-2 font-bold">
                        Historická poznámka od HV:
                      </Text>
                      {record.permanentNote}
                    </p>
                  </>
                )}
                {record.parentNote && (
                  <>
                    <p>
                      <Text span className="relative top-[2px] mr-2 inline-flex w-fit items-center gap-2 font-bold">
                        Poznámka od rodiče:
                      </Text>
                      {record.parentNote}
                    </p>
                  </>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </Paper>
  );
}
