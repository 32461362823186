import { ActionIcon, Group, Loader, Text, Tooltip } from "@mantine/core";
import { useEffect, useState } from "react";
import { useFetch } from "../../helpers/useFetch";
import Drawer from "../drawer/Drawer";
import Table from "../table/core/Table";
import { getMode, parseFullName } from "../../helpers/helpers";
import { IconCheck } from "@tabler/icons-react";

export default function DelegateOverviewDrawer({ opened, setOpened, type }) {
  const [transport, setTransport] = useState([]);
  const [moneyData, setMoneyData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [checkedChildren, setCheckedChildren] = useState([]);

  const { mode, theme } = getMode();

  const getTransport = async () => {
    setLoading(true);
    const res = await useFetch(`/merch/transport${type ? `?type=${type}` : ""}`, "GET");
    setTransport(res.data);
    setLoading(false);
  };

  const getTowns = async () => {
    const res = await useFetch("/merch/transport/cities");
    if (res.data) {
      setCities(
        res.data.map((city) => ({
          label: city,
          value: city,
        }))
      );
    }
  };

  const getMoneyData = async () => {
    setLoading(true);
    const moneyRes = await useFetch("/money/deposit");
    setMoneyData(moneyRes.data);
    setLoading(false);
  };

  useEffect(() => {
    if (opened) {
      Promise.all([getMoneyData(), getTransport(), getTowns()]).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(true);
    }
  }, [opened]);

  const columns = [
    {
      accessor: "name",
      title: "Jméno",
      render: (child) => (
        <Text fw="bold" className="capitalize">
          {parseFullName(child)}
        </Text>
      ),
    },
    {
      accessor: "city",
      title: "Město",
      render: (child) => child.city,
    },
    {
      accessor: "phone",
      title: "Telefonní čísla",
      render: (child) => (
        <Text>
          <Text
            span
            className="cursor-pointer hover:underline"
            component="a"
            href={`tel:${child.tel1}`}
            color={theme.colors[theme.primaryColor][4]}>
            {child.tel1}
          </Text>
          {child.tel2 ? (
            <Text span>
              ,{" "}
              <Text
                span
                className="cursor-pointer hover:underline"
                component="a"
                href={`tel:${child.tel2}`}
                color={theme.colors[theme.primaryColor][4]}>
                {child.tel2}
              </Text>
            </Text>
          ) : (
            ""
          )}
        </Text>
      ),
    },
    {
      accessor: "note",
      title: "Poznámka",
      render: (child) => child.note,
    },
  ];

  const filterConfig = [
    {
      key: "mesto",
      label: "Město",
      type: "select",
      options: cities,
      function: (child, value) => child.merch[0].name === value,
    },
  ];

  return (
    <Drawer title={"Přehled dětí s dopravou"} opened={opened} onClose={() => setOpened(false)}>
      {loading ? (
        <Text align="center" mt={50}>
          <Loader />
        </Text>
      ) : (
        <>
          {transport?.summary && (
            <Text mb={moneyData ? "0" : "xs"} color="dimmed">
              <Text span fw="bold">
                Celkem:
              </Text>{" "}
              {Object.keys(transport.summary).map((key) => {
                return (
                  <Text>
                    {" "}
                    - {key}: {transport.summary[key]}
                  </Text>
                );
              })}
            </Text>
          )}
          {moneyData && transport?.summary && (
            <Text mb={"xs"} color="dimmed">
              <Text span fw="bold">
                Kauce:{"\n"}
              </Text>
              {Object.keys(transport.summary).map((key) => {
                return (
                  <Text>
                    {" "}
                    - {key}: {moneyData.bus.czk + " CZK, " + moneyData.bus.eur + " EUR"}{" "}
                    {window.location.pathname.includes("delegat-prijezd") &&
                      `(mělo by být vybráno: ${500 * transport.summary[key]} CZK)`}
                  </Text>
                );
              })}
            </Text>
          )}
          <Table id="delegat-deti" filterConfig={filterConfig} columns={columns} records={transport?.children} />
        </>
      )}
    </Drawer>
  );
}
