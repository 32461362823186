import { useEffect, useState } from "react";
import { Alert, Button, Loader, MultiSelect, Select } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch.js";
import { useRevalidator } from "react-router-dom";

export default function ChildTransferModal({ context, id, innerProps }) {
  const [fetching, setFetching] = useState(true);
  const [loading, setLoading] = useState(false);
  const [teamsOld, setTeamsOld] = useState(null);
  const [teamsNew, setTeamsNew] = useState(null);
  const [error, setError] = useState(false);
  const [childrenNew, setChildrenNew] = useState(null);
  const [teams, setTeams] = useState([]);
  const selectedChild = innerProps?.child;
  const setShouldFetch = innerProps.setShouldFetch;
  const revalidator = useRevalidator();

  function getTeams() {
    useFetch("/teams").then((res) => {
      if (res.data) {
        setTeams(res.data);
        setTeamsOld(res.data.filter((team) => selectedChild?.team !== null && team.id === selectedChild?.team.id));
        setTeamsNew(res.data.filter((team) => team.id !== selectedChild?.team?.id));
        setFetching(false);
      }
    });
  }

  useEffect(() => {
    getTeams();
  }, [innerProps]);

  async function handleSubmit(values) {
    await setLoading(true);
    let valuesLocal = values;

    if (valuesLocal?.change_for?.length === 0) {
      delete valuesLocal.change_for;
    }

    const res = await useFetch(`children/${selectedChild.id}/team-change`, "PUT", { ...valuesLocal });

    if (res.status === "ok") {
      toast.success(
        `${valuesLocal.change_for ? "Děti" : "Dítě"} byl${valuesLocal.change_for ? "y" : "o"} úspěšně přesunut${
          valuesLocal.change_for ? "y" : "o"
        }.`
      );
      if (setShouldFetch) setShouldFetch(true);
      setLoading(false);
      context.closeModal(id);
      revalidator.revalidate();
    } else {
      console.error(res);
      setError(res.message);
      setLoading(false);
    }
  }

  const form = useForm({
    initialValues: {
      team_id: null,
      change_for: [],
    },
  });

  useEffect(() => {
    if (form.values.team_id) {
      setLoading(true);
      useFetch(`teams/${form.values.team_id}`, "GET").then((res) => {
        if (res.data) {
          setChildrenNew(res.data.children);
          setLoading(false);
        }
      });
    }
  }, [form.values.team_id]);

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {error && (
          <Alert
            icon={<IconAlertCircle size={16} stroke={1.5} />}
            title="Při přesunu dítěte nastala chyba."
            color="red"
            mb="md"
            mt="lg"
            withCloseButton
            closeButtonLabel="Zavřít upozornění"
            onClose={() => setError(false)}>
            {error}
          </Alert>
        )}
        {fetching ? (
          <div className="flex h-full w-full flex-col items-center justify-center py-8">
            <Loader />
          </div>
        ) : (
          <>
            {selectedChild?.team !== null && (
              <Select
                label="Původní oddíl"
                data={
                  teamsOld?.length >= 1 &&
                  teamsOld?.map((team) => ({
                    label: `${team.name} #${team.number} (${team.childrenCount} dětí)`,
                    value: String(team.id),
                  }))
                }
                readOnly
                disabled={loading}
                defaultValue={selectedChild?.team?.id && String(selectedChild?.team.id)}
                mb="sm"
              />
            )}

            <Select
              label="Nový oddíl"
              placeholder="Vyber oddíl..."
              disabled={loading}
              rightSection={loading && <Loader size="xs" />}
              data={teamsNew?.map((team) => ({
                label: `${team.name} #${team.number} (${team.childrenCount} dětí)`,
                value: String(team.id),
              }))}
              mb="sm"
              {...form.getInputProps("team_id")}
            />

            {selectedChild?.team !== null && (
              <MultiSelect
                label="Chceš nějaké jiné dítě přesunout do původního oddílu?"
                placeholder="Vyber dítě..."
                data={
                  childrenNew !== null && !loading
                    ? childrenNew?.map((child) => ({
                        label: `${child.firstName} ${child.lastName} (${child.age} let)`,
                        value: String(child.id),
                      }))
                    : []
                }
                disabled={!form.values.team_id || loading}
                rightSection={loading && <Loader size="xs" />}
                searchable
                hoverOnSearchChange={true}
                clearable
                mb="sm"
                {...form.getInputProps("change_for")}
              />
            )}

            <Button fullWidth type="submit" loading={loading} disabled={form.values.team_id === null} mt="lg">
              Přesunout {form.values.change_for ? "děti" : "dítě"}
            </Button>
          </>
        )}
      </form>
    </>
  );
}
