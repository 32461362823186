import { isRouteErrorResponse, useNavigate, useRouteError } from "react-router-dom";
import { Button, Center, Code, Stack, Text, Title, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import errorImage from "../assets/error.jpg";
import errorKubik from "../assets/kubik-error.jpeg";
import dayjs from "dayjs";

export default function RootErrorBoundary() {
  const error = useRouteError();

  useEffect(() => {
    console.error(error);
  }, [error]);

  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <div className="absolute left-0 top-0 flex h-full w-full items-center">
          <div
            className="flex h-full grow basis-0 items-center justify-center"
            style={{
              backgroundColor: theme.colors[theme.primaryColor][7],
            }}>
            <Text fw="bold" className="text-[25vw] tracking-tighter" color={theme.colors[theme.primaryColor][3]}>
              404
            </Text>
          </div>
          <Stack justify="center" align="center" gap={0} className="grow basis-0">
            <Text mb="lg">Tuto stránku jsme nemohli najít. Zkus se vrátit.</Text>
            <Button w="fit-content" onClick={() => navigate(-1)}>
              Zpět
            </Button>
          </Stack>
        </div>
      );
    } else if (error.status === 401) {
      return <div>You aren't authorized to see this</div>;
    } else if (error.status === 503) {
      return (
        <div className="absolute left-0 top-0 flex h-full w-full items-center">
          <div
            className="flex h-full grow basis-0 items-center justify-center"
            style={{
              backgroundColor: theme.colors[theme.primaryColor][7],
            }}>
            <Text fw="bold" className="text-[25vw] tracking-tighter" color={theme.colors[theme.primaryColor][3]}>
              503
            </Text>
          </div>
          <Stack justify="center" align="center" gap={0} className="grow basis-0">
            <Text mb="lg">API je dole. Zkus tuto stránku za chvíli.</Text>
            <Button w="fit-content" onClick={() => navigate(-1)}>
              Zpět
            </Button>
          </Stack>
        </div>
      );
    } else if (error.status === 418) {
      return <div>🫖</div>;
    }
  }

  if (dayjs().month() === 5 && dayjs().date() === 6) {
    return (
      <Center className="h-full">
        <Stack justify="center" align="center" gap="sm">
          <img className="mb-2 rounded-lg" width={400} src={errorKubik} alt="spink" />

          <Title
            className="font-headline"
            order={1}
            color={colorScheme === "dark" ? theme.colors[theme.primaryColor][4] : theme.colors[theme.primaryColor][7]}>
            Kubík špatně zapojil monitor
          </Title>
          <Text mb="lg" align="center">
            Někde se stala chyba a nebylo možné stránku zobrazit.
            <br />I mistr tesař se někdy utne a proto Kubík přesně v tento den v roce 2023 poprvé v životě selhal.
            <br />
            Povedlo se mu zapojit monitory do integrované grafiky, namísto do GPU. Extrémní selhání, ultra GG.
          </Text>
          <Stack align="center" gap="xs">
            <Button w="fit-content" onClick={() => navigate(0)}>
              Resetovat stránku
            </Button>
            <Button
              w="fit-content"
              leftSection={!showError ? <IconChevronDown stroke={1.5} /> : <IconChevronUp stroke={1.5} />}
              variant="subtle"
              onClick={() => setShowError(!showError)}>
              Zobrazit celou zprávu
            </Button>
          </Stack>
          {showError && (
            <Code mt="md" style={{ whiteSpace: "pre-wrap" }} maw={750} block>
              {error.message}
            </Code>
          )}
        </Stack>
      </Center>
    );
  }

  return (
    <Center className="h-full">
      <Stack justify="center" align="center" gap="sm">
        <Title
          className="font-headline"
          order={1}
          color={colorScheme === "dark" ? theme.colors[theme.primaryColor][4] : theme.colors[theme.primaryColor][7]}>
          Někde nastala chyba
        </Title>
        <Text mb="lg">Bohužel jsme nemohli načíst tuto stránku. Zkus to znovu.</Text>
        <Stack align="center" gap="xs">
          <Button w="fit-content" onClick={() => navigate(0)}>
            Resetovat stránku
          </Button>
          <Button
            w="fit-content"
            leftSection={!showError ? <IconChevronDown stroke={1.5} /> : <IconChevronUp stroke={1.5} />}
            variant="subtle"
            onClick={() => setShowError(!showError)}>
            Zobrazit celou zprávu
          </Button>
        </Stack>
        {showError && (
          <Code mt="md" style={{ whiteSpace: "pre-wrap" }} maw={750} block>
            {error.message}
          </Code>
        )}
      </Stack>
    </Center>
  );
}
