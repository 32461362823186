import { IconCake, IconDoorExit, IconId, IconNote, IconSwitchHorizontal, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import ChildrenTable from "../../components/table/ChildrenTable.jsx";
import useTitle from "../../hooks/useTitle.js";
import Page from "../../components/layout/Page.jsx";
import { ActionIcon, Button, Group, Skeleton, Tooltip } from "@mantine/core";
import { Link } from "react-router-dom";
import { openChildTransferModal, openEarlyLeaveModal, openHistoricalNoteModal } from "../../helpers/modals.js";
import { parseFullName } from "../../helpers/helpers.js";
import { useFetch } from "../../helpers/useFetch.js";
import { useDebouncedState } from "@mantine/hooks";
import { NamedayBirthdayDrawer } from "../../components/children/NamedayBirthdayDrawer.jsx";

export default function ChildrenManagePage() {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [teams, setTeams] = useState(null);
  const [filteringTeam, setFilteringTeam] = useState("");
  const [filteringName, setFilteringName] = useDebouncedState("", 300);
  const [serverFetching, setServerFetching] = useState(false);
  const [nameBirthDayDrawer, setNameBirthDayDrawer] = useState(false);
  useTitle(`Přehled dětí`);

  useEffect(() => {
    if (!teams) {
      useFetch("/teams")
        .then((data) => {
          setTeams(data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  const actionButtons = [
    {
      name: "Přehled narozenin a svátků",
      onTrigger: (e) => {
        e.stopPropagation();
        setNameBirthDayDrawer(true);
      },
      icon: IconCake,
    },
  ];

  const actionButtonsTable = (child) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Změna oddílu">
          <ActionIcon
            variant="light"
            color="gray"
            onClick={(e) => {
              e.stopPropagation();
              openChildTransferModal("Přesun dítěte", child, parseFullName(child), setShouldFetch);
            }}>
            <IconSwitchHorizontal stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={child.earlyLeaving === null ? "Předčasný odjezd" : "Již existuje"}>
          <ActionIcon
            variant="light"
            color="gray"
            disabled={child.earlyLeaving !== null}
            onClick={(e) => {
              e.stopPropagation();
              openEarlyLeaveModal(`Vytvořit předčasný odjezd`, child, null, parseFullName(child), setShouldFetch);
            }}>
            <IconDoorExit stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label="Upravit historickou poznámku">
          <ActionIcon
            variant="light"
            color="gray"
            onClick={(e) => {
              e.stopPropagation();
              openHistoricalNoteModal(`Historická poznámka`, child, parseFullName(child));
            }}>
            <IconNote stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Link to={"/deti/" + child.id}>
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Karta dítěte">
            <ActionIcon>
              <IconId stroke={1.5} size={18} />
            </ActionIcon>
          </Tooltip>
        </Link>
      </Group>
    );
  };

  return (
    <Page title="Přehled dětí" actionIcons={actionButtons}>
      {teams?.length > 0 && (
        <Group gap="sm" className="w-full" mb="sm">
          <Group gap="sm" className="flex-grow" grow>
            {teams
              ? teams.map((team) => (
                  <Button
                    onClick={() => (filteringTeam === team.id ? setFilteringTeam(null) : setFilteringTeam(team.id))}
                    className="min-w-fit"
                    variant={filteringTeam === team.id ? "filled" : "light"}
                    key={team.id}
                    disabled={serverFetching}>
                    {team.shortcut}
                  </Button>
                ))
              : [...Array(9)].map((_, i) => <Skeleton key={i} radius="sm" height={36} />)}
          </Group>
          <ActionIcon size={36} className="grow-0" variant="light" color="red" onClick={() => setFilteringTeam("")}>
            <IconX stroke={1.5} size={24} />
          </ActionIcon>
        </Group>
      )}

      <ChildrenTable
        setShouldFetch={setShouldFetch}
        shouldFetch={shouldFetch}
        team={filteringTeam}
        name={filteringName}
        teams={teams}
        actionButtons={actionButtonsTable}
        serverFetching={serverFetching}
        setServerFetching={setServerFetching}
      />
      <NamedayBirthdayDrawer opened={nameBirthDayDrawer} onClose={() => setNameBirthDayDrawer(false)} />
    </Page>
  );
}
