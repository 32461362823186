import { Button, Group, Paper, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function Filters({ filterConfig, setFilters }) {
  const [params, setParams] = useSearchParams();

  let formInitialValues = {};
  filterConfig.map((filter) => {
    formInitialValues[filter.key] = params.get(`filter[${filter.key}]`) || "";
  });

  const form = useForm({
    initialValues: formInitialValues,
  });

  const handleSubmit = (values) => {
    let paramsCopy = params;
    filterConfig.forEach((filter) => {
      paramsCopy.set(`filter[${filter.key}]`, values[filter.key]);
    });
    setParams(paramsCopy);
    setFilters(values);
  };

  const resetFilters = () => {
    let paramsCopy = params;
    filterConfig.forEach((filter) => {
      paramsCopy.delete(`filter[${filter.key}]`);
    });
    setParams(paramsCopy);
    Object.keys(form.values).forEach((key) => {
      form.setFieldValue(key, "");
    });
    setFilters([]);
  };

  return (
    <Paper className="mb-6 rounded-2xl p-6" withBorder>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
          {filterConfig.map((filter) => {
            return <TextInput key={filter.label} label={filter.label} {...form.getInputProps(filter.key)} />;
          })}
        </div>
        <Group position="right" className="pt-2">
          <div className="flex gap-2">
            <Button onClick={resetFilters} variant="light" className="order-4 md:order-3 md:mt-0" loading={false}>
              Resetovat filtry
            </Button>
            <Button type="submit" className="order-3 mt-4 md:order-4 md:mt-0" loading={false}>
              Filtrovat
            </Button>
          </div>
        </Group>
      </form>
    </Paper>
  );
}
