import { Box, ScrollArea } from "@mantine/core";
import { BuffetItem } from "./BuffetItem";

export const BuffetItems = ({ products }) => {
  return (
    <ScrollArea h={"75vh"} offsetScrollbars>
      <Box className="grid grid-cols-3 gap-4">
        {products.map((product) => {
          return <BuffetItem key={product.name} product={product} />;
        })}
      </Box>
    </ScrollArea>
  );
};
