import { Center, Loader } from "@mantine/core";
import classes from "./TableLoader.module.scss";
import classNames from "classnames";

export default function TableLoader({ fetching }) {
  return (
    <Center className={classNames(classes.root, { [classes.fetching]: fetching })} sx={{ backdropFilter: `blur(1px)` }}>
      {fetching && <Loader />}
    </Center>
  );
}
