import useTitle from "../../hooks/useTitle.js";
import { useFetch } from "../../helpers/useFetch.js";
import { useLoaderData } from "react-router-dom";
import Page from "../../components/layout/Page.jsx";
import RoomGroupList from "../../components/accommodation/points/RoomGroupList.jsx";
import { IconBed, IconDownload, IconMedal2, IconMoodNervous, IconTrash, IconUsers } from "@tabler/icons-react";
import { useNavigate } from "react-router";
import { useState } from "react";
import PointsSummaryDrawer from "../../components/accommodation/points/PointsSummaryDrawer.jsx";
import PointsSinnersDrawer from "../../components/accommodation/points/PointsSinnersDrawer.jsx";
import { useUser } from "../../providers/UserProvider.jsx";
import { usePermissions } from "../../providers/PermissionsProvider.jsx";

export async function loader() {
  const res = await useFetch(`accommodation/rooms/points`);

  return res;
}

export default function RoomsPointsPage() {
  const groups = useLoaderData().data;
  const navigate = useNavigate();
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [sinnersOpened, setSinnersOpened] = useState(false);

  const { user } = useUser();
  const { hasPermission } = usePermissions();

  useTitle(`Bodování pokojů`);

  const actionButtons = [
    {
      name: "Přehled pokojů",
      onTrigger: (e) => {
        e.stopPropagation();
        navigate("/ubytovani/prehled", { replace: true });
      },
      icon: IconBed,
    },
    {
      name: "Hříšníci",
      onTrigger: (e) => {
        e.stopPropagation();
        setSinnersOpened(true);
      },
      icon: IconMoodNervous,
    },
    ...(hasPermission("accommodation.rooms.points.edit")
      ? [
          {
            name: "Součty bodů",
            onTrigger: (e) => {
              e.stopPropagation();
              setDrawerOpened(true);
            },
            icon: IconMedal2,
          },
          {
            name: "Stáhnout přehled bodování",
            onTrigger: (e) => {
              e.stopPropagation();
              const downloadLink = document.createElement("a");
              downloadLink.target = "_blank";
              downloadLink.href = new URL(
                "/export/pdf/room-points/?_token=" + user.token,
                import.meta.env.VITE_API_URL
              ).href;
              downloadLink.click();
            },
            icon: IconDownload,
          },
        ]
      : []),
  ];

  return (
    <Page title="Bodování pokojů" actionIcons={actionButtons}>
      <RoomGroupList groups={groups} />
      <PointsSummaryDrawer isOpen={drawerOpened} onClose={() => setDrawerOpened(false)} groups={groups} />
      <PointsSinnersDrawer isOpen={sinnersOpened} onClose={() => setSinnersOpened(false)} groups={groups} />
    </Page>
  );
}
