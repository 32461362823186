import { useFetch } from "../../helpers/useFetch.js";
import { useLoaderData } from "react-router-dom";
import TeamsSetup from "../../components/modals/TeamsSetup.jsx";
import useTitle from "../../hooks/useTitle.js";
import Page from "../../components/layout/Page.jsx";

export function loader() {
  return useFetch(`/children/import`);
}

export default function TeamsSetupPage() {
  const importState = useLoaderData();
  useTitle(`Vytvoření oddílů`);

  //const staff = "{\"data\":[{\"id\":25397,\"firstName\":\"Luk\u00e1\u0161\",\"lastName\":\"P\u00e1nek \",\"age\":25,\"position\":\"Odd\u00edlov\u00fd vedouc\u00ed\"},{\"id\":27199,\"firstName\":\"Daniel\",\"lastName\":\"Cvejn\",\"age\":26,\"position\":\"Odd\u00edlov\u00fd vedouc\u00ed\"},{\"id\":22207,\"firstName\":\"Ladislav\",\"lastName\":\"Dvo\u0159\u00e1k\",\"age\":25,\"position\":\"Odd\u00edlov\u00fd vedouc\u00ed\"},{\"id\":25400,\"firstName\":\"Tom\u00e1\u0161\",\"lastName\":\"Fiala\",\"age\":22,\"position\":\"Odd\u00edlov\u00fd vedouc\u00ed\"},{\"id\":27797,\"firstName\":\"Michal\",\"lastName\":\"Dobe\u0161\",\"age\":20,\"position\":\"Odd\u00edlov\u00fd vedouc\u00ed\"},{\"id\":27798,\"firstName\":\"Jakub\",\"lastName\":\"Veli\u010dka\",\"age\":25,\"position\":\"Programov\u00fd vedouc\u00ed\\/lektor\"}]}"
  return (
    <Page title="Vytvoření oddílů">
      <TeamsSetup importState={importState} />
    </Page>
  );
}
