import dayjs from "dayjs";
import Table from "./core/Table";

export const DepotLogsTable = () => {
  const columns = [
    {
      accessor: "Typ",
      title: "Typ",
      render: (row) => (row.type === "in" ? "Naskladnění" : row.type === "out" ? "Vyskladnění" : "Přeskladnění"),
    },
    {
      accessor: "article",
      title: "Artikl",
      render: (row) => row.product.name,
    },
    {
      accessor: "quantity",
      title: "Množství",
      render: (row) => row.amount + " ks",
    },
    {
      accessor: "depot",
      title: "Sklad",
      render: (row) =>
        row.type === "in"
          ? "Do " + row.depot_to?.name
          : row.type === "out"
          ? "Z " + row.depot_from?.name
          : "Z " + row.depot_from?.name + " do " + row.depot_to?.name,
    },
    {
      accessor: "name",
      title: "Autor",
      render: (row) => row.user.firstName + " " + row.user.lastName,
    },
    {
      accessor: "created_at",
      title: "Datum a čas",
      render: (row) => dayjs(row.created_at).format("D. M. YYYY HH:mm"),
    },
  ];

  return <Table id={"depot-logs"} columns={columns} useServer={true} serverUrl={"depot/logs"} />;
};
