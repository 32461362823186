import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import styles from "../../pages.module.scss";
import classNames from "classnames";
import { IconArrowBackUp, IconCheck, IconEdit, IconId, IconNote } from "@tabler/icons-react";
import { Link, useRevalidator } from "react-router-dom";
import Table from "./core/Table.jsx";
import TicketsMobile from "./responsive/TicketsMobile.jsx";
import { openTicketModal } from "../../helpers/modals.js";
import ModalTitle from "../modals/ModalTitle.jsx";
import { useFetch } from "../../helpers/useFetch.js";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { parseDatetime } from "../../helpers/helpers.js";

export default function TicketsTable({ tickets, loading, refreshing, hiddenColumns = [] }) {
  const revalidator = useRevalidator();
  const [ticketToggleLoading, setTicketToggleLoading] = useState(false);

  let toggleTicketStatus = async (ticket) => {
    setTicketToggleLoading(true);
    let type = ticket.closedAt === null ? "close" : "open";
    const res = await useFetch(`/tickets/${ticket.id}/${type}`, "PUT");
    if (res.status === "ok") {
      toast.success("Požadavek byl" + (type === "close" ? " vyřešen" : " znovu otevřen") + ".");
      setTicketToggleLoading(false);
      revalidator.revalidate();
    }
  };

  const childName = (ticket) => {
    return (
      <Text span fw="bold" color={ticket.closedAt && "dimmed"}>
        {ticket.child ? ticket.child.firstName + " " + ticket.child.lastName : "–"}
      </Text>
    );
  };

  const creatorName = (ticket) => {
    return (
      <Text span color={ticket.closedAt && "dimmed"}>
        {ticket.creator.firstName + " " + ticket.creator.lastName}
      </Text>
    );
  };

  const createdAt = (ticket) => {
    return (
      <Text span color={ticket.closedAt && "dimmed"}>
        {parseDatetime(ticket.createdAt)}
      </Text>
    );
  };

  const closedAt = (ticket) => {
    return (
      <Text span color={ticket.closedAt && "dimmed"}>
        {ticket.closedAt
          ? `${ticket.closedAt} (${ticket.resolver.firstName} ${ticket.resolver.lastName})`
          : "Čeká na vyřešení"}
      </Text>
    );
  };

  const text = (ticket) => {
    return (
      <Text span color={ticket.closedAt && "dimmed"}>
        {ticket.text}
      </Text>
    );
  };

  const actionButtons = (ticket) => {
    return (
      <Group wrap="nowrap" position="right" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Upravit">
          <ActionIcon
            variant="light"
            loading={loading}
            onClick={(e) => {
              e.stopPropagation();
              openTicketModal("Upravit požadavek", null, ticket);
            }}>
            <IconEdit stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        {ticket?.child ? (
          <Link to={"/deti/" + ticket.child.id}>
            <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Karta dítěte">
              <ActionIcon variant="light">
                <IconId stroke={1.5} size={18} />
              </ActionIcon>
            </Tooltip>
          </Link>
        ) : (
          ""
        )}
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={ticket.closedAt === null ? "Vyřešit" : "Znovu otevřít"}>
          <ActionIcon
            variant="light"
            loading={ticketToggleLoading}
            color={ticket.closedAt === null ? "teal" : "red"}
            onClick={(e) => {
              e.stopPropagation();
              toggleTicketStatus(ticket);
            }}>
            {ticket.closedAt === null ? (
              <IconCheck stroke={1.5} size={18} />
            ) : (
              <IconArrowBackUp stroke={1.5} size={18} />
            )}
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const records = [
    {
      accessor: "name",
      title: "Jméno dítěte",
      render: (ticket) => childName(ticket),
      sortable: true,
      sortValue: (ticket) => (ticket.child ? ticket.child?.lastName : ""),
    },
    {
      accessor: "creator",
      title: "Vedoucí",
      render: (ticket) => creatorName(ticket),
      sortable: true,
      sortValue: (ticket) => ticket.creator.lastName,
    },
    {
      accessor: "createdAt",
      title: "Vytvořeno",
      render: (ticket) => createdAt(ticket),
    },
    {
      accessor: "text",
      title: "Text",
      render: (ticket) => text(ticket),
    },
    {
      accessor: "closedAt",
      title: "Stav",
      render: (ticket) => closedAt(ticket),
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (ticket) => actionButtons(ticket),
      narrow: true,
    },
  ];

  return (
    <Table
      id="pozadavky"
      columns={records.filter((col) => !hiddenColumns.includes(col.accessor))}
      records={tickets}
      fetching={refreshing}
      responsiveComponent={<TicketsMobile toggleTicketStatus={toggleTicketStatus} />}
    />
  );
}
