import { useRef, useState } from "react";
import Table from "./core/Table";
import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import { IconArrowsTransferDown, IconArrowsTransferUp, IconEye, IconSignature, IconTrash } from "@tabler/icons-react";
import DocumentViewer from "../boarding/DocumentViewer";
import { useUser } from "../../providers/UserProvider";
import DocumentSign from "../boarding/DocumentSign";
import { useFetch } from "../../helpers/useFetch";
import { openConfirmModal } from "@mantine/modals";
import { modalTitle } from "../modals/Modal";
import { optimize } from "svgo";
import { useRevalidator } from "react-router-dom";
import toast from "react-hot-toast";

export const DepotHandoversTable = ({ shouldFetch, setShouldFetch, serverFetching, setServerFetching }) => {
  const [documentViewer, setDocumentViewer] = useState(false);
  const [documentViewed, setDocumentViewed] = useState(null);
  const [opened, setOpened] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [signing, setSigning] = useState("transferer");
  const [transfererSign, setTransfererSign] = useState(null);
  const [receiverSign, setReceiverSign] = useState(null);
  const [loading, setLoading] = useState(false);

  const signatureRef = useRef();

  const revalidator = useRevalidator();

  const user = useUser();

  const columns = [
    {
      accessor: "name",
      title: "Název",
      render: (row) => row.name,
    },
    {
      accessor: "signed",
      title: "Podepsáno",
      render: (row) =>
        row.filePath ? (
          <Text c="teal.6" span>
            Ano
          </Text>
        ) : (
          <Text c="red.6" span>
            Ne
          </Text>
        ),
    },
    {
      accessor: "transferer",
      title: "Předávající",
      render: (row) => row.transferer.firstName + " " + row.transferer.lastName,
    },
    {
      accessor: "receiver",
      title: "Přebírající",
      render: (row) => row.receiver.firstName + " " + row.receiver.lastName,
    },
    {
      accessor: "date",
      title: "Datum předání",
      render: (row) => new Date(row.handoverDate).toLocaleDateString(),
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (row) => (
        <Group wrap="nowrap" gap="xs">
          <Tooltip
            position="top"
            withArrow
            transition="fade"
            transitionDuration={200}
            label={row.filePath ? "Zobrazit soubor" : "Předávák náhled"}>
            <ActionIcon
              variant="light"
              color="gray"
              onClick={(e) => {
                e.stopPropagation();
                setDocumentViewer(true);
                setDocumentViewed(
                  row.filePath
                    ? row.filePath + `?_token=${user.user.token}`
                    : `${row.preview}?_token=${user.user.token}`
                );
              }}>
              <IconEye stroke={1.5} size={18} />
            </ActionIcon>
          </Tooltip>
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Podepsat předávák">
            <ActionIcon
              variant="filled"
              color={row.filePath ? "teal" : "red"}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedDocument(row);
                openConfirmModal({
                  title: modalTitle(IconArrowsTransferUp, "Podpis předávajícího"),
                  children: (
                    <Text size="sm">
                      Nyní bude podepisovat <strong>předávající</strong> vedoucí.
                    </Text>
                  ),
                  labels: { confirm: "Přejít k podpisu", cancel: "Zrušit" },
                  onConfirm: () => {
                    openTransfererSign();
                  },
                });
              }}>
              <IconSignature stroke={1.5} size={18} />
            </ActionIcon>
          </Tooltip>
          <Tooltip
            position="top"
            withArrow
            transition="fade"
            transitionDuration={200}
            label="Smazat předávací protokol">
            <ActionIcon
              variant="light"
              color="red"
              loading={loading}
              disabled={row.filePath}
              onClick={(e) => {
                e.stopPropagation();
                openConfirmModal({
                  title: modalTitle(IconTrash, "Smazat předávací protokol"),
                  children: <Text size="sm">Opravdu chcete smazat tento předávací protokol?</Text>,
                  labels: { confirm: "Smazat", cancel: "Zrušit" },
                  onConfirm: () => {
                    setLoading(true);
                    useFetch(`/handover-protocols/${row.id}`, "DELETE")
                      .then((res) => {
                        if (res.status === "ok") {
                          toast.success("Předávací protokol byl úspěšně smazán");
                          setShouldFetch(true);
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                        toast.error("Někde nastala chyba, zkuste to prosím později");
                      });
                    setLoading(false);
                  },
                });
              }}>
              <IconTrash stroke={1.5} size={18} />
            </ActionIcon>
          </Tooltip>
        </Group>
      ),
      narrow: true,
    },
  ];

  const openReceiverSign = () => {
    setSigning("receiver");
    setOpened(true);
  };

  const openTransfererSign = () => {
    setSigning("transferer");
    setOpened(true);
  };

  const signDocument = async (fields) => {
    const svgElement = document.createElement("svg");
    const trimElement = signatureRef.current;

    const bBox = trimElement.getBBox();
    svgElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svgElement.setAttribute("viewBox", `${bBox.x} ${bBox.y} ${bBox.width} ${bBox.height}`);
    svgElement.setAttribute("width", trimElement.getBBox().width);
    svgElement.setAttribute("height", trimElement.getBBox().height);
    svgElement.appendChild(trimElement.cloneNode(true));

    const result = optimize(svgElement.outerHTML, { multipass: true, floatPrecision: 2 });

    let parser = new DOMParser();
    let doc = parser.parseFromString(result.data, "image/svg+xml");
    let optimizedSvg = doc.querySelector("svg");
    optimizedSvg.setAttribute(
      "viewBox",
      `${Math.round(bBox.x)} ${Math.round(bBox.y)} ${Math.round(bBox.width)} ${Math.round(bBox.height)}`
    );
    optimizedSvg.setAttribute("width", Math.round(bBox.width));
    optimizedSvg.setAttribute("height", Math.round(bBox.height));

    const serialized = new XMLSerializer().serializeToString(optimizedSvg);

    const payload = btoa(serialized);

    if (signing === "transferer") {
      setOpened(false);
      setTransfererSign(payload);
      openConfirmModal({
        title: modalTitle(IconArrowsTransferDown, "Podpis přebírajícího"),
        children: (
          <Text size="sm">
            Nyní bude <strong>přebírající</strong> vedoucí.
          </Text>
        ),
        labels: { confirm: "Přejít k podpisu", cancel: "Zrušit" },
        onConfirm: () => {
          openReceiverSign();
        },
        onCancel: () => {
          setSigning("transferer");
          setTransfererSign(null);
        },
      });
    } else {
      setReceiverSign(payload);
      useFetch(`/handover-protocols/${selectedDocument.id}/sign`, "POST", {
        transferer_signature: transfererSign,
        receiver_signature: payload,
      }).then((res) => {
        console.log(res);
        if (res.status === "ok") {
          setOpened(false);
          toast.success("Předávák úspěšně podepsán.");
          setShouldFetch(true);
        }
      });
    }
  };

  return (
    <>
      <Table
        id={"depot-handovers"}
        columns={columns}
        useServer={true}
        serverUrl={"handover-protocols"}
        shouldServerFetch={shouldFetch}
        setShouldServerFetch={setShouldFetch}
        serverFetching={serverFetching}
        setServerFetching={setServerFetching}
      />
      {documentViewer && <DocumentViewer document={documentViewed} setOpened={setDocumentViewer} />}
      {opened && (
        <DocumentSign
          signDocument={signDocument}
          signatureRef={signatureRef}
          document={selectedDocument}
          setOpened={setOpened}
        />
      )}
    </>
  );
};
