import { ActionIcon } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { useEffect } from "react";
import { toast, ToastBar, Toaster, useToasterStore } from "react-hot-toast";
import styles from "./ToastsProvider.module.scss";
import { getMode } from "../helpers/helpers";

export default function ToastsProvider() {
  const { toasts } = useToasterStore();

  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i >= 5)
      .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  const { mode, theme } = getMode();

  return (
    <Toaster
      reverseOrder={false}
      position="bottom-center"
      gutter={8}
      containerClassName={styles.container}
      toastOptions={{
        duration: 5000,
        style: {
          background: mode === "dark" ? theme.colors.dark[7] : "#fff",
          color: mode === "dark" ? theme.colors.dark[0] : theme.colors.gray[9],
          borderRadius: "10px",
          boxShadow: "0 10px 20px rgba(13, 13, 18, 0.1)",
          border: `2px solid ${mode === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]}`,
          padding: "0.5rem 0.5rem 0.5rem 1rem",
          userSelect: "none",
        },
        success: {
          iconTheme: {
            primary: "#12B886",
            secondary: "#C3FAE8",
          },
        },
        error: {
          iconTheme: {
            primary: "#FA5252",
            secondary: "#FFE3E3",
          },
        },
      }}
    >
      {(t) => (
        <ToastBar position="bottom-center" toast={t}>
          {({ icon, message }) => (
            <div className={styles.toast} onClick={() => toast.dismiss(t.id)}>
              {icon}
              {message}
              {t.type !== "loading" && (
                <ActionIcon
                  variant="subtle"
                  ml={8}
                  size="sm"
                  className={styles.dismiss}
                  onClick={() => toast.dismiss(t.id)}
                >
                  <IconX stroke={1.5} size={12} />
                </ActionIcon>
              )}
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}
