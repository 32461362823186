import { useEffect, useState } from "react";
import { useFetch } from "../../helpers/useFetch";
import { useForm } from "@mantine/form";
import { Button, Input, Loader, SegmentedControl, Select, TextInput } from "@mantine/core";
import toast from "react-hot-toast";

const getData = async () => {
  const categories = await useFetch("depot/categories");
  return { categories: categories.data };
};

export const ArticleCategoriesModal = ({ innerProps: { setShouldFetch }, context, id }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [type, setType] = useState("create");

  const form = useForm({
    initialValues: {
      name: "",
    },
  });

  const handleSubmit = async () => {
    setLoading(true);
    const options = {
      url: "depot/categories",
      method: "POST",
    };
    if (selectedCategory) {
      options.url = `depot/categories/${selectedCategory}`;
      options.method = "PUT";
    }
    const res = await useFetch(options.url, options.method, form.values);
    setLoading(false);
    if (res.status === "ok") {
      toast.success("Kategorie byla úspěšně " + (selectedCategory ? "upravena" : "přidána"));
      if (setShouldFetch) setShouldFetch(true);
      return context.closeModal(id);
    }
    console.error(res);
    toast.error("Nastala chyba při " + (selectedCategory ? "úpravě" : "přidávání") + " kategorie");
    return;
  };

  useEffect(() => {
    setFetching(true);
    getData().then((res) => {
      setCategories(res.categories);
      setFetching(false);
    });
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      form.setValues({ name: categories.find((category) => category.id.toString() === selectedCategory).name });
    } else {
      form.setValues({ name: "" });
    }
  }, [selectedCategory]);

  useEffect(() => {
    setSelectedCategory(null);
    form.setValues({ name: "" });
  }, [type]);

  return (
    <>
      {fetching ? (
        <div className="flex h-full w-full flex-col items-center justify-center py-8">
          <Loader />
        </div>
      ) : (
        <form onSubmit={form.onSubmit(handleSubmit)} className="flex flex-col gap-2">
          <SegmentedControl
            value={type}
            onChange={setType}
            color={"indigo"}
            data={[
              { label: "Přidat kategorii", value: "create" },
              { label: "Upravit kategorii", value: "edit" },
            ]}
          />
          {type === "edit" && (
            <Select
              label="Vyberte kategorii k upravení"
              placeholder="Vyberte kategorii k upravení"
              data={categories.map((category) => ({ label: category.name, value: category.id.toString() }))}
              value={selectedCategory}
              onChange={(value) => setSelectedCategory(value)}
              disabled={loading}
              clearable
            />
          )}
          <Input.Wrapper label="Název" withAsterisk>
            <TextInput
              {...form.getInputProps("name")}
              placeholder="Název"
              required
              disabled={loading || (type === "edit" && !selectedCategory)}
            />
          </Input.Wrapper>
          <Button type="submit" variant="filled" mt={"lg"} loading={loading} className="w-full">
            {selectedCategory ? "Upravit " : "Přidat "}kategorii
          </Button>
        </form>
      )}
    </>
  );
};
