import { useEffect, useRef, useState } from "react";
import Page from "../components/layout/Page";
import { Button, Text } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import RichSelect from "../components/RichSelect";

export default function TestPage() {
  /* const [stars, setStars] = useState([]);
  const divRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    setStars(
      [...Array(10)].map((_, i) => ({
        id: i.toString(),
        x: Math.random() * divRef?.current?.clientWidth,
        y: Math.random() * divRef?.current?.clientHeight,
        rotation: Math.random() * 180,
        isDragging: false,
      }))
    );
  }, [dimensions]);

  useEffect(() => {
    if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
      setDimensions({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });
    }
  }, []);

  const handleDragStart = (e) => {
    const id = e.target.id();
    setStars(
      stars.map((star) => {
        return {
          ...star,
          isDragging: star.id === id,
        };
      })
    );
  };
  const handleDragEnd = (e) => {
    setStars(
      stars.map((star) => {
        return {
          ...star,
          isDragging: false,
        };
      })
    );
  };

  const width = 20;
  const height = 20;

  return (
    <Page title="Testovací stránka">
      <Button onClick={() => metodaCoNeexistuje()}>Sentry test</Button>
      <div className="h-full w-full" ref={divRef}>
        <Stage width={dimensions.width} height={dimensions.height}>
          <Layer>
            <Text text="Try to drag a star" />
            {stars.map((star) => (
              <Star
                key={star.id}
                id={star.id}
                x={star.x}
                y={star.y}
                numPoints={5}
                innerRadius={20}
                outerRadius={40}
                fill="#89b717"
                opacity={0.8}
                draggable
                rotation={star.rotation}
                shadowColor="black"
                shadowBlur={10}
                shadowOpacity={0.6}
                shadowOffsetX={star.isDragging ? 10 : 5}
                shadowOffsetY={star.isDragging ? 10 : 5}
                scaleX={star.isDragging ? 1.2 : 1}
                scaleY={star.isDragging ? 1.2 : 1}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              />
            ))}
          </Layer>
          <Layer>
            {[...Array(width)].map((_, i) => (
              <Line key={i} points={[i * 40, 0, i * 40, height * 40]} stroke="#fff" strokeWidth={1} opacity={0.1} />
            ))}
            <Line points={[width * 40, 0, width * 40, height * 40]} stroke="#fff" strokeWidth={1} opacity={0.1} />
            {[...Array(height)].map((_, i) => (
              <Line key={i} points={[0, i * 40, width * 40, i * 40]} stroke="#fff" strokeWidth={1} opacity={0.1} />
            ))}
            <Line points={[0, height * 40, width * 40, height * 40]} stroke="#fff" strokeWidth={1} opacity={0.1} />
          </Layer>
        </Stage>
      </div>
    </Page>
  ); */
  const [value, setValue] = useState(null);

  const [searchValue, setSearchValue] = useState("");
  const [searchValueLocal, setSearchValueLocal] = useState("");

  useEffect(() => {
    setSearchValueLocal(searchValue + " (test)");
  }, [searchValue]);

  const [debug, setDebug] = useLocalStorage({
    key: "debug",
    defaultValue: false,
  });

  const requestNotification = () => {
    Notification.requestPermission().then((result) => {
      if (result === "granted") {
        new Notification("Testovací notifikace", {
          body: "Toto je testovací notifikace.",
          icon: "https://panel.4camps.cz/icon.png",
        });
      }
    });
  };

  return (
    <Page title="Testovací stránka">
      <Button onClick={() => requestNotification()}>Notification request</Button>
      <Button onClick={() => setDebug(!debug)}>Toggle debug</Button>
      {searchValueLocal}
      <RichSelect
        data={[
          {
            value: "test",
            label: "Test",
          },
        ]}
        creatable
        searchValue={searchValue}
        onSearchChange={setSearchValue}
        searchable
        value={value}
        label={({ label }) => <Text>{label}</Text>}
        onChange={setValue}
      />
    </Page>
  );
}
