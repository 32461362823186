import { Collapse, Group, Paper, Text, Tooltip, ActionIcon } from "@mantine/core";
import { IconChevronDown, IconChevronUp, IconPower, IconRadar, IconRotate, IconShield } from "@tabler/icons-react";
import { useState } from "react";
import ComputerCard from "./ComputerCard";
import { openConfirmModal } from "@mantine/modals";
import { modalTitle } from "../modals/Modal";
import { useSocketFetch } from "../../helpers/useSocketFetch";
import { toast } from "react-hot-toast";

export default function RoomComputers({ room, socketComputers, fetchSockets }) {
  const [opened, setOpened] = useState(false);

  const turnOffAll = () => {
    openConfirmModal({
      title: modalTitle(IconPower, "Vypnutí všech počítačů"),
      children: (
        <Text size="sm">
          Opravdu chceš vypnout všechny počítače? Tento krok prováděj pouze na konci programu případně v nouzi.
        </Text>
      ),
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
      onConfirm: () => {
        useSocketFetch(
          "/shutdown/start/60/&area/array",
          "POST",
          socketComputers.map((c) => c.socketID)
        ).then((data) => {
          setTimeout(() => {
            toast.success("Počítače se vypnou za minutu.");
            fetchSockets();
          }, 100);
        });
      },
    });
  };

  const abortTurnOfAll = () => {
    useSocketFetch(
      "/shutdown/abort/&area/array",
      "POST",
      socketComputers.map((c) => c.socketID)
    ).then((data) => {
      setTimeout(() => {
        toast.success("Vypnutí počítačů zrušeno.");
        fetchSockets();
      }, 100);
    });
  };

  const rebootAll = () => {
    openConfirmModal({
      title: modalTitle(IconRotate, "Restart všech počítačů"),
      children: <Text size="sm">Opravdu chceš restartovat všechny počítače? Tento krok prováděj pouze v nouzi.</Text>,
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
      onConfirm: () => {
        useSocketFetch(
          "/reboot/start/60/&area/array",
          "POST",
          socketComputers.map((c) => c.socketID)
        ).then((data) => {
          setTimeout(() => {
            toast.success("Počítače se restartují za minutu.");
            fetchSockets();
          }, 100);
        });
      },
    });
  };

  const abortRebootAll = () => {
    useSocketFetch(
      "/reboot/abort/&area/array",
      "POST",
      socketComputers.map((c) => c.socketID)
    ).then((data) => {
      setTimeout(() => {
        toast.success("Restart počítačů zrušen.");
        fetchSockets();
      }, 100);
    });
  };

  const blockAll = () => {
    useSocketFetch(
      "/block/&area/array",
      "POST",
      socketComputers.map((c) => c.socketID)
    ).then((data) => {
      setTimeout(() => {
        toast.success("Počítače zablokovány.");
        fetchSockets();
      }, 100);
    });
  };

  const unblockAll = () => {
    useSocketFetch(
      "/unblock/&area/array",
      "POST",
      socketComputers.map((c) => c.socketID)
    ).then((data) => {
      setTimeout(() => {
        toast.success("Počítače odblokovány.");
        fetchSockets();
      }, 100);
    });
  };

  const pingAll = () => {
    useSocketFetch(
      "/ping/&area/array",
      "POST",
      socketComputers.map((c) => c.socketID)
    ).then((data) => {
      setTimeout(() => {
        toast.success("Ping na počítače odeslán.");
        fetchSockets();
      }, 100);
    });
  };

  return (
    <>
      <Paper p="sm" className="mb-4 cursor-pointer" withBorder radius="md" onClick={() => setOpened(!opened)}>
        <Group justify="space-between">
          <h2>{room.name}</h2>
          <div className="flex items-center gap-4">
            <Group gap="sm">
              <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Vypnout místnost">
                <ActionIcon
                  variant="light"
                  color="red"
                  size="lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    turnOffAll();
                  }}>
                  <IconPower size={24} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
              <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Restartovat místnost">
                <ActionIcon
                  variant="light"
                  size="lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    rebootAll();
                  }}>
                  <IconRotate size={24} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
              <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Zablokovat místnost">
                <ActionIcon
                  variant="light"
                  size="lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    blockAll();
                  }}>
                  <IconShield size={24} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
              <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Ping místnosti">
                <ActionIcon
                  variant="light"
                  size="lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    pingAll();
                  }}>
                  <IconRadar size={24} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
              {/* <Tooltip
            position="top"
            withArrow
            transition="fade"
            transitionDuration={200}
            
            label="Zobrazit obrazovku"
          >
            <ActionIcon
              variant="light"
              size="lg"
              disabled
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconScreenShare size={24} stroke={1.5} />
            </ActionIcon>
          </Tooltip> */}
            </Group>
            {opened ? <IconChevronUp stroke={1.5} /> : <IconChevronDown stroke={1.5} />}
          </div>
        </Group>
      </Paper>
      <Collapse in={opened}>
        <div className="mb-5 grid grid-cols-2 gap-4 md:grid-cols-4">
          {room.computers.length === 0 && (
            <Text c="dimmed" className="col-span-full text-center text-lg">
              😢 Žádné počítače
            </Text>
          )}
          {room.computers.map((computer) => (
            <ComputerCard
              socketComputer={socketComputers.find((c) => c.hostname.toUpperCase() === computer.hostname.toUpperCase())}
              fetchSockets={fetchSockets}
              key={computer.id}
              computer={computer}
            />
          ))}
        </div>
      </Collapse>
    </>
  );
}
