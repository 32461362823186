import { useEffect, useState } from "react";
import { Alert, Button, NumberInput, Text, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch.js";
import { useRevalidator } from "react-router-dom";
import { IconAlertCircle } from "@tabler/icons-react";

export default function CreateRoomModal({ context, id }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const revalidator = useRevalidator();

  async function handleSubmit(values) {
    setLoading(true);

    const res = await useFetch(`pcapp/room`, "POST", values);

    if (res.status === "ok") {
      context.closeModal(id);
      form.reset();
      setLoading(false);
      toast.success("Místnost vytvořena.");
      revalidator.revalidate();
    } else {
      console.error(res);
      setLoading(false);
      setError(res.message);
    }
  }

  const form = useForm({
    initialValues: {
      name: "",
      width: 0,
      height: 0,
      notes: "",
    },
    validate: {
      name: (value) => (value === "" ? "Musíš zadat název" : null),
    },
  });

  return (
    // <Modal
    //     title={ticket?.child ? `Požadavek – ${ticket?.child.firstName} ${ticket?.child.lastName}` : "Požadavek"}
    //     opened={opened}
    //     setOpened={setOpened}
    //     icon={IconMessageShare}
    //     description="Zadej text požadavku na hlavního vedoucího."
    // >
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title={`Při vytváření místnosti nastala chyba.`}
          color="red"
          mb="md"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      <TextInput
        disabled={loading}
        placeholder="Zadej jméno místnosti..."
        autosize
        label="Jméno místnosti"
        required
        {...form.getInputProps("name")}
      />
      <Textarea
        disabled={loading}
        mt="sm"
        placeholder="Zadej poznámku..."
        maxRows={3}
        minRows={3}
        autosize
        label="Poznámka k místnosti"
        {...form.getInputProps("notes")}
      />
      <div className="mt-3 grid grid-cols-2 gap-4">
        <NumberInput disabled={true} label="Šířka místnosti" {...form.getInputProps("width")} />
        <NumberInput disabled={true} label="Výška místnosti" {...form.getInputProps("height")} />
      </div>
      <Text size="sm" color="dimmed">
        Aktuálně nefunguje. Určuje počet kostiček v editu místnosti.
      </Text>

      <Button fullWidth type="submit" loading={loading} mt="md">
        Vytvořit místnost
      </Button>
    </form>
    // </Modal>
  );
}
