import { ActionIcon, Alert, Button, Group, Input, Loader, Paper, ScrollArea, Select, Text } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useHotkeys, useViewportSize } from "@mantine/hooks";
import { IconCalendar, IconCheck, IconEye, IconEyeOff, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import SignaturePad from "./SignaturePad";
import "dayjs/locale/cs";
import dayjs from "dayjs";
import classNames from "classnames";
import { getMode } from "../../helpers/helpers";
import { useUser } from "../../providers/UserProvider";

export default function DocumentSign({
  document,
  opened,
  setOpened,
  signatureRef,
  signDocument,
  setFields,
  setViewerParentOpened,
}) {
  const [numPages, setNumPages] = useState(null);
  const { width } = useViewportSize();
  const [loading, setLoading] = useState(true);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [link, setLink] = useState("");
  const [canSign, setCanSign] = useState(false);
  const [openSignature, setOpenSignature] = useState(false);
  const [pathsCount, setPathsCount] = useState(0);

  const user = useUser();

  let values = {};
  let validation = {};

  if (document.fields) {
    document.fields.forEach((field) => {
      if (field.type === "date") values[field.variable] = dayjs(field.value, "DD.MM.YYYY").toDate();
      else values[field.variable] = field.value;
    });

    document.fields.forEach((field) => {
      validation[field.variable] = (value) => (value.length < 1 ? "Pole je povinné" : null);
    });
  }

  const form = useForm({
    initialValues: values,
    validate: validation,
  });

  useEffect(() => {
    if (pathsCount > 0) {
      setCanSign(true);
    } else {
      setCanSign(false);
    }
  }, [pathsCount]);

  useEffect(() => {
    if (document.preview && document.preview !== link) {
      setLink(document.preview);
    }
  }, [document]);

  const onDocumentLoadSuccess = ({ numPages, ...other }) => {
    setNumPages(numPages);
  };

  const { mode, theme } = getMode();

  useHotkeys([["Escape", () => setOpened(false)]]);

  return (
    <div
      className={classNames("absolute left-0 top-0 z-20 flex h-full w-full flex-col justify-center", {
        "bg-dark-200": mode === "dark",
        "bg-dark-900": mode === "light",
      })}>
      <ScrollArea className="h-full w-full">
        <Document
          file={`${document.preview}?_token=${user.user.token}`}
          onLoadSuccess={onDocumentLoadSuccess}
          className="h-full w-full"
          loading={
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center">
              <Loader />
              <Text mt="md">Načítání dokumentu...</Text>
            </div>
          }
          error={
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center text-center">
              <Text className="text-6xl">😰</Text>
              <Text mt="md">Dokument nelze načíst, nejspíše chyba na straně serveru.</Text>
            </div>
          }>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              renderAnnotationLayer={false}
              renderTextLayer={false}
              key={`page_${index + 1}`}
              className="mx-auto my-4 w-fit"
              loading={null}
              width={Math.min(width, 1000)}
              pageNumber={index + 1}
              onRenderSuccess={() => setLoading(false)}
            />
          ))}
          <div style={{ width: Math.min(width, 1000) }} className="mx-auto h-20" onClick={() => setOpened(false)} />
        </Document>
        {!loading && (
          <Button
            className="absolute bottom-6 right-6 shadow-md"
            leftSection={show ? <IconEye size="1rem" stroke={1.5} /> : <IconEyeOff size="1rem" stroke={1.5} />}
            onClick={() => setShow(!show)}>
            {show ? "Zobrazit dokument" : "Schovat dokument"}
          </Button>
        )}
      </ScrollArea>
      {show && !loading && (
        <Paper withBorder className="flex max-h-[90%] flex-col rounded-none border-0 border-t-2 shadow-xl">
          <ScrollArea p="lg">
            <form
              onSubmit={form.onSubmit(() => {
                setFetchLoading(true);
                signDocument(form.values);
              })}>
              <div className="mx-auto w-full max-w-[1000px]">
                {document.fields?.length >= 1 && (
                  <div className="mb-6 grid gap-x-4 gap-y-2 sm:grid-cols-2">
                    {document.fields.map((field) => (
                      <Input.Wrapper key={field.variable} label={field.name}>
                        {field.type === "text" && (
                          <Input {...form.getInputProps(field.variable)} disabled={field.readonly} />
                        )}
                        {field.type === "date" && (
                          <DateInput
                            valueFormat="D. M. YYYY"
                            locale="cs"
                            leftSection={<IconCalendar size={18} stroke={1.5} />}
                            {...form.getInputProps(field.variable)}
                            format="dd.MM.yyyy"
                            disabled={field.readonly}
                          />
                        )}
                        {field.type === "select" && (
                          <Select
                            placeholder="Vyberte důvod..."
                            creatable={field.predefinedValues.some((localField) => localField.type === "other")}
                            searchable
                            disabled={field.readonly}
                            getCreateLabel={(query) => (
                              <Text>
                                <Text span fw="bold">
                                  Jiný důvod:
                                </Text>{" "}
                                {query}
                              </Text>
                            )}
                            data={field.predefinedValues
                              .filter((localField) => localField.type !== "other")
                              .map((localField) => ({
                                label: localField.text,
                                value: localField.text,
                              }))}
                            {...form.getInputProps(field.variable)}
                          />
                        )}
                      </Input.Wrapper>
                    ))}
                  </div>
                )}

                <Alert mb="md">Prosím podepište se do pole níže.</Alert>
                <div className="flex h-[350px] gap-4">
                  <SignaturePad setPathsCount={setPathsCount} signatureRef={signatureRef} />
                  <div className="flex flex-col gap-4">
                    <ActionIcon
                      variant="filled"
                      color="red"
                      type="submit"
                      onClick={() => {
                        setOpened(false);
                        if (setViewerParentOpened) setViewerParentOpened(false);
                      }}
                      size="xl">
                      <IconX size={24} stroke={1.5} />
                    </ActionIcon>
                    <ActionIcon
                      variant="filled"
                      color="teal"
                      type="submit"
                      size="xl"
                      className="grow"
                      loading={fetchLoading}
                      disabled={!canSign}>
                      <IconCheck size={24} stroke={1.5} />
                    </ActionIcon>
                  </div>
                </div>
              </div>
            </form>
          </ScrollArea>
        </Paper>
      )}
    </div>
  );
}
