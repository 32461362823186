import { useLoaderData } from "react-router-dom";
import Page from "../../components/layout/Page";
import RoomOverviewTable from "../../components/table/RoomOverviewTable";
import { useFetch } from "../../helpers/useFetch";
import useTitle from "../../hooks/useTitle";
import { IconPlus } from "@tabler/icons-react";
import { openCreateRoomModal } from "../../helpers/modals";

export function loader() {
  return useFetch("/pcapp/room");
}

export default function RoomsOverviewPage() {
  const rooms = useLoaderData().data;
  useTitle("Správa místností");

  const actionIcons = [
    {
      name: "Vytvořit místnost",
      icon: IconPlus,
      onTrigger: () => openCreateRoomModal(),
      primary: true,
    },
  ];

  return (
    <Page title="Správa místností" actionIcons={actionIcons}>
      <RoomOverviewTable rooms={rooms} />
    </Page>
  );
}
