import { Loader, SegmentedControl, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useFetch } from "../../helpers/useFetch";
import Drawer from "../drawer/Drawer";
import Table from "../table/core/Table";
import { getMode, parseFullName } from "../../helpers/helpers";

const BoardingMerchOverviewDrawer = ({ isOpen, onClose }) => {
  const [merch, setMerch] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("name");

  const getMerch = async () => {
    setLoading(true);
    const res = await useFetch("/merch", "GET");
    let merchTmp = res.data.summary;
    let merchKeys = Object.keys(merchTmp);
    let merchArray = [];
    merchKeys.map((key) => {
      if (isNaN(merchTmp[key])) {
        let subKeys = Object.keys(merchTmp[key]);
        let children = [];
        subKeys.map((subKey) => {
          return children.push({ name: subKey, value: merchTmp[key][subKey] });
        });
        merchArray.push({
          name: key,
          children: children,
        });
      } else {
        merchArray.push({ name: key, value: merchTmp[key] });
      }
    });
    const childrenList = [];
    res.data.children.map((child) => {
      const childMerch = child.merch.filter((merchItem) => !merchItem.delivered);
      if (childMerch.length > 0) {
        childrenList.push(child);
      }
    });
    setMerch({ summary: merchArray, children: childrenList });
    setLoading(false);
  };

  useEffect(() => {
    getMerch();
  }, []);

  const merchName = (piece) => {
    return <Text fw={"bold"}>{piece.name}</Text>;
  };

  const merchCount = (piece) => {
    if (piece.value) {
      return <Text>{piece.value}x</Text>;
    } else {
      let text = [];
      piece.children.map((size) => {
        return text.push(size.name + ": " + size.value + "x");
      });
      return <Text>{text.join(", ")}</Text>;
    }
  };

  const merchOverview = (child) => {
    return (
      <ul className="m-0 p-0">
        {child.merch
          .filter((merchItem) => !merchItem.delivered)
          .map((merchItem) => {
            return (
              <li>
                {merchItem.count > 1 && merchItem.count + "x"} {merchItem.name}
                {merchItem.size && " - " + merchItem.size}
              </li>
            );
          })}
      </ul>
    );
  };

  const columnsCount = [
    {
      accessor: "name",
      title: "Název",
      render: (piece) => merchName(piece),
    },
    {
      accessor: "count",
      title: "Počet",
      render: (piece) => merchCount(piece),
    },
  ];

  const columnsName = [
    {
      accessor: "name",
      title: "Jméno",
      render: (child) => parseFullName(child),
    },
    {
      accessor: "merch-names",
      title: "Merch",
      render: (child) => merchOverview(child),
    },
  ];

  return (
    <Drawer title={"Přehled celkového merche"} opened={isOpen} onClose={onClose}>
      {loading && !merch ? (
        <Text align="center" mt={50}>
          <Loader />
        </Text>
      ) : (
        <>
          <SegmentedControl
            mb="sm"
            fullWidth
            value={selectedType}
            onChange={setSelectedType}
            data={[
              {
                label: <Text className="flex items-center justify-center gap-1">Podle jména</Text>,
                value: "name",
              },
              {
                label: <Text className="flex items-center justify-center gap-1">Podle počtů</Text>,
                value: "count",
              },
            ]}
          />
          {selectedType === "name" ? (
            <Table id="merch-name" columns={columnsName} records={merch?.children} key={"children"} />
          ) : (
            <Table id="merch-count" columns={columnsCount} records={merch?.summary} key={"count"} />
          )}
        </>
      )}
    </Drawer>
  );
};

export default BoardingMerchOverviewDrawer;
