import { useContext, useEffect, useState } from "react";
import { CartContext } from "./CartContext";
import toast from "react-hot-toast";

export const BarcodeScanner = ({ products }) => {
  const [code, setCode] = useState("");
  const { addToCart, checkoutDrawerOpened } = useContext(CartContext);

  useEffect(() => {
    const keyDown = (e) => {
      if (checkoutDrawerOpened) return;
      if (e.key === "Enter") {
        e.preventDefault();
        const product = products.find((p) => p.ean === code || p.ean2 === code);
        if (product) {
          addToCart(product);
        } else {
          toast.error("Product nebyl nalezen");
        }
        setCode("");
      } else if (e.keyCode <= 57 && e.keyCode >= 48) {
        const key = e.code.split("Digit")[1];
        setCode(code + key);
      }
    };

    window.addEventListener("keydown", keyDown);

    return () => {
      window.removeEventListener("keydown", keyDown);
    };
  });

  return <></>;
};
