import { Button, Modal, Text } from "@mantine/core";
import { useEffect } from "react";
import { useRegisterSW } from "virtual:pwa-register/react";
import ModalTitle from "./modals/ModalTitle";
import { IconRefresh } from "@tabler/icons-react";
import dayjs from "dayjs";

export default function ReloadPrompt() {
  const buildDate = "__DATE__";
  const reloadSW = "__RELOAD_SW__";
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      // eslint-disable-next-line no-console
      console.log(`Service Worker at: ${swUrl}`);
      // @ts-expect-error just ignore
      if (reloadSW === "true") {
        r &&
          setInterval(() => {
            // eslint-disable-next-line no-console
            console.log("Checking for sw update");
            r.update();
          }, 20000 /* 20s for testing purposes */);
      } else {
        // eslint-disable-next-line prefer-template,no-console
        console.log("SW Registered: " + r);
      }
    },
    onRegisterError(error) {
      // eslint-disable-next-line no-console
      console.log("SW registration error", error);
    },
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };

  useEffect(() => {
    if (reloadSW) {
      updateServiceWorker(true);
    }
  }, [reloadSW, updateServiceWorker]);

  useEffect(() => {
    console.log("offlineReady", offlineReady);
    console.log("needRefresh", needRefresh);
  }, [offlineReady, needRefresh]);

  return (
    <div className="ReloadPrompt-container">
      {(offlineReady || needRefresh) && (
        <div className="ReloadPrompt-toast">
          <div className="ReloadPrompt-message">
            {offlineReady ? (
              <span>App ready to work offline</span>
            ) : (
              <span>New content available, click on reload button to update.</span>
            )}
          </div>
          {needRefresh && (
            <button className="ReloadPrompt-toast-button" onClick={() => updateServiceWorker(true)}>
              Reload
            </button>
          )}
          <button className="ReloadPrompt-toast-button" onClick={() => close()}>
            Close
          </button>
        </div>
      )}
      <Modal
        opened={offlineReady || needRefresh}
        onClose={() => null}
        closeOnClickOutside
        withCloseButton
        size={600}
        radius="lg"
        padding="1.5rem"
        title={
          <ModalTitle
            title={offlineReady ? "Připraveno pro offline" : needRefresh && "Potřeba reloadu"}
            icon={IconRefresh}
          />
        }>
        {offlineReady && (
          <>
            <p>
              Aplikace je připravena pro fungování offline. Cachujeme nějaké základní stránky, abys pokaždé nemusel
              potřebovat internet. Pro potvrzení musíš znovu načíst Panel.
            </p>
            <Button mt="lg" fullWidth onClick={() => window.location.reload()}>
              Znovu načíst stránku
            </Button>
          </>
        )}
        {needRefresh && (
          <>
            <p>
              Od posledního otevření Panelu byl aktualizován. Pro zachování funkčnosti musíš stránku restartovat přes
              tlačítko níže.
            </p>
            <Button mt="lg" fullWidth onClick={() => updateServiceWorker(true)}>
              Aktualizovat aplikaci
            </Button>
          </>
        )}
        <Text size="sm" mt="sm" c="dimmed">
          Verze SW: {dayjs(buildDate).format("DDMMYYYYHHmm")}
        </Text>
      </Modal>
    </div>
  );
}

// deployyy
