import { Alert, Button, NumberInput, SegmentedControl, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState, useEffect } from "react";
import { useRevalidator } from "react-router-dom";
import { useFetch } from "../../helpers/useFetch";
import { IconAlertCircle } from "@tabler/icons-react";
import { getMode } from "../../helpers/helpers";
import toast from "react-hot-toast";

const PcEditQuestModal = ({ context, id, innerProps }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const setShouldFetch = innerProps.setShouldFetch;
  const revalidate = useRevalidator();

  useEffect(() => {
    if (innerProps?.quest) {
      form.setValues({
        campId: innerProps.quest.camp.id.toString(),
        title: innerProps.quest.title,
        text: innerProps.quest.text,
        day: innerProps.quest.day.toString(),
        lore: innerProps.quest.lore,
        url: innerProps.quest.url || "",
        points: innerProps.quest.points,
        type: innerProps.quest.type,
      });
    } else {
      form.reset();
    }
  }, [innerProps]);

  const form = useForm({
    initialValues: {
      campId: null,
      title: "",
      text: "",
      day: new Date().getDay() < 6 ? new Date().getDay() : 0,
      lore: "",
      url: "",
      points: 1,
      type: "main",
    },
  });

  const { mode, theme } = getMode();

  let camps = innerProps?.camps || [];

  if (camps) {
    camps = camps.map((camp) => ({
      label: camp.label,
      value: camp.id.toString(),
    }));
  }

  const days = innerProps?.days || [];

  const handleSubmit = async (values) => {
    setLoading(true);

    if (values.url === "") delete values.url;
    const quest = innerProps?.quest;

    values.id = quest.id;

    values.camp_id = values.campId;
    delete values.campId;

    const route = `/pc/quests/${quest.id}`;

    const res = await useFetch(route, "PUT", values);
    if (res.status === "ok") {
      context.closeModal(id);
      form.reset();
      if (setShouldFetch) setShouldFetch(true);
      toast.success("Quest upraven.");
      revalidate.revalidate();
    } else {
      setLoading(false);
      setError(res.message);
    }
  };

  return (
    <div className="max-h-[70vh] overflow-hidden overflow-y-auto lg:max-h-full">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {error && (
          <Alert
            icon={<IconAlertCircle size={16} stroke={1.5} />}
            title="Při úpravě questu nastala chyba."
            color="red"
            mb="md"
            mt="lg"
            withCloseButton
            closeButtonLabel="Zavřít upozornění"
            onClose={() => setError(false)}>
            {error}
          </Alert>
        )}
        <SegmentedControl
          data={camps}
          value={form.values.campId}
          color={theme.primaryColor}
          className="mb-4 w-full"
          onChange={(value) => {
            form.setFieldValue("campId", value);
          }}
        />
        <div className="mb-4 overflow-auto">
          <SegmentedControl
            data={days}
            value={form.values.day}
            color={theme.primaryColor}
            className="min-w-full"
            onChange={(value) => form.setFieldValue("day", value)}
          />
        </div>
        <TextInput label="Název" placeholder="Hlavasování" mb="sm" {...form.getInputProps("title")} />
        <Textarea
          maxRows={2}
          label="Popis"
          placeholder="Já jsem hlavas, já jsem hlavas, já jsem hlavas..."
          mb="sm"
          {...form.getInputProps("text")}
        />
        <Textarea
          maxRows={2}
          label="Lore"
          placeholder="Potřebujeme novou hlavu areálu..."
          mb="sm"
          {...form.getInputProps("lore")}
        />
        <NumberInput min={0} label="Odměna" placeholder="1" mb="sm" {...form.getInputProps("points")} />
        <TextInput label="URL" placeholder="https://example.com" mb="sm" {...form.getInputProps("url")} />
        <Button fullWidth type="submit" loading={loading}>
          Aktualizovat
        </Button>
      </form>
    </div>
  );
};

export default PcEditQuestModal;
