import { Button, Group } from "@mantine/core";
import { IconArrowDown, IconArrowsMoveVertical, IconArrowUp } from "@tabler/icons-react";

export default function TableMobileSort({ columns, handleSort, currentSortColumn, currentSortDirection }) {
  return (
    <Group gap="xs" mb="md">
      {columns.map(
        (column) =>
          column.sortable && (
            <Button
              key={column.accessor}
              variant={currentSortColumn === column.accessor ? "filled" : "light"}
              onClick={() => {
                handleSort(column);
              }}>
              {column.title}
              {currentSortColumn === column.accessor ? (
                currentSortDirection === "asc" ? (
                  <IconArrowUp size="15" stroke={1.5} />
                ) : (
                  <IconArrowDown size="15" stroke={1.5} />
                )
              ) : (
                <IconArrowsMoveVertical size="15" stroke={1.5} />
              )}
            </Button>
          )
      )}
    </Group>
  );
}
