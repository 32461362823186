import { useLoaderData } from "react-router-dom";
import Page from "../../components/layout/Page";
import RoomOverviewTable from "../../components/table/RoomOverviewTable";
import { useFetch } from "../../helpers/useFetch";
import useTitle from "../../hooks/useTitle";
import { IconPlus } from "@tabler/icons-react";
import GameGroupsTable from "../../components/table/GameGroupsTable.jsx";
import { openGameGroupModal } from "../../helpers/modals.js";

export function loader() {
  return useFetch("/pc/game-group");
}

export default function GameGroupsPage() {
  const groups = useLoaderData().data;
  useTitle("Správa herních skupin");

  const actionIcons = [
    {
      name: "Přidat herní skupinu",
      icon: IconPlus,
      onTrigger: () => openGameGroupModal(),
      primary: true,
    },
  ];

  return (
    <Page title="Správa herních skupin" actionIcons={actionIcons}>
      <GameGroupsTable groups={groups} />
    </Page>
  );
}
