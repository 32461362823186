import { useEffect, useState } from "react";
import Page from "../../components/layout/Page";
import useTitle from "../../hooks/useTitle";
import { DepotTable } from "../../components/table/DepotTable";
import { openArticleCategoriesModal, openDepotsManageModal, openNewArticleModal } from "../../helpers/modals";
import { IconBox, IconBuildingWarehouse, IconCategory, IconX } from "@tabler/icons-react";
import { useFetch } from "../../helpers/useFetch";
import { ActionIcon, Button, Group } from "@mantine/core";

const DepotPage = ({ sellData }) => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [serverFetching, setServerFetching] = useState(false);
  const [categories, setCategories] = useState(null);
  const [filteringCategory, setFilteringCategory] = useState("");
  useTitle(sellData ? "Přehled produktů" : "Přehled artiklů");

  useEffect(() => {
    if (!categories) {
      useFetch("/depot/categories")
        .then((data) => {
          setCategories(data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  const actionButtons = [
    {
      name: sellData ? "Kategorie produktů" : "Kategorie artiklů",
      onTrigger: () => openArticleCategoriesModal(setShouldFetch, sellData),
      icon: IconCategory,
    },
    {
      name: "Skladovací místnosti",
      onTrigger: () => openDepotsManageModal(setShouldFetch, sellData),
      icon: IconBuildingWarehouse,
    },
    {
      name: sellData ? "Nový produktů" : "Nový artikl",
      onTrigger: () => openNewArticleModal(setShouldFetch, sellData),
      icon: IconBox,
      primary: true,
    },
  ];

  return (
    <Page title={"Přehled artiklů"} actionIcons={actionButtons}>
      {categories?.length > 0 && !sellData && (
        <Group gap="sm" className="w-full" mb="sm">
          <Group gap="sm" className="flex-grow" grow>
            {categories
              ? categories
                  .filter((category) => category.isMainFilter)
                  .map((category) => (
                    <Button
                      onClick={() =>
                        filteringCategory === category.id
                          ? setFilteringCategory(null)
                          : setFilteringCategory(category.id)
                      }
                      className="min-w-fit"
                      variant={filteringCategory === category.id ? "filled" : "light"}
                      key={category.id}
                      disabled={serverFetching}>
                      {category.name}
                    </Button>
                  ))
              : [...Array(5)].map((_, i) => <Skeleton key={i} radius="sm" height={36} />)}
          </Group>
          <ActionIcon size={36} className="grow-0" variant="light" color="red" onClick={() => setFilteringCategory("")}>
            <IconX stroke={1.5} size={24} />
          </ActionIcon>
        </Group>
      )}
      <DepotTable
        shouldFetch={shouldFetch}
        setShouldFetch={setShouldFetch}
        serverFetching={serverFetching}
        setServerFetching={setServerFetching}
        sellData={sellData}
        categories={categories}
        category={filteringCategory}
      />
    </Page>
  );
};

export default DepotPage;
