import { Alert, Button, Group, Input, Loader, NumberInput, Select, Text, Textarea } from "@mantine/core";
import RichSelect from "../RichSelect";
import { useForm } from "@mantine/form";
import { IconAlertCircle } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useRevalidator } from "react-router-dom";
import { useFetch } from "../../helpers/useFetch";
import { dosageAllOptions as dosageOptions } from "../../helpers/dosages";
import { parseFullName } from "../../helpers/helpers";

const getChildren = async () => {
  const res = await useFetch("/children/list");
  return res.data;
};

const NewMedicationModal = ({ innerProps, context, id }) => {
  const { child, medications: childMedications, setMedications: setChildMedications, setShouldFetch } = innerProps;
  const [addNext, setAddNext] = useState(false);
  const [dosage, setDosage] = useState([]);
  const [update, setUpdate] = useState(false);
  const [medications, setMedications] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [children, setChildren] = useState([]);
  const revalidator = useRevalidator();

  const form = useForm({
    initialValues: {
      childId: child?.id ? child.id : null,
      name: "",
      description: "",
    },
  });

  const createChilderList = async () => {
    setFetching(true);
    const childrenFetchList = await getChildren();
    const childrenList = childrenFetchList.map((child) => {
      return {
        label: `${parseFullName(child)} (${child.age} let${child.team ? ", oddíl " + child.team : ""})`,
        value: String(child.id),
      };
    });
    setChildren(childrenList);
    setFetching(false);
  };

  useEffect(() => {
    if (!child) {
      createChilderList();
    }
    useFetch("medical/medications-list").then((res) => {
      setMedications(res);
    });
  }, []);

  const findDosage = (label) => {
    return dosage.find((dose) => dose?.label === label);
  };

  const getIndex = (label) => {
    return dosage.findIndex((dose) => dose?.label === label);
  };

  const handleDosageChange = (value, type) => {
    let dosageTmp = dosage;
    const id = getIndex(type);
    if (findDosage(type) && value > 0) {
      dosageTmp[id].value = value;
    } else if (findDosage(type) && value === 0) {
      dosageTmp.splice(id, 1);
    } else {
      dosageTmp.push({ label: type, value: value });
    }
    setDosage(dosageTmp);
    setUpdate(!update);
  };

  const handleSubmit = async (values) => {
    if (dosage.length < 1) {
      return setError("Nebylo vybráno žádné dávkování");
    }
    setLoading(true);
    dosage.forEach((dose) => {
      values[dose.label] = dose.value;
    });
    const childId = values.childId;
    delete values.childId;
    const res = await useFetch("children/" + childId + "/medical-medication", "POST", values);
    setLoading(false);
    if (res.status === "ok") {
      form.reset();
      setDosage([]);
      if (childMedications && setChildMedications) {
        setChildMedications([...childMedications, res.data]);
      }
      toast.success("Pravidelný lék byl úspěšně přidán");
      if (setShouldFetch) setShouldFetch(true);
      revalidator.revalidate();
      if (addNext) {
        return setAddNext(false);
      }
      return context.closeModal(id);
    }
    console.error(res);
    values.childId = childId;
    setError("Nastala chyba při přidávání léku");
  };

  return (
    <>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při přidání léku nastala chyba"
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      {fetching ? (
        <div className="flex h-full w-full flex-col items-center justify-center py-8">
          <Loader />
        </div>
      ) : (
        <form onSubmit={form.onSubmit(handleSubmit)}>
          {!child && (
            <Select
              label="Dítě"
              mb={"sm"}
              data={children}
              {...form.getInputProps("childId")}
              searchable
              hoverOnSearchChange={true}
              required
              clearable
              loading={loading}
              placeholder="Vyber dítě, ke kterému bude přidán lék"
            />
          )}
          <Text className="mb-1 mt-3 text-sm font-bold">
            Lék<span className="ml-1 text-red-200">*</span>
          </Text>
          <RichSelect
            mb={"sm"}
            data={medications.map((item) => ({ label: item, value: item }))}
            {...form.getInputProps("name")}
            withAsterisk
            placeholder="Vyber lék nebo zadej nový..."
            searchable
            hoverOnSearchChange={true}
            creatable
            disabled={!form.values.childId || loading}
            required
            label={(data) => <Text>{data?.label}</Text>}
            getCreateLabel={(query) => `Zadat jiný lék: ${query}`}
            onCreate={(item) => {
              setMedications([...medications, item]);
              form.setFieldValue("name", item);
              return {
                value: item,
                label: item,
              };
            }}
          />

          <Text className="mb-1 mt-3 text-sm font-bold">
            Dávkování<span className="ml-1 text-red-200">*</span>
          </Text>
          <Group grow>
            {dosageOptions.map((dose) => {
              return (
                <>
                  <Button
                    type="button"
                    variant="light"
                    className={findDosage(dose.type) ? "hidden" : "font-bold"}
                    disabled={!form.values.childId || loading || !form.values.name}
                    onClick={() => {
                      handleDosageChange(1, dose.type);
                    }}>
                    {dose.text}
                  </Button>
                  <Input.Wrapper className={!findDosage(dose.type) ? "hidden w-1/4" : "w-1/7"} loading={loading}>
                    <NumberInput
                      min={0}
                      max={100}
                      step={0.5}
                      precision={1}
                      removeTrailingZeros
                      value={findDosage(dose.type)?.value}
                      disabled={!form.values.childId || loading}
                      onChange={(value) => {
                        handleDosageChange(value, dose.type);
                      }}
                    />
                  </Input.Wrapper>
                </>
              );
            })}
          </Group>
          <Textarea
            label="Poznámka"
            minRows={2}
            maxRows={4}
            placeholder="Poznámka například k úžívání..."
            mt={4}
            {...form.getInputProps("description")}
            disabled={!form.values.childId}
            loading={loading}
          />
          <Button
            type="submit"
            onClick={() => {
              setAddNext(false);
            }}
            variant="filled"
            mt={"lg"}
            disabled={!form.values.childId}
            loading={loading}
            className="w-full">
            Přidat pravidelný lék
          </Button>
          <Button
            type="submit"
            onClick={() => {
              setAddNext(true);
            }}
            variant="light"
            mt={"sm"}
            disabled={!form.values.childId}
            loading={loading}
            className="w-full">
            Uložit a přidat další
          </Button>
        </form>
      )}
    </>
  );
};

export default NewMedicationModal;
