import { Alert, Button, Paper, Text } from "@mantine/core";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch";
import { IconAlertCircle, IconPencil } from "@tabler/icons-react";

const PcFillQuestModal = ({ context, id, innerProps }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const setShouldFetch = innerProps.setShouldFetch;
  const setChanged = innerProps.setChanged;

  useEffect(() => {
    if (!innerProps?.quest || !innerProps?.team) {
      context.closeModal(id);
    }
  });

  const quest = innerProps.quest;
  const team = innerProps.team;

  const handleSubmit = async () => {
    setLoading(true);
    const res = await useFetch(`/pc/quests/${quest.id}/${team.id}/${quest.done ? "discard" : "complete"}`, "PUT");
    setChanged({ questId: quest.id, teamId: team.id });

    if (res.status === "ok") {
      context.closeModal(id);
      if (setShouldFetch) setShouldFetch(true);
      toast.success(quest.done ? "Úkol označen jako nehotový" : "Úkol označen jako hotový");
    } else {
      setChanged(null);
      setLoading(false);
      setError(res.message);
    }
  };

  return (
    <div>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při mazání úkolu nastala chyba."
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      <Paper withBorder py="xs" px="sm" mb={20}>
        <Text fw="bold" mb={4}>
          {team.shortcut}
          {team.customName ? ` - ${team.customName}` : ""}
        </Text>
        <Text>
          <strong>Název:</strong> {quest.title}
          <br />
          <strong>Popis:</strong> {quest.text}
          <br />
          <strong>Lore:</strong> {quest.lore}
        </Text>
      </Paper>
      <div className="hidden w-full gap-5 md:flex">
        <Button variant="light" fullWidth onClick={() => context.closeModal(id)}>
          Zrušit
        </Button>
        <Button fullWidth onClick={handleSubmit} loading={loading}>
          {quest.done ? "Označit jako nehotový" : "Označit jako hotový"}
        </Button>
      </div>
      <div className="flex w-full gap-5 md:hidden">
        <Button variant="light" fullWidth onClick={() => context.closeModal(id)}>
          Zrušit
        </Button>
        <Button fullWidth onClick={handleSubmit} loading={loading}>
          {quest.done ? "Nehotový" : "Hotový"}
          <IconPencil size={16} className="ml-1" stroke={1.5} />
        </Button>
      </div>
    </div>
  );
};

export default PcFillQuestModal;
