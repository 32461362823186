import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useEffect, useState } from "react";
import FullPageLoader from "./components/layout/FullPageLoader.jsx";
import "./global.scss";
import StylesProvider from "./providers/StylesProvider.jsx";
import routes from "./helpers/routes.jsx";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

if (!import.meta.env.DEV) {
  posthog.init("phc_KiXZRkXOtvUy3wggLgcuczeWlTN3qqcAVFbQTKtGFF4", { api_host: "https://eu.posthog.com" });
}

function App() {
  const router = createBrowserRouter(routes);

  const [loading, setLoading] = useState(false);

  const changeTheme = () => {
    const body = document.querySelector("body");
    let rainbow = localStorage.getItem("rainbow");
    if (rainbow === "enable") {
      body.classList.add("rainbow");
    }
  };

  useEffect(() => {
    async function updateUser() {
      if (localStorage.getItem("user")) {
        setLoading(true);
        const userData = JSON.parse(localStorage.getItem("user"));

        posthog.identify(String(userData?.id), {
          email: userData?.email,
          name: `${userData?.firstName} ${userData?.lastName}`,
          area: userData?.area,
        });

        const optionsDefault = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userData.token,
          },
        };
        const res = await fetch(import.meta.env.VITE_API_URL + "/user", optionsDefault);
        if (res.status === 401) {
          localStorage.removeItem("user");
          window.location.href = "/prihlaseni";
          return;
        }
        if (res.status === 503 && window.location.href !== "/probiha-udrzba") {
          window.location.href = "/probiha-udrzba";
          return;
        }
        const json = await res.json();
        json.data.token = userData.token;
        localStorage.setItem("user", JSON.stringify(json.data));
        setLoading(false);
      } else if (
        !window.location.pathname.startsWith("/registrace") &&
        !window.location.pathname.startsWith("/prihlaseni") &&
        !window.location.pathname.startsWith("/heslo")
      ) {
        window.location.href = "/prihlaseni";
      }
    }

    changeTheme();
    if (!window.location.pathname.startsWith("/probiha-udrzba")) {
      updateUser();
    }
  }, []);

  return (
    <StylesProvider>
      <PostHogProvider client={posthog}>
        {loading ? <FullPageLoader /> : <RouterProvider router={router} />}
      </PostHogProvider>
    </StylesProvider>
  );
}

export default App;
