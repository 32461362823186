import { Alert } from "@mantine/core";
import { IconAlertOctagon, IconAlertTriangle, IconCheck, IconInfoCircle } from "@tabler/icons-react";

export function AlertError({ title, children, mb, mt, onClose }) {
  return (
    <Alert
      mb={mb}
      mt={mt}
      onClose={onClose}
      icon={<IconAlertOctagon size={16} stroke={1.5} />}
      title={title}
      color="red"
      withCloseButton={onClose !== undefined}
    >
      {children}
    </Alert>
  );
}

export function AlertWarning({ title, children, mb, mt, onClose }) {
  return (
    <Alert
      mb={mb}
      mt={mt}
      onClose={onClose}
      icon={<IconAlertTriangle size={16} stroke={1.5} />}
      title={title}
      color="yellow"
      withCloseButton={onClose !== undefined}
    >
      {children}
    </Alert>
  );
}

export function AlertSuccess({ title, children, mb, mt, onClose }) {
  return (
    <Alert
      mb={mb}
      mt={mt}
      onClose={onClose}
      icon={<IconCheck size={16} stroke={1.5} />}
      title={title}
      color="teal"
      withCloseButton={onClose !== undefined}
    >
      {children}
    </Alert>
  );
}

export function AlertInfo({ title, children, mb, mt, onClose }) {
  return (
    <Alert
      mb={mb}
      mt={mt}
      onClose={onClose}
      icon={<IconInfoCircle size={16} stroke={1.5} />}
      title={title}
      color="indigo"
      withCloseButton={onClose !== undefined}
    >
      {children}
    </Alert>
  );
}
