import { ActionIcon, Alert, Button, Group, Input, Loader, Paper, ScrollArea, Select, Text } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useHotkeys, useViewportSize } from "@mantine/hooks";
import { IconCalendar, IconEye, IconEyeOff, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import classNames from "classnames";
import { getMode } from "../../helpers/helpers";

export default function DocumentViewer({ document, setOpened }) {
  const [numPages, setNumPages] = useState(null);
  const { width } = useViewportSize();
  const [loading, setLoading] = useState(true);
  const [link, setLink] = useState("");

  useEffect(() => {
    if (document && document !== link) {
      setLink(document);
    }
  }, [document]);

  useHotkeys([["Escape", () => setOpened(false)]]);

  const onDocumentLoadSuccess = ({ numPages, ...other }) => {
    setNumPages(numPages);
  };

  const downloadDocument = () => {
    const link = document;
    const anchor = window.document.createElement("a");
    anchor.href = link;
    anchor.download = link.split("/").pop();
    anchor.target = "_blank";
    anchor.click();
  };

  const { mode, theme } = getMode();

  return (
    <div
      className={classNames("absolute left-0 top-0 z-20 flex h-full w-full flex-col justify-center", {
        "bg-dark-200": mode === "dark",
        "bg-dark-900": mode === "light",
      })}>
      <ScrollArea className="h-full w-full">
        <Document
          file={document}
          onLoadSuccess={onDocumentLoadSuccess}
          className="h-full w-full"
          loading={
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center">
              <Loader />
              <Text mt="md">Načítání dokumentu...</Text>
            </div>
          }
          error={
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center text-center">
              <Text className="text-6xl">😰</Text>
              <Text mt="md">Dokument nelze načíst, nejspíše chyba na straně serveru.</Text>
            </div>
          }>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              renderAnnotationLayer={false}
              renderTextLayer={false}
              key={`page_${index + 1}`}
              className="mx-auto my-4 w-fit"
              loading={null}
              width={Math.min(width, 1000)}
              pageNumber={index + 1}
              onRenderSuccess={() => setLoading(false)}
            />
          ))}
        </Document>
        <Button onClick={downloadDocument} className="absolute bottom-0 right-0 m-8" variant="light">
          Stáhnout dokument
        </Button>
        <ActionIcon
          size="lg"
          onClick={() => {
            setOpened(false);
          }}
          className="absolute right-0 top-0 z-10 m-8"
          variant="light"
          color="red">
          <IconX size={24} stroke={1.5} />
        </ActionIcon>
      </ScrollArea>
    </div>
  );
}
