import Table from "./core/Table";
import { parseFullName } from "../../helpers/helpers";
import { ActionIcon, Group, Stack, Text, Tooltip } from "@mantine/core";
import { IconChevronDown, IconCloudCheck, IconFileText, IconSignature, IconX } from "@tabler/icons-react";
import { useState } from "react";
import styles from "../../pages.module.scss";
import classNames from "classnames";
import DocumentViewer from "../boarding/DocumentViewer";
import { sortDocuments } from "./sort";
import { openFilePhotoModal } from "../../helpers/modals";

const MedicalDocumentsTable = ({ data }) => {
  const [openChildId, setOpenChildId] = useState(0);
  const [viewerOpened, setViewerOpened] = useState(false);
  const [viewingDocument, setViewingDocument] = useState("");

  const documentsState = (child, type) => {
    const documents = child.documents;

    return documents[type] ? (
      <Tooltip
        position="top"
        withArrow
        transition="fade"
        transitionDuration={200}
        label={
          documents[type]?.status === "core"
            ? "Dokument evidován online"
            : documents[type]?.status === "signed"
            ? "Dokument podepsán elektronicky"
            : documents[type]?.status === "paper"
            ? "Dokument předán papírově"
            : "Dokument nepředán"
        }>
        <ActionIcon
          color={
            documents[type] && documents[type]?.status !== "none"
              ? documents[type]?.status === "paper"
                ? "yellow"
                : "teal"
              : "red"
          }
          variant="filled"
          onClick={
            documents[type].filePath
              ? (e) => {
                  setViewingDocument(documents[type].filePath);
                  setViewerOpened(true);
                  e.stopPropagation();
                }
              : documents[type]?.status === "paper"
              ? (e) => {
                  e.stopPropagation();
                  openFilePhotoModal(child, documents[type].type);
                }
              : null
          }>
          {documents[type]?.status === "core" && <IconCloudCheck stroke={1.5} size={20} />}
          {documents[type]?.status === "none" && <IconX stroke={1.5} size={20} />}
          {documents[type]?.status === "signed" && <IconSignature stroke={1.5} size={20} />}
          {documents[type]?.status === "paper" && <IconFileText stroke={1.5} size={20} />}
        </ActionIcon>
      </Tooltip>
    ) : (
      <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Dokument nepředán">
        <ActionIcon color="red" variant="filled" onClick={(e) => e.stopPropagation()}>
          <IconX stroke={1.5} size={20} />
        </ActionIcon>
      </Tooltip>
    );
  };

  const expandContent = (child) => {
    if (
      child.medicalInfo.medical_restrictions === "" &&
      child.medicalInfo.allergies === "" &&
      child.medicalInfo.dietary_restrictions === "" &&
      child.parentMedicalInfo.medical_restrictions === "" &&
      child.parentMedicalInfo.allergies === "" &&
      child.parentMedicalInfo.dietary_restrictions === ""
    ) {
      return (
        <Group py={"sm"}>
          <p>Nic tu není</p>
        </Group>
      );
    }

    return (
      <Group py={"sm"} className="w-full" grow gap={"md"} align="flex-start">
        <Stack>
          {child.medicalInfo.medical_restrictions !== "" && (
            <div>
              <Text fw={"bold"}>Zdravotní omezení:</Text>
              <Text fw={"normal"}>{child.medicalInfo.medical_restrictions}</Text>
            </div>
          )}

          {child.medicalInfo.allergies !== "" && (
            <div>
              <Text fw={"bold"}>Alergie:</Text>
              <Text fw={"normal"}>{child.medicalInfo.allergies}</Text>
            </div>
          )}

          {child.medicalInfo.dietary_restrictions !== "" && (
            <div>
              <Text fw={"bold"}>Stravování:</Text>
              <Text fw={"normal"}>{child.medicalInfo.dietary_restrictions}</Text>
            </div>
          )}
        </Stack>
        <Stack>
          {child.parentMedicalInfo.medical_restrictions !== "" && (
            <div>
              <Text fw={"bold"} color={"dimmed"}>
                Zdravotní omezení od rodiče:
              </Text>
              <Text fw={"normal"} color={"dimmed"}>
                {child.parentMedicalInfo.medical_restrictions}
              </Text>
            </div>
          )}

          {child.parentMedicalInfo.allergies !== "" && (
            <div>
              <Text fw={"bold"} color={"dimmed"}>
                Alergie od rodiče:
              </Text>
              <Text fw={"normal"} color={"dimmed"}>
                {child.parentMedicalInfo.allergies}
              </Text>
            </div>
          )}

          {child.parentMedicalInfo.dietary_restrictions !== "" && (
            <div>
              <Text fw={"bold"} color={"dimmed"}>
                Stravování od rodiče:
              </Text>
              <Text fw={"normal"} color={"dimmed"}>
                {child.parentMedicalInfo.dietary_restrictions}
              </Text>
            </div>
          )}
        </Stack>
      </Group>
    );
  };

  const expandChild = (child) => {
    if (openChildId === child.id) return setOpenChildId(0);
    setOpenChildId(child.id);
  };

  const actionButtons = (child) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Rozbalit informace">
          <ActionIcon
            variant="light"
            color="gray"
            className={classNames(styles.Expander, openChildId === child.id ? styles.Expanded : "")}>
            <IconChevronDown stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const columns = [
    {
      accessor: "name",
      title: "Jméno",
      render: (child) => parseFullName(child),
      sortable: true,
      sortFunction: (a, b) => {
        return parseFullName(a).localeCompare(parseFullName(b));
      },
    },
    {
      accessor: "age",
      title: "Věk",
      render: (child) => child.age,
      sortable: true,
    },
    {
      accessor: "medicalCertificate",
      title: "LP",
      render: (child) => documentsState(child, "medicalCertificate"),
      narrow: true,
      sortable: true,
    },
    {
      accessor: "infectiousness",
      title: "BI",
      render: (child) => documentsState(child, "infectiousness"),
      narrow: true,
      sortable: true,
    },
    {
      accessor: "insuranceCard",
      title: "KP",
      render: (child) => documentsState(child, "insuranceCard"),
      narrow: true,
      sortable: true,
    },
    {
      accessor: "campRules",
      title: "TŘ",
      render: (child) => documentsState(child, "campRules"),
      narrow: true,
      sortable: true,
    },
    {
      accessor: "handover",
      title: "Př",
      render: (child) => documentsState(child, "handover"),
      narrow: true,
      sortable: true,
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (child) => actionButtons(child),
      narrow: true,
    },
  ];

  const filterConfig = [
    {
      key: "jmeno",
      label: "Jméno",
      type: "search",
      function: (child, value) => {
        return parseFullName(child).toLowerCase().includes(value.toLowerCase());
      },
    },
  ];

  return (
    <>
      <Table
        id={"medical-documents"}
        columns={columns}
        filterConfig={filterConfig}
        records={data}
        onRowClick={(record) => {
          expandChild(record);
        }}
        sortFunction={sortDocuments}
        rowExpansion={{
          collapseProps: {
            transitionDuration: 300,
            transitionTimingFunction: "ease",
          },
          content: ({ record }) => expandContent(record),
        }}
      />
      {viewerOpened && <DocumentViewer document={viewingDocument} setOpened={setViewerOpened} />}
    </>
  );
};

export default MedicalDocumentsTable;
