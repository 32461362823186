import { useLoaderData } from "react-router-dom";
import Page from "../../components/layout/Page";
import { useFetch } from "../../helpers/useFetch";
import { Accordion, Alert, Button, FileInput, Group, Paper, Text } from "@mantine/core";
import { IconCheck, IconClock, IconEye, IconSignature, IconUpload, IconX } from "@tabler/icons-react";
import classNames from "classnames";
import DocumentSign from "../../components/boarding/DocumentSign";
import { useRef, useState } from "react";
import { optimize } from "svgo";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import DocumentViewer from "../../components/boarding/DocumentViewer";
import { AlertError } from "../../components/Alerts";

export async function loader({ params }) {
  const res = await useFetch(`user/contracts`);

  if (res.data.find((contract) => contract.id === Number(params.contractId)) === undefined) {
    throw new Response("Smlouva nenalezena. Zkus se vrátit a zkusit to znovu.", {
      status: 404,
    });
  }

  return res.data.find((contract) => contract.id === Number(params.contractId));
}

export default function ContractsSignPage({ params }) {
  const contract = useLoaderData();
  const [signOpened, setSignOpened] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [viewerOpened, setViewerOpened] = useState(false);
  const [viewingDocument, setViewingDocument] = useState(null);
  const [uploadingDocument, setUploadingDocument] = useState(null);

  const [documentsLocal, setDocumentsLocal] = useState(contract.documents);

  const signatureRef = useRef();

  const handleDocumentClick = (document) => {
    if (document.status === "signed" && document.filePath) {
      setViewingDocument(document.filePath);
      setViewerOpened(true);
      return;
    }
    setSelectedDocument(document);
    setSignOpened(true);
  };

  const signDocument = async (fields) => {
    const svgElement = document.createElement("svg");
    const trimElement = signatureRef.current;

    const bBox = trimElement.getBBox();
    svgElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svgElement.setAttribute("viewBox", `${bBox.x} ${bBox.y} ${bBox.width} ${bBox.height}`);
    svgElement.setAttribute("width", trimElement.getBBox().width);
    svgElement.setAttribute("height", trimElement.getBBox().height);
    svgElement.appendChild(trimElement.cloneNode(true));

    const result = optimize(svgElement.outerHTML, { multipass: true, floatPrecision: 2 });

    let parser = new DOMParser();
    let doc = parser.parseFromString(result.data, "image/svg+xml");
    let optimizedSvg = doc.querySelector("svg");
    optimizedSvg.setAttribute(
      "viewBox",
      `${Math.round(bBox.x)} ${Math.round(bBox.y)} ${Math.round(bBox.width)} ${Math.round(bBox.height)}`
    );
    optimizedSvg.setAttribute("width", Math.round(bBox.width));
    optimizedSvg.setAttribute("height", Math.round(bBox.height));

    const serialized = new XMLSerializer().serializeToString(optimizedSvg);

    const payload = btoa(serialized);

    useFetch(`/user/contract/${contract.id}/sign`, "POST", {
      type: selectedDocument.type,
      status: "signed",
      fields:
        fields &&
        Object.keys(fields).map((field) => {
          let value = fields[field];
          if (dayjs(value).isValid()) {
            value = dayjs(value).format("D. M. YYYY");
          }
          return {
            variable: field,
            value: value,
          };
        }),
      svg: payload,
    })
      .then((res) => {
        if (res.status === "ok") {
          toast.success("Dokument úspěšně podepsán.");
          setDocumentsLocal(
            documentsLocal.map((doc) =>
              doc.type === selectedDocument.type
                ? {
                    ...doc,
                    status: "signed",
                    filePath: res.filePath,
                  }
                : doc
            )
          );
        } else {
          toast.error("Nastala chyba při podpisu dokumentu a byla nahlášena.");
        }
        setSignOpened(false);
      })
      .catch((err) => {
        toast.error("Nastala chyba při podpisu dokumentu a byla nahlášena.");
        setSignOpened(false);
        console.error(err);
      });
  };

  const handleDocumentUpload = (e, document) => {
    setUploadingDocument(document.type);
    const formData = new FormData();
    formData.append("file", uploadingDocument);
    formData.append("type", document.type);
    formData.append("status", "signed");

    useFetch(`/user/contract/${contract.id}/sign`, "POST", formData, false)
      .then((res) => {
        if (res.status === "ok") {
          toast.success("Dokument úspěšně nahrán.");
          setDocumentsLocal(
            documentsLocal.map((doc) =>
              doc.type === document.type
                ? {
                    ...doc,
                    status: "signed",
                    filePath: res.filePath,
                  }
                : doc
            )
          );
        } else {
          toast.error("Nastala chyba při nahrávání dokumentu a byla nahlášena.");
        }
        setUploadingDocument(null);
      })
      .catch((err) => {
        toast.error("Nastala chyba při nahrávání dokumentu a byla nahlášena.");
        setUploadingDocument(null);
        console.error(err);
      });
  };

  console.log(contract);

  return (
    <>
      <Page title="Podpis smlouvy">
        <Paper className="mx-auto max-w-3xl" p="xl" radius="lg" withBorder>
          <h2>Seznam dokumentů</h2>
          {contract.canSignContract ? (
            <Accordion variant="separated" radius="md" mt="md">
              {documentsLocal.map((document) => (
                <Accordion.Item key={document.type} className="overflow-hidden border-2" value={document.type}>
                  <Accordion.Control className="relative overflow-hidden pl-16">
                    <Group align="center" gap="sm">
                      <div
                        className={classNames(
                          "text-white absolute left-0 top-0 flex h-full w-[50px] items-center justify-center",
                          {
                            "bg-teal-200": document.status === "signed" && document.canSign,
                            "bg-red-200": document.status !== "signed" && document.canSign,
                            "bg-yellow-200": !document.canSign,
                          }
                        )}>
                        {document.status === "signed" ? (
                          <IconCheck stroke={1.5} size={24} />
                        ) : document.canSign ? (
                          <IconX stroke={1.5} size={24} />
                        ) : (
                          <IconClock stroke={1.5} size={24} />
                        )}
                      </div>
                      <strong>{document.name}</strong>
                    </Group>
                  </Accordion.Control>
                  <Accordion.Panel className="mt-3">
                    <Text>{document.description}</Text>
                    {document.status === "signed" ? (
                      <Button
                        mt="md"
                        fullWidth
                        leftSection={<IconEye size={18} stroke={1.5} />}
                        onClick={() => handleDocumentClick(document)}>
                        Zobrazit podepsaný dokument
                      </Button>
                    ) : document.canSign && document.canUpload ? (
                      <Group mt="md" align="center" gap="sm" grow>
                        <FileInput
                          leftSection={<IconUpload size={18} stroke={1.5} />}
                          onChange={setUploadingDocument}
                          value={uploadingDocument}
                          accept="image/*, .pdf"
                          placeholder="Nahrát dokument"
                        />
                        <Button
                          leftSection={<IconUpload size={18} stroke={1.5} />}
                          onClick={() => handleDocumentUpload(null, document)}
                          disabled={!uploadingDocument}>
                          Nahrát dokument
                        </Button>
                      </Group>
                    ) : document.canSign ? (
                      <Button
                        mt="md"
                        fullWidth
                        leftSection={<IconSignature size={18} stroke={1.5} />}
                        onClick={() => handleDocumentClick(document)}>
                        Podepsat dokument
                      </Button>
                    ) : (
                      <Button mt="md" fullWidth disabled={true}>
                        Ještě nenastal ten správný čas na podpis tohoto dokumentu.
                      </Button>
                    )}
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>
          ) : (
            <AlertError title="Tuto smlouvu nemůžeš zatím podepisovat" mt="md">
              {contract.canSignContractErrorMessage}
            </AlertError>
          )}
        </Paper>
      </Page>
      {signOpened && (
        <DocumentSign
          signDocument={signDocument}
          signatureRef={signatureRef}
          document={selectedDocument}
          setOpened={setSignOpened}
        />
      )}
      {viewerOpened && <DocumentViewer document={viewingDocument} setOpened={setViewerOpened} />}
    </>
  );
}
