import {
  IconDeviceGamepad,
  IconDeviceGamepad2,
  IconDownload,
  IconFilePlus,
  IconId,
  IconIdBadge2,
  IconListDetails,
  IconPill,
  IconVirus,
  IconX,
  IconNotebook,
  IconList,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import ChildrenTable from "../../components/table/ChildrenTable.jsx";
import useTitle from "../../hooks/useTitle.js";
import Page from "../../components/layout/Page.jsx";
import { ActionIcon, Button, Group, Skeleton, Tooltip } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { openPcChildAccounts, openMcNicknameModal, openPcNoteModal } from "../../helpers/modals.js";
import { useFetch } from "../../helpers/useFetch.js";
import { parseFullName } from "../../helpers/helpers.js";
import { useUser } from "../../providers/UserProvider.jsx";
import PcChildrenTable from "../../components/table/PcChildrenTable.jsx";

const PcChildrenPage = () => {
  const [openedFilters, setOpenedFilters] = useState(false);
  const [newReport, setNewReport] = useState(false);
  const [teams, setTeams] = useState(null);
  const [filteringTeam, setFilteringTeam] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false);
  useTitle(`Přehled dětí`);

  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    if (!teams) {
      useFetch("/teams")
        .then((data) => {
          setTeams(data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  const actionButtonsTable = (child) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label="Otevřít počítačovou poznámku">
          <ActionIcon
            variant="light"
            color="gray"
            onClick={(e) => {
              e.stopPropagation();
              openPcNoteModal(child, setShouldFetch);
            }}>
            <IconNotebook stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Změnit Minecraft přezdívku">
          <ActionIcon
            variant="light"
            color="gray"
            onClick={(e) => {
              e.stopPropagation();
              openMcNicknameModal(child, setShouldFetch);
            }}>
            <IconDeviceGamepad2 stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Seznam herních účtů">
          <ActionIcon
            variant="light"
            color="gray"
            onClick={(e) => {
              e.stopPropagation();
              openPcChildAccounts(child, setShouldFetch);
            }}>
            <IconList stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  return (
    <Page title="Přehled dětí">
      <Group gap="sm" className="w-full" mb="lg">
        <Group gap="sm" className="flex-grow" grow>
          {teams
            ? teams.map((team) => (
                <Button
                  onClick={() => (filteringTeam === team.id ? setFilteringTeam(null) : setFilteringTeam(team.id))}
                  className="min-w-fit"
                  variant={filteringTeam === team.id ? "filled" : "light"}
                  key={team.id}>
                  {team.shortcut}
                </Button>
              ))
            : [...Array(9)].map((_, i) => <Skeleton key={i} radius="sm" height={36} />)}
        </Group>
        <ActionIcon size={36} className="grow-0" variant="light" color="red" onClick={() => setFilteringTeam("")}>
          <IconX stroke={1.5} size={24} />
        </ActionIcon>
      </Group>

      <PcChildrenTable
        shouldFetch={shouldFetch}
        setShouldFetch={setShouldFetch}
        team={filteringTeam}
        teams={teams}
        showFilters={openedFilters}
        actionButtons={actionButtonsTable}
      />
    </Page>
  );
};

export default PcChildrenPage;
