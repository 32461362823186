import { useFetch } from "../../helpers/useFetch.js";
import { useLoaderData } from "react-router-dom";
import { TeamTable } from "../../components/table/TeamTable.jsx";
import useTitle from "../../hooks/useTitle.js";
import Page from "../../components/layout/Page.jsx";
import { useEffect, useState } from "react";

export async function loader({ params }) {
  const res = await useFetch(`teams/${params.teamId}`);
  if (!res.data) {
    throw new Response("Oddíl nenalezen. Jsi ve správném turnusu?", {
      status: 404,
    });
  }
  return res;
}

export default function TeamPage() {
  const team = useLoaderData().data;
  useTitle(`${team.name} #${team.number} – Detail oddílu`);

  team.children.forEach((child) => {
    child.medicalValue = 0;
    const medicalInfo = child.medicalInfo;
    for (const key in medicalInfo) {
      if (!key.includes("_important") && medicalInfo[key] !== "") child.medicalValue++;
      if (key.includes("_important") && medicalInfo[key] === true) child.medicalValue++;
    }
    if (!child.canSwim) {
      child.medicalValue++;
    }
  });

  /* useEffect(() => {
    rooms.map((floor) => {
      floor.rooms.map((room) => {
        room.occupation.map((childLocal) => {
          let child = children.find((child) => child.id === childLocal.id);

          let hasToClean = false;
          let recordsLocal = records;
          if (childLocal?.id === child?.id) {
            let latestPoints = room?.points[Object.keys(room?.points)[Object.keys(room?.points).length - 1]];

            if (latestPoints && child) {
              if (latestPoints.points <= 2) {
                hasToClean = true;

                recordsLocal = recordsLocal.map((child) => {
                  if (child.id === childLocal.id) {
                    return {
                      ...child,
                      hasToClean: true,
                    };
                  } else {
                    return child;
                  }
                });

                setRecords(recordsLocal);
              }
            }
          }
        });
      });
    });
  }, []); */

  return (
    <Page subtitle="Detail oddílu" title={`${team.name} #${team.number}`}>
      <TeamTable childrenData={team.children} />
    </Page>
  );
}
