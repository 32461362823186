import { createContext, useState } from "react";

export const CartContext = createContext();

export const CartContextProvider = ({ children }) => {
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")) || []);
  const [checkoutDrawerOpened, setCheckoutDrawerOpened] = useState(false);
  const [updateCart, setUpdateCart] = useState(false);

  const openCheckoutDrawer = () => {
    setCheckoutDrawerOpened(true);
  };

  const closeCheckoutDrawer = () => {
    setCheckoutDrawerOpened(false);
  };

  const addToCart = (product) => {
    let cartTemp = cart;
    if (cart.some((p) => p.id === product.id)) {
      if (product.quantity === cart.find((p) => p.id === product.id).quantity) return;
      cartTemp = cart.map((p) => {
        if (p.id === product.id) {
          return { ...p, quantity: p.quantity + 1 };
        }
        return p;
      });
    } else {
      cartTemp.push({ id: product.id, quantity: 1 });
    }
    setCart(cartTemp);
    setUpdateCart(!updateCart);
    localStorage.setItem("cart", JSON.stringify(cartTemp));
    return;
  };

  const removeFromCart = (product) => {
    let cartTemp = cart;
    if (cart.some((p) => p.id === product.id)) {
      if (cart.find((p) => p.id === product.id).quantity === 1) {
        cartTemp = cart.filter((p) => p.id !== product.id);
      } else {
        cartTemp = cart.map((p) => {
          if (p.id === product.id) {
            return { ...p, quantity: p.quantity - 1 };
          }
          return p;
        });
      }
    }
    setCart(cartTemp);
    localStorage.setItem("cart", JSON.stringify(cartTemp));
    return;
  };

  const setQuantityInCart = (product, quantity) => {
    let cartTemp = cart;
    var quantityLocal = quantity > product.quantity ? product.quantity : quantity;
    if (quantity === 0) {
      cartTemp = cart.filter((p) => p.id !== product.id);
    } else {
      if (cart.some((p) => p.id === product.id)) {
        cartTemp = cart.map((p) => {
          if (p.id === product.id) {
            return { ...p, quantity: quantityLocal };
          }
          return p;
        });
      } else {
        cartTemp.push({ id: product.id, quantity: quantityLocal });
      }
    }
    setCart(cartTemp);
    localStorage.setItem("cart", JSON.stringify(cart));
    return;
  };

  const getOverallPrice = (products) => {
    var overallPrice = 0;
    cart.forEach((cartItem) => {
      var product = products.find((p) => p.id === cartItem.id);
      overallPrice += (product?.sellPrice || 0) * cartItem.quantity;
    });
    return overallPrice;
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem("cart");
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        setQuantityInCart,
        getOverallPrice,
        clearCart,
        checkoutDrawerOpened,
        openCheckoutDrawer,
        closeCheckoutDrawer,
      }}>
      {children}
    </CartContext.Provider>
  );
};
