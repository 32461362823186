import useTitle from "../../hooks/useTitle.js";
import { IconBrandTelegram, IconEdit, IconMessagePlus, IconSend, IconTrash } from "@tabler/icons-react";
import Page from "../../components/layout/Page.jsx";
import Table from "../../components/table/core/Table.jsx";
import { parseFullName } from "../../helpers/helpers.js";
import { Group, Tooltip, ActionIcon, Text, Paper } from "@mantine/core";
import { useUser } from "../../providers/UserProvider.jsx";
import { useState } from "react";
import dayjs from "dayjs";
import { useFetch } from "../../helpers/useFetch.js";
import { openConfirmModal } from "@mantine/modals";
import toast from "react-hot-toast";
import { modalTitle } from "../../components/modals/Modal.jsx";
import { openDiscordScheduledMessageModal } from "../../helpers/modals.js";

export default function ScheduledMessagesPage() {
  const [serverFetching, setServerFetching] = useState(false);
  const [shouldServerFetch, setShouldServerFetch] = useState(false);
  useTitle(`Plánování zpráv`);

  const { user } = useUser();

  const actionIcons = [
    {
      name: "Vytvořit naplánovanou zprávu",
      onTrigger: () => {
        openDiscordScheduledMessageModal(setShouldServerFetch);
      },
      icon: IconMessagePlus,
      primary: true,
    },
  ];

  const deleteMessage = (message) => {
    openConfirmModal({
      title: modalTitle(IconTrash, "Smazat zprávu"),
      children: (
        <>
          <Text size="sm" mb="sm">
            Opravdu chceš smazat tuto zprávu?
          </Text>
          <Paper withBorder py="xs" px="sm">
            <Text fw="bold" mb={4}>
              Zpráva #{message.id}
            </Text>
            <Text>
              <strong>Odesláno:</strong> {dayjs(message.sendDateConcatenated).format("D. M. YYYY HH:mm")}
              <br />
              <strong>Zpráva:</strong>
              <br />
              {message.message}
            </Text>
          </Paper>
        </>
      ),
      labels: { confirm: "Smazat", cancel: "Zrušit smazání" },
      onConfirm: () => {
        useFetch(`/discord/scheduled-messages/${message.id}`, "DELETE")
          .then(() => {
            toast.success("Zpráva byla smazána");
            setShouldServerFetch(true);
          })
          .catch((err) => {
            console.error(err);
            toast.error("Nepodařilo se smazat zprávu");
          });
      },
    });
  };

  const sendNow = (message) => {
    openConfirmModal({
      title: modalTitle(IconBrandTelegram, "Odeslat zprávu nyní"),
      children: (
        <>
          <Text size="sm" mb="sm">
            Opravdu chceš poslat tuto zprávu na server?
          </Text>
          <Paper withBorder py="xs" px="sm">
            <Text fw="bold" mb={4}>
              Zpráva #{message.id}
            </Text>
            <Text>
              <strong>Zpráva:</strong>
              <br />
              {message.message}
            </Text>
          </Paper>
        </>
      ),
      labels: { confirm: "Odeslat", cancel: "Zrušit odeslání" },
      onConfirm: () => {
        /* useFetch(`/discord/scheduled-messages/${message.id}`, "DELETE")
          .then(() => {
            toast.success("Zpráva byla smazána");
            setShouldServerFetch(true);
          })
          .catch((err) => {
            console.error(err);
            toast.error("Nepodařilo se smazat zprávu");
          }); */
      },
    });
  };

  const columns = [
    {
      accessor: "id",
      title: "ID",
      render: (message) => "#" + message.id,
    },
    {
      accessor: "message",
      title: "Text zprávy",
      disableHiding: true,
      render: (message) => message.message,
    },
    {
      accessor: "sendDate",
      title: "Datum odeslání",
      render: (message) => dayjs(message.sendDateConcatenated).format("D. M. YYYY HH:mm (ddd)"),
    },
    {
      accessor: "actions",
      title: "Akce",
      render: (message) => (
        <Group wrap="nowrap" gap="xs">
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Smazat zprávu">
            <ActionIcon
              variant="light"
              color="red"
              onClick={(e) => {
                e.stopPropagation();
                deleteMessage(message);
              }}>
              <IconTrash stroke={1.5} size={18} />
            </ActionIcon>
          </Tooltip>
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Upravit zprávu">
            <ActionIcon
              variant="light"
              onClick={(e) => {
                e.stopPropagation();
                openDiscordScheduledMessageModal(setShouldServerFetch, message);
              }}>
              <IconEdit stroke={1.5} size={18} />
            </ActionIcon>
          </Tooltip>
        </Group>
      ),
      narrow: true,
    },
  ];

  return (
    <Page title="Plánování zpráv" actionIcons={actionIcons}>
      <Table
        columns={columns}
        serverUrl="/discord/scheduled-messages"
        useServer={true}
        id="discord-messages"
        serverFetching={serverFetching}
        setServerFetching={setServerFetching}
        shouldServerFetch={shouldServerFetch}
        setShouldServerFetch={setShouldServerFetch}
      />
    </Page>
  );
}
