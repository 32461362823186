import { useEffect, useState } from "react";
import { useLoaderData, useRevalidator } from "react-router-dom";
import Page from "../../components/layout/Page";
import MedicalReportDetailDrawer from "../../components/medical/MedicalReportDetailDrawer";
import TodayChecksTable from "../../components/table/TodayChecksTable";
import { useFetch } from "../../helpers/useFetch";
import useTitle from "../../hooks/useTitle.js";
import NewMedicalReportDrawer from "../../components/medical/NewMedicalReportDrawer";
import { toast } from "react-hot-toast";

export function loader() {
  return useFetch("medical/records/check-list");
}
const TodayChecksPage = () => {
  const checks = useLoaderData().data;
  useTitle("Dnešní kontroly");
  const [reportDetail, setReportDetail] = useState(null);
  const revalidator = useRevalidator();
  const [update, setUpdate] = useState(false);
  const [selectedCheck, setSelectedCheck] = useState(null);

  useEffect(() => {
    setUpdate(!update);
  }, [checks]);

  const handleCheck = async (check, created) => {
    if (!created) return setSelectedCheck(null);
    const res = await useFetch("medical/records/" + check.id + "/check", "PUT");
    if (res.status === "ok") {
      check.isChecked = true;
      toast.success("Kontrola byla provedena.");
      resetTable();
    }
    setSelectedCheck(null);
  };

  const resetTable = () => {
    revalidator.revalidate();
  };

  return (
    <Page title={"Dnešní kontroly"}>
      <TodayChecksTable
        checks={checks}
        setReportDetail={setReportDetail}
        resetTable={resetTable}
        handleCheck={setSelectedCheck}
      />
      <MedicalReportDetailDrawer
        opened={reportDetail}
        onClose={() => {
          setReportDetail(null);
        }}
        report={reportDetail}
        resetTable={resetTable}
      />
      <NewMedicalReportDrawer
        child={selectedCheck?.child}
        opened={selectedCheck}
        onClose={(created) => handleCheck(selectedCheck, created)}
        type={"Kontrola"}
      />
    </Page>
  );
};

export default TodayChecksPage;
