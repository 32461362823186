import { useFetch } from "../../helpers/useFetch.js";
import { useLoaderData } from "react-router-dom";
import { MyTeamTable } from "../../components/table/MyTeamTable.jsx";
import useTitle from "../../hooks/useTitle.js";
import Page from "../../components/layout/Page.jsx";
import { Center, Stack, Text, Title, useMantineTheme } from "@mantine/core";
import { useEffect, useState } from "react";

export function loader() {
  return Promise.all([useFetch(`my-team`), useFetch(`accommodation/rooms/points`)]);
}

export default function MyTeamPage() {
  const loaderData = useLoaderData();
  const theme = useMantineTheme();

  if (!loaderData[0].data) {
    return (
      <Center className="h-full">
        <Stack justify="center" align="center" gap="sm">
          <Title className="font-headline text-center" order={1} color={theme.colors[theme.primaryColor][4]}>
            Nemáš přiřazený oddíl
          </Title>
          <Text mb="lg">Nejspíše je ještě hlavní vedoucí nevytvořil.</Text>
        </Stack>
      </Center>
    );
  }

  const children = loaderData[0].data.children;
  const rooms = loaderData[1].data;

  const [records, setRecords] = useState(children);

  useEffect(() => {
    rooms.map((floor) => {
      floor.rooms.map((room) => {
        room.occupation.map(async (childLocal) => {
          let child = children.find((child) => child.id === childLocal.id);
          let recordsLocal = records;
          let latestPoints = room?.points[Object.keys(room?.points)[Object.keys(room?.points).length - 1]];

          if (latestPoints && child) {
            if (latestPoints.points <= 2) {
              await recordsLocal.forEach((child) => {
                if (child.id === childLocal.id) {
                  child.hasToClean = true;
                }
              });
              setRecords(recordsLocal);
            }
          }
        });
      });
    });
  }, []);

  useTitle(`Můj oddíl`);

  return (
    <Page title="Můj oddíl">
      <MyTeamTable childrenData={records} rooms={rooms} />
    </Page>
  );
}
