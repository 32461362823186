import { useState } from "react";
import Page from "../../components/layout/Page";
import { DepotHandoversTable } from "../../components/table/DepotHandoversTable";
import useTitle from "../../hooks/useTitle";
import { openDepotHandoverCreateModal } from "../../helpers/modals";
import { IconFilePlus } from "@tabler/icons-react";

export const DepotHandoversPage = () => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [serverFetching, setServerFetching] = useState(false);
  useTitle("Předávací protokoly");

  const actionButtons = [
    {
      name: "Nový předávací protokol",
      onTrigger: () => openDepotHandoverCreateModal(setShouldFetch),
      icon: IconFilePlus,
      primary: true,
    },
  ];

  return (
    <Page title={"Předávací protokoly"} actionIcons={actionButtons}>
      <DepotHandoversTable
        shouldFetch={shouldFetch}
        setShouldFetch={setShouldFetch}
        serverFetching={serverFetching}
        setServerFetching={setServerFetching}
      />
    </Page>
  );
};
