import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export function useMediaQueryStringOrFunction(mediaQuery) {
  const theme = useMantineTheme();
  const mediaQueryValue = typeof mediaQuery === "function" ? mediaQuery(theme) : mediaQuery;
  return useMediaQuery(mediaQueryValue || "", true);
}

export function getValueAtPath(obj, path) {
  if (!path) return undefined;
  const pathArray = path.match(/([^[.\]])+/g);
  return pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj);
}
