import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import {
  IconBiohazard,
  IconCake,
  IconCalendarEvent,
  IconCloudCheck,
  IconDoorExit,
  IconFile,
  IconNote,
  IconSignature,
  IconX,
} from "@tabler/icons-react";
import Table from "./core/Table.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import MedicalInfoCol from "../MedicalInfoCol";
import { useUser } from "../../providers/UserProvider.jsx";
import DocumentViewer from "../boarding/DocumentViewer.jsx";
import { useEffect, useState } from "react";
import { usePermissions } from "../../providers/PermissionsProvider.jsx";

export default function ChildrenTable({
  fetching,
  showFilters,
  actionButtons,
  team,
  name,
  shouldFetch,
  setShouldFetch,
  teams,
  serverFetching,
  setServerFetching,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const { hasPermission } = usePermissions();
  const [viewerOpened, setViewerOpened] = useState(false);
  const [viewingDocument, setViewingDocument] = useState("");

  const handleDocumentView = (document) => {
    setViewingDocument(document);
    setViewerOpened(true);
  };

  const childName = (child) => {
    return (
      <div>
        <strong className="capitalize">
          {child.firstName}&nbsp;{child.lastName} <span className="mobileAge">({child.age})</span>
        </strong>
        {child.hasBirthday ? (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Narozeniny">
            <Text h={18} span color="yellow.6">
              <IconCake stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        ) : (
          ""
        )}
        {child.hasNameday ? (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Svátek">
            <Text h={18} span color="teal.6">
              <IconCalendarEvent stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        ) : (
          ""
        )}
        {child.permanentNote ? (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Historická poznámka">
            <Text h={18} span color="pink.6">
              <IconNote stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        ) : (
          ""
        )}
        {child.earlyLeaving ? (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Předčasný odjezd">
            <Text h={18} span color="red.6">
              <IconDoorExit stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        ) : (
          ""
        )}
        {child.isIsolated ? (
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="V izolaci">
            <Text h={18} span color="green.6">
              <IconBiohazard stroke={1.5} size={18} />
            </Text>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    );
  };

  const childTeam = (child) => {
    return (
      <Text span color={!child?.team?.number && "red"}>
        {child?.team?.number ? child.team.number : "Nepřidělen"}
      </Text>
    );
  };

  const documentsIcons = (documents) => {
    const documentTypes = ["medicalCertificate", "insuranceCard", "infectiousness"];

    return (
      <Group gap={"sm"}>
        {documentTypes.map((type) => {
          return (
            <Tooltip
              position="top"
              withArrow
              transition="fade"
              transitionDuration={200}
              label={
                documents[type]?.status === "core"
                  ? "Dokument evidován online"
                  : documents[type]?.status === "signed"
                  ? "Dokument podepsán elektronicky"
                  : documents[type]?.status === "paper"
                  ? "Dokument předán papírově"
                  : "Dokument nepředán"
              }
              key={type}>
              <ActionIcon
                color={documents[type] && documents[type]?.status !== "none" ? "teal" : "red"}
                variant="filled"
                onClick={(e) => {
                  e.stopPropagation();
                  if (documents[type]?.filePath) {
                    handleDocumentView(documents[type].filePath);
                  }
                }}
                className="m-0 w-[1%]">
                {documents[type]?.status === "core" && <IconCloudCheck stroke={1.5} size={20} />}
                {(!documents[type] || documents[type]?.status === "none") && <IconX stroke={1.5} size={20} />}
                {documents[type]?.status === "signed" && <IconSignature stroke={1.5} size={20} />}
                {documents[type]?.status === "paper" && <IconFile stroke={1.5} size={20} />}
              </ActionIcon>
            </Tooltip>
          );
        })}
      </Group>
    );
  };

  const columns = [
    {
      accessor: "ucastnik_prijmeni",
      title: "Jméno",
      disableHiding: true,
      render: (child) => childName(child),
      sortable: true,
    },
    {
      accessor: "oddil",
      title: "Oddíl",
      render: (child) => childTeam(child),
      sortable: true,
    },
    {
      accessor: "ucastnik_date_narozeni",
      title: "Věk",
      sortable: true,
      visibleMediaQuery: (theme) => `(min-width: 600px)`,
      render: (x) => x.age,
    },
    {
      accessor: "zdravi",
      title: "Zdraví",
      render: (child) => {
        return <MedicalInfoCol child={child} />;
      },
      visibleMediaQuery: (theme) => `(min-width: 420px)`,
      sortable: true,
      // firstSort: "desc",
    },
    {
      accessor: "infectiousness",
      title: (
        <Group gap={"sm"}>
          <Text span className="min-w-[1.75rem]" align="center">
            LP
          </Text>
          <Text span className="min-w-[1.75rem]" align="center">
            KP
          </Text>
          <Text span className="min-w-[1.75rem]" align="center">
            BI
          </Text>
        </Group>
      ),
      subtitle: "LP, KP, BI",
      visibleMediaQuery: (theme) => `(min-width: 420px)`,
      render: (child) => documentsIcons(child.documents),
      defaultHidden: location.pathname.split("/")[1] !== "zdravotnik",
    },
    {
      accessor: "roomName",
      title: "Pokoj",
      visibleMediaQuery: (theme) => `(min-width: 420px)`,
      render: (x) => x.roomName,
    },
    {
      accessor: "tShirtSize",
      title: "Velikost trika",
      render: (x) => x.tShirtSize,
      defaultHidden: true,
      showInCols: true,
    },
    ...(!hasPermission("children.detail.edit")
      ? []
      : [
          {
            accessor: "actions",
            title: "Akce",
            disableHiding: true,
            hidden: !hasPermission("children.detail.edit"),
            render: (child) => actionButtons(child),
            narrow: true,
          },
        ]),
  ];

  const filterConfig = [
    {
      key: "jmeno",
      label: "Jméno",
      type: "search",
    },
    {
      key: "oddil",
      label: "Oddíl",
      type: "select",
      options: teams?.map((team) => {
        return { value: String(team.id), label: `${team.name} ${team.number}` };
      }),
    },
    /* {
      key: "pohlavi",
      label: "Pohlaví",
      type: "select",
      options: [
        { value: "Chlapec", label: "Chlapec" },
        { value: "Dívka", label: "Dívka" },
      ],
    }, */
  ];

  return (
    <>
      <Table
        id="deti-prehled"
        columns={columns}
        useServer={true}
        serverUrl={"/children"}
        shouldServerFetch={shouldFetch}
        setShouldServerFetch={setShouldFetch}
        onRowClick={(child) => {
          if (hasPermission("children.detail.edit")) {
            navigate("/deti/" + child.id);
          }
        }}
        fetching={fetching}
        filtersParent={{
          oddil: team ? team : "",
        }}
        filterConfig={filterConfig}
        serverFetching={serverFetching}
        setServerFetching={setServerFetching}
      />
      {viewerOpened && <DocumentViewer document={viewingDocument} setOpened={setViewerOpened} />}
    </>
  );
}
