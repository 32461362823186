import Page from "../components/layout/Page.jsx";
import { Paper, Skeleton, Text } from "@mantine/core";
import { useUser } from "../providers/UserProvider.jsx";
import { useFetch } from "../helpers/useFetch.js";
import { Link, useNavigate } from "react-router-dom";
import useTitle from "../hooks/useTitle.js";
import { useEffect, useState } from "react";
import {
  IconBed,
  IconCalendarEvent,
  IconDeviceDesktop,
  IconDoor,
  IconDoorExit,
  IconFileText,
  IconFlag,
  IconMedal2,
  IconMoodKid,
  IconPill,
  IconUsb,
  IconUsersGroup,
} from "@tabler/icons-react";
import { getMode } from "../helpers/helpers.js";
import { dosageOptions } from "../helpers/dosages.js";

function Dashboard() {
  const [myTeam, setMyTeam] = useState();
  const [stats, setStats] = useState();
  const [medicationData, setMedicationData] = useState();
  const navigate = useNavigate();
  const { user } = useUser();
  const [opened, setOpened] = useState(false);
  useTitle("Přehled");

  const { mode, theme } = getMode();

  const blocks = {
    24: [
      {
        title: "Můj oddíl",
        icon: IconFlag,
        link: "/oddily/muj",
      },
      {
        title: "Přehled dětí",
        icon: IconMoodKid,
        link: "/deti/prehled",
      },
      {
        title: "Ubytování",
        icon: IconBed,
        link: "/ubytovani/prehled",
      },
      {
        title: "Bodování pokojů",
        icon: IconMedal2,
        link: "/ubytovani/bodovani",
      },
    ],
    22: [
      {
        title: "Přehled dětí",
        icon: IconMoodKid,
        link: "/deti/prehled",
      },
      {
        title: "Seznam flashek",
        icon: IconUsb,
        link: "/fotograf/flashky",
      },
    ],
    15: [
      {
        title: "Přehled dětí",
        icon: IconMoodKid,
        link: "/deti/prehled",
      },
      {
        title: "Předčasné odjezdy",
        icon: IconDoorExit,
        link: "/deti/odjezdy",
      },
      {
        title: "Seznam oddílů",
        icon: IconFlag,
        link: "/oddily/prehled",
      },
      {
        title: "Přehled dokumentů",
        icon: IconFileText,
        link: "/deti/dokumenty",
      },
    ],
    18: [
      {
        title: "Přehled dětí",
        icon: IconMoodKid,
        link: "/deti/prehled",
      },
      {
        title: "Předčasné odjezdy",
        icon: IconDoorExit,
        link: "/deti/odjezdy",
      },
      {
        title: "Seznam oddílů",
        icon: IconFlag,
        link: "/oddily/prehled",
      },
      {
        title: "Přehled dokumentů",
        icon: IconFileText,
        link: "/deti/dokumenty",
      },
    ],
    21: [
      {
        title: "Dnešní kontroly",
        icon: IconCalendarEvent,
        link: "/zdravotnik/dnesni-kontroly",
      },
      {
        title: "Pravidelné léky",
        icon: IconPill,
        link: "/zdravotnik/pravidelne-leky",
      },
      {
        title: "Přehled dětí",
        icon: IconMoodKid,
        link: "/zdravotnik/prehled",
      },
      {
        title: "Bodování pokojů ",
        icon: IconMedal2,
        link: "/ubytovani/bodovani",
      },
    ],
    25: [
      {
        title: "Můj oddíl",
        icon: IconFlag,
        link: "/oddily/muj",
      },
      {
        title: "Přehled dětí",
        icon: IconMoodKid,
        link: "/deti/prehled",
      },
      {
        title: "Ubytování",
        icon: IconBed,
        link: "/ubytovani/prehled",
      },
      {
        title: "Bodování pokojů",
        icon: IconMedal2,
        link: "/ubytovani/bodovani",
      },
    ],
    23: [
      {
        title: "Přehled počítačů",
        icon: IconDeviceDesktop,
        link: "/pc-program/prehled",
      },
      {
        title: "Správce místností",
        icon: IconDoor,
        link: "/pc-program/mistnosti",
      },
      {
        title: "Přehled dětí",
        icon: IconMoodKid,
        link: "/deti/prehled",
      },
      {
        title: "Bodování pokojů",
        icon: IconMedal2,
        link: "/ubytovani/bodovani",
      },
    ],
    20: [
      {
        title: "Můj oddíl",
        icon: IconFlag,
        link: "/oddily/muj",
      },
      {
        title: "Přehled dětí",
        icon: IconMoodKid,
        link: "/deti/prehled",
      },
      {
        title: "Ubytování",
        icon: IconBed,
        link: "/ubytovani/prehled",
      },
      {
        title: "Bodování pokojů",
        icon: IconMedal2,
        link: "/ubytovani/bodovani",
      },
    ],
  };

  const blocksPosition = blocks[Object.keys(blocks).filter((key) => Number(user.roles[0]) === Number(key))[0]];

  const findDosage = (type) => {
    return dosageOptions.find((dose) => dose.type === type);
  };

  const refactorDosages = (data) => {
    let period;
    const hours = new Date().getHours();
    if (hours < 11) {
      period = "s";
    } else if (hours < 16) {
      period = "o";
    } else if (hours < 25) {
      period = "v";
    }
    const periods = ["s_m", "s_p", "o_m", "o_p", "v_m", "v_p"];
    let medicationsTaken = {
      taking: 0,
      taken: 0,
      children: [],
    };
    data.map((child) => {
      const timeState = [];
      periods.map((time) => {
        const periodState = [];
        let isTaking = false;
        const dosage = findDosage(time);
        child.medicalMedications.map((medication) => {
          if (medication[dosage.type].amount) {
            periodState.push(medication[dosage.type].taked);
            isTaking = true;
          }
        });
        periodState.length < 1 && periodState.push(false);
        const data = { period: time, isTaking, taked: periodState.reduce((a, b) => a && b) };
        timeState.push(data);
        if (!child[time[0]]) child[time[0]] = isTaking;
      });
      const childMedication = timeState.filter((stamp) => stamp.period.includes(period));
      if (childMedication[0].isTaking || childMedication[1].isTaking) medicationsTaken.taking++;
      if (childMedication[0].isTaking && childMedication[0].taked && !childMedication[1].isTaking)
        medicationsTaken.taken++;
      if (childMedication[1].isTaking && childMedication[1].taked && !childMedication[0].isTaking)
        medicationsTaken.taken++;
      if (
        childMedication[0].isTaking &&
        childMedication[0].taked &&
        childMedication[1].isTaking &&
        childMedication[1].taked
      )
        medicationsTaken.taken++;
      child.refactoredMedications = timeState;
    });
    return medicationsTaken;
  };

  const getMyTeam = async () => {
    const res = await useFetch("/my-team");
    if (!res.data) return setMyTeam({ team: null });
    const basic = res.data.number + ". " + res.data.name;
    const childrenCount = res.data.children.length;
    const medicationsTaken = refactorDosages(res.data.children);
    setMyTeam({
      team: true,
      name: basic,
      childrenCount: childrenCount,
      medication: medicationsTaken.taking - medicationsTaken.taken,
      medicationTaken: medicationsTaken.taken,
      medicationTaking: medicationsTaken.taking,
    });
  };

  const getStats = async () => {
    const childrenList = await useFetch("children/list");
    const earlyLeavings = await useFetch("children/early-leavings");
    const staffList = await useFetch("staff");
    const statsLocal = [];
    statsLocal.push({
      id: "left",
      label: "Počet odjetých dětí",
      value: earlyLeavings.data.filter((leaving) => leaving.hasLeft).length,
      link: "/deti/odjezdy",
    });
    statsLocal.push({
      id: "children",
      label: "Počet dětí",
      value: childrenList.data.length,
      link: "/deti/prehled",
    });
    statsLocal.push({
      id: "personal",
      label: "Počet personálu",
      value: staffList.data.length,
      link: "/personal/prehled",
    });
    statsLocal.push({
      id: "eaters",
      label: "Počet strávníků",
      value:
        statsLocal.find((stat) => stat.id === "children").value +
        statsLocal.find((stat) => stat.id === "personal").value,
    });
    setStats(statsLocal);
  };

  const getMedicationData = async () => {
    const children = await useFetch("medical/medications/children-list");
    const childrenMedication = refactorDosages(children.data);
    const checkList = await useFetch("medical/records/check-list");
    setMedicationData({
      medicationsTaken: childrenMedication,
      todayChecks: {
        checked: checkList.data.filter((check) => check.isChecked).length,
        waiting: checkList.data.length,
      },
    });
  };

  useEffect(() => {
    if (user.position === "Oddílový vedoucí") getMyTeam();
    if (user.position === "Zdravotník") getMedicationData();
    if (user.position === "Hlavní vedoucí" || user.position === "Zástupce hlavního vedoucího") getStats();
  }, []);

  return (
    <Page title="Přehled">
      <div className="mb-6 flex gap-4 max-md:flex-col max-md:gap-2">
        {(user.position === "Hlavní vedoucí" || user.position === "Zástupce hlavního vedoucího") && (
          <>
            {stats ? (
              <>
                {stats.map((stat) => {
                  return (
                    <Paper
                      className="w-full px-6 py-4"
                      withBorder
                      radius="md"
                      key={stat.id}
                      to={stat.link && stat.link}
                      component={stat.link && Link}>
                      <Text fw={"bold"} size={"lg"} className="flex w-full items-center justify-between">
                        {stat.label}
                        <Text span className="text-2xl">
                          {stat.value}
                        </Text>
                      </Text>
                    </Paper>
                  );
                })}
              </>
            ) : (
              <>
                {[...Array(4)].map((i) => {
                  return <Skeleton className="w-full" height={"4rem"} key={i} />;
                })}
              </>
            )}
          </>
        )}
        {user.position === "Oddílový vedoucí" && (
          <>
            {myTeam ? (
              <>
                <Paper className="w-full px-6 py-4" withBorder radius="md">
                  <Text fw={"bold"} size={"lg"} className="flex w-full items-center justify-between">
                    Oddíl
                    <Text span className="text-2xl">
                      {myTeam.name}
                    </Text>
                  </Text>
                </Paper>
                <Paper className="w-full px-6 py-4" withBorder radius="md">
                  <Text fw={"bold"} size={"lg"} className="flex w-full items-center justify-between">
                    Počet dětí
                    <Text span className="text-2xl">
                      {myTeam.childrenCount}
                    </Text>
                  </Text>
                </Paper>
                <Paper className="w-full px-6 py-4" withBorder radius="md">
                  <Text fw={"bold"} size={"lg"} className="flex w-full items-center justify-between">
                    Léky
                    <Text
                      span
                      className={
                        "text-2xl " +
                        (myTeam.medicationTaken < myTeam.medicationTaking ? "text-red-50" : "text-teal-200")
                      }>
                      {myTeam.medicationTaken}/{myTeam.medicationTaking}
                    </Text>
                  </Text>
                </Paper>
              </>
            ) : (
              <>
                {[...Array(3)].map((_, index) => {
                  return <Skeleton className="w-full" height={"4rem"} key={index} />;
                })}
              </>
            )}
          </>
        )}
        {user.position === "Zdravotník" && (
          <>
            {medicationData ? (
              <>
                <Paper
                  className="w-full px-6 py-4"
                  withBorder
                  radius="md"
                  component={Link}
                  to={"/zdravotnik/pravidelne-leky"}>
                  <Text fw={"bold"} size={"lg"} className="flex w-full items-center justify-between">
                    Léky
                    <Text
                      span
                      className={
                        "text-2xl " +
                        (medicationData.medicationsTaken.taken < medicationData.medicationsTaken.taking
                          ? "text-red-50"
                          : "text-teal-200")
                      }>
                      {medicationData.medicationsTaken.taken}/{medicationData.medicationsTaken.taking}
                    </Text>
                  </Text>
                </Paper>
                <Paper
                  className="w-full px-6 py-4"
                  withBorder
                  radius="md"
                  component={Link}
                  to={"/zdravotnik/dnesni-kontroly"}>
                  <Text fw={"bold"} size={"lg"} className="flex w-full items-center justify-between">
                    Dnešní kontroly
                    <Text span className="text-2xl">
                      {medicationData.todayChecks.checked}/{medicationData.todayChecks.waiting}
                    </Text>
                  </Text>
                </Paper>
              </>
            ) : (
              <>
                {[...Array(2)].map((i) => {
                  return <Skeleton className="w-full" height={"4rem"} key={i} />;
                })}
              </>
            )}
          </>
        )}
      </div>
      <div className="grid h-full gap-4 max-md:gap-2 md:grid-cols-2">
        {blocksPosition &&
          blocksPosition.map((block) => (
            <Paper
              component={Link}
              to={block.link}
              className="flex flex-col justify-center text-center"
              p="lg"
              withBorder
              radius="md">
              <Text color={theme.colors[theme.primaryColor][5]}>
                <block.icon size={48} stroke={1.5} />
              </Text>
              <h3 className="text-2xl">{block.title}</h3>
            </Paper>
          ))}
      </div>
    </Page>
  );
}

export default Dashboard;
