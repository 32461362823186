import {
  Alert,
  Anchor,
  Box,
  Button,
  Checkbox,
  Group,
  PasswordInput,
  PinInput,
  Popover,
  Progress,
  ScrollArea,
  Select,
  Stack,
  Stepper,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useTitle from "../../hooks/useTitle.js";
import {
  IconAlertCircle,
  IconAt,
  IconBabyCarriage,
  IconCash,
  IconCheck,
  IconEye,
  IconEyeOff,
  IconHealthRecognition,
  IconHome,
  IconId,
  IconLock,
  IconPhone,
  IconUser,
  IconX,
} from "@tabler/icons-react";
import LogoSymbol from "../../components/LogoSymbol.jsx";
import logoText from "../../assets/logo-text.svg";
import { DateInput } from "@mantine/dates";

import "dayjs/locale/cs";
import { useFetch } from "../../helpers/useFetch.js";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import successImage from "../../assets/success.webp";

function PasswordRequirement({ meets, label }) {
  return (
    <Text c={meets ? "teal" : "red"} className="flex items-center" mt={7} size="sm">
      {meets ? <IconCheck stroke={1.5} size="0.9rem" /> : <IconX stroke={1.5} size="0.9rem" />}{" "}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}

function getStrength(password) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const requirements = [
  { re: /[0-9]/, label: "Obsahuje číslo" },
  { re: /[a-z]/, label: "Obsahuje malé písmeno" },
  { re: /[A-Z]/, label: "Obsahuje velké písmeno" },
];

export default function RegistrationPage() {
  const [active, setActive] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [success, setSuccess] = useState(false);
  const [healthInsuranceCompanies, setHealthInsuranceCompanies] = useState([]);

  const navigate = useNavigate();
  useTitle(`Registrace`);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/");
    }

    setLoading(true);
    useFetch(`public/codebook/health-insurance-companies`).then((res) => {
      setHealthInsuranceCompanies(res.data);
    });
    setLoading(false);
  }, []);

  const form = useForm({
    initialValues: {
      email: "",
      pass: "",
      pass_confirmation: "",
      jmeno: "",
      prijmeni: "",
      rc: "",
      narozeni: "",
      narozeni_misto: "",
      cislo_op: "",
      cislo_ucet: "",
      tajny_kod: "",
      DORtelefon: "",
      DORmesto: "",
      DORulice: "",
      DORpsc: "",
      gdpr: false,
      health_insurance_company_id: "",
    },
  });

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(form.values.pass)} />
  ));

  const strength = getStrength(form.values.pass);
  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";

  const nextStep = (values) => {
    if (active === 0) {
      if (values.email && values.pass && values.pass_confirmation) {
        if (values.pass === values.pass_confirmation) {
          if (getStrength(values.pass) === 100) {
            setActive(1);
            setError(null);
          } else {
            setError("Heslo je příliš slabé.");
          }
        } else {
          setError("Hesla se neshodují.");
        }
      } else {
        setError("Vyplň všechna pole.");
      }
    } else if (active === 1) {
      if (values.jmeno && values.prijmeni && values.narozeni) {
        setActive(2);
        setError(null);
      } else {
        setError("Vyplň všechna pole.");
      }
    } else if (active === 2) {
      setActive(3);
      setError(null);
    } else if (active === 3) {
      setLoading(true);
      values.narozeni = dayjs(values.narozeni).format("YYYY-MM-DD");
      useFetch("/register", "POST", values)
        .then((res) => {
          if (res.message) {
            setError(res.message);
          } else if (res.status === "ok") {
            setSuccess(true);
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Bohužel tě nyní nemůžeme zaregistrovat. Zkus to později.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="flex h-screen w-full items-center justify-center">
      {success ? (
        <Stack gap={0} className="mt-4 max-h-screen max-w-[900px] items-center" p="md">
          <Group wrap="nowrap" gap="sm" className="mb-6 flex h-16 px-4" align="center">
            <LogoSymbol height={48} />
            <img src={logoText} height={48} alt="Logo" />
          </Group>
          <div className="mt-8 flex flex-wrap items-center gap-12 md:flex-nowrap">
            <img className="rounded-lg" src={successImage} alt="Úspěch" />
            <div>
              <h1>Registrace úspěšná!</h1>
              <p className="mt-4">
                Gratulujeme a vítej mezi námi. Nyní ti na e-mail přišel odkaz, kterým potvrdíš registraci a správnost
                tvého e-mailu. Po kliknutí na odkaz se již budeš moct do Panelu bez problému přihlásit.
              </p>
              <p className="mt-2">
                Dej prosím poslání chvilku. Kdyby ti náhodou e-mail nepřišel, zkontroluj svoji složku se SPAMem. Kdybys
                ho ani tak nenašel/la, napiš do #support na Discordu.
              </p>
              {form.values.email.includes("@gmail.com") && (
                <Button
                  component="a"
                  href={`https://mail.google.com/mail/u/?authuser=${form.values.email}`}
                  target="_blank"
                  mt="xl">
                  Přejít na Gmail
                </Button>
              )}
            </div>
          </div>
        </Stack>
      ) : (
        <ScrollArea
          viewportProps={{
            style: {
              display: "flex",
              alignItems: "center",
            },
          }}
          className="h-full">
          <Stack gap={0} className="mt-4 max-h-screen max-w-[600px] items-center" p="md">
            <Group wrap="nowrap" gap="sm" className="mb-6 flex h-16 px-4" align="center">
              <LogoSymbol height={48} />
              <img src={logoText} height={48} alt="Logo" />
            </Group>
            {error && (
              <Alert
                icon={<IconAlertCircle size={16} stroke={1.5} />}
                title="Ve formuláři nastala chyba."
                color="red"
                mb="lg"
                className="w-full"
                withCloseButton
                closeButtonLabel="Zavřít upozornění"
                onClose={() => setError(false)}>
                {error}
              </Alert>
            )}
            <form className="w-full" onSubmit={form.onSubmit((values) => nextStep(values))}>
              <Stepper
                active={active}
                onStepClick={setActive}
                completedleftSection={<IconCheck stroke={1.5} size={24} />}>
                <Stepper.Step allowStepSelect={active > 0}>
                  <p className="mt-2 text-center">
                    Vítej v registaci do Panelu! Prvně potřebujeme e-mail a heslo, které budeš používat při
                    přihlašování.
                  </p>
                  <TextInput
                    required
                    //disabled={loading}
                    type="email"
                    leftSection={<IconAt stroke={1.5} size={16} />}
                    placeholder="test@gmail.com"
                    label="E-mail"
                    mb="xs"
                    mt="lg"
                    {...form.getInputProps("email")}
                  />
                  <Popover position="top" opened={popoverOpened} width="target" transitionProps={{ transition: "pop" }}>
                    <Popover.Target>
                      <div onFocusCapture={() => setPopoverOpened(true)} onBlurCapture={() => setPopoverOpened(false)}>
                        <PasswordInput
                          required
                          leftSection={<IconLock stroke={1.5} size={16} />}
                          //disabled={loading}
                          placeholder="TajneHeslo"
                          label="Heslo"
                          mb="xs"
                          visibilityToggleleftSection={({ reveal, size }) =>
                            reveal ? <IconEyeOff stroke={1.5} size={size} /> : <IconEye stroke={1.5} size={size} />
                          }
                          {...form.getInputProps("pass")}
                        />
                      </div>
                    </Popover.Target>
                    <Popover.Dropdown p="lg">
                      <Progress color={color} value={strength} size={5} mb="md" />
                      <PasswordRequirement label="Obsahuje 8 znaků" meets={form.values.pass.length > 7} />
                      {checks}
                      <Text size="sm" mt="sm" color="dimmed">
                        Musíš splnit všechny požadavky.
                      </Text>
                    </Popover.Dropdown>
                  </Popover>
                  <PasswordInput
                    required
                    leftSection={<IconLock stroke={1.5} size={16} />}
                    //disabled={loading}
                    placeholder="TajneHeslo"
                    label="Zopakování hesla"
                    mb="lg"
                    visibilityToggleleftSection={({ reveal, size }) =>
                      reveal ? <IconEyeOff stroke={1.5} size={size} /> : <IconEye stroke={1.5} size={size} />
                    }
                    {...form.getInputProps("pass_confirmation")}
                  />
                  <Checkbox
                    label={
                      <>
                        Souhlasím se zpracováním{" "}
                        <Anchor href="gdpr.pdf" target="_blank">
                          osobních údajů
                        </Anchor>
                        .
                      </>
                    }
                    mb="lg"
                    required
                    {...form.getInputProps("gdpr")}
                  />
                  <Button /* loading={loading} */ type="submit" variant="filled" fullWidth>
                    Pokračovat
                  </Button>
                </Stepper.Step>
                <Stepper.Step allowStepSelect={active > 1}>
                  <p className="mt-2 text-center">
                    Super! Tvé přihlašovací údaje máme! Nyní potřebujeme vědět, jak ti máme říkat a kdy ses narodil.
                  </p>
                  <TextInput
                    required
                    //disabled={loading}
                    type="text"
                    leftSection={<IconUser stroke={1.5} size={16} />}
                    placeholder="Alfonz"
                    label="Křestní jméno"
                    mb="xs"
                    mt="lg"
                    {...form.getInputProps("jmeno")}
                  />
                  <TextInput
                    required
                    //disabled={loading}
                    type="text"
                    leftSection={<IconUser stroke={1.5} size={16} />}
                    placeholder="Novák"
                    mb="sm"
                    label="Přijmení"
                    {...form.getInputProps("prijmeni")}
                  />
                  <DateInput
                    required
                    //disabled={loading}
                    leftSection={<IconBabyCarriage stroke={1.5} size={16} />}
                    placeholder="15. 1. 2002"
                    valueFormat="D. M. YYYY"
                    label="Datum narození"
                    mb="lg"
                    locale="cs"
                    {...form.getInputProps("narozeni")}
                  />
                  <Button type="submit" /* loading={loading} */ variant="filled" fullWidth>
                    Pokračovat
                  </Button>
                </Stepper.Step>
                <Stepper.Step allowStepSelect={active > 2}>
                  <p className="mt-2 text-center">
                    V předposledním kroku potřebujeme vědět tvé osobní údaje, abychom ti mohli vygenerovat smlouvu.
                    Tento krok můžeš přeskočit, ale mysli na to, že bez nich není možné smlouvu vygenerovat.
                  </p>
                  <div className="mb-0 mt-5 gap-4 md:columns-2 [&>*]:grow [&>*]:basis-0">
                    <TextInput
                      required
                      //disabled={loading}
                      type="text"
                      leftSection={<IconBabyCarriage stroke={1.5} size={16} />}
                      placeholder="020115/2945"
                      label="Rodné číslo"
                      mb="sm"
                      {...form.getInputProps("rc")}
                    />
                    <TextInput
                      required
                      //disabled={loading}
                      type="text"
                      leftSection={<IconBabyCarriage stroke={1.5} size={16} />}
                      placeholder="Brno"
                      label="Místo narození"
                      mb="sm"
                      {...form.getInputProps("narozeni_misto")}
                    />
                  </div>
                  <div className="mb-0 gap-4 md:columns-2 [&>*]:grow [&>*]:basis-0">
                    <TextInput
                      required
                      //disabled={loading}
                      type="text"
                      leftSection={<IconId stroke={1.5} size={16} />}
                      placeholder="92945612"
                      label="Číslo občanského průkazu"
                      mb="sm"
                      {...form.getInputProps("cislo_op")}
                    />
                    <TextInput
                      required
                      //disabled={loading}
                      type="text"
                      leftSection={<IconCash stroke={1.5} size={16} />}
                      placeholder="12563578/2010"
                      label="Číslo účtu"
                      mb="sm"
                      {...form.getInputProps("cislo_ucet")}
                    />
                  </div>
                  <TextInput
                    required
                    //disabled={loading}
                    type="text"
                    leftSection={<IconPhone stroke={1.5} size={16} />}
                    placeholder="+420 605 744 451"
                    label="Telefonní číslo"
                    mb="sm"
                    {...form.getInputProps("DORtelefon")}
                  />
                  <TextInput
                    required
                    //disabled={loading}
                    type="text"
                    leftSection={<IconHome stroke={1.5} size={16} />}
                    placeholder="Elišky Krásnohorské 2069"
                    label="Ulice a číslo popisné"
                    mb="sm"
                    {...form.getInputProps("DORulice")}
                  />
                  <div className="mb-5 gap-4 md:columns-2 [&>*]:grow [&>*]:basis-0">
                    <TextInput
                      required
                      //disabled={loading}
                      type="text"
                      leftSection={<IconHome stroke={1.5} size={16} />}
                      placeholder="Dvůr Králové nad Labem"
                      label="Město"
                      mb="sm"
                      {...form.getInputProps("DORmesto")}
                    />
                    <TextInput
                      required
                      //disabled={loading}
                      type="text"
                      leftSection={<IconHome stroke={1.5} size={16} />}
                      placeholder="54401"
                      mb="sm"
                      label="PSČ"
                      {...form.getInputProps("DORpsc")}
                    />
                  </div>
                  <Select
                    required
                    //disabled={loading}
                    type="text"
                    leftSection={<IconHealthRecognition stroke={1.5} size={16} />}
                    label="Zdravotní pojišťovna"
                    mb="sm"
                    {...form.getInputProps("health_insurance_company_id")}
                    data={healthInsuranceCompanies.map((item) => ({
                      value: item.id.toString(),
                      label: item.code + " " + item.name,
                    }))}
                  />
                  <Button type="submit" /* loading={loading} */ variant="filled" fullWidth>
                    Pokračovat
                  </Button>
                </Stepper.Step>
                <Stepper.Step allowStepSelect={false}>
                  <p className="mt-2 text-center">
                    Jako finální krok zadej heslo, které jsi dostal od hlavního vedoucího. Toto heslo je pro každý areál
                    jiné, tak si prosím zkontroluj, že používáš to správné.
                  </p>
                  <div className="flex w-full justify-center">
                    <PinInput
                      disabled={loading}
                      length={6}
                      size="xl"
                      mb="lg"
                      mt="lg"
                      fw="bold"
                      {...form.getInputProps("tajny_kod")}
                    />
                  </div>
                  <Button loading={loading} type="submit" /* loading={loading} */ variant="filled" fullWidth>
                    Dokončit registraci
                  </Button>
                </Stepper.Step>
              </Stepper>
            </form>
            <Button mt="xs" fullWidth variant="subtle" component={Link} to="/prihlaseni">
              Již mám účet
            </Button>
          </Stack>
        </ScrollArea>
      )}
    </div>
  );
}
