import { useState } from "react";
import Drawer from "../../drawer/Drawer";
import Table from "../../table/core/Table";
import { SegmentedControl, Text } from "@mantine/core";
import { modalTitle } from "../../modals/Modal";
import { IconMedal2, IconMoodNervous, IconTrash } from "@tabler/icons-react";
import { parseFullName, parsePointsColor } from "../../../helpers/helpers";
import PointsSummary from "./PointsSummary";
import { sortPoints } from "../../table/sort";

export default function PointsSinnersDrawer({ isOpen, onClose, groups }) {
  let rooms = [];
  const [type, setType] = useState("rooms");

  groups.forEach((group) => {
    group.rooms.forEach((room) => {
      rooms.push(room);
    });
  });

  const columnsChildren = [
    {
      accessor: "name",
      title: "Jméno",
      sortable: true,
      render: (child) => parseFullName(child),
    },
    {
      accessor: "team",
      title: "Oddíl",
      sortable: true,
      render: (child) => child.team,
    },
    {
      accessor: "room",
      title: "Pokoj",
      render: (child) => child.room,
    },
    {
      accessor: "count",
      title: "Počet",
      sortable: true,
      render: (child) => (
        <Text span color={parsePointsColor(child.latestPoints)}>
          {child.latestPoints} bodů
        </Text>
      ),
    },
  ];

  let childrenData = [];

  rooms.forEach((room) => {
    room.occupation.forEach((child) => {
      let latestPoints = room?.points[Object.keys(room?.points)[Object.keys(room?.points).length - 1]];

      if (child?.id && latestPoints && latestPoints.points <= 2) {
        childrenData.push({
          ...child,
          room: room.name,
          latestPoints: latestPoints.points,
        });

        childrenData = childrenData.sort((a, b) => {
          return a.latestPoints - b.latestPoints;
        });
      }
    });
  });

  rooms = rooms.filter((room) => room.occupationCount > 0);

  return (
    <Drawer
      title={modalTitle(IconMoodNervous, "Seznam hříšníků")}
      smallTitle={"Počty bodů se odvíjí od posledního hodnocení."}
      opened={isOpen}
      onClose={onClose}>
      {groups && (
        <Table id="pokoje-hrisnici" columns={columnsChildren} sortFunction={sortPoints} records={childrenData} />
      )}
    </Drawer>
  );
}
