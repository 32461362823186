import { Group, Paper, Text, Tooltip, ActionIcon, Progress } from "@mantine/core";
import {
  IconArrowBack,
  IconEditCircle,
  IconEditCircleOff,
  IconLoader2,
  IconLock,
  IconPlugConnectedX,
  IconPower,
  IconRadar,
  IconRotate,
  IconScreenShare,
  IconShield,
  IconShieldOff,
  IconTrash,
} from "@tabler/icons-react";
import React from "react";
import { useSocketFetch } from "../../helpers/useSocketFetch";
import { getMode } from "../../helpers/helpers";
import { openFormStartModal } from "../../helpers/modals";
import { openConfirmModal } from "@mantine/modals";
import { modalTitle } from "../modals/Modal";

export default function ComputerCard({ computer, socketComputer, fetchSockets }) {
  const shutdown = (id) => {
    useSocketFetch("/shutdown/start/60/&area/" + id, "POST").then((data) => {
      setTimeout(() => {
        fetchSockets();
      }, 100);
    });
  };

  const abortShutdown = (id) => {
    useSocketFetch("/shutdown/abort/&area/" + id, "POST").then((data) => {
      setTimeout(() => {
        fetchSockets();
      }, 100);
    });
  };

  const reboot = (id) => {
    useSocketFetch("/reboot/start/60/&area/" + id, "POST").then((data) => {
      setTimeout(() => {
        fetchSockets();
      }, 100);
    });
  };
  const abortReboot = (id) => {
    useSocketFetch("/reboot/abort/&area/" + id, "POST").then((data) => {
      setTimeout(() => {
        fetchSockets();
      }, 100);
    });
  };

  const block = (id) => {
    useSocketFetch("/block/&area/" + id, "POST").then((data) => {
      setTimeout(() => {
        fetchSockets();
      }, 100);
    });
  };

  const unblock = (id) => {
    useSocketFetch("/unblock/&area/" + id, "POST").then((data) => {
      setTimeout(() => {
        fetchSockets();
      }, 100);
    });
  };

  const ping = (id) => {
    useSocketFetch("/ping/&area/" + id, "POST").then((data) => {
      setTimeout(() => {
        fetchSockets();
      }, 100);
    });
  };

  const initForm = (computer) => {
    openFormStartModal(computer);
  };

  const startForm = (id, child) => {
    useSocketFetch("/forms/start/&area/" + id, "POST", {
      childID: String(child),
    }).then((data) => {
      setTimeout(() => {
        fetchSockets();
      }, 100);
    });
  };

  const cancelForm = (id) => {
    useSocketFetch("/forms/cancel/&area/" + id, "POST").then((data) => {
      setTimeout(() => {
        fetchSockets();
      }, 100);
    });
  };

  const forceDisconnect = (id) => {
    openConfirmModal({
      title: modalTitle(IconPlugConnectedX, "Natvdo odpojit socket"),
      children: (
        <Text size="sm">
          Opravdu chceš odpojit tento počítač ze socket serveru? Toto ti může pomoct, pokud se stav počítače z nějakého
          důvodu zasekl. Po potvrzení musíš restartovat aplikaci.
        </Text>
      ),
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
      onConfirm: () => {
        useSocketFetch("/disconnect/&area/" + id, "POST").then((data) => {
          setTimeout(() => {
            fetchSockets();
          }, 100);
        });
      },
    });
  };

  const { mode, theme } = getMode();

  return (
    <Paper className="select-none overflow-hidden" withBorder radius="md">
      <Progress
        color={
          socketComputer?.status === "ready"
            ? "teal"
            : socketComputer?.status === "shuttingDown" || socketComputer?.status === "rebooting"
            ? "yellow"
            : socketComputer?.status === "blocked"
            ? "red"
            : socketComputer?.status === "pinging"
            ? "pink"
            : socketComputer?.status === "formsInit" || socketComputer?.status === "formsStart"
            ? "indigo"
            : mode === "dark"
            ? "dark.6"
            : "gray.2"
        }
        animated={
          socketComputer?.status === "shuttingDown" ||
          socketComputer?.status === "rebooting" ||
          socketComputer?.status === "pinging" ||
          socketComputer?.status === "formsInit"
        }
        radius={0}
        value={100}
        className="w-full"
      />
      <div className="px-4 py-3 pb-4">
        <p className="text-xl font-bold">{computer.hostname}</p>
        {socketComputer?.status === "ready" && (
          <Group justify="space-between">
            <Text size="sm" c="dimmed">
              Zapnuto
            </Text>
            <Text size="sm" c="dimmed">v{socketComputer.appVersion}</Text>
          </Group>
        )}
        {socketComputer?.status === "shuttingDown" && (
          <Group justify="space-between">
            <Text size="sm" c="dimmed">
              Vypíná se (prodleva 60s)
            </Text>
            <Text size="sm" c="dimmed">v{socketComputer.appVersion}</Text>
          </Group>
        )}
        {socketComputer?.status === "rebooting" && (
          <Group justify="space-between">
            <Text size="sm" c="dimmed">
              Restartuje se (prodleva 60s)
            </Text>
            <Text size="sm" c="dimmed">v{socketComputer.appVersion}</Text>
          </Group>
        )}
        {socketComputer?.status === "blocked" && (
          <Group justify="space-between">
            <Text size="sm" c="dimmed">
              Zablokován
            </Text>
            <Text size="sm" c="dimmed">v{socketComputer.appVersion}</Text>
          </Group>
        )}
        {socketComputer?.status === "pinging" && (
          <Group justify="space-between">
            <Text size="sm" c="dimmed">
              Pinguji...
            </Text>
            <Text size="sm" c="dimmed">v{socketComputer.appVersion}</Text>
          </Group>
        )}
        {socketComputer?.status === "formsInit" && (
          <Group justify="space-between">
            <Text size="sm" c="dimmed">
              Dotazník čeká na uvolnění
            </Text>
            <Text size="sm" c="dimmed">v{socketComputer.appVersion}</Text>
          </Group>
        )}
        {socketComputer?.status === "formsStart" && (
          <Group justify="space-between">
            <Text size="sm" c="dimmed">
              Vyplňuje dotazník
            </Text>
            <Text size="sm" c="dimmed">v{socketComputer.appVersion}</Text>
          </Group>
        )}
        {!socketComputer?.status && (
          <Text size="sm" c="dimmed">
            Vypnuto
          </Text>
        )}

        <div className="mt-3 flex w-full justify-between gap-2">
          <Group gap="sm">
            {socketComputer?.status === "ready" && (
              <>
                <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Vypnout počítač">
                  <ActionIcon
                    variant="light"
                    color="gray"
                    size="lg"
                    onClick={(e) => {
                      e.stopPropagation();
                      shutdown(socketComputer.socketID);
                    }}>
                    <IconPower size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
                <Tooltip
                  position="top"
                  withArrow
                  transition="fade"
                  transitionDuration={200}
                  label="Restartovat počítač">
                  <ActionIcon
                    variant="light"
                    color="gray"
                    size="lg"
                    onClick={(e) => {
                      e.stopPropagation();
                      reboot(socketComputer.socketID);
                    }}>
                    <IconRotate size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
                <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Zablokovat počítač">
                  <ActionIcon
                    variant="light"
                    color="gray"
                    size="lg"
                    onClick={(e) => {
                      e.stopPropagation();
                      block(socketComputer.socketID);
                    }}>
                    <IconShield size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
                <Tooltip
                  position="top"
                  withArrow
                  transition="fade"
                  transitionDuration={200}
                  label="Ping počítače"
                  onClick={() => ping(socketComputer.socketID)}>
                  <ActionIcon variant="light" size="lg" color="gray">
                    <IconRadar size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
                <Tooltip
                  position="top"
                  withArrow
                  transition="fade"
                  transitionDuration={200}
                  label="Spustit dotazník"
                  onClick={() => initForm(socketComputer)}>
                  <ActionIcon variant="light" size="lg" color="gray">
                    <IconEditCircle size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </>
            )}
            {!socketComputer?.status && (
              <>
                <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Vypnout počítač">
                  <ActionIcon variant="light" size="lg" disabled>
                    <IconPower size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
                <Tooltip
                  position="top"
                  withArrow
                  transition="fade"
                  transitionDuration={200}
                  label="Restartovat počítač">
                  <ActionIcon variant="light" color="red" size="lg" disabled>
                    <IconRotate size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
                <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Zablokovat počítač">
                  <ActionIcon variant="light" size="lg" disabled>
                    <IconShield size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
                <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Ping počítače">
                  <ActionIcon variant="light" size="lg" disabled>
                    <IconRadar size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
                <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Spustit dotazník">
                  <ActionIcon variant="light" size="lg" disabled>
                    <IconEditCircle size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </>
            )}
            {socketComputer?.status === "shuttingDown" && (
              <>
                <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Zrušit vypnutí">
                  <ActionIcon
                    variant="filled"
                    color="yellow"
                    size="lg"
                    onClick={(e) => {
                      e.stopPropagation();
                      abortShutdown(socketComputer.socketID);
                    }}>
                    <IconArrowBack size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </>
            )}
            {socketComputer?.status === "rebooting" && (
              <>
                <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Zrušit restart">
                  <ActionIcon
                    variant="filled"
                    color="yellow"
                    size="lg"
                    onClick={(e) => {
                      e.stopPropagation();
                      abortReboot(socketComputer.socketID);
                    }}>
                    <IconArrowBack size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </>
            )}
            {socketComputer?.status === "blocked" && (
              <>
                <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Odblokovat počítač">
                  <ActionIcon
                    variant="filled"
                    size="lg"
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      unblock(socketComputer.socketID);
                    }}>
                    <IconShieldOff size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </>
            )}
            {socketComputer?.status === "pinging" && (
              <>
                <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Ping počítače">
                  <ActionIcon variant="filled" color="pink" size="lg">
                    <IconRadar size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </>
            )}
            {socketComputer?.status === "formsInit" && (
              <>
                <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Uvolnit dotazník">
                  <ActionIcon
                    variant="filled"
                    size="lg"
                    color="indigo"
                    onClick={() => startForm(socketComputer.socketID, socketComputer.childInfo.ticketID.childID)}>
                    <IconEditCircle size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
                <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Zrušit dotazník">
                  <ActionIcon
                    variant="light"
                    size="lg"
                    color="indigo"
                    onClick={() => cancelForm(socketComputer.socketID)}>
                    <IconEditCircleOff size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </>
            )}
            {socketComputer?.status === "formsStart" && (
              <>
                <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Zrušit dotazník">
                  <ActionIcon
                    variant="filled"
                    size="lg"
                    color="indigo"
                    onClick={() => cancelForm(socketComputer.socketID)}>
                    <IconEditCircleOff size={24} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </>
            )}
            {/* <Tooltip
            position="top"
            withArrow
            transition="fade"
            transitionDuration={200}

            label="Zobrazit obrazovku"
          >
            <ActionIcon
              variant="light"
              size="lg"
              disabled
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconScreenShare size={24} stroke={1.5} />
            </ActionIcon>
          </Tooltip> */}
          </Group>
          <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Natvrdo odpojit">
            <ActionIcon
              variant="light"
              size="lg"
              color="red"
              onClick={() => forceDisconnect(socketComputer.socketID)}
              disabled={!socketComputer?.status}>
              <IconPlugConnectedX size={24} stroke={1.5} />
            </ActionIcon>
          </Tooltip>
        </div>
      </div>
    </Paper>
  );
}
