import useTitle from "../../hooks/useTitle";
import { useFetch } from "../../helpers/useFetch";
import { useLoaderData } from "react-router-dom";
import Page from "../../components/layout/Page";
import MedicalDocumentsTable from "../../components/table/MedicalDocumentsTable";

export const loader = async () => {
  return await useFetch("medical/documents");
};

const MedicalDocumentsPage = () => {
  const data = useLoaderData().data;
  useTitle("Přehled dokumentů");

  return (
    <Page title={"Přehled dokumentů"}>
      <MedicalDocumentsTable data={data} />
    </Page>
  );
};

export default MedicalDocumentsPage;
