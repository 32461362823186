export async function useFetch(endpoint, method = "GET", data, useJson = true) {
  const userItem = localStorage.getItem("user");
  const debug = localStorage.getItem("debug");

  let start;
  let end;

  if (debug) {
    start = new Date();
  }

  const user = JSON.parse(userItem);

  const optionsDefault = {
    method,
    headers: {
      Authorization: user?.token ? "Bearer " + user.token : "",
    },
    body: data ? (useJson ? JSON.stringify(data) : data) : undefined,
  };

  if (useJson) {
    optionsDefault.headers["Content-Type"] = "application/json";
    optionsDefault.headers["Accept"] = "application/json";
  }

  let res = await fetch(new URL(endpoint, import.meta.env.VITE_API_URL), optionsDefault);

  if (debug) {
    end = new Date();
    const time = end - start;
    const history = JSON.parse(localStorage.getItem("requests-history"));

    if (history) {
      if (history.length > 10) {
        history.shift();
      }
      localStorage.setItem("requests-history", JSON.stringify([...history, { endpoint, time, method }]));
    }
  }

  if (res.status === 403) {
    return (window.location.href = "/neautorizovano");
  }
  if (res.status === 503 && window.location.href !== "/probiha-udrzba") {
    return (window.location.href = "/probiha-udrzba");
  }
  return res.json();

  // let promise = await new Promise((resolve, reject) => {
  //     fetch(new URL(endpoint, 'https://api.4camps.ga'), optionsDefault)
  //         .then((res) => res.json())
  //         .then((data) => {
  //             resolve(data);
  //         })
  //         .catch((error) => {
  //             reject(error);
  //         });
  // });
  //
  // return promise;
  //

  // https://usehooks-ts.com/react-hook/use-fetch
  // const cache = useRef({})
  //
  // // Used to prevent state update if the component is unmounted
  //
  // const cancelRequest = useRef(false)
  //
  // const initialState = {
  //     error: undefined,
  //     data: undefined,
  // }
  //
  // const fetchReducer = (state, action) => {
  //     switch (action.type) {
  //         case 'loading':
  //             return { ...initialState }
  //         case 'fetched':
  //             return { ...initialState, data: action.payload }
  //         case 'error':
  //             return { ...initialState, error: action.payload }
  //         default:
  //             return state
  //     }
  // }
  //
  // const [state, dispatch] = useReducer(fetchReducer, initialState)
  //
  // const optionsDefault = {
  //     method,
  //     headers: {
  //         ContentType: "application/json",
  //         Authorization: "Bearer " + user.token,
  //     },
  //     body: data ? JSON.stringify(data) : undefined,
  // };
  //
  //
  // useEffect(() => {
  //     // Do nothing if the url is not given
  //     if (!url) return
  //     cancelRequest.current = false
  //
  //     const fetchData = async () => {
  //         dispatch({ type: 'loading' })
  //         // If a cache exists for this url, return it
  //         if (cache.current[url]) {
  //             dispatch({ type: 'fetched', payload: cache.current[url] })
  //             return
  //         }
  //         try {
  //             const response = await fetch(new URL(endpoint, 'https://api.4camps.ga'), optionsDefault)
  //             if (!response.ok) {
  //                 throw new Error(response.statusText)
  //             }
  //
  //             const data = (await response.json())
  //             cache.current[url] = data
  //             if (cancelRequest.current) return
  //
  //             dispatch({ type: 'fetched', payload: data })
  //         } catch (error) {
  //             if (cancelRequest.current) return
  //             dispatch({ type: 'error', payload: error })
  //         }
  //     }
  //
  //     void fetchData()
  //
  //
  //     // Use the cleanup function for avoiding a possibly...
  //
  //     // ...state update after the component was unmounted
  //
  //     return () => {
  //         cancelRequest.current = true
  //     }
  //
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //
  // }, [url])
  //
  // return state
}
