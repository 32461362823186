import useTitle from "../../hooks/useTitle";
import { IconDownload, IconEdit, IconPill } from "@tabler/icons-react";
import { useLoaderData } from "react-router-dom";
import Page from "../../components/layout/Page";
import MedicationsTable from "../../components/table/MedicationsTable";
import { openMedicationEditModal, openNewMedicationModal } from "../../helpers/modals";
import { useFetch } from "../../helpers/useFetch";

export const loader = ({ request }) => {
  const date = new URLSearchParams(window.location.search).get("date");
  return useFetch(`medical/medications/children-list${date ? "?date=" + date : ""}`);
};

const MedicationsPage = () => {
  const medications = useLoaderData().data;
  useTitle("Pravidelné léky");
  const userItem = localStorage.getItem("user");
  const user = JSON.parse(userItem);

  const actionButtons = [
    {
      name: "Upravit nebo smazat lék",
      onTrigger: (e) => {
        e.stopPropagation();
        openMedicationEditModal("Úprava nebo smazání léku", medications);
      },
      icon: IconEdit,
    },
    {
      name: "Stáhnout evidenci vydaných léků",
      onTrigger: (e) => {
        e.stopPropagation();
        const url = import.meta.env.VITE_API_URL + "/export/pdf/medications-children-list";
        const downloadLink = document.createElement("a");
        downloadLink.target = "_blank";
        downloadLink.href = new URL(url + "?_token=" + user.token, import.meta.env.VITE_API_URL).href;
        downloadLink.click();
      },
      icon: IconDownload,
    },
    {
      name: "Přidat nový lék",
      onTrigger: (e) => {
        e.stopPropagation();
        openNewMedicationModal("Přidat pravidelný lék");
      },
      icon: IconPill,
      primary: true,
    },
  ];

  return (
    <Page title={"Pravidelné léky"} actionIcons={actionButtons}>
      <MedicationsTable medications={medications} />
    </Page>
  );
};

export default MedicationsPage;
