import { Button, Group, Paper, Text, Transition, useMantineTheme } from "@mantine/core";

const SaveChangesBar = ({ show, text, onConfirm, onCancel, loading }) => {
  const theme = useMantineTheme();

  return (
    <Transition mounted={show} transition="slide-up" duration={200}>
      {(styles) => (
        <Paper
          className={"sticky bottom-8 z-50 flex w-full items-center justify-between text-white-900 shadow-md"}
          style={styles}
          py={"md"}
          px={"lg"}
          radius="lg"
          bg={theme.colors[theme.primaryColor][6]}
        >
          <Text size={"md"} ml="xs">
            {text}
          </Text>
          <Group>
            <Button type="reset" variant="subtle" onClick={onCancel} loading={loading}>
              Zrušit změny
            </Button>
            <Button
              type="submit"
              variant="filled"
              color={theme.colors[theme.primaryColor][5]}
              className="w-fit md:w-[200px]"
              onClick={onConfirm}
              loading={loading}
            >
              Uložit změny
            </Button>
          </Group>
        </Paper>
      )}
    </Transition>
  );
};

export default SaveChangesBar;
