import { Button, Paper, Stack, Text } from "@mantine/core";
import { IconBackspace } from "@tabler/icons-react";
import { useEffect, useState } from "react";

export const CheckoutCalculator = ({ overallPrice }) => {
  const [inserted, setInserted] = useState(0);

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === "Backspace") {
        setInserted((prev) => Math.floor(prev / 10));
        return;
      }
      if (e.key.toLowerCase() === "c") {
        setInserted(0);
        return;
      }
      if (isNaN(e.key)) return;
      setInserted((prev) => prev * 10 + parseInt(e.key));
    };

    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <Stack gap={"md"} mt={"lg"} className="mx-auto w-full max-w-[400px]">
      <div className="mt-8 grid w-full grid-cols-4 grid-rows-5 gap-2">
        <Paper className="col-span-4 flex h-16 items-center" px={"lg"} py={"sm"}>
          <Text className="text-2xl">Dáno: {inserted}Kč</Text>
        </Paper>
        <div className="col-span-3 row-span-3 grid grid-cols-3 grid-rows-3 gap-2">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
            <Button
              key={number}
              onClick={() => {
                setInserted((prev) => prev * 10 + number);
              }}
              className="flex items-center justify-center text-3xl"
              size="lg"
              fullWidth
              h={"100%"}
              variant="light">
              {number}
            </Button>
          ))}
        </div>
        <Button
          onClick={() => {
            setInserted((prev) => Math.floor(prev / 10));
          }}
          className="row-span-2 flex items-center justify-center"
          size="lg"
          fullWidth
          h={"100%"}
          variant="light">
          <IconBackspace size={36} stroke={1.5} />
        </Button>
        <Button
          onClick={() => {
            setInserted(0);
          }}
          className="row-span-2 flex items-center justify-center text-3xl"
          size="lg"
          fullWidth
          h={"100%"}
          variant="light"
          color="red">
          C
        </Button>
        <Button
          onClick={() => {
            setInserted((prev) => prev * 10 + 0);
          }}
          className="col-span-3 flex items-center justify-center text-3xl"
          size="lg"
          fullWidth
          h={"100%"}
          variant="light">
          0
        </Button>
      </div>
      <div>
        <Text size="lg">K zaplacení: {overallPrice - inserted >= 0 ? overallPrice - inserted : 0}Kč</Text>
        <Text size="lg">K vrácení: {inserted - overallPrice >= 0 ? inserted - overallPrice : 0}Kč</Text>
      </div>
    </Stack>
  );
};
