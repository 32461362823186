import { Alert, Button, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch";

const PcCopyQuestsModal = ({ context, id, innerProps }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const setShouldFetch = innerProps.setShouldFetch;

  useEffect(() => {
    if (!innerProps.setShouldFetch) {
      context.closeModal(id);
    }
  }, [innerProps]);

  const handleSubmit = async () => {
    setLoading(true);

    const res = await useFetch(`/pc/quests/import-quests-from-last-session`, "POST");

    if (res.status === "ok") {
      context.closeModal(id);
      if (setShouldFetch) setShouldFetch(true);
      toast.success("Úkoly zkopírovány");
    } else {
      setLoading(false);
      setError(res.message);
    }
  };

  return (
    <div>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při kopírování úkolů nastala chyba."
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      <Text size="lg" mb="lg">
        Opravdu chceš zkopírovat úkoly z minulého turnusu?
      </Text>
      <div className="flex w-full gap-5">
        <Button variant="light" fullWidth onClick={() => context.closeModal(id)}>
          Zrušit
        </Button>
        <Button fullWidth onClick={handleSubmit} loading={loading}>
          Importovat
        </Button>
      </div>
    </div>
  );
};

export default PcCopyQuestsModal;
