import { forwardRef, useEffect, useState } from "react";
import { Alert, Button, Group, Loader, Select, Text, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useRevalidator } from "react-router-dom";
import { IconAlertCircle, IconBus, IconNote } from "@tabler/icons-react";
import { useFetch } from "../../helpers/useFetch";
import { toast } from "react-hot-toast";
import { useSocketFetch } from "../../helpers/useSocketFetch";

const getChildren = async () => {
  const res = await useFetch("/children/list");
  return res.data;
};

export default function FormStartModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);
  const [children, setChildren] = useState([]);
  const revalidator = useRevalidator();
  const [step, setStep] = useState(1);
  const computer = innerProps?.computer;

  const form = useForm({
    initialValues: {
      child_id: "",
    },
  });

  useEffect(() => {
    getChildren()
      .then((res) => {
        setChildren(res);
        setFetching(false);
      })
      .catch((err) => {
        setError(err);
        setFetching(false);
      });
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    const res = await useSocketFetch("/forms/init/&area/" + computer.socketID, "POST", {
      childID: String(values.child_id),
    });
    if (res.success === true) {
      context.closeModal(id);
      toast.success("Dotazník inicializován.");
    } else {
      setLoading(false);
      toast.error("Nastala chyba při inicializaci dotazníku.");
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title={`Při požadavku nastala chyba.`}
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      {fetching ? (
        <div className="flex h-full w-full flex-col items-center justify-center py-8">
          <Loader />
        </div>
      ) : (
        <>
          {step === 1 && (
            <>
              <Select
                data={children.map((child) => ({
                  label: `${child.firstName} ${child.lastName} (${child.team}, ${child.age} let)`,
                  value: String(child.id),
                }))}
                mb="sm"
                required
                searchable
                hoverOnSearchChange={true}
                label="Dítě"
                placeholder="Vyber dítě..."
                {...form.getInputProps("child_id")}
              />

              <Button fullWidth type="submit" loading={loading} mt="lg">
                Inicializovat dotazník
              </Button>
            </>
          )}
        </>
      )}
    </form>
    // </Modal>
  );
}
