import { useFetch } from "../../helpers/useFetch.js";
import { useLoaderData } from "react-router-dom";
import useTitle from "../../hooks/useTitle.js";
import Page from "../../components/layout/Page.jsx";
import ContractsTable from "../../components/table/ContractsTable.jsx";

export function loader() {
  return useFetch(`user/contracts`);
}

export default function ContractsPage() {
  const contracts = useLoaderData().data;
  useTitle(`Mé smlouvy`);

  return (
    <Page title="Mé smlouvy">
      <ContractsTable contracts={contracts} myContracts />
    </Page>
  );
}
