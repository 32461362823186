import { Alert, Button, Paper, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch";

const PcDeleteQuestModal = ({ context, id, innerProps }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const setShouldFetch = innerProps.setShouldFetch;

  useEffect(() => {
    if (!innerProps?.quest) context.closeModal(id);
  }, [innerProps]);

  const quest = innerProps.quest;
  async function handleDelete() {
    setLoading(true);

    const res = await useFetch(`/pc/quests/${quest.id}`, "DELETE", quest);

    if (res.status === "ok") {
      context.closeModal(id);
      if (setShouldFetch) setShouldFetch(true);
      setLoading(false);
      toast.success("Úkol byl smazán.");
    } else {
      setLoading(false);
      setError(res.message);
    }
  }

  return (
    <div>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při mazání úkolu nastala chyba."
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      <Paper withBorder py="xs" px="sm" mb={20}>
        <Text fw="bold" mb={4}>
          {quest.title}
        </Text>
        <Text>
          <strong>Popis:</strong> {quest.text}
          <br />
          <strong>Lore:</strong> {quest.lore}
        </Text>
      </Paper>
      <div className="flex w-full gap-5">
        <Button variant="light" fullWidth onClick={() => context.closeModal(id)}>
          Zrušit
        </Button>
        <Button fullWidth onClick={handleDelete} loading={loading}>
          Smazat
        </Button>
      </div>
    </div>
  );
};

export default PcDeleteQuestModal;
