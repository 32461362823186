import { useFetch } from "../../helpers/useFetch.js";
import { useLoaderData } from "react-router-dom";
import TicketsTable from "../../components/table/TicketsTable.jsx";
import useTitle from "../../hooks/useTitle.js";
import { IconMessageShare } from "@tabler/icons-react";
import { openTicketModal } from "../../helpers/modals.js";
import Page from "../../components/layout/Page.jsx";

export function loader() {
  return useFetch(`tickets`);
}

export default function TicketsPage() {
  const tickets = useLoaderData().data;
  useTitle(`Požadavky od vedoucích`);

  const actionButtons = [
    {
      name: "Vytvořit požadavek",
      onTrigger: () => {
        openTicketModal("Vytvořit požadavek");
      },
      icon: IconMessageShare,
      primary: true,
    },
  ];

  return (
    <Page title="Požadavky" actionIcons={actionButtons}>
      <TicketsTable tickets={tickets} />
    </Page>
  );
}
