import { useFetch } from "../../helpers/useFetch.js";
import { useParams, useRevalidator } from "react-router-dom";
import useTitle from "../../hooks/useTitle.js";
import Page from "../../components/layout/Page.jsx";
import { ChildrenMoneyTable } from "../../components/table/ChildrenMoneyTable.jsx";
import { Group, Paper, Text } from "@mantine/core";
import { IconCash } from "@tabler/icons-react";
import { openMoneyChangeModal } from "../../helpers/modals.js";
import { useEffect, useState } from "react";

export default function ChildrenMoneyPage() {
  const { category } = useParams();
  const [shouldFetch, setShouldFetch] = useState(false);
  const [fetchCategory, setFetchCategory] = useState(category === "kauce" ? "deposit" : "pocketMoney");
  const [moneyData, setMoneyData] = useState(null);

  const revalidator = useRevalidator();

  let title;

  if (category === "kauce") {
    title = "Kauce";
  } else if (category === "kapesne") {
    title = "Kapesné";
  }

  const getMoneyData = async () => {
    const res = await useFetch("/money/" + (category === "kauce" ? "deposit" : "pocketMoney"));
    setMoneyData(res.data);
  };

  useEffect(() => {
    setShouldFetch(true);
    setFetchCategory(category === "kauce" ? "deposit" : "pocketMoney");
    getMoneyData();
    revalidator.revalidate();
  }, [category]);

  useEffect(() => {
    getMoneyData();
  }, [shouldFetch]);

  useEffect(() => {
    if (!moneyData) {
      getMoneyData();
    }
  }, []);

  useTitle(title);

  const actionButtons = [
    {
      name: "Změnit stav peněz",
      icon: IconCash,
      primary: true,
      onTrigger: () => {
        openMoneyChangeModal(`Změna stavu ${category === "kauce" ? "kaucí" : "kapesného"}`, null, null, null, () =>
          setShouldFetch(true)
        );
      },
    },
  ];

  return (
    <Page actionIcons={actionButtons} title={title}>
      {moneyData ? (
        <Group gap="xl" mb="md">
          <Group gap="xs">
            <Text fw="bold">V kase:</Text>

            <Paper py={4} px="sm" withBorder>
              {moneyData.amount.czk} CZK
            </Paper>
            {fetchCategory === "deposit" && (
              <Paper py={4} px="sm" withBorder>
                {moneyData.amount.eur} EUR
              </Paper>
            )}
          </Group>

          {fetchCategory === "deposit" ? (
            <>
              <Group gap="xs">
                <Text fw="bold">Do areálu:</Text>

                <Paper py={4} px="sm" withBorder>
                  {moneyData.area.czk} CZK
                </Paper>
                <Paper py={4} px="sm" withBorder>
                  {moneyData.area.eur} EUR
                </Paper>
              </Group>
              <Group gap="xs">
                <Text fw="bold">Do busu:</Text>

                <Paper py={4} px="sm" withBorder>
                  {moneyData.bus.czk} CZK
                </Paper>
                <Paper py={4} px="sm" withBorder>
                  {moneyData.bus.eur} EUR
                </Paper>
              </Group>
            </>
          ) : null}
        </Group>
      ) : null}
      <ChildrenMoneyTable
        setShouldFetch={setShouldFetch}
        shouldFetch={shouldFetch}
        moneyCategory={fetchCategory}
        moneyData={moneyData}
      />
    </Page>
  );
}
