import { useRef, useState } from "react";
import { ActionIcon, Alert, Button, Paper, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useUser } from "../../providers/UserProvider.jsx";
import { useRevalidator } from "react-router-dom";
import { useFetch } from "../../helpers/useFetch.js";
import { toast } from "react-hot-toast";
import { IconAlertCircle, IconUpload, IconX } from "@tabler/icons-react";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";

export default function FilePhotoModal({ context, id, innerProps }) {
  const [error, setError] = useState(null);
  const { user } = useUser();
  const revalidator = useRevalidator();
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [images, setImages] = useState([]);

  const handleFileInput = useRef(null);

  const handleImageChange = (files) => {
    setImages((prev) => [
      ...prev,
      {
        imagePreview: URL.createObjectURL(files[0]),
        imageFile: files[0],
      },
    ]);
  };

  async function handleSubmit(values) {
    setLoading(true);

    let formData = new FormData();
    images.map((image) => {
      formData.append("images[]", image.imageFile);
    });
    const res = await useFetch(
      `/children/${innerProps.child.id}/documents/${innerProps.type}/upload-photos`,
      "POST",
      formData,
      false
    );

    if (res.status === "ok") {
      if (innerProps.closeParent) innerProps.closeParent();
      innerProps?.setShouldFetch && innerProps.setShouldFetch(true);
      context.closeModal(id);
      form.reset();
      setLoading(false);
      toast.success("Fotky nahrány.");
      revalidator.revalidate();
    } else {
      console.error(res);
      setError(res.message);
      setLoading(false);
    }
  }

  const removeImage = (index) => {
    images.splice(index, 1);
    setImages(images);
    setUpdate(!update);
  };

  const form = useForm({
    initialValues: {
      text: "",
      is_permanent: false,
      room_id: "",
      place: "",
    },
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title="Při focení dokumentu nastala chyba."
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}

      <>
        <Text fw="bold" mb="xs" size="sm">
          Fotky
        </Text>
        <label>
          <input
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            capture="environment"
            ref={handleFileInput}
            onChange={handleImageChange}
          />
        </label>
        <div className="mb-3 flex flex-wrap gap-2">
          <Dropzone
            onDrop={handleImageChange}
            loading={loading}
            accept={IMAGE_MIME_TYPE}
            className="flex h-[100px] w-[100px] cursor-pointer items-center justify-center rounded-md duration-100 hover:scale-105">
            <Dropzone.Idle>
              <Text>
                <IconUpload stroke={1.5} size={48} />
              </Text>
            </Dropzone.Idle>
            <Dropzone.Accept>
              <Text>
                <IconUpload stroke={1.5} size={48} />
              </Text>
            </Dropzone.Accept>
            <Dropzone.Reject>
              <Text color="red">
                <IconX stroke={1.5} size={48} />
              </Text>
            </Dropzone.Reject>
          </Dropzone>
          {images.length > 0 &&
            images.map((image, index) => (
              <Paper className="relative h-[100px] w-[100px] overflow-hidden rounded-md" withBorder key={index}>
                <ActionIcon
                  variant="filled"
                  className="absolute left-2 top-2"
                  color="red"
                  onClick={() => {
                    removeImage(index);
                  }}>
                  <IconX size={16} stroke={1.5} />
                </ActionIcon>
                <img key={index} className="h-full w-full object-cover object-center" src={image.imagePreview} />
              </Paper>
            ))}
        </div>

        <Button
          // disabled={!form.values.text || (!form.values.room_id && !form.values.place)}
          fullWidth
          type="submit"
          loading={loading}
          mt="lg">
          Nahrát a uložit
        </Button>
      </>
    </form>
  );
}
