import { ActionIcon, Drawer as MantineDrawer, Text } from "@mantine/core";
import styles from "./Drawer.module.scss";
import classNames from "classnames";
import { IconX } from "@tabler/icons-react";
import { modalTitle } from "../modals/Modal";

const Drawer = ({ opened, onClose, title, icon, children, smallTitle, closeOnEscape }) => {
  const Icon = icon;

  return (
    <MantineDrawer
      position="right"
      size={800}
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      transitionDuration={500}
      closeOnEscape={closeOnEscape}
      transition="slide-left">
      <div className="p-6">
        <div className="mb-5 flex items-center justify-between">
          {modalTitle(icon, title, smallTitle)}
          <ActionIcon variant="transparent" onClick={onClose}>
            <IconX size={32} stroke={1.5} />
          </ActionIcon>
        </div>
        {children}
      </div>
    </MantineDrawer>
  );
};

export default Drawer;
