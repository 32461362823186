import { useEffect, useState } from "react";
import { useFetch } from "../../helpers/useFetch";
import { useForm } from "@mantine/form";
import { Button, Input, Loader, SegmentedControl, Select, TextInput } from "@mantine/core";
import toast from "react-hot-toast";

const getData = async () => {
  const depots = await useFetch("depot/depots");
  return { depots: depots.data };
};

export const DepotsManageModal = ({ innerProps: { setShouldFetch }, context, id }) => {
  const [depots, setDepots] = useState([]);
  const [selectedDepot, setSelectedDepot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [type, setType] = useState("create");

  const form = useForm({
    initialValues: {
      name: "",
    },
  });

  const handleSubmit = async () => {
    setLoading(true);
    const options = {
      url: "depot/depots",
      method: "POST",
    };
    if (selectedDepot) {
      options.url = `depot/depots/${selectedDepot}`;
      options.method = "PUT";
    }
    const res = await useFetch(options.url, options.method, form.values);
    setLoading(false);
    if (res.status === "ok") {
      toast.success("Místnost byla úspěšně " + (selectedDepot ? "upravena" : "přidána"));
      if (setShouldFetch) setShouldFetch(true);
      return context.closeModal(id);
    }
    console.error(res);
    toast.error("Nastala chyba při přidávání " + (selectedDepot ? "úpravě" : "přidávání") + " místnosti");
    return;
  };

  useEffect(() => {
    setFetching(true);
    getData().then((res) => {
      setDepots(res.depots);
      setFetching(false);
    });
  }, []);

  useEffect(() => {
    if (selectedDepot) {
      form.setValues({ name: depots.find((depot) => depot.id.toString() === selectedDepot).name });
    } else {
      form.setValues({ name: "" });
    }
  }, [selectedDepot]);

  useEffect(() => {
    setSelectedDepot(null);
    form.setValues({ name: "" });
  }, [type]);

  return (
    <>
      {fetching ? (
        <div className="flex h-full w-full flex-col items-center justify-center py-8">
          <Loader />
        </div>
      ) : (
        <form onSubmit={form.onSubmit(handleSubmit)} className="flex flex-col gap-2">
          <SegmentedControl
            value={type}
            onChange={setType}
            color={"indigo"}
            data={[
              { label: "Přidat místnost", value: "create" },
              { label: "Upravit místnost", value: "edit" },
            ]}
          />
          {type === "edit" && (
            <Select
              label="Vyberte místnost k upravení"
              placeholder="Vyberte místnost k upravení"
              data={depots.map((depot) => ({ label: depot.name, value: depot.id.toString() }))}
              value={selectedDepot}
              onChange={(value) => setSelectedDepot(value)}
              disabled={loading}
              clearable
            />
          )}
          <Input.Wrapper label="Název" withAsterisk>
            <TextInput
              {...form.getInputProps("name")}
              placeholder="Název"
              required
              disabled={loading || (type === "edit" && !selectedDepot)}
            />
          </Input.Wrapper>
          <Button type="submit" variant="filled" mt={"lg"} loading={loading} className="w-full">
            {selectedDepot ? "Upravit " : "Přidat "}místnost
          </Button>
        </form>
      )}
    </>
  );
};
