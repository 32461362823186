import { AlertError, AlertWarning } from "../Alerts";
import useHash from "../../hooks/useHash";
import { ActionIcon, Button, Group, Loader, Paper, Select, Stack, Stepper, Text } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { useListState } from "@mantine/hooks";
import {
  IconCheck,
  IconChevronLeft,
  IconDeviceDesktop,
  IconFile,
  IconGripVertical,
  IconTrash,
  IconUpload,
  IconUsers,
  IconX,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFetch } from "../../helpers/useFetch.js";
import { useUser } from "../../providers/UserProvider";
import { getMode } from "../../helpers/helpers";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import classNames from "classnames";
import { useSocketFetch } from "../../helpers/useSocketFetch";

export default function FormsSetup() {
  const user = useUser();

  const [hash, setHash] = useHash();

  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [dividedRooms, setDividedRooms] = useState([]);
  const [socketComputers, setSocketComputers] = useState([]);
  const { theme, mode } = getMode();

  const navigate = useNavigate();

  const nextStep = () => {
    setActive((current) => (current < 3 ? current + 1 : current));
  };
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  useEffect(() => {
    if (hash.split("#step")[1]) {
      if (hash < active) {
        setActive(Number(hash.split("#step")[1]) - 1);
      }
    }
  }, [hash]);

  const getRooms = () => {
    Promise.all([useFetch("/pcapp/room"), useSocketFetch("/clients/&area")]).then(([rooms, computers]) => {
      setRooms(
        rooms.data.map((room) => ({ ...room, active: computers.data.filter((c) => c.group_id === room.id).length }))
      );
      setSocketComputers(computers.data.filter((computer) => computer.status === "ready"));
      setDividedRooms(
        rooms.data.map((room) => {
          return {
            id: room.id,
            name: room.name,
            capacity: computers.data.filter((computer) => computer.group_id === room.id && computer.status === "ready")
              .length,
            active: 0,
            teams: [],
          };
        })
      );
      setLoading(false);
    });
  };

  useEffect(() => {
    setHash("#step" + (active + 1));

    if (active === 1) {
      setLoading(true);
      useFetch("/teams").then((teams) => {
        setTeams(teams.data);
        setLoading(false);
      });
    }
    if (active === 0) {
      getRooms();
    }
  }, [active]);

  const shouldAllowStepSelect = (step) => {
    return active > step;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? (mode === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]) : null,

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    padding: 4,
  });

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const source = result.source.droppableId.split("-");
    const destination = result.destination.droppableId.split("-");

    if (source[0] === "room" && destination[0] === "room") {
      let roomsCopy = [...dividedRooms];

      let sourceRoom = roomsCopy.find((room) => room.id === Number(source[1]));
      let destinationRoom = roomsCopy.find((room) => room.id === Number(destination[1]));

      let sourceTeam = sourceRoom.teams.find((team) => team.id === Number(result.draggableId.split("-")[1]));

      sourceRoom.teams = sourceRoom.teams.filter((team) => team.id !== Number(result.draggableId.split("-")[1]));
      destinationRoom.teams = [...destinationRoom.teams, sourceTeam];
      sourceRoom.active = sourceRoom.active - sourceTeam.childrenCount;
      destinationRoom.active = destinationRoom.active + sourceTeam.childrenCount;

      setDividedRooms(roomsCopy);
    }

    if (source[0] === "room" && destination[0] === "teams") {
      let roomsCopy = [...dividedRooms];
      let teamsCopy = [...teams];

      let sourceRoom = roomsCopy.find((room) => room.id === Number(source[1]));
      let sourceTeam = sourceRoom.teams.find((team) => team.id === Number(result.draggableId.split("-")[1]));

      sourceRoom.teams = sourceRoom.teams.filter((team) => team.id !== Number(result.draggableId.split("-")[1]));
      sourceRoom.active = sourceRoom.active - sourceTeam.childrenCount;
      teamsCopy = [...teamsCopy, sourceTeam];

      setDividedRooms(roomsCopy);
      setTeams(teamsCopy);
    }

    if (source[0] === "teams" && destination[0] === "room") {
      let destinationRoom = dividedRooms.find((room) => room.id === Number(destination[1]));

      let roomsCopy = [...dividedRooms];
      let teamsCopy = [...teams];

      teamsCopy = teamsCopy.filter((team) => team.id !== Number(result.draggableId.split("-")[1]));
      roomsCopy.find((room) => room.id === Number(destination[1])).teams = [
        ...destinationRoom.teams,
        teams.find((team) => team.id === Number(result.draggableId.split("-")[1])),
      ];
      roomsCopy.find((room) => room.id === Number(destination[1])).active =
        roomsCopy.find((room) => room.id === Number(destination[1])).active +
        teams.find((team) => team.id === Number(result.draggableId.split("-")[1])).childrenCount;

      setDividedRooms(roomsCopy);
      setTeams(teamsCopy);
    }
  };

  const checkTeams = async () => {
    setLoading(true);
    let teamsCopy = [...teams];
    let roomsCopy = [...dividedRooms];

    let roomsParsed = {};

    teamsCopy = await teamsCopy.filter((team) => team.childrenCount > 0);

    roomsCopy = await roomsCopy.map((room) => {
      room.teams = room.teams.filter((team) => team.childrenCount > 0);
      return room;
    });

    roomsCopy.forEach((room, i) => {
      room.teams.forEach((team, j) => {
        useFetch(`/teams/${team.id}`).then((res) => {
          console.log("MÁM TÝM");
          roomsParsed[room.id] = res.data.children.map((child) => child.id);
          if (j + 1 === room.teams.length) {
            console.log("PARSNUTY PC");
            let computersFiltered = socketComputers.filter((computer) => computer.group_id === room.id);
            roomsParsed[room.id].forEach((child, k) => {
              console.log("PROJIZDIM ROOM");
              if (computersFiltered[k]) {
                console.log("INIR");
                useSocketFetch("/forms/init/&area/" + computersFiltered[k].socketID, "POST", {
                  childID: String(child),
                });
              }
              if (j + 1 === room.teams.length && k + 1 === roomsParsed[room.id].length) {
                setLoading(false);
                nextStep();
              }
            });
          }
        });
      });
    });
  };

  const startForm = () => {
    console.log("START");
    useSocketFetch("/clients/&area").then((res) => {
      setSocketComputers(res.data);
      res.data.forEach((computer) => {
        if (computer.status === "formsInit") {
          useSocketFetch("/forms/start/&area/" + computer.socketID, "POST", {
            childID: String(computer.childInfo.ticketID.childID),
          });
        }
      });
    });
  };

  return (
    <Paper className="mx-auto max-w-3xl" p="xl" radius="lg" withBorder>
      <h2>✏️ Průvodce</h2>
      {/* po vybrání vedoucího ho odebrat ze seznamu, naprogramovat */}
      <Text c="dark.1">Je na čase vyplnit dotazníky? S pomocí tohoto průvodce jsou dotazníky hračka.</Text>
      <Stepper mt="sm" active={active} onStepClick={setActive} breakpoint="sm" completedIcon={<IconCheck />}>
        {/*
          - přiřazení oddílů do místností (i se zobrazením kapacity)
          - potvrzení - spustit init na počítače, kontrola stavu (seznam všech PC, musí zezelenat)
            - když se tak nestane, zobrazit chybu a nabídnout možnost znovu spustit init
          - úspěšné initování - dětí si sedají k PC
          - tlačítko na spuštění dotazníků -> redirect na /pocitace
        */}

        <Stepper.Step allowStepSelect={active > 0}>
          <Text mb="md" mt="sm">
            V prvním kroku si zkontroluj tvé místnosti a počítače. Je možné, že někde nebudeš mít spuštěnou aplikaci –
            počet připravených počítačů by měl sedět s celkovým počtem.
          </Text>
          {loading ? (
            <div className="flex justify-center py-10">
              <Loader />
            </div>
          ) : (
            <>
              {rooms.length === 0 && (
                <AlertWarning className="mb-4">
                  <Text>Nebyly nalezeny žádné místnosti. Prvně je přidej ve Správci místností.</Text>
                </AlertWarning>
              )}
              <div class="mb-4 grid grid-cols-2 gap-4">
                {rooms.length > 0 &&
                  rooms.map((room) => (
                    <Paper p="md" radius="md" withBorder key={room.id}>
                      <div className="mb-2 flex items-center justify-between">
                        <h3>{room.name}</h3>
                      </div>
                      <ul className="m-0 px-4">
                        <li>Celkem počítačů: {room.computers.length}</li>
                        <li>Připraveno: {room.active}</li>
                      </ul>
                    </Paper>
                  ))}
              </div>
              <Button mt="sm" loading={loading} fullWidth onClick={nextStep}>
                Počítače sedí, přiřadit oddíly
              </Button>
            </>
          )}
        </Stepper.Step>
        <Stepper.Step allowStepSelect={shouldAllowStepSelect(0)}>
          <Text mb="md" mt="sm">
            Níže vidíš seznam místností a oddíly. Vyber si, pro které oddíly chceš spustit dotazníky v které místnosti a
            potvrď tlačítkem. Systém poté připraví dotazníky a bude tě informovat o postupu.
          </Text>

          {loading ? (
            <div className="flex justify-center py-10">
              <Loader />
            </div>
          ) : (
            <>
              <DragDropContext onDragEnd={handleDragEnd}>
                <div className="mb-4 grid grid-cols-2 gap-4">
                  {dividedRooms.map((room) => (
                    <Paper p="md" radius="md" withBorder>
                      <div className="mb-3 flex items-center justify-between">
                        <h3>{room.name}</h3>
                        <Text
                          color={room.active > room.capacity ? "red.5" : "teal.5"}
                          fw={room.active > room.capacity && "bold"}
                          className="flex items-center gap-1">
                          <IconDeviceDesktop stroke={1.5} size={18} />
                          <Text>
                            {room.active}/{room.capacity}
                          </Text>
                        </Text>
                      </div>
                      <Droppable droppableId={`room-${room.id}`}>
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {room.teams.map((team, indexDraggable) => (
                              <Draggable key={team.id} draggableId={`team-${team.id}`} index={indexDraggable}>
                                {(provided, snapshot) => (
                                  <Paper
                                    bg={mode === "dark" ? "dark.5" : "gray.1"}
                                    p="xs"
                                    className="mb-3 flex select-none items-center justify-between last:mb-0"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                    <Text span fw="bold">
                                      {team.name} {team.number}
                                    </Text>
                                    <Text className="flex items-center gap-1">
                                      <IconUsers stroke={1.5} size={18} />
                                      <Text>{team.childrenCount}</Text>
                                      <Text c="dimmed" className="ml-2 flex items-center">
                                        <IconGripVertical stroke={1.5} size={18} />
                                      </Text>
                                    </Text>
                                  </Paper>
                                )}
                              </Draggable>
                            ))}
                            {room.teams.length === 0 && (
                              <Paper
                                p="xs"
                                className={classNames("mb-0 select-none border-2 border-dashed text-center", {
                                  "border-dark-500": mode === "dark",
                                  "border-gray-500": mode === "light",
                                })}>
                                <Text span color="dimmed">
                                  Žádné oddíly
                                </Text>
                              </Paper>
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </Paper>
                  ))}
                </div>

                <Paper withBorder radius="md" p="md">
                  <h3 className="mb-3">Oddíly</h3>
                  <Droppable droppableId="teams">
                    {(provided, snapshot) => (
                      <div
                        className="grid grid-cols-2 gap-3"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}>
                        {teams.map((team, indexDraggable) => (
                          <Draggable key={team.id} draggableId={`team-${team.id}`} index={indexDraggable}>
                            {(provided, snapshot) => (
                              <Paper
                                bg={mode === "dark" ? "dark.5" : "gray.1"}
                                p="xs"
                                className="flex select-none items-center justify-between"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                <Text span fw="bold">
                                  {team.name} {team.number}
                                </Text>
                                <Text className="flex items-center gap-1">
                                  <IconUsers stroke={1.5} size={18} />
                                  <Text>{team.childrenCount}</Text>
                                  <Text c="dimmed" className="ml-2 flex items-center">
                                    <IconGripVertical stroke={1.5} size={18} />
                                  </Text>
                                </Text>
                              </Paper>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Paper>
              </DragDropContext>

              <Button mt="md" loading={loading} fullWidth onClick={checkTeams}>
                Inicializovat dotazníky
              </Button>
            </>
          )}
        </Stepper.Step>
        <Stepper.Step>
          <Text mb="md" mt="sm">
            Zkontroluj, zda inicializace proběhla v pořádku. Na počítačích bys měl/a nyní vidět jména dětí. Až děti
            budou připravené, klikni na tlačítko dole.
            <Button mt="md" loading={loading} fullWidth onClick={startForm}>
              Spustit dotazníky
            </Button>
          </Text>
        </Stepper.Step>
      </Stepper>
    </Paper>
  );
}
