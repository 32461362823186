import { MantineProvider, alpha, createTheme, localStorageColorSchemeManager, rgba } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/spotlight/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/code-highlight/styles.css";

export default function StylesProvider({ children }) {
  const [color, setColor] = useLocalStorage({ key: "color", defaultValue: "indigo" });
  const colorSchemeManager = localStorageColorSchemeManager({ key: "mode" });

  const colorScheme = colorSchemeManager.get();

  const theme = createTheme({
    primaryColor: color,
    primaryShade: 7,
    fontFamily: "Moderat, sans-serif",
    fontFamilyMonospace: "Monaco, Courier, monospace",
    headings: { fontFamily: "Moderat Extended" },
    colors: {
      dark: [
        "#F9F9FB",
        "#A2A4BE",
        "#7277A6",
        "#484A67",
        "#323449",
        "#232533",
        "#191A24",
        "#0E0F15",
        "#08090C",
        "#050507",
      ],
    },
    components: {
      Input: {
        styles: (theme) => ({
          input: {
            borderWidth: "2px",
            fontSize: "16px",
            lineHeight: "20px",

            "&[dataDisabled]": {
              opacity: 0.4,
            },
          },
        }),
      },
      Tooltip: {
        defaultProps: (theme) => ({
          color: colorScheme === "dark" ? "dark" : "gray.2",
          withArrow: true,
        }),
        styles: (theme) => ({
          tooltip: {
            color: colorScheme === "dark" ? theme.white : theme.black,
          },
        }),
      },
      InputWrapper: {
        styles: (theme) => ({
          label: { marginBottom: "0.25rem", fontWeight: "bold" },
        }),
      },
      PasswordInput: {
        styles: (theme) => ({
          input: { borderWidth: "2px" },
          innerInput: { fontSize: "16px" },
        }),
      },
      TimeInput: {
        styles: (theme) => ({
          controls: { height: "20px" },
          timeInput: { fontSize: "16px" },
        }),
      },
      MultiSelect: {
        styles: (theme) => ({
          pillsList: { minHeight: "unset", lineHeight: "20px", gap: "0.25rem" },
          value: { margin: "0", height: "20px" },
          input: { padding: "0.375rem 0.7rem" },
          inputField: { fontSize: "16px", lineHeight: "20px", height: "auto" },
        }),
      },
      Table: {
        styles: (theme) => ({
          table: {
            background: colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[1],
            /* borderRadius: theme.radius.lg, */
            border: "0px",
            fontSize: "1rem",

            "& thead tr th, & tbody tr td, & tbody tr th": {
              padding: "0.6rem 1rem",
              fontSize: "1rem",
            },

            "& tbody tr": {
              border: `solid ${colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[3]}`,
              borderWidth: "1px 0",
            },

            "& thead tr th": {
              border: 0,
            },
          },
        }),
      },
      Paper: {
        styles: (theme) => ({
          root: {
            background: colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[1],

            "--_paper-border": `2px solid ${colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
          },
        }),
      },
      SegmentedControl: {
        styles: (theme) => ({
          root: {
            background: colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[1],
            /* border: `2px solid ${colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]}`, */
          },
          indicator: {
            "--_bg": colorScheme === "dark" ? theme.colors[color][7] : theme.colors[color][5],
          },
        }),
      },
      LoadingOverlay: {
        styles: (theme) => ({
          overlay: {
            background: colorScheme === "dark" ? rgba(theme.colors.dark[8], 0.75) : rgba(theme.colors.gray[0], 0.75),
          },
        }),
      },
      Stepper: {
        styles: (theme) => ({
          root: {
            "--stepper-radius": theme.radius.md,
          },
          stepIcon: {
            fontFamily: "Moderat Extended",
            fontSize: "1.5rem",
            fontWeight: 700,
          },
          /* stepBody: {
            display: "none",
          },

          step: {
            padding: 0,
          },

          stepIcon: {
            background: alpha(theme.colors[theme.primaryColor][7], 0.25),
            border: 0,
            borderRadius: theme.radius.md,
            fontSize: "1.5rem",
            fontFamily: "Moderat Extended",
            fontWeight: 900,
            color: theme.colors.dark[0],

            "&[data-progress]": {
              background: theme.colors[theme.primaryColor][7],
            },
          },

          separator: {
            height: 2,
            marginLeft: 0,
            marginRight: 0,
            background: `repeating-linear-gradient(90deg,transparent,transparent 10px,${theme.colors.dark[3]} 10px,${theme.colors.dark[3]} 20px)`,

          }, */
        }),
      },
      // Table: {
      //     styles: (theme) => ({
      //         root: {},
      //         table: {
      //             borderRadius: 0,
      //         },
      //     }),
      // },
      Modal: {
        styles: (theme) => ({
          header: {
            fontWeight: 700,
            background: colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
            zIndex: 50,
          },
          inner: {
            alignItems: "center",
          },
          content: {
            background: colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
            border: `2px solid ${colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]}`,
            overflowY: "auto",
          },
          overlay: {
            backdropFilter: "blur(3px)",
            background: "transparent !important",
            opacity: "1 !important",

            "&::after": {
              position: "absolute",
              content: '""',
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: `black`,
              opacity: 0.25,
            },
          },
        }),
      },
      Kbd: {
        styles: (theme) => ({
          root: {
            padding: "0 5px",
          },
        }),
      },
      Spotlight: {
        styles: (theme) => ({
          content: {
            background: colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
            border: colorScheme === "dark" ? "2px solid " + theme.colors.dark[4] : "2px solid " + theme.colors.gray[3],
            borderRadius: theme.radius.md,
          },
          searchInput: {
            background: colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0],
          },
          actionIcon: {
            color: colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[7],
          },
          actionBody: {
            span: {
              fontWeight: 700,
            },
            div: {
              lineHeight: 1.25,
            },
          },
        }),
      },
      Drawer: {
        styles: (theme) => ({
          content: {
            background: colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
            borderLeft: `2px solid ${colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]}`,
          },
        }),
      },

      /* ActionIcon: {
        styles: (theme) => ({
          root: {
            "&[data-disabled]": {
              color: colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[6],
              background: theme.fn.rgba(
                colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[4],
                0.5
              ),
              border: "0",
            },
          },
        }),
      }, */
      /* Button: {
        styles: (theme) => ({
          root: {
            "&[data-disabled]": {
              color: colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[6],
              background: theme.fn.rgba(
                colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[4],
                0.5
              ),
            },
          },
        }),
      }, */
    },
  });

  const resolver = (theme) => ({
    variables: {
      "--mantine-hero-height": theme.other.heroHeight,
    },
    /* light: {
      '--mantine-color-deep-orange': theme.other.deepOrangeLight,
    },
    dark: {
      '--mantine-color-deep-orange': theme.other.deepOrangeDark,
    }, */
  });

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      withCSSVariables
      theme={theme}
      colorSchemeManager={colorSchemeManager}>
      {children}
    </MantineProvider>
  );
}
