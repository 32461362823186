import { ActionIcon, Checkbox, Group, Loader, Paper, Select, Stack, Text, Textarea, Tooltip } from "@mantine/core";
import {
  IconEdit,
  IconIdBadge2,
  IconPill,
  IconPlus,
  IconReportMedical,
  IconTrash,
  IconVirus,
} from "@tabler/icons-react";
import { useOutletContext } from "react-router-dom";
import DocumentsList from "../../components/boarding/DocumentsList";
import { openMedicationEditModal, openNewMedicationModal } from "../../helpers/modals";
import { getMode, parseFullName } from "../../helpers/helpers";
import { useForm } from "@mantine/form";
import { useFetch } from "../../helpers/useFetch";
import { useEffect, useState } from "react";
import { openConfirmModal } from "@mantine/modals";
import { modalTitle } from "../../components/modals/Modal";
import { toast } from "react-hot-toast";
import SaveChangesBar from "../../components/SaveChangesBar";
import classNames from "classnames";

const BoardingMedical = () => {
  const child = useOutletContext();

  const [showSaveBar, setShowSaveBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [childLocal, setChildLocal] = useState(null);
  const [medications, setMedications] = useState(null);
  const [medicationList, setMedicationList] = useState([]);
  const [medicationLoading, setMedicationLoading] = useState(false);
  const [viewerParentOpened, setViewerParentOpened] = useState(false);

  useEffect(() => {
    setLoading(true);
    useFetch(`children/${child}`).then((res) => {
      setChildLocal(res.data);
      setMedications(res.data.medicalMedications);
      form.setValues({
        medical_restrictions: res.data.medicalInfo.medical_restrictions,
        is_medical_restrictions_important: res.data.medicalInfo.is_medical_restrictions_important,
        allergies: res.data.medicalInfo.allergies,
        is_allergies_important: res.data.medicalInfo.is_allergies_important,
        dietary_restrictions: res.data.medicalInfo.dietary_restrictions,
        is_dietary_restrictions_important: res.data.medicalInfo.is_dietary_restrictions_important,
        can_swim: res.data.canSwim ? "true" : "false",
        has_diet: res.data.medicalInfo.has_diet,
      });
      setLoading(false);
    });
  }, [child]);

  let dosages = {
    s_m: "S-",
    s_p: "S+",
    o_m: "O-",
    o_p: "O+",
    v_m: "V-",
    v_p: "V+",
  };

  const handleMedicationRemove = async (id, name) => {
    setMedicationLoading(true);
    openConfirmModal({
      title: modalTitle(IconTrash, "Smazat pravidelný lék"),
      children: <Text size="sm">Opravdu chceš smazat lék {name}?</Text>,
      labels: { confirm: "Potvrdit", cancel: "Zrušit" },
      onCancel: () => setMedicationLoading(false),
      onConfirm: () => {
        toast.promise(useFetch("/medical/child-medications/" + id, "DELETE"), {
          loading: "Odstraňuji lék...",
          success: (res) => {
            setMedications(medications.filter((med) => med.id !== id));
            setMedicationLoading(false);
            return <p>Lék odstraněn</p>;
          },
          error: <p>Nastala chyba při odstranění léku a byla nahlášena.</p>,
        });
      },
      onClose: () => setMedicationLoading(false),
    });
  };

  useEffect(() => {
    const medicationFunction = async () => {
      let medicationLocal = [];
      if ((await medications.length) < 0) return setMedicationList(medicationLocal);
      await medications.forEach(async (med) => {
        let medicationDosages = "";
        await Object.keys(dosages).forEach((key) => {
          if (key.includes("_")) {
            if (med[key].amount !== null) {
              medicationDosages = `${medicationDosages}${dosages[key]} ${med[key].amount}x, `;
            }
          }
          if (key === "pp") {
            if (med[key].amount !== null) {
              medicationDosages = `${medicationDosages}${dosages[key]} ${med[key].amount}x`;
            }
          }
        });
        medicationLocal.push({
          name: med.name,
          dosage: medicationDosages.replace(/,\s*$/, ""),
          id: med.id,
        });
      });
      setMedicationList(medicationLocal);
    };
    medicationFunction();
  }, [medications]);

  const handleSubmit = (values) => {
    setFormLoading(true);
    const finalValues = { ...form.values, can_swim: form.values.can_swim === "true" };
    useFetch("/children/" + child + "/medical-info", "POST", { ...finalValues })
      .then((res) => {
        if (res.status === "ok") {
          toast.success("Změny uloženy");
          form.resetDirty(form.values);
        }
        setFormLoading(false);
      })
      .catch((err) => {
        toast.error("Nastala chyba a byla nahlášena.");
        console.error(err);
        setFormLoading(false);
      });
  };

  const documents = [
    "insurance_card",
    "infectiousness",
    "medical_certificate",
    ...(childLocal?.isSlovak || childLocal?.age >= 15 ? ["identity_card"] : []),
  ];

  const form = useForm({
    initialValues: {
      medical_restrictions: null,
      is_medical_restrictions_important: null,
      allergies: null,
      is_allergies_important: null,
      dietary_restrictions: null,
      is_dietary_restrictions_important: null,
      medications: null,
      can_swim: null,
      has_diet: null,
    },
  });

  useEffect(() => {
    form.resetDirty();
  }, [loading]);

  const resetForm = () => {
    form.setValues({
      medical_restrictions: childLocal.medicalInfo.medical_restrictions,
      is_medical_restrictions_important: childLocal.medicalInfo.is_medical_restrictions_important,
      allergies: childLocal.medicalInfo.allergies,
      is_allergies_important: childLocal.medicalInfo.is_allergies_important,
      dietary_restrictions: childLocal.medicalInfo.dietary_restrictions,
      is_dietary_restrictions_important: childLocal.medicalInfo.is_dietary_restrictions_important,
      can_swim: childLocal.canSwim,
      has_diet: childLocal.medicalInfo.has_diet,
    });
  };

  useEffect(() => {
    if (form.isDirty()) return setShowSaveBar(true);
    return setShowSaveBar(false);
  }, [form.isDirty()]);

  const { theme, mode } = getMode();

  return loading ? (
    <Text align="center" mt={50}>
      <Loader />
    </Text>
  ) : (
    <div>
      <DocumentsList
        childId={child}
        isSlovak={childLocal?.isSlovak}
        setViewerParentOpened={setViewerParentOpened}
        documents={documents}
      />
      {!viewerParentOpened && (
        <Paper p="lg" className="mb-4" withBorder radius="lg">
          <h2 className="mb-3 flex items-center gap-4">
            Pravidelné léky
            <ActionIcon
              onClick={() =>
                openNewMedicationModal(
                  `Přidat pravidelný lék`,
                  childLocal,
                  parseFullName(childLocal),
                  medications,
                  setMedications
                )
              }
              variant="light">
              <IconPlus stroke={1.5} size={24} />
            </ActionIcon>
          </h2>
          <Text color={theme.colors[theme.primaryColor][theme.primaryShade]} size="sm" className="mt-1">
            <strong>Léky od rodičů:</strong>{" "}
            {childLocal?.parentMedicalInfo.medications ? childLocal?.parentMedicalInfo.medications : "–"}
          </Text>
          <Stack gap={0}>
            {medicationList.length > 0 ? (
              medicationList.map((record) => (
                <Paper
                  p="xs"
                  mt="sm"
                  withBorder
                  key={record.id}
                  className={classNames({
                    "border-dark-500 bg-dark-300": mode === "dark",
                    "border-gray-500 bg-white-900": mode === "light",
                  })}>
                  <Group className="w-full" justify="space-between">
                    <Group gap="xs" align="center">
                      <IconPill size={24} stroke={1.5} />
                      <Text className="leading-5">
                        <strong>{record.name}</strong>
                        <br />
                        <Text span size="sm" color="dimmed">
                          {record.dosage}
                        </Text>
                      </Text>
                    </Group>

                    <Group gap="xs">
                      <Tooltip label="Upravit lék">
                        <ActionIcon
                          onClick={() =>
                            openMedicationEditModal("Úprava léku", medications, childLocal, record, setMedications)
                          }
                          loading={medicationLoading}
                          variant="light">
                          <IconEdit size={20} stroke={1.5} />
                        </ActionIcon>
                      </Tooltip>
                      <Tooltip label="Smazat lék">
                        <ActionIcon
                          loading={medicationLoading}
                          onClick={() => handleMedicationRemove(record.id, record.name)}
                          color="red"
                          variant="light">
                          <IconTrash size={20} stroke={1.5} />
                        </ActionIcon>
                      </Tooltip>
                    </Group>
                  </Group>
                </Paper>
              ))
            ) : (
              <Text className="mb-3 mt-3 flex items-center font-bold">🤔 Dítě nemá žádné pravidelné léky.</Text>
            )}
            {/* <Group className="justify-between rounded-md border-2 border-solid border-dark-500 bg-dark-300 p-2">
            <Group gap={"6px"}>
              <IconPill stroke={1.5} size={24} />
              <Text>Lék</Text>
            </Group>
            <ActionIcon variant={"light"} color={"teal"} onClick={() => log}>
              <IconCheck stroke={1.5} size={20} />
            </ActionIcon>
          </Group> */}
          </Stack>
          <h2 className="mb-3 mt-6">Zdravotní informace</h2>
          <form onSubmit={() => {}}>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div>
                <Text color={theme.colors[theme.primaryColor][theme.primaryShade]} size="sm" className="mb-1">
                  <strong>Zdravotní omezení od rodičů:</strong>{" "}
                  {childLocal?.parentMedicalInfo.medical_restrictions
                    ? childLocal?.parentMedicalInfo.medical_restrictions
                    : "–"}
                </Text>
                <Textarea
                  minRows={2}
                  autosize
                  label="Zdravotní omezení od zdravotníka"
                  disabled={formLoading}
                  {...form.getInputProps("medical_restrictions")}
                />
                <Checkbox
                  label="Jsou zdravotní omezení závažná? (Mohou vést k zranění, je nutné na ně dávat velký pozor...)"
                  className="mt-2"
                  {...form.getInputProps("is_medical_restrictions_important", { type: "checkbox" })}
                  disabled={formLoading}
                />
              </div>
              <div>
                <Text color={theme.colors[theme.primaryColor][theme.primaryShade]} size="sm" className="mb-1">
                  <strong>Alergie od rodičů:</strong>{" "}
                  {childLocal?.parentMedicalInfo.allergies ? childLocal?.parentMedicalInfo.allergies : "–"}
                </Text>
                <Textarea
                  minRows={2}
                  autosize
                  label="Alergie od zdravotníka"
                  disabled={formLoading}
                  {...form.getInputProps("allergies")}
                />

                <Checkbox
                  label="Jsou alergie žávažné? (Silná alergická reakce, nutné podání epipenu...)"
                  className="mt-2"
                  {...form.getInputProps("is_allergies_important", { type: "checkbox" })}
                  disabled={formLoading}
                />
              </div>

              <div>
                <Text color={theme.colors[theme.primaryColor][theme.primaryShade]} size="sm" className="mb-1">
                  <strong>Stravování od rodičů:</strong>{" "}
                  {childLocal?.parentMedicalInfo.dietary_restrictions
                    ? childLocal?.parentMedicalInfo.dietary_restrictions
                    : "–"}
                </Text>
                <Textarea
                  minRows={2}
                  autosize
                  label="Stravování od zdravotníka"
                  disabled={formLoading}
                  {...form.getInputProps("dietary_restrictions")}
                />

                <Checkbox
                  label="Jsou informace o stravování závažné? (Životu nebezpečná alergie...)"
                  className="mt-2"
                  {...form.getInputProps("is_dietary_restrictions_important", { type: "checkbox" })}
                  disabled={formLoading}
                />
                <Checkbox
                  label="Je dietář pro jídelnu?"
                  className="mt-2"
                  {...form.getInputProps("has_diet", { type: "checkbox" })}
                  disabled={formLoading}
                />
              </div>
              <div>
                <Text color={theme.colors[theme.primaryColor][theme.primaryShade]} size="sm" className="mb-1">
                  <strong>Poznámka od rodiče:</strong> {childLocal?.parentNote ? childLocal?.parentNote : "–"}
                </Text>
                <Select
                  label="Plavec"
                  data={[
                    { label: "Ano", value: "true" },
                    { label: "Ne", value: "false" },
                  ]}
                  {...form.getInputProps("can_swim")}
                  disabled={formLoading}
                  required
                />
              </div>
            </div>
          </form>
        </Paper>
      )}
      <SaveChangesBar
        text="V akreditaci nemáš uložené změny."
        show={showSaveBar}
        onConfirm={() => {
          handleSubmit(form.values);
        }}
        onCancel={resetForm}
        loading={formLoading}
      />
    </div>
  );
};

export default BoardingMedical;
