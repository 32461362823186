import { Accordion, Text } from "@mantine/core";
import Table from "./core/Table";
import { useEffect } from "react";
import { useState } from "react";
import CampIcon from "../../pages/pc/CampIcon";
import classNames from "classnames";
import { useMediaQuery } from "@mantine/hooks";

export default function PcQuestTable({
  actionButtons,
  camps,
  camp,
  shouldFetch,
  setShouldFetch,
  serverFetching,
  setServerFetching,
  teams,
  days,
  quests,
}) {
  const [data, setData] = useState([]);

  useEffect(() => {
    let questsData = [];
    if (!camp) {
      questsData = quests;
    } else {
      for (const questDay of quests) {
        questsData.push(questDay.filter((quest) => quest.camp.id === camp));
      }
    }
    setData(questsData);
  }, [quests, camp]);

  const campIcon = (quest) => {
    return <CampIcon camp={quest.camp} />;
  };

  const createDiv = (text, big = false) => {
    return (
      <div
        className={classNames(
          "block overflow-hidden text-ellipsis text-nowrap",
          big && "max-w-60 text-ellipsis md:w-80 md:min-w-full md:max-w-fit md:text-wrap"
        )}>
        {text}
      </div>
    );
  };

  const columns = [
    {
      accessor: "title",
      title: createDiv("Název"),
      render: (quest) => createDiv(quest.title, true),
    },
    {
      accessor: "text",
      title: createDiv("Popis"),
      visibleMediaQuery: (theme) => `(min-width: 600px)`, // ??? dělá to vůbec něco xd?, budu dělat že jo :)
      render: (quest) => createDiv(quest.text, true),
    },
    {
      accessor: "lore",
      title: createDiv("Lore"),
      visibleMediaQuery: (theme) => `(min-width: 600px)`,
      render: (quest) => createDiv(quest.lore, true),
    },
    {
      accessor: "points",
      title: createDiv("Body"),
      render: (quest) => quest.points,
    },
    {
      accessor: "camp",
      title: createDiv("Tábor"),
      render: (quest) => campIcon(quest),
    },
    {
      accessor: "actions",
      title: "Akce",
      disableHiding: true,
      render: (quest) => actionButtons(quest),
      narrow: true,
    },
  ];
  return (
    <>
      <Accordion multiple variant="separated" radius="md">
        {days.map((day) => (
          <Accordion.Item key={day.value} value={day.value.toString()}>
            <Accordion.Control>
              <Text>{day.label}</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Table
                id={`pc-quests-${day.value}`}
                columns={columns}
                records={data && data[day.value]}
                serverUrl={"/pc/quests"}
                shouldServerFetch={shouldFetch}
                setShouldServerFetch={setShouldFetch}
              />
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
}
