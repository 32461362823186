import { useEffect, useState } from "react";
import { Alert, Button, TextInput, Textarea, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch.js";
import { useRevalidator } from "react-router-dom";
import { IconAlertCircle } from "@tabler/icons-react";

export default function GameGroupModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const revalidator = useRevalidator();

  async function handleSubmit(values) {
    setLoading(true);

    const gameGroup = innerProps?.gameGroup;
    const res = await useFetch(
      `pc/game-group${gameGroup ? "/" + gameGroup.id : ""}`,
      gameGroup ? "PUT" : "POST",
      values
    );

    if (res.status === "ok") {
      context.closeModal(id);
      form.reset();
      setLoading(false);
      toast.success("Herní skupina uložena.");
      revalidator.revalidate();
    } else {
      console.error(res);
      setLoading(false);
      setError(res.message);
    }
  }

  const loginIdentifiers = [
    {
      value: "email",
      label: "E-mail",
    },
    {
      value: "username",
      label: "Uživatelské jméno",
    },
  ];

  const form = useForm({
    initialValues: {
      name: "",
      login_identifier: "",
      note: "",
    },
    validate: {
      name: (value) => (value === "" ? "Musíš zadat název" : null),
      login_identifier: (value) => (value === "" ? "Musíš vybrat identifikátor" : null),
    },
  });

  useEffect(() => {
    const gameGroup = innerProps?.gameGroup;

    if (gameGroup) {
      form.setValues({
        name: gameGroup.name,
        login_identifier: gameGroup.loginIdentifier,
        note: gameGroup.note,
      });
    } else {
      form.reset();
    }
  }, [innerProps]);

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title={`Při vytváření herní skupiny nastala chyba.`}
          color="red"
          mb="md"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      <TextInput
        disabled={loading}
        placeholder="Steam"
        autosize
        label="Název služby"
        required
        {...form.getInputProps("name")}
        mb="sm"
      />
      <Select
        data={loginIdentifiers}
        {...form.getInputProps("login_identifier")}
        label="Vyber identifikátor"
        placeholder="Uživatelské jméno"
        disabled={loading}
        required
        mb="sm"
      />
      <Textarea
        disabled={loading}
        mt="sm"
        placeholder="Zadej poznámku..."
        maxRows={3}
        minRows={3}
        autosize
        label="Poznámka k herní skupině"
        {...form.getInputProps("note")}
        mb="sm"
      />

      <Button fullWidth type="submit" loading={loading} mt="md">
        Uložit herní skupinu
      </Button>
    </form>
  );
}
