import { Paper, Table, Text, Group, Accordion, Center } from "@mantine/core";
import React, { useEffect, useState } from "react";
import QuestCheckbox from "./QuestCheckbox";
import CampIcon from "../../pages/pc/CampIcon";
import styles from "../../pages.module.scss";
import classNames from "classnames";
import classes from "../../components/table/core/TableEmptyState.module.scss";
import { IconDatabaseOff } from "@tabler/icons-react";

const QuestsCard = ({ teamQuests, camps, setShouldFetch, mode, changed, setChanged }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const questsData = camps.map((camp) => ({
      camp: camp,
      quests: undefined,
      done: [],
      teams: [],
    }));

    teamQuests.forEach((questDay, index) => {
      const campIndex = camps.findIndex((camp) => camp.shortcut === questDay.camp.shortcut);
      const curDay = new Date().getDay();
      const day = curDay > 5 ? 0 : curDay;
      if (campIndex !== -1) {
        if (questsData[campIndex].quests == undefined) {
          questsData[campIndex].quests = questDay.quests[day];
        }
        let doneAr = [];
        for (const quest of questDay.quests[day]) {
          doneAr.push(quest.done);
        }
        questsData[campIndex].done.push(doneAr);
        questsData[campIndex].teams.push(questDay.team);
      }
    });

    setData(questsData);
  }, [teamQuests, camps]);

  return (
    <Accordion variant="separated" radius="md" multiple>
      {data &&
        data.map((campData, index) => (
          <Accordion.Item value={campData.camp.shortcut} key={index}>
            <Accordion.Control>
              <span className="flex gap-1">
                <CampIcon camp={campData.camp} />
                <Text>- {campData.camp.name}</Text>
              </span>
            </Accordion.Control>
            <Accordion.Panel>
              <div
                className={classNames("rounded-lg border-2 border-solid", {
                  "border-dark-400": mode === "dark",
                  "border-gray-600": mode === "light",
                })}>
                <Table
                  striped
                  highlightOnHover
                  captionSide="bottom"
                  className={classNames("max-w-full rounded-lg", styles.Table)}>
                  <Table.Thead className="text-nowrap border-b-2">
                    <Table.Tr>
                      <Table.Th>Quest</Table.Th>
                      {campData.teams.map((team, teamIndex) => (
                        <Table.Th key={teamIndex}>{team.shortcut}</Table.Th>
                      ))}
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {campData.quests.length !== 0 &&
                      campData.quests.map((quest, questIndex) => (
                        <Table.Tr key={questIndex} className="text-sm md:text-base">
                          <Table.Td className={questIndex === campData.quests.length - 1 ? "rounded-bl-[6px]" : ""}>
                            {quest.title}
                          </Table.Td>
                          {campData.teams.map((team, teamIndex) => (
                            <React.Fragment key={teamIndex}>
                              <Table.Td className={questIndex === campData.quests.length - 1 ? "rounded-br-[6px]" : ""}>
                                <QuestCheckbox
                                  quest={quest}
                                  team={team}
                                  setShouldFetch={setShouldFetch}
                                  done={campData.done[teamIndex][questIndex]}
                                  changed={changed}
                                  setChanged={setChanged}
                                />
                              </Table.Td>
                            </React.Fragment>
                          ))}
                        </Table.Tr>
                      ))}
                    {campData.quests.length === 0 && (
                      <Table.Tr>
                        <Table.Td colSpan={100} className={classes.wrapper}>
                          <div>
                            <Center className="my-4 flex flex-col font-normal">
                              <div className={classes.standardIcon}>
                                <IconDatabaseOff stroke={1.5} className="m-0" />
                              </div>
                              <Text size="sm" c="dimmed">
                                Nic tu není 😿
                              </Text>
                            </Center>
                          </div>
                        </Table.Td>
                      </Table.Tr>
                    )}
                  </Table.Tbody>
                </Table>
              </div>
            </Accordion.Panel>
          </Accordion.Item>
        ))}
    </Accordion>
  );
};

export default QuestsCard;
