import { useEffect, useState } from "react";
import { Alert, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { toast } from "react-hot-toast";
import { useFetch } from "../../helpers/useFetch.js";
import { useRevalidator } from "react-router-dom";
import { IconAlertCircle } from "@tabler/icons-react";

export default function NewPcMinecraftAdminWhitelistModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const revalidator = useRevalidator();

  async function handleSubmit(values) {
    await setLoading(true);

    const res = await useFetch(`pc/mc/global-whitelist/${values.user_id}/${values.mcNickname}`, "POST");

    if (res.status === "ok") {
      context.closeModal(id);
      form.reset();
      setLoading(false);
      toast.success(`Nick ${values.mcNickname} byl přidán do seznamu Minecraft adminů.`);
      revalidator.revalidate();
    } else {
      console.error(res);
      setLoading(false);
      setError(res.error);
    }
  }

  const form = useForm({
    initialValues: {
      mcNickname: "",
      user_id: innerProps?.user?.id,
    },
    validate: {
      mcNickname: (value) => (value === "" ? "Musíš napsat Minecraft nickname" : null),
    },
  });

  useEffect(() => {
    form.setValues({ user_id: innerProps?.user?.id });
  }, [innerProps]);

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} stroke={1.5} />}
          title={`Při přidávání Minecraft admina nastala chyba.`}
          color="red"
          mb="md"
          mt="lg"
          withCloseButton
          closeButtonLabel="Zavřít upozornění"
          onClose={() => setError(false)}>
          {error}
        </Alert>
      )}
      <TextInput
        disabled={loading}
        placeholder="Zadej Minecraft přezdívku..."
        autosize
        label="Minecraft přezdívka"
        required
        {...form.getInputProps("mcNickname")}
      />

      <Button fullWidth type="submit" loading={loading} mt="md">
        Přidat admina
      </Button>
    </form>
  );
}
