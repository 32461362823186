import { useEffect, useState, useRef } from "react";
import Page from "../../components/layout/Page";
import { useFetch } from "../../helpers/useFetch";
import { Group, Table, Text, ScrollArea, Accordion, Center } from "@mantine/core";
import QuestCheckbox from "../../components/quests/QuestCheckbox";
import classNames from "classnames";
import styles from "../../pages.module.scss";
import { getMode } from "../../helpers/helpers";
import React from "react";
import { IconDatabaseOff } from "@tabler/icons-react";
import classes from "../../components/table/core/TableEmptyState.module.scss";
import { useMediaQuery } from "@mantine/hooks";
import QuestsCard from "../../components/quests/QuestsCard";

const PcQuestsFillPage = () => {
  const [teamQuests, setTeamQuests] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);
  const rowsLen = useRef([0, 0, 0, 0, 0, 0]);
  const { mode } = getMode();
  const mobile = useMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(true);
  const camps = useRef([]);
  const [changed, setChanged] = useState(null);

  useEffect(() => {
    if (shouldFetch) {
      setLoading(true);
      const fetchData = async () => {
        try {
          const [questsResponse, teamsResponse, campsResponse] = await Promise.all([
            useFetch("pc/quests"),
            useFetch("teams"),
            useFetch("public/camps"),
          ]);

          const data = [];
          for (const team of teamsResponse.data) {
            const match = campsResponse.data.find((camp) => camp.name === team.name);

            let found = false;
            for (const camp of camps.current) {
              found = camp.shortcut === match.shortcut ? true : found;
            }
            if (!found) camps.current.push(match);

            let questsRaw;
            questsRaw = questsResponse.data.filter((quest) => quest.camp.shortcut === match.shortcut);

            const quests = [[], [], [], [], [], []];
            let done = [0, 0, 0, 0, 0, 0];
            let max = [0, 0, 0, 0, 0, 0];
            for (const quest of questsRaw) {
              const doneQ = quest.teams_complete.some((qTeam) => qTeam === team.id);
              quests[quest.day].push({
                ...quest,
                done: doneQ,
              });
              done[quest.day] += doneQ ? quest.points : 0;
              max[quest.day] += quest.points;
            }
            if (quests.length > rowsLen.current) rowsLen.current = quests.length;
            quests.forEach((day, index) => {
              if (day.length > rowsLen.current[index]) rowsLen.current[index] = day.length;
            });
            data.push({
              team: team,
              quests: quests,
              done: done,
              max: max,
              camp: match,
            });
          }
          setTeamQuests(data);
        } catch (err) {
          console.error(err);
        } finally {
          setChanged(null);
          setShouldFetch(false);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [shouldFetch]);

  const rows = [[], [], [], [], [], []];
  for (let i = 0; i < 6; i++) {
    for (let j = 0; j < rowsLen.current[i]; j++) {
      rows[i].push(
        <Table.Tr key={j}>
          <Table.Td>{j + 1}</Table.Td>
          {teamQuests &&
            teamQuests.map((teamQuest) => {
              const quest = teamQuest.quests[i][j];
              return (
                <Table.Td key={teamQuest.team.id}>
                  {quest ? (
                    <Group>
                      <QuestCheckbox
                        quest={quest}
                        team={teamQuest.team}
                        setShouldFetch={setShouldFetch}
                        done={quest.done}
                        setChanged={setChanged}
                        changed={changed}
                      />
                      <Text className="text-nowrap">{quest.title.substring(0, 5)}</Text>
                    </Group>
                  ) : (
                    ""
                  )}
                </Table.Td>
              );
            })}
        </Table.Tr>
      );
    }
  }

  for (let i = 0; i < 6; i++) {
    if (rows[i].length === 0) {
      rows[i].push(
        <Table.Tr key={i}>
          <Table.Td colSpan={100} className={classes.wrapper}>
            <div>
              <Center className="my-4 flex flex-col font-normal">
                <div className={classes.standardIcon}>
                  <IconDatabaseOff stroke={1.5} className="m-0" />
                </div>
                <Text size="sm" c="dimmed">
                  Nic tu není 😿
                </Text>
              </Center>
            </div>
          </Table.Td>
        </Table.Tr>
      );
    }
  }

  const days = [
    { label: "Neděle", value: 0 },
    { label: "Pondělí", value: 1 },
    { label: "Úterý", value: 2 },
    { label: "Středa", value: 3 },
    { label: "Čtvrtek", value: 4 },
    { label: "Pátek", value: 5 },
  ];

  return (
    <Page title="Plnění úkolů">
      {!mobile ? (
        <Accordion defaultValue={new Date().getDay().toString()} variant="separated" radius="md">
          {days.map((day) => (
            <Accordion.Item key={day.value} value={day.value.toString()}>
              <Accordion.Control disabled={loading}>
                <Text>{day.label}</Text>
              </Accordion.Control>
              <Accordion.Panel>
                <div
                  className={classNames("rounded-lg border-2 border-solid", {
                    "border-dark-400": mode === "dark",
                    "border-gray-600": mode === "light",
                  })}>
                  <ScrollArea>
                    <Table
                      striped
                      highlightOnHover
                      captionSide="bottom"
                      className={classNames(
                        "max-w-full rounded-lg",
                        styles.Table,
                        teamQuests?.length === 0 && styles.Empty
                      )}>
                      <Table.Thead className="text-nowrap border-b-2">
                        <Table.Tr>
                          <Table.Th>Quest</Table.Th>
                          {teamQuests &&
                            teamQuests.map((teamQuest, index) => (
                              <Table.Th key={index}>{teamQuest.team.shortcut}</Table.Th>
                            ))}
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody>
                        <>
                          {rows[day.value]}

                          <Table.Tr
                            className={
                              mode === "dark"
                                ? rowsLen.current[day.value] !== 0
                                  ? rowsLen.current[day.value] % 2 === 0
                                    ? "hover:bg-[#191A24]"
                                    : "hover:bg-[#08090C]"
                                  : "hover:bg-[#08090C]"
                                : rowsLen.current[day.value] !== 0
                                ? rowsLen.current[day.value] % 2 === 0
                                  ? "hover:bg-[#F8F9FA]"
                                  : "hover:bg-[#F1F3F5]"
                                : "hover:bg-[#F1F3F5]"
                            }>
                            <Table.Td className="rounded-bl-[6px]">Body</Table.Td>
                            {teamQuests &&
                              teamQuests.map((teamQuest, index) => (
                                <Table.Td
                                  key={teamQuest.team.id}
                                  className={index === teamQuests.length - 1 ? "rounded-br-[6px]" : ""}>
                                  <Text>{`${teamQuest.done[day.value]}/${teamQuest.max[day.value]}`}</Text>
                                </Table.Td>
                              ))}
                          </Table.Tr>
                        </>
                      </Table.Tbody>
                    </Table>
                  </ScrollArea>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      ) : (
        <QuestsCard
          teamQuests={teamQuests}
          camps={camps.current}
          setShouldFetch={setShouldFetch}
          mode={mode}
          setChanged={setChanged}
          changed={changed}
        />
      )}
    </Page>
  );
};

export default PcQuestsFillPage;
